import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {isPendingAction , isRejectedAction , isFulfilledAction} from './slice.helper';
import Api from '../services/LivService';

const initialState = {
    loading: false,
    error: '',
    data: null,
}

export const getRsTask = createAsyncThunk(
    "liv/getRsTask",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.getRsTask(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getPickupTask = createAsyncThunk(
    "liv/getPickupTask",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.getPickupTask(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const selfAfectPick = createAsyncThunk(
    "liv/selfAfectPick",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.selfAfectPick(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const pickMuColis = createAsyncThunk(
    "liv/pickMuColis",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.pickMuColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const updateColis = createAsyncThunk(
    "liv/updateColis",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.updateColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


const livSlice = createSlice({
    name: "liv",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
        },

    },
    extraReducers: builder => {
        builder

            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
                state.data = action.payload;
            })






    }
})

const { reducer } = livSlice;
export const { clear } = livSlice.actions;
export default reducer;