import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class FactureService {

    listExpedFacture(data){
        let token = storage.getToken();
        return axios.post(`${api_url}/api/bill/exped/list`, data , {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    getExpedFactureByCode(code){
        let token = storage.getToken();
        return axios.get(`${api_url}/api/bill/exped/getbycode/${code}`, {
            headers: {
                'cgo-tkn': token
            }
        })
    }



//Adm servces --------------------------------------------------------------------------------------------------------------


    getAdmDettes(){
        let token = storage.getToken();
        return axios.get(`${api_url}/api/bill/adm/dettes`, {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    getAdmDettesByClient(id_exped){
        let token = storage.getToken();
        return axios.get(`${api_url}/api/bill/adm/dettes/client/${id_exped}`, {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    createAdmFacture(data){
        let token = storage.getToken();
        return axios.post(`${api_url}/api/bill/adm/create`,data , {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    cancelAdmFacture(data){
        let token = storage.getToken();
        return axios.post(`${api_url}/api/bill/adm/cancel`,data , {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    getAdmFactureByCode(code){
        let token = storage.getToken();
        return axios.get(`${api_url}/api/bill/adm/view/${code}`, {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    listAdmFacture(data){
        let token = storage.getToken();
        return axios.post(`${api_url}/api/bill/adm/list`, data , {
            headers: {
                'cgo-tkn': token
            }
        })
    }


}

export default new FactureService();