export const  routes = {
    home_public :"/",
    login :"/login",
    register :"/register",
    dashb : "/dashb",
    mesColis : "/dashb/colis/mescolis",
    colis_create: "/dashb/colis/create",
    colis_create_multiple: "/dashb/colis/create/many",
    colis_edit :"/dashb/colis/edit/",
    colis_print :"/dashb/colis/print/",
    mu_create :"/dashb/mu/create",
    mu_edit :"/dashb/mu/edit/",
    mu_list :"/dashb/mu/mesmu",
    mu_print : "/dashb/mu/print",
    mes_bill : "/dashb/bill/mesbill",
    mes_bill_print : "/dashb/bill/mesbill/",
    mu_adm_depo : "/dashb/mu/depot/",
    mu_adm_list_rcv : "/dashb/mu/receive",
    mu_adm_list_reco : "/dashb/mu/reconcile",
    rs_adm_create : "/dashb/rs/create",
    rs_adm_edit : "/dashb/rs/edit/",
    rs_adm_pd : "/dashb/rs/pending",
    rs_adm_reco : "/dashb/rs/reconcile",
    rs_adm_details : "/dashb/rs/reconcile/",
    rs_adm_print : "/dashb/rs/print",
    bill_adm_invoice : "/dashb/invoice",
    bill_adm_list : "/dashb/bill",

}



