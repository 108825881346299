import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {isPendingAction , isRejectedAction , isFulfilledAction} from './slice.helper';
import FactureService from '../services/FactureService';

const initialState = {
    loading: false,
    error: '',
    done: false,
    item: null,
    item_list: [],
    selected_item: null,
    search_colis : [],
    item_list_src : [],
};


export const listExpedFacture = createAsyncThunk(
    "bill/listExpedFacture",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.listExpedFacture(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getExpedFactureByCode = createAsyncThunk(
    "bill/getExpedFactureByCode",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.getExpedFactureByCode(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)







const muSlice = createSlice({
    name: "bill",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.item = null;
            state.done = false;
            state.item_list = [];
            state.item_list_src = [];
            state.selected_item = null ;
            state.search_colis = []
        },

    },
    extraReducers: builder => {
        builder

            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
                state.data = action.payload;
            })

    }
})

const { reducer } = muSlice;
export const { clear } = muSlice.actions;
export default reducer;