import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class ColisService {

    //Exped Services----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------
    loadFrai() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/expd/getfrai`, {}, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    createColis(colis) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/colis/exped/create`, colis, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    editColis(colis) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/colis/exped/update`, colis, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    deleteColis(data) {
        let token = storage.getToken();
        if(data)
        return axios.post(`${api_url}/api/colis/exped/del`, { ref: data.ref }, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    getClientColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/colis/exped/mescolis`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getColisByCode(data) {
        let token = storage.getToken();
        if(data)
        return axios.post(`${api_url}/api/colis/exped/mescolis/getbycode`, { code: data }, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getClientColisHistory(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/colis/exped/mescolis/history`, data ,{
            headers: {
                'cgo-tkn': token
            }
        })
    }

    searchClientColis(search) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/colis/mescolis/search/${search}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    searchClientColisAll(search) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/colis/mescolis/searchall/${search}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getClientColisArchive() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/expd/archivepackagesList`, {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    //Adm Services----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------


    getAdmColisList(data) {
        let token = storage.getToken();
        if(data)
        return axios.post(`${api_url}/api/colis/adm/list`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getOneColisByFilter(data) {
        let token = storage.getToken();
        if(data)
        return axios.post(`${api_url}/api/colis/adm/view/filter`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getColisByCodeAdm(code) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/colis/adm/details/${code}` , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    // getAdmColisStatList(data) {
    //     let token = storage.getToken();
    //     if(data)
    //     return axios.post(`${api_url}/api/colis/stated/list`, data , {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     });
    // }

    // searchAdmColisStatList(data) {
    //     let token = storage.getToken();
    //     if(data)
    //     return axios.post(`${api_url}/api/colis/stated/search`, data , {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     });
    // }

    // searchAdmAllColis(code) {
    //     let token = storage.getToken();
    //     return axios.get(`${api_url}/api/colis/all/search/${code}`, {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     })
    // }

    // getStatColisByCode(data) {
    //     let token = storage.getToken();
    //     return axios.post(`${api_url}/api/colis/stated/getbycode`, data , {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     })
    // }

    editColisInfo(data) {
        let token = storage.getToken();
        if(data)
        return axios.post(`${api_url}/api/colis/adm/edit`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    searchAdmGlobal(data) {
        let token = storage.getToken();
        if(data)
        return axios.post(`${api_url}/api/colis/adm/global/search`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    
}

export default new ColisService();