import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";

const ConfirmWithPassModal = (props) => {
  const [password , set_password] = useState(false)

  const onSubmit = ()=> {
    props.onOk(password)
  }

  useEffect(()=>{
    if(!props?.show)
      set_password('')
  },[props?.show])

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter " className="txt-red">
          <FontAwesomeIcon icon={["fas", "info-circle"]} /> {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <h6 className="pt-5 pb-0">Mot de passe</h6>
        <Form.Control type="password" value={password} onChange={e => set_password(e?.target?.value)} />
        <div className="col-md-12">
          {props?.error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{props?.error}</span>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props?.loading ? (
          <div class="col-md-12 p-4 txt-ctr">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Button variant="secondary" onClick={props?.onHide} disabled={props?.loading}>
              Annuler
            </Button>
            <Button variant="success" onClick={onSubmit} disabled={props?.loading || !password}>
              confirmer
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmWithPassModal;
