import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MuAdmHeader({ loading , error , lst_data , zone , onZoneChange }) {

   


    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-8 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'dollar-sign']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Dettes    </b>
                </div>

                <div className="col-md-4">
                    {/* <ZoneBox zone={zone} onZoneChange={onZoneChange} /> */}
                </div>

            </div>

            <div className="row no-marg rw-header pb-2 pt-2">

                <div className="col-md-4 offset-md-8 no-padd mb-2 ">

                    {loading &&
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    }

                   

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                   
                </div>



            </div>

        </>
    );
}

export default MuAdmHeader;