/* eslint-disable */
import React, {  useState , useEffect} from 'react';
import RegisterForm from '../../../ui/forms/Register.form';
import { registerExped , clear } from '../../../slices/User.slice';
import { useDispatch, useSelector } from "react-redux";

const Register = (props) => {

    const dispatch = useDispatch();
    const { loading , error  } = useSelector(state => state.user)

    const [register_done, set_register_done] = useState(false);

    

    const onSubmit = data => {
        dispatch(registerExped(data)).unwrap().then(res => {
            console.log(res)
            set_register_done(res?.done)
        });
    }

    const OnResendMail = () => {
        //dispatch(resendVmail());
    }

  

    useEffect(()=>{
        return ()=>{
            dispatch(clear());
        }
    },[])





    return (
        <RegisterForm onDataSubmit={onSubmit} OnResendMail={OnResendMail}  loading={loading} error={error} register_done={register_done} />
    )
}



export default Register ;



