import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import {routes} from '../../../utils/helpers/routing.helper';

const RunsheetHistoryDetails = (props) => {

    const handlePrint = () => {
        if (props.selData) {
            UserStorage.setParam(props.selData)
            const win = window.open(routes.rs_adm_print, "_blank");
            win.focus();
        }
    }


    const getDecisionforRS = (bags)=>{
        if(bags){
            let rs = bags.find(o => o.runsheetId === props.selData.id);
            if(rs)
                return rs.decision ;
                else
                return "_" ;
        }else{
            return "_" ;
        }

    }


    return (
        <div className={props.showP ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" onClick={() => props.setShowP(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>

            {props.selData &&
                <div className="row no-marg bg-wt p-4">

                    <div className="col-md-12">
                        <h4>Runsheet <span className="txt-blue">{props.selData.ref}</span> </h4>
                        <br />

                        <button className="btn btn-outline-success ml-4" onClick={handlePrint}><FontAwesomeIcon icon={['fas', 'print']} /> Imprimer fiche</button>

                        <br /> <br />
                    </div>

                    <div className="col-md-12">
                        <h4 className="txt-blue">Liste des colis :</h4>

                        <table className="table table-striped table-bordered fnt-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Colis</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Montant TTC</th>
                                    <th scope="col">Adresse</th>
                                    <th scope="col">Décision</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.selData && props.selData.colis.map((item) =>
                                    <tr>
                                        <td className="p-1">{item.code}</td>
                                        <td className="p-1">{item.nom_cl} {item.prenom_cl}</td>
                                        <td className="p-1">{item.montant_ttc}</td>
                                        <td className="p-1">{item.adresse}</td>
                                        <td className="p-1">{getDecisionforRS(item.runsheetBags)}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>

                    </div>

                </div>
            }



        </div>
    )
}

export default RunsheetHistoryDetails;