import React, { useState, useEffect } from 'react';
import UserStorage from '../../../services/UserStorage';
import Form from 'react-bootstrap/Form'

import { listHub , selectHub , setCurrentHub } from "../../../slices/Hub.slice";
import { checkUser } from "../../../slices/AccessCheck";
import { useDispatch, useSelector } from 'react-redux';

const HubBox = ({isReview }) => {

    const dispatch = useDispatch();
    const { loading : loading_hub , error : error_hub , current_hub } = useSelector((state) => state.hub);
    const { loading : loading_access , error : error_access } = useSelector((state) => state.accessCheck);

    const [lst_hub, set_lst_hub] = useState([]);
    const [sel_hub, set_sel_hub] = useState(null);
    const [logged_user, set_logged_user] = useState(null);

    //const [curr_zone, set_curr_Zone] = useState(UserStorage.getZone());
    //const [last_zone, set_last_Zone] = useState(UserStorage.getLastZone());
    //const [allow, set_allow] = useState(false);

    // const zoneChange = (e) => {
    //     onZoneChange(e.target.value)
    //     UserStorage.setLastZone(e.target.value);
    // }
    const onHubChange = (e) => {
        if(e.target.value != current_hub)
            onSelectHub(e.target.value)
    }

    const load_data_hub = () => {
    
        dispatch(listHub())
          .unwrap()
          .then((res) => {
            set_lst_hub(Array.isArray(res) ? res : []);
          })
          .catch((err) => console.log(err));
    };

    const load_data_user = () => {
    
        dispatch(checkUser())
          .unwrap()
          .then((res) => {
              set_logged_user(res)
              dispatch(setCurrentHub(res?.hub?.id))
                load_data_hub()
          })
          .catch((err) => console.log(err));
    };

    const onSelectHub = (value) => {
    
        dispatch(selectHub(value))
          .unwrap()
          .then((res) => {
            if(res?.done && res?.token){
                UserStorage.setToken(res?.token)
                dispatch(setCurrentHub(value))
            }
          })
          .catch((err) => console.log(err));
    };

    useEffect(()=>{
        load_data_user()
    },[])

    // useEffect(() => {

    //     if (zone == "null" || !curr_zone) {
    //         set_allow(true);
    //     } else {
    //         set_allow(false);
    //     }

    //     if (zone == "null" || !zone) {
    //         //UserProfile.setZone(ZONE.grand_tunis);
    //         if (last_zone)
    //             onZoneChange(last_zone);
    //         else
    //             onZoneChange(ZONE.grand_tunis);
    //     }

    // }, [])



    return (
        <div className="row p-2 zone-box no-marg">
            <div className="col-md-4 pt-1">
                <b>Hub</b>
            </div>

            <div className="col-md-8">

                {logged_user?.role == "root" ?
                    <Form.Control as="select" className="form-control force-apparence form-control" disabled={loading_hub || loading_access} style={{ borderRadius: 0, color: "#000", fontWeight: 500 }} size="sm" value={current_hub} onChange={onHubChange} >
                        <option disabled>Choisissez un Hub </option>
                        {isReview && <option value="all">Global </option>}
                        {Array.isArray(lst_hub) && lst_hub.map(el => 
                            <option key={`opt-hub-${el?.id}`} value={el?.id}>{el?.nom}</option>
                        )}

                    </Form.Control>
                    :
                    <Form.Control as="select" disabled style={{ borderRadius: 0, color: "#000", fontWeight: 500 }} size="sm" value={current_hub} >
                        <option value={logged_user?.hub?.id}>{logged_user?.hub?.nom || "Aucune" }</option>
                    </Form.Control>
                }

            </div>
        </div>
    )

}

export default HubBox;