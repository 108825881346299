import React, { useEffect, useState } from "react";
import { clear, getRsTask, updateColis } from "../../../slices/Liv.slice";
import { loadLoggedUser } from "../../../slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import { ColisStatus, ColisStatusBg, colisStatusLabels } from "../../../utils/constants/Colis.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

const RunsheetLivContainer = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.liv);
  const { loggedUser } = useSelector((state) => state.auth);

  const [runsheet, set_runsheet] = useState(null);
  const [show_details, set_show_details] = useState(false);
  const [sel_colis, set_sel_colis] = useState(null);

  const [nbr_colis_livre, set_nbr_colis_livre] = useState(0);
  const [nbr_colis_reporte, set_nbr_colis_reporte] = useState(0);
  const [nbr_colis_annuler, set_nbr_colis_annuler] = useState(0);
  const [nbr_colis_cours, set_nbr_colis_cours] = useState(0);

  const [montant_collecte, set_montant_collecte] = useState(0.0);

  const load_runsheet = () => {
    dispatch(getRsTask())
      .unwrap()
      .then((res) => {
        set_runsheet(res?.rs);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const onViewColis = (colis) => {
    set_sel_colis(colis);
    set_show_details(true);
  };

  const onCloseDetails = () => {
    set_sel_colis(null);
    set_show_details(false);
  };

  useEffect(() => {
    dispatch(loadLoggedUser());
    load_runsheet();
  }, []);

  useEffect(() => {
    if (Array.isArray(runsheet?.colis)) {
      set_nbr_colis_livre(runsheet?.colis?.filter((el) => el?.etat == ColisStatus["LIV-COURS-SUCC"])?.length);
      set_nbr_colis_reporte(runsheet?.colis?.filter((el) => el?.etat == ColisStatus["LIV-COURS-REPO"])?.length);
      set_nbr_colis_annuler(runsheet?.colis?.filter((el) => el?.etat == ColisStatus["LIV-COURS-ECHEC"])?.length);
      set_nbr_colis_cours(runsheet?.colis?.filter((el) => el?.etat == ColisStatus["LIV-COURS"])?.length);

      set_montant_collecte(runsheet?.colis?.filter((el) => el?.etat == ColisStatus["LIV-COURS-SUCC"])?.reduce((acc, curr) => +acc + +curr?.montant_ttc, 0));
    
      if(sel_colis?.ref)
        set_sel_colis(runsheet?.colis?.find(el => el?.ref == sel_colis?.ref))
    }
  }, [runsheet?.colis]);

  return (
    <div className="row m-0">
      <div className="col-12 text-center">
        <Card className="mt-2">
          <Card.Body>
            <div className="row m-0">
              <div className="col-6">
                <b className="d-block fnt-sm fnt-w5">Runsheet</b>
                <h6 style={{ marginTop: -4 }}>{runsheet?.ref}</h6>
              </div>

              <div className="col-6 brd-l-1">
                <h6>{Number(+montant_collecte).toFixed(3)} TND</h6>
                <b className="d-block text-success fnt-sm fnt-w5" style={{marginTop : -8}}>Montant collecté</b>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Body>
            <div className="row m-0">
              <div className="col-3">
                <h4>{nbr_colis_livre}</h4>
                <b className="d-block text-success fnt-sm">Livré</b>
              </div>

              <div className="col-3 brd-l-1">
                <h4>{nbr_colis_reporte}</h4>
                <b className="d-block text-warning fnt-sm">Reporté</b>
              </div>

              <div className="col-3 brd-l-1">
                <h4>{nbr_colis_annuler}</h4>
                <b className="d-block text-danger fnt-sm">Annuler</b>
              </div>
              <div className="col-3 brd-l-1">
                <h4>{nbr_colis_cours}</h4>
                <b className="d-block text-info fnt-sm">En-cours</b>
              </div>
            </div>
          </Card.Body>
        </Card>

        <div className="row m-0">
          {Array.isArray(runsheet?.colis) && runsheet?.colis?.map(el => <ColisItem colis={el} onViewDetails={onViewColis} />)}
        </div>

        <ColisDetails colis={sel_colis} show={show_details} onClose={onCloseDetails} load_data={load_runsheet} />


      </div>
    </div>
  );
};


const ColisItem = ({colis , onViewDetails}) => {
  return(
    <Card className="mt-1 p-0" style={{cursor : 'pointer'}} onClick={e => onViewDetails(colis)}>
      <Card.Body className="p-1">
         <div className="row m-0">
            <div className="col-auto text-center pt-3">
                <span className={`d-inline-block p-1 ${ColisStatusBg?.[colis?.etat]}`} style={{color : 'white', width : 32, height :32, borderRadius : '100%'}}>
                    <FontAwesomeIcon icon={['fas', 'box']} />
                </span>
            </div>

            <div className="col-10 brd-l-1 text-start pe-1">
              <h6 className="fnt-w6 m-0">{colis?.code}
              <span className="float-end" style={{ clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", width: 24, height: 24, background: "#263238", display: "inline-block" }}></span>

              </h6>
              <h6 className="fnt-w5 fnt-sm text-muted" style={{marginTop : -2}}>{`${colis?.nom_cl} `}</h6>
              <h6 className="fnt-w5 fnt-sm m-0 dvdr-t-gray pt-1"><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /> {`${colis?.adresse}`}</h6>
              <h6 className="fnt-w5 fnt-sm "><FontAwesomeIcon icon={['fas', 'map-marker-alt']} style={{opacity :0}} /> {`${colis?.adresse_lieu}`}</h6>
              <h6 className="fnt-w5 fnt-sm text-success" style={{marginTop : 0}}><FontAwesomeIcon icon={['fas', 'mobile']} /> {`${colis?.tel_cl}`} <span className="float-end">{colis?.montant_ttc} TND</span></h6>
            </div>
         </div>
      </Card.Body>
    </Card>
  )
}

const ColisDetails = ({ colis, show, onClose, load_data }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.liv);
  const [show_date_form, set_show_date_form] = useState(false);
  const [sel_date, set_sel_date] = useState(null);

  const onUpdateColis = (status) => {
    if (colis?.ref && status) {
      let data = { ref: colis?.ref, etat: status };
      if (status == ColisStatus["LIV-COURS-REPO"] && sel_date) {
        data.date_p = sel_date;
      }

      dispatch(updateColis(data))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            load_data();
            set_show_date_form(false)
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onSubmitColis = ()=>{
    if(colis && sel_date)
      onUpdateColis(ColisStatus["LIV-COURS-REPO"])
  }

  return (
    <div className={show ? "col-12 bg-gray inf_box_pop_r" : "col-12 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row p-0 m-0 bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="col-12  p-2 bg-wt ">
        <div className="row m-0">
            <div className="col-auto text-center pt-1">
                <span className={`d-inline-block p-1 ${ColisStatusBg?.[colis?.etat]}`} style={{color : 'white', width : 32, height :32, borderRadius : '100%'}}>
                    <FontAwesomeIcon icon={['fas', 'box']} />
                </span>
            </div>
            <div className="col-10 brd-l-1 text-start pe-1">
              <h6 className="fnt-w6 m-0">{colis?.code}</h6>
              <h6 className="fnt-w5 fnt-sm text-muted" style={{marginTop : -2}}>{`${colis?.nom_cl} ${colis?.prenom_cl}`}</h6>
            </div>
        </div>
        <div className="row m-0">
            <div className="col-12  text-start pe-1">
              <h6 className="fnt-w5 fnt-sm m-0 dvdr-t-gray pt-2"><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /> {`${colis?.adresse}`}</h6>
              <h6 className="fnt-w5 fnt-sm pb-2"><FontAwesomeIcon icon={['fas', 'map-marker-alt']} style={{opacity :0}} /> {`${colis?.adresse_gov}, ${colis?.adresse_deleg}`}</h6>
              <span className="float-end mt-1"><a className="btn btn-sm btn-success fnt-sm px-4" href={`tel:${colis?.tel_cl}`}>Appeler</a></span>
              <h6 className="fnt-w5  text-success dvdr-t-gray py-2" style={{marginTop : 0}}><FontAwesomeIcon icon={['fas', 'mobile']} /> {`${colis?.tel_cl}`} </h6>
              <h5 className="fnt-w5  text-center dvdr-t-gray py-2" style={{marginTop : 0}}> {`${colis?.montant_ttc} TND`} </h5>
            </div>
         </div>

         {colis?.etat == ColisStatus["LIV-COURS"] ? 
          <>
            {show_date_form ?
              <div className="row m-0">
                  <div className="col-12">
                    <b className="fnt-w5 fnt-sm">Date de report</b>
                    <input className="form-control" type="date" required value={sel_date} onChange={e => set_sel_date(e?.target?.value)} />
                  </div>
                  <div className="col-12 text-end pt-1">
                    <button className="btn btn-sm btn-secondary me-1" onClick={e => set_show_date_form(false)}>Fermer</button>
                    <button className="btn btn-sm btn-success" onClick={onSubmitColis}>Confirmer</button>
                  </div>
              </div>
              :
              <div className="row m-0">
                <div className="col-4">
                  <button className="btn btn-sm btn-success w-100" onClick={e => onUpdateColis(ColisStatus["LIV-COURS-SUCC"])}> Livré</button>
                </div>
                <div className="col-4">
                  <button className="btn btn-sm btn-warning w-100" onClick={e => set_show_date_form(true)}> Reporté</button>
                </div>
                <div className="col-4">
                  <button className="btn btn-sm btn-danger w-100" onClick={e => onUpdateColis(ColisStatus["LIV-COURS-ECHEC"])}> Annuler</button>
                </div>
            </div>
            }
         </>
         :
         <div className="row m-0">
            <div className="col-12 text-center">
              <span className={`badge badge-sm ${ColisStatusBg?.[colis?.etat]}`}>{colisStatusLabels?.[colis?.etat]}</span>
            </div>
         </div>
          }
      </div>
    </div>
  );
};

export default RunsheetLivContainer;
