import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { stat as colisStat } from '../../utils/constants/Colis.enum';

function BillCreatorHeader({ loading, client, error, onSubmit , frais_liv , setFrais_liv , frais_retour , setFrais_retour , montant_colis , montant_pay  , totMontantLiv  , totMontantRetour , montant_cheque , error_colis, frais_poids_supp, set_frais_poids_supp, montant_poids_supp, frais_timbre_fiscal  }) {


    const [err_frais_liv, set_err_frais_liv] = useState("");
    const [err_frais_retour, set_err_frais_retour] = useState("");
    const [err_frais_poids_supp, set_err_frais_poids_supp] = useState("");

    const onFraiLivChange = (p) => {
        let rgxd = /^\d+(\.\d{1,3})?$/;
        setFrais_liv(p);
        if (!rgxd.test(p)) {
            set_err_frais_liv("valeur invalide ! ");
        } else {
            set_err_frais_liv("");
        }
    }

    const onFraiRetourChange = (p) => {
        let rgxd = /^\d+(\.\d{1,3})?$/;
        setFrais_retour(p);
        if (!rgxd.test(p)) {
            set_err_frais_retour("valeur invalide ! ");
        } else {
            set_err_frais_retour("");
        }
    }

    const onFraiPoidsChange = (p) => {
        let rgxd = /^\d+(\.\d{1,3})?$/;
        set_frais_poids_supp(p);
        if (!rgxd.test(p)) {
            set_err_frais_poids_supp("valeur invalide ! ");
        } else {
            set_err_frais_poids_supp("");
        }
    }

  
    const onCreate = () => {
        onSubmit(frais_liv, frais_retour);
    }


    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-8 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'cash-register']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Bill / {client && client.nom_com} </b>
                </div>

            </div>

            <div className="row no-marg rw-header ">

                <div className="col-md-10 offset-md-1">

                    <div className="row no-marg">
                        <Form.Group as={Col} controlId="formGridState" className="no-marg">
                            <Form.Label className="no-marg small-label" >Frais livraison (en TND) </Form.Label>
                            <Form.Control type="text" size="sm" name="frailiv" value={frais_liv} onChange={e => onFraiLivChange(e.target.value)} />
                            <Form.Text className="text-muted txt-red">
                                {err_frais_liv && err_frais_liv}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState" className="no-marg">
                            <Form.Label className="no-marg small-label" >Frais retour (en TND) </Form.Label>
                            <Form.Control type="text" size="sm" name="frairetour" value={frais_retour} onChange={e => onFraiRetourChange(e.target.value)} />
                            <Form.Text className="text-muted txt-red">
                                {err_frais_retour && err_frais_retour}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState" className="no-marg">
                            <Form.Label className="no-marg small-label" >Frais Poids Supp (en TND/KG) </Form.Label>
                            <Form.Control type="text" size="sm" name="frais_poids_supp" value={frais_poids_supp} onChange={e => onFraiPoidsChange(e.target.value)} />
                            <Form.Text className="text-muted txt-red">
                                {err_frais_poids_supp && err_frais_poids_supp}
                            </Form.Text>
                        </Form.Group>
                    </div>


                </div>


                <div className="row m-0 p-2">
            

                    <div className="col-md-12 p-1 green_box">

                        <div className="row">
                            <div className="col-md-2 dvdr-r-gray">
                                <b className="fnt-w5 fnt-sm">Total Montant Colis:</b> <br />
                                <b className="item_box_money">{montant_colis} <span>TND</span> </b>
                            </div>

                            <div className="col-md-2 dvdr-r-gray">
                                <b className="fnt-w5 fnt-sm">Total Frais (Livraison/Retour):</b> <br />
                                <b className="item_box_money">{(+totMontantLiv + +totMontantRetour)} <span>TND</span> </b>
                            </div>
                            <div className="col-md-2 dvdr-r-gray">
                                <b className="fnt-w5 fnt-sm">Total Frais Poids Supp:</b> <br />
                                <b className="item_box_money">{montant_poids_supp} <span>TND</span> </b>
                            </div>

                            <div className="col-md-2 dvdr-r-gray">
                                <b className="fnt-w5 fnt-sm">Montant en chéques:</b> <br />
                                <b className="item_box_money">{+montant_cheque} <span>TND</span> </b>
                            </div>

                            <div className="col-md-2 dvdr-r-gray">
                                <b className="fnt-w5 fnt-sm">Frais Timbre Fiscal:</b> <br />
                                <b className="item_box_money">{frais_timbre_fiscal} <span>TND</span> </b>
                            </div>

                            <div className="col-md-2 ">
                                <b className="fnt-w5 fnt-sm"> Net à payer:</b> <br />
                                <b className="item_box_money">{montant_pay} <span>TND</span> </b>
                            </div>
                        </div>

                    </div>


                    <div className="col-md-12 text-end pt-4">
                        {loading ?
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            :
                            <button className="btn btn-outline-success" onClick={onCreate} > Créer facture </button>
                        }
                    </div>

                    <div className="col-md-12">
                        {error &&

                            <div className="alert alert-danger" role="alert">
                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                            </div>
                        }
                        {error_colis &&

                            <div className="alert alert-danger mt-1" role="alert">
                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error_colis}</span>
                            </div>
                        }

                    </div>

                </div>






            </div>

        </>
    );
}

export default BillCreatorHeader;