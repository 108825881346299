import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { stat as colisStat } from '../../utils/constants/Colis.enum';
import { useHistory } from "react-router-dom";
import { routes } from '../../utils/helpers/routing.helper';
import {stat as Mv_state } from '../../utils/constants/Movable.enum';

function MuClientHeader({ onFilter, loading, lst_data,error }) {

    const [filter_values, set_filter_values] = useState({});
    const history = useHistory()

    const searchOnchange = e => {
        set_filter_values({...filter_values, search : e.target.value})
    }

    const onSearchClick = e => {
        if(filter_values){
            let filter_data = {}
            if(filter_values?.search)
                filter_data.search = filter_values?.search

            if(filter_values?.etat && filter_values?.etat != "all")
                filter_data.etat = filter_values?.etat

            onFilter(filter_data)
        }
    }

    const onFilterChange = e => {
        set_filter_values({...filter_values, etat : e.target.value})
    }

    const onClearFilter = e => {
        set_filter_values({etat : 'all', search:''})
        onFilter({})
    }

    const gotoCreate = () => {
        history.push(routes.mu_create)
    }


    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'shipping-fast']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Transfert   </b>
                </div>

            </div>

            <div className="row no-marg rw-header p-2">

                <Form.Group className="col-md-4 px-2">
                    <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
                    <Form.Control type="search" className="form-control inpt_search shadow-none" style={{borderRadius : '.2rem'}} placeholder="Code/Client ..." value={filter_values?.search} onChange={searchOnchange} />
                </Form.Group>

                <Form.Group className="col-md-4 px-2">
                    <Form.Label className="fnt-sm fnt-w5 m-0">Status</Form.Label>
                    <Form.Control as="select" defaultValue="Choose..." size="sm" value={filter_values?.etat} onChange={onFilterChange} className="force-apparence" >

                                <option value="all">Tous</option>
                                <option value={Mv_state.PENDING}>A confirmer le transfert</option>
                                <option value={Mv_state.PENDING_PICK}>En attente de ramassage</option>
                                <option value={Mv_state.IN_PROGRESS}>En cours de ramassage</option>
                                <option value={Mv_state.COMPLETED}>Terminé</option>

                    </Form.Control>
                </Form.Group>

                <Form.Group className="col-md-3 px-2 pt-4">
                    <button className="btn btn_search m-0" onClick={onSearchClick}>
                    <FontAwesomeIcon icon={["fas", "search"]} />
                    </button>

                    <button className="btn btn_search bg-success m-0 ms-1" onClick={onSearchClick}>
                    <FontAwesomeIcon icon={["fas", "sync-alt"]} />
                    </button>

                    <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
                    <FontAwesomeIcon icon={["fas", "times"]} />
                    </button>
                </Form.Group>



                <div className="col-md-6  fnt-largr">

                    <div className="row no-marg ">

                        <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
                            <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">unité(s)</b>
                        </div>


                    </div>


                </div>

                <div className="col-md-6 px-0 mb-2 text-end">

                    {loading ?
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                            <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={gotoCreate}>
                                Créer une unité mobile
                            </button>
                            
                        </>
                    }

                    {/* <span className="txt-red pl-2">{transfer_err}</span> */}

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                   
                </div>



            </div>

        </>
    );
}

export default MuClientHeader;