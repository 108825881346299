import React from "react";
import Item from "../items/ColisDepot.item";
import { Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from "../../utils/helpers/date.helper";

const ColisList = ({ loading, lst_data, onItemDetailsClick, onItemSelected, onCheckAll }) => {
  return (
    <div className="col-md-12 bg-wt mt-2">
      {loading ? (
        <div class="col-md-12 p-4 txt-ctr">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        Array.isArray(lst_data) && (
          //lst_data.map((s, index) => <Item key={`colis_.${index}`} data={s} onItemDetailsClick={onItemDetailsClick} onSelect={onItemSelected} />)
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>
                  <Form.Group id="formGridCheckbox" className="no-marg">
                    <Form.Check type="checkbox" className="chek_size_lg" id="chk" onChange={(e) => onCheckAll(e.target.checked)} />
                  </Form.Group>
                </th>
                <th>Code</th>
                <th>Tentative</th>
                <th>Expéditeur</th>
                <th>Adresse</th>
                <th>Date Colis</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(lst_data) &&
                lst_data.map((el) => (
                  <tr style={{ background: `${el?.checked ? "#37ba7740" : "#dd475540"}` }}>
                    <td>
                      <Form.Group id="formGridCheckbox" className="ms-2">
                        <Form.Check type="checkbox" className="chek_size_lg" id="chk" checked={el.checked} onChange={(e) => onItemSelected(el?.ref, e.target.checked)} />
                      </Form.Group>
                    </td>
                    <td className="fnt-w5">
                      <span>
                        <FontAwesomeIcon icon={["fas", "barcode"]} />{" "}
                      </span>
                      <span className="fnt-w5 txt_blk">{el?.code}</span>
                    </td>

                    <td>
                      <span class={el?.attempt < 3 ? "badge badge-pill bg-success txt_wt" : "badge badge-pill bg-danger txt_wt"}>{el?.attempt}/3</span>
                    </td>

                    <td>{el?.user?.nom_com}</td>

                    <td className="text-capitalize">{`${el?.adresse} (${el?.adresse_gov}/${el?.adresse_deleg})`}</td>

                    <td>{el?.createdAt ? getStringDate(el.createdAt) : "---"}</td>
                    <td>
                        <button className="btn btn-sm btn-dark me-1" disabled={loading} onClick={(e) => onItemDetailsClick(el)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} /> Fiche
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )
      )}
    </div>
  );
};

export default ColisList;
