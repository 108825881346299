import React, { useEffect, useState } from "react"; 
import ColisAdmDetails from "../../../ui/layouts/ColisAdmDetails";
import UserStorage from "../../../services/UserStorage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { editColisInfo, getColisByCodeAdm } from "../../../slices/ColisAdm";
import { syncAfexColis } from "../../../slices/Afex.slice";
import ConfirmModal from "../../../ui/modals/ConfirmModal";

const ColisAdmDetailsContainer = ({ show, onClose, sel_colis, load_data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector((state) => state.colis);
  const [colis, set_colis] = useState(null);

  const [show_cancel_afex_mod, set_show_cancel_afex_mod] = useState(false);

  const load_colis = () => {
    if(sel_colis?.code)
    dispatch(getColisByCodeAdm(sel_colis?.code))
      .unwrap()
      .then((res) => {
        set_colis(res);
      })
      .catch((err) => console.log(err));
  };

  const onPrint = () => {
    if (colis) {
        UserStorage.setParam([colis])
        const win = window.open("/dashb/colis/print/", "_blank");
        win.focus();
    }
  };

  const onEditField = (field , value) => {
    dispatch(editColisInfo({id : colis?.ref , field : field , f_data : value })).unwrap().then(d =>  {
        load_colis()
        load_data()
    });
}

const onCancelAfex = ()=>{
  if(colis?.is_afex)
  set_show_cancel_afex_mod(true)
}
const onCancelAfexConfirm = ()=>{
  dispatch(editColisInfo({id : colis?.ref , field : 'is_afex' , f_data : false })).unwrap().then(d =>  {
    set_show_cancel_afex_mod(false)
    load_colis()
    load_data()
    onClose()
});
}

const onSyncAfexStatus = ()=> {
  if(colis?.code)
    dispatch(syncAfexColis(colis?.code)).unwrap().then(d =>  {
      load_colis()
      load_data()
  });
}

  useEffect(() => {
    load_colis();
  }, [sel_colis]);

  return (
    <>
      <ColisAdmDetails data={colis} isShow={show} onHide={onClose} onPrint={onPrint}  onEditField={onEditField} loading={loading} onCancelAfex={onCancelAfex} onSyncAfexStatus={onSyncAfexStatus} />
      <ConfirmModal onHide={e => set_show_cancel_afex_mod(false)} onOk={onCancelAfexConfirm} title={"Annulation gestion d'afex"} msg={`Pouvez-vous confirmer cette opération (opération non réversible) ?`} show={show_cancel_afex_mod} loading={loading} />

    </>
  );
};

export default ColisAdmDetailsContainer;
