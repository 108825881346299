/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../assets/img/logo.svg'
import logo_wt from '../../assets/img/logo.png'
import bg from '../../assets/img/home_banner.png'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { routes } from '../../utils/helpers/routing.helper';
import { useHistory } from "react-router-dom";

const Login = ({ onDataSubmit, loading, error }) => {

    const history = useHistory();

    const goHome = () => {
        history.push(routes.home_public)
    }

    const goRegister = () => {
        history.push(routes.register)
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Vous devez saisir votre E-mail !')
            .email('Email non valid !'),
        password: Yup.string()
            .min(6, 'Mot de passe non valid !')
            .required('Vous devez saisir votre mot de passe !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data.email, data.password);
    }







    return (
        <div className="row no-marg login-container bg-wt">

            <div className="col-md-8 h-100">

                <div className="row no-marg">
                    <div className="col-md-2 no-padd">
                        <button className="btn btn-primary btn_back_home" onClick={goHome} ><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
                    </div>

                    <div className="col-md-8 txt-ctr no-padd">
                        {/* <img src={logo} className="scale15" style={{ filter: "opacity(70%)" }} width="200" alt="" /> */}
                    </div>
                </div>



                <div className="col-md-8 offset-md-2 " >
                    <br /> <br />
                    <h2 className="padd-10 mb-5">Connectez-vous</h2>
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="marg-10 padd-10">
                        <div className="form-group">
                            <label className="fnt-w5"><FontAwesomeIcon icon={['fas', 'envelope']} /> Email</label>
                            <input placeholder="email ..."  {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.email?.message}</p>
                        </div>

                        <div className="form-group">
                            <label className="fnt-w5"><FontAwesomeIcon icon={['fas', 'unlock-alt']} /> Mot de passe</label>
                            <input type="password" placeholder="mot de passe ..."  {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.password?.message}</p>
                        </div>

                        {/* <a href="/forget-pass" className="btn_forget_mdp pb-4">Mot de passe oublié ?</a> */}
                        <a href="#" className="btn_forget_mdp pb-4">Mot de passe oublié ?</a>
                        <div className="col-md-12 txt-ctr" >
                            {loading ?
                                <div className="col-md-12 p-4 txt-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-primary btn-login mt-4" >Se connecter</button>
                            }
                        </div>
                        <br />
                        <span className="fnt-w5">Pas encore membre ? <a href="" className="btn_forget_mdp pb-4 d-inline-block" onClick={goRegister}>S'inscrire</a></span>
                        <br /> <br />
                    </form>




                </div>

            </div>



            <div className="col-md-4 bg-green-smooth h-100" style={{ position: "relative", overflowY : 'hidden' }}>
                <img src={logo_wt} className="avatar_logo" alt="" width="50%" style={{width : '50%'}} />
                <img src={bg} className="avatar_prog" alt="" />
            </div>
        </div>
    )
}


export default Login;






