import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PackageFormItem from "./ColisFormItem";
import axios from "axios";
import UserStorage from "../../../services/UserStorage";
import { useHistory } from "react-router-dom";
import { routes } from "../../../utils/helpers/routing.helper";
import { api_url } from "../../../services/http_constant";
import { useDispatch, useSelector } from "react-redux";
import { searchLocation } from "../../../slices/Location.slice";

const steps = {
  idle: "idle",
  validate: "validate",
  ready: "ready",
  send: "send",
  loading: "loading",
  done: "done",
};

const steps_ctr = {
  idle: "idle",
  validate: "validate",
  networking: "networking",
  loading: "loading",
  done: "done",
};

const PackageMakeMultiple = ({ match }) => {
  const dispatch = useDispatch();
  const { loading, error, done } = useSelector((state) => state.colis);

  const [showP, setShowP] = useState(false);
  const [user, setUser] = useState(UserStorage.getData());
  const [token, setToken] = useState(UserStorage.getToken());

  const [succStat, setSuccStat] = useState();
  const [succMsg, setSuccMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const showModal = () => setModalShow(true);
  const closeModal = () => setModalShow(false);
  const history = useHistory();
  const [frai_liv, setFrai_liv] = useState(0.0);
  const [frai_tva, setFrai_tva] = useState(0.19);
  const [frai_timbre, setFrai_timbre] = useState(0.6);

  const [isLoading, setIsLoading] = useState(false);
  const [lst_colis, set_lst_colis] = useState([]);
  const [step, set_step] = useState(steps_ctr.idle);
  const [err_msg, set_err_msg] = useState("");

  const addItem = () => {
    if (lst_colis.length < 20) {
      set_lst_colis([
        ...lst_colis,
        {
          step: steps.idle,
          result: false,
          data: {
            nom_cl: "",
            prenom_cl: "",
            tel_cl: "",
            adresse_cl: "",
            zone_dest: "",
            montant_ht: 0.0,
            montant_ttc: 0.0,
            montant_ttc_frai: 0.0,
            marchandise: "",
          },
        },
      ]);
    } else {
      set_err_msg("Maximum 20 formulaires");
    }
  };

  const removeItem = (indx) => {
    let array = [...lst_colis];

    if (indx !== -1) {
      //setMontant(parseFloat(montant - array[indx].prix_ht));
      array.splice(indx, 1);
      set_lst_colis(array);
    }
  };

  useEffect(() => {
    if (step === steps_ctr.validate) {
      let ready_succ = lst_colis.filter((elm) => elm.step === steps.ready && elm.result === true);
      let ready_fail = lst_colis.filter((elm) => elm.step === steps.ready && elm.result === false);

      if (ready_succ.length + ready_fail.length === lst_colis.length) {
        if (ready_fail.length === 0) {
          set_step(steps_ctr.networking);
          sendData();
        } else {
          set_step(steps_ctr.idle);
          set_err_msg("Echec de validation des données , vérifiez vos données !");
        }
      }
    }
  }, [lst_colis]);

  const valid = () => {
    set_err_msg("");
    setSuccMsg("");
    set_lst_colis(lst_colis.map((item) => ({ ...item, step: steps.validate })));
    set_step(steps_ctr.validate);
  };

  const sendData = async () => {
    let succ = 0;
    let fail = 0;

    for (let index = 0; index < lst_colis.length; index++) {
      setIsLoading(true);
      try {
        let res = await axios.post(`${api_url}/api/colis/exped/create`, lst_colis[index].data, {
          headers: {
            "cgo-tkn": token,
          },
        });
        setIsLoading(false);
        console.log(res);
        if (res.data.done) {
          succ++;
        }
      } catch (error) {
        setIsLoading(false);
        fail++;
        if (error.response) {
          console.log(error.response.data.message);
        }
      }
    }
    set_err_msg("");
    setSuccMsg(succ + " colis ont été créés avec succès");
    set_lst_colis([]);
    set_step(steps_ctr.idle);
  };

  const onSubmit = () => {
    valid();
  };

  const onSearchLocation = (search) => {
    return dispatch(searchLocation(search)).unwrap();
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "box-open"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Créer des colis </b>
        </div>
      </div>
      <div className="row no-marg rw-header no-padd">
        <div className="col-md-8  fnt-largr pt-2">
          <b>{lst_colis.length}</b> <b className="fnt-w5 fnt-sm">Colis</b>
          {/* <b className="pl-4 fnt-w5"><span className="fnt-sm text-success">Total TTC inclus frais de livraison ({frai_liv} TND):</span> {montant_ttc_frai} <span className="fnt-w4 fnt-sm">TND</span>  </b> */}
        </div>
        {/* <div className="col-md-4   pt-2 ">
                    <span className="txt-red"> {err_msg && err_msg}</span>
                    <span className="txt-blue"> {succMsg && succMsg}</span>
                </div> */}

        <div className="col-md-4  mb-2 pt-2 text-end">
          {isLoading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              {lst_colis.length > 0 && (
                <button className="btn btn-success me-2" onClick={onSubmit}>
                  {" "}
                  <FontAwesomeIcon icon={["fas", "check"]} /> Crée tous
                </button>
              )}
            </>
          )}
        </div>

        <div className="col-md-12">
          {err_msg && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{err_msg}</span>
            </div>
          )}
          {succMsg && (
            <div className="alert alert-success" role="alert">
              <FontAwesomeIcon icon={["fas", "check"]} /> <span className="ps-3">{succMsg}</span>
            </div>
          )}
        </div>
      </div>

      <div className="row no-marg">
        <div className="col-md-12">
          {lst_colis.map((colis, index) => (
            <PackageFormItem
              colis={colis}
              lst_colis={lst_colis}
              set_lst_colis={set_lst_colis}
              index={index}
              key={index}
              onCancel={removeItem}
              frais_timbre={frai_timbre}
              frais_tva={frai_tva}
              frais_liv={frai_liv}
              isLoading={isLoading}
              onSearchLocation={onSearchLocation}
            />
          ))}
        </div>
      </div>

      <div className="row no-marg">
        <div className="col-md-12 text-end py-1">
          <button className="btn btn-danger me-2" onClick={addItem} disabled={isLoading}>
            {" "}
            <FontAwesomeIcon icon={["fas", "plus"]} /> Ajouter un formulaire
          </button>
        </div>
      </div>

      {/* <MessageModal message={succMsg} state={succStat} show={modalShow} onHide={closeModal} /> */}
    </>
  );
};

export default PackageMakeMultiple;
