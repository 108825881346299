import React from "react";
import Item from "../items/ColisBill.item";
import { Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColisAfexStatusBg, ColisAfexStatusLabels, ColisStatus, ColisStatusBg, colisStatusLabels} from "../../utils/constants/Colis.enum";

const ColisAdmList = ({ loading, lst_facture_bag, set_lst_facture_bag, onItemDetailsClick, frais_liv, frais_retour }) => {
  const onSelectItem = (ref, value) => {
    if (Array.isArray(lst_facture_bag)) set_lst_facture_bag(lst_facture_bag.map((el) => (el?.colis?.ref == ref ? { ...el, selected: value } : el)));
  };
  const onSelectAllItem = (value) => {
    if (Array.isArray(lst_facture_bag)) set_lst_facture_bag(lst_facture_bag.map((el) => ({ ...el, selected: value })));
  };

  return (
    <>
      {/* <div className="col-md-12 bg-wt mt-2">
        {loading ? (
          <div class="col-md-12 p-4 txt-ctr">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          Array.isArray(lst_data) &&
          lst_data.map((s, index) => <Item key={`colis_.${index}`} data={s} onItemClick={onItemDetailsClick} onItemSelect={onItemSelected} frais_liv={frais_liv} frais_retour={frais_retour} />)
        )}
      </div> */}

      <div className="row no-marg rw-header ">
        <div className="col-md-4 p-2">
          <h6 className="fnt-w7">Colis</h6>
        </div>
      </div>
      <div className="row m-0">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>
                <Form.Group id="formGridCheckbox" className="no-marg">
                  <Form.Check type="checkbox" className="chek_size_lg" id="chk" onChange={(e) => onSelectAllItem(e.target.checked)} />
                </Form.Group>
              </th>
              <th>Ref</th>
              <th>Statut</th>
              <th>Résultat</th>
              <th>Montant (TND)</th>
              <th>Frais service (TND)</th>
              <th>Frais Poids Supp(TND)</th>
              <th>Modifié le</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(lst_facture_bag) &&
              lst_facture_bag.map((el, index) => (
                <tr key={el?.ref}>
                  <td style={{textAlign : 'center'}}>
                    <Form.Group id="formGridCheckbox" className="no-marg px-2">
                      <Form.Check type="checkbox" className="chek_size_lg" id="chk" checked={el?.selected} onChange={(e) => onSelectItem(el?.colis?.ref, e.target.checked)} />
                    </Form.Group>
                  </td>
                  <td>
                    <span>
                      <FontAwesomeIcon icon={["fas", "barcode"]} />{" "}
                    </span>
                    <span className="fnt-w5 txt_blk">{el?.colis?.code}</span>
                    &nbsp;&nbsp;
                    <span class={el?.colis?.attempt < 3 ? "badge badge-pill bg-dark txt_wt" : "badge badge-pill bg-danger txt_wt"}>{el?.colis?.attempt}/3</span>
                  </td>

                  {/* <td><span class={`badge badge-pill txt-wt ${ColisStatusBg[el?.colis?.etat]}`}>{colisStatusLabels[el?.colis?.etat]} </span> </td> */}
                  {el?.colis?.is_afex ? (
                      <td>
                        <span class={`badge badge-pill  ${ColisAfexStatusBg[el?.colis?.etat_afex]}`}>{ColisAfexStatusLabels[el?.colis?.etat_afex]} (Afex) </span>
                      </td>
                    ) : (
                      <td>
                        <span class={`badge badge-pill  ${ColisStatusBg[el?.colis?.etat]}`}>{colisStatusLabels[el?.colis?.etat]} </span>
                      </td>
                    )}
                  <td><span class={`badge badge-pill txt-wt ${el?.decision == 'livre' ? 'bg-success' : el?.decision == 'retour' ? 'bg-danger' : 'bg-warning'}`}>{el?.decision} </span> </td>


                  <td className="text-success fnt-w5">{ el?.montant_colis }</td>

                  <td className="text-success fnt-w5">{el?.frais_service}</td>

                  <td className="text-success fnt-w5">{el?.decision == 'livre' ? `${Number(el?.frais_poids_supp).toFixed(3)} (${+el?.poids_supp} KG)` : '_'}</td>

                  <td className="text-danger fnt-w5">{String(el?.colis?.updatedAt).substring(0,10)}</td>

                  <td className="text-success fnt-w5">
                    <button className="box_item_btn no-marg btn-sm py-1" style={{fontSize : 'small'}} onClick={(e) => onItemDetailsClick(el?.colis)}>
                      <FontAwesomeIcon icon={["fas", "long-arrow-alt-right"]} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ColisAdmList;
