/* eslint-disable */
import React, {  useState , useEffect} from 'react';
import ColisForm from '../../../ui/forms/Colis.form';
import { clear , create } from '../../../slices/Colis';
import { searchLocation } from '../../../slices/Location.slice';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {routes} from '../../../utils/helpers/routing.helper';

const CreateColis = (props) => {

    const dispatch = useDispatch();
    const { loading , error , done  } = useSelector(state => state.colis);
    const history = useHistory()

    const onSubmit = data => {
        dispatch(create(data)).unwrap().then(data => {
            history.push(routes.mesColis)
        })
        
    }

    const onSearchLocation = search => {
        return dispatch(searchLocation(search)).unwrap()
    }

    useEffect(()=>{
        return ()=>{
            dispatch(clear());
        }
    },[])

    return (
        <ColisForm onDataSubmit={onSubmit}   loading={loading} error={error} done={done} onSearchLocation={onSearchLocation}  />
    )
}

export default CreateColis ;



