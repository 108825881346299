exports.role = {
    ROOT : 'root',
    ADM: 'admin',
    EXPD: 'exped',
    LIV: 'liv'
}

exports.UserRoleLabels = {
    root : 'Super-Admin',
    admin: 'Admin',
    exped: 'Expediteur',
    liv: 'Livreur'
}

exports.UserRoleBg = {
    root : 'bg-primary',
    admin: 'bg-info',
    exped: 'bg-success',
    liv: 'bg-warning'
}

exports.etat = {
    active: 'active',
    disabled: 'disabled',
    invalid: 'invalid'
}
exports.UserStatusLabels = {
    active: 'Actif',
    disabled: 'Désactivé',
    invalid: 'Invalide'
}

exports.UserStatusBg = {
    active: 'bg-success',
    disabled : 'bg-danger',
    invalid: 'bg-warning'
}


