const RunsheetState = {
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    CLOSED: 'closed',
}

exports.stat = RunsheetState ;

exports.RsStatusOptions = [
    {value : 'pending' , label : 'En-Attente de dispatch'},
    {value : 'in_progress' , label : 'En-Cours'},
    {value : 'completed' , label : 'Terminé'},
];