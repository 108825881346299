/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisList from '../../../ui/lists/ColisAdm.list';
import ColisAdmHeader from '../../../ui/headers/ColisAdm.header';
import ColisAdmDetails from '../../../ui/layouts/ColisAdmDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear, del, listColisAdm, selectColis, checkColis , doFilter , editColisInfo , loadExpedsList ,   } from '../../../slices/ColisAdm';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import ColisAdmDetailsContainer from './ColisAdmDetails.container';
import PaginationBox from '../../../ui/pagination/PaginationBox';


const ColisAdmListContainer = () => {

    const dispatch = useDispatch();
    const {current_hub } = useSelector((state) => state.hub);
    const { loading, error, colis_list, selected_colis , expeds_list, count } = useSelector(state => state.colis_adm);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    //Modal transfer confirm
    const [md_title_transfer, setMd_title_transfer] = useState("");
    const [md_msg_transfer, setMd_msg_transfer] = useState("");
    const [modalShow_transfer, setModalShow_transfer] = useState(false);
    const handleClose_transfer = () => setModalShow_transfer(false);

    //pagination
    const [last_filter , set_last_filter] = useState(null);
    const [page , set_page] = useState(0);


    const onItemDetailsClick = data => {
        dispatch(selectColis(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }
    const onItemSelected = (data, etat) => {
        dispatch(checkColis({ data, etat }));

    }

    const onPrint = data => {
        if (data) {
            UserStorage.setParam([data])
            const win = window.open("/dashb/colis/print/", "_blank");
            win.focus();
        }
    }

    const onPrintMany = () => {
        if (colis_list) {
            let c = colis_list.filter(elem => elem.checked == true);
            if (c.length) {
                UserStorage.setParam(c)
                const win = window.open("/dashb/colis/print/", "_blank");
                win.focus();
            }
        }
    }

    const onEdit = data => {
        if (data) {
            history.push("/dashb/colis/edit/" + data.code)
        }
    }

    const onDelete = data => {
        if (data) {
            set_mod_del_title("Supprimer colis")
            set_mod_del_msg("Voulez-vous supprimer le colis " + data.code + " ?")
            setShowDelModal(true);

        }
    }

    const doDelete = () => {
        if (selected_colis) {
            console.log(selected_colis)
            dispatch(del({ ref: selected_colis.ref })).unwrap().then(() => {
                setShowDetails(false)
                setShowDelModal(false);
                dispatch(listColisAdm());
            })
        }
    }

    const askTransfer = () => {

        let lst_c;
        lst_c = colis_list.filter((i) => i.checked === true);

        if (colis_list) {
            if (lst_c.length) {
                let tb = <table className="table table-striped table-bordered fnt-sm">

                    <tbody>
                        <tr>
                            <td colSpan="2">Vous confirmer le transfert de la liste des colis suivante ?</td>
                        </tr>
                        {lst_c && lst_c.map((item) =>
                            <tr>
                                <td className="p-1"> <b>Colis</b> </td>
                                <td className="p-1">{item.code}</td>
                            </tr>
                        )}

                    </tbody>
                </table>;

                setShowDelModal(false)
                setMd_title_transfer("Transfert Colis")
                setMd_msg_transfer(tb);
                setModalShow_transfer(true);
            } else {
                set_transfer_err("aucun colis selectionné !");
            }
        }
    }

    // const doTransfer = () => {
    //     if (colis_list) {
    //         let sel_c = colis_list.filter(elem => elem.checked == true)
    //         if (sel_c.length) {
    //             sel_c = sel_c.map(i => (i.ref));
    //             dispatch(createDispatch({ colis: sel_c, nbr_colis: sel_c.length })).unwrap().then(data => {
    //                 history.push(routes.mu_list)
    //             })
    //         }
    //     }
    // }


    const onEditField = (field , value) => {
        dispatch(editColisInfo({id : selected_colis.ref , field : field , f_data : value })).unwrap().then(d =>  {
            load_data()
            setShowDetails(false) ;
        });
    }


    const load_data = (filter)=>{
        dispatch(listColisAdm({ filter : {...filter, is_afex: false} , page : 0}))
        set_last_filter({...filter, is_afex: false})
        set_page(0)
    }

    const on_paginate = (value) => {
        set_page(value);
        dispatch(listColisAdm({ filter : last_filter , page : value}));
    }

    useEffect(() => {
        dispatch(loadExpedsList());
        load_data()
        return () => {
            dispatch(clear());
        }
    }, [])

    useEffect(() => {
        load_data()
    }, [current_hub ])

    useEffect(() => {
        if(Array.isArray(colis_list) && selected_colis && !loading){
            dispatch(selectColis(colis_list.find(el => el?.ref == selected_colis?.ref)))
        }
    }, [colis_list ])

    return (
        <>
            <ColisAdmHeader lst_data={colis_list} onPrintMany={onPrintMany} onTransfer={askTransfer} error={error}  lst_exped={expeds_list} load_data={load_data} loading={loading} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'colis'} />
            <ColisList loading={loading} error={error} lst_data={colis_list} onItemDetailsClick={onItemDetailsClick} onItemSelected={onItemSelected} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'colis'} />
            {/* <ColisAdmDetails data={selected_colis} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} onEditField={onEditField} loading={loading} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} /> */}
            <ColisAdmDetailsContainer show={showDetails} onClose={hideDetails} load_data={load_data} sel_colis={selected_colis} />
        </>
    )
}

export default ColisAdmListContainer;



