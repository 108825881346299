import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { stat as colisStat } from '../../utils/constants/Colis.enum';
import { useHistory } from "react-router-dom";
import { routes } from '../../utils/helpers/routing.helper';
import { stat as Mv_state } from '../../utils/constants/Movable.enum';
import AsyncSelect from 'react-select/async';

function MuCreateHeader({ onSearch, loading, lst_data, error, onSubmit, set_lst_data, isEdit }) {

    const [search, setSearch] = useState("");
    const history = useHistory()

    const searchOnchange = v => {
        if (!verifColisExist(v.value.ref)) {
            set_lst_data([v.value, ...lst_data]);
            //setSearch(v);
        }
    }

    const verifColisExist = (ref) => {
        console.log(ref);
        let found = false;
        lst_data.forEach(i => {
            if (i.ref === ref)
                found = true;
        });
        return found;
    }


    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'shipping-fast']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Transfert   </b>
                </div>

            </div>

            <div className="row no-marg rw-header ">

                <div className="col-md-6 offset-md-3 flex_ctr p-2">

                    <Form.Group as={Col} controlId="formGridState" className="no-marg">
                        <AsyncSelect loadOptions={onSearch} value={search} onChange={searchOnchange} placeholder="cherchez par Ref colis" />
                    </Form.Group>

                </div>



                <div className="col-md-8  fnt-largr">

                    <div className="row no-marg ">

                        <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
                            <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">unité(s)</b>
                        </div>


                    </div>


                </div>

                <div className="col-md-4 mb-2 pe-4 text-end">

                    {loading ?
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                            {isEdit == false ?
                                <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={onSubmit}>
                                    Créer une unité mobile
                                </button>
                                :
                                <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={onSubmit}>
                                    Enregistrer
                                </button>
                            }
                        </>
                    }

                    {/* <span className="txt-red pl-2">{transfer_err}</span> */}

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }


                </div>



            </div>

        </>
    );
}

export default MuCreateHeader;