/* eslint-disable */
import React, { useState, useEffect } from "react";
import ColisBillList from "../../../ui/lists/ColisBill.list";
import BillCreatorHeader from "../../../ui/headers/BillCreator.header";
import ColisAdmDetails from "../../../ui/layouts/ColisAdmDetails";
import { clear, listDettesByClient, createFacture } from "../../../slices/FactureAdm";
import { clear as clear_colis, selectColis, editColisInfo, setError } from "../../../slices/ColisAdm";
import { listCheque } from "../../../slices/Cheque.slice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "../../../utils/helpers/routing.helper";
import ChequeBillList from "../../../ui/lists/ChequeBill.list";
import { ColisAfexStatus, ColisAfexStatusBg, ColisStatus } from "../../../utils/constants/Colis.enum";
import ColisAdmDetailsContainer from "../package/ColisAdmDetails.container";

const frais_timbre_fiscal = 1;
const BillCreator = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error, client } = useSelector((state) => state.bill_adm);
  const { loading: loading_colis, error: error_colis, selected_colis } = useSelector((state) => state.colis_adm);
  const [showDetails, setShowDetails] = useState(false);

  const id = props.match.params.client;

  const [frais_liv, setFrais_liv] = useState(0.0);
  const [frais_retour, setFrais_retour] = useState(0.0);
  const [frais_poids_supp_kg, set_frais_poids_supp_kg] = useState(0.0);
  const [montant_pay, setMontant_pay] = useState(0.0);
  const [montant_colis, setMontant_colis] = useState(0.0);
  const [montant_poids_supp, set_montant_poids_supp] = useState(0.0);
  const [montant_cheque, setMontant_cheque] = useState(0.0);
  const [totMontantLiv, setTotMontantLiv] = useState(0.0);
  const [totMontantRetour, setTotMontantRetour] = useState(0.0);

  const [lst_cheque, set_lst_cheque] = useState([]);
  //const [lst_colis, set_lst_colis] = useState([]);
  const [lst_facture_bag, set_lst_facture_bag] = useState([]);

  const onItemClicked = (data) => {
    dispatch(selectColis(data));
    setShowDetails(true);
  };
  const hideDetails = () => {
    setShowDetails(false);
  };

  const onPrint = (data) => {
    if (data) {
      UserStorage.setParam([data]);
      const win = window.open("/dashb/colis/print/", "_blank");
      win.focus();
    }
  };

  const onEditField = (field, value) => {
    dispatch(editColisInfo({ id: selected_colis.ref, field: field, f_data: value }))
      .unwrap()
      .then((d) => {
        setShowDetails(false);
        if (id) dispatch(invoicesByClient(id));
      });
  };

  const onSubmit = () => {
    if (client) {
      if (Array.isArray(lst_facture_bag) && Array.isArray(lst_cheque)) {
        let factureBags = [];
        let ids_cheque = [];

        factureBags = lst_facture_bag
          .filter((el) => el?.selected)
          .map((el) => ({
            coliRef: el?.colis?.ref,
            decision: el?.decision,
            frais_service: el?.frais_service,
            montant_colis: el?.montant_colis,
            poids_supp: el?.poids_supp,
            frais_poids_supp: el?.frais_poids_supp,
          }));
        ids_cheque = lst_cheque.filter((el) => el?.selected).map((el) => el?.idCheque);

        if (factureBags.length > 0) {
          let bill = {
            nbr_colis: factureBags.length,
            montant_colis: montant_colis,
            montant_frais_service: +totMontantLiv + +totMontantRetour,
            montant_cheque: montant_cheque,
            montant_paye: montant_pay,
            frais_poids_supp_kg: frais_poids_supp_kg,
            montant_frais_poids_supp: montant_poids_supp,
            montant_droit_timbre: frais_timbre_fiscal,
            id_exped: client.id,
            frais_liv: frais_liv,
            frais_retour: frais_retour,
            factureBags: factureBags,
            cheques: ids_cheque,
          };

          console.log(bill);

          dispatch(createFacture(bill))
            .unwrap()
            .then((data) => {
              history.push(routes.bill_adm_invoice);
            });
        } else {
          dispatch(setError("vous devez inclure au moins 1 colis , pour créer une facture ! "));
        }
      }
    }
  };

  const load_data = () => {
    dispatch(listDettesByClient(id))
      .unwrap()
      .then((res) => {
        ///set_lst_colis(Array.isArray(res?.colis) ? res?.colis.map((el) => ({ ...el, selected: false })) : []);
        set_lst_facture_bag(Array.isArray(res?.colis) ? res?.colis.map((el) => getFactureBagItem(el)) : []);
      });
    dispatch(listCheque({ etat: "dispo", id_exped: id }))
      .unwrap()
      .then((res) => {
        set_lst_cheque(Array.isArray(res?.rows) ? res?.rows.map((el) => ({ ...el, selected: false })) : []);
      });
  };

  const getFactureBagItem = (colis) => {
    if (colis?.etat == ColisStatus.LIVRE)
      return {
        colis: colis,
        decision: "livre",
        frais_service: frais_liv,
        montant_colis: colis?.montant_ttc,
        poids_supp: colis?.poids_supp,
        frais_poids_supp: +Number(+frais_poids_supp_kg * +colis?.poids_supp).toFixed(3),
        selected: false,
      };
    else if (colis?.etat == ColisStatus.RECUPERER) return { colis: colis, decision: "recupere", frais_service: frais_liv, montant_colis: colis?.montant_ttc, selected: false };
    else if (colis?.etat == ColisStatus.ANL) return { colis: colis, decision: "retour", frais_service: frais_retour, montant_colis: 0.0, selected: false };
    else if (colis?.etat_afex == ColisAfexStatus.delivered && colis?.is_afex == true)
      return {
        colis: colis,
        decision: "livre",
        frais_service: frais_liv,
        montant_colis: colis?.montant_ttc,
        poids_supp: colis?.poids_supp,
        frais_poids_supp: +Number(+frais_poids_supp_kg * +colis?.poids_supp).toFixed(3),
        selected: false,
      };
    else if (colis?.is_afex == true && (colis?.etat_afex == ColisAfexStatus.recovered ))
      return { colis: colis, decision: "recupere", frais_service: frais_liv, montant_colis: colis?.montant_ttc, selected: false };
    else if (colis?.is_afex == true && (colis?.etat_afex == ColisAfexStatus.returned || colis?.etat_afex == ColisAfexStatus.canceled))
      return { colis: colis, decision: "retour", frais_service: frais_retour, montant_colis: 0.0, selected: false };
    else return { colis: colis, decision: "rembourse", frais_service: 0.0, montant_colis: colis?.montant_remboursement, selected: false };
  };

  useEffect(() => {
    if (id) {
      load_data();
    }

    return () => {
      dispatch(clear());
    };
  }, []);

  useEffect(() => {
    setFrais_liv(client?.frais_liv);
    setFrais_retour(client?.frais_retour);
    set_frais_poids_supp_kg(client?.frais_poids_supp_kg);
  }, [client]);

  useEffect(() => {
    if (Array.isArray(lst_facture_bag)) {
      const total_colis = lst_facture_bag.filter((el) => el?.selected).reduce((acc, curr) => acc + +Number(+curr?.montant_colis).toFixed(3), 0.0);

      const total_frais_liv = lst_facture_bag.filter((el) => el?.selected && el?.decision == "livre").reduce((acc, curr) => acc + +Number(+curr?.frais_service).toFixed(3), 0.0);

      const total_frais_retour = lst_facture_bag.filter((el) => el?.selected && el?.decision != "livre").reduce((acc, curr) => acc + +Number(+curr?.frais_service).toFixed(3), 0.0);

      const total_cheque = lst_cheque.filter((el) => el?.selected).reduce((acc, curr) => acc + +Number(+curr?.montant).toFixed(3), 0.0);

      const total_frais_poids_supp = lst_facture_bag.filter((el) => el?.selected && el?.decision == "livre").reduce((acc, curr) => acc + +Number(+curr?.frais_poids_supp).toFixed(3), 0.0);

      const total_paye = Number(+total_colis - (+total_frais_liv + +total_frais_retour + +total_cheque + +total_frais_poids_supp + +frais_timbre_fiscal));

      setMontant_colis(total_colis);
      setTotMontantLiv(total_frais_liv);
      setTotMontantRetour(total_frais_retour);
      setMontant_cheque(total_cheque);
      setMontant_pay(+total_paye > 0 ? +total_paye : 0.0);
      set_montant_poids_supp(total_frais_poids_supp);
    }
  }, [lst_facture_bag, lst_cheque]);

  useEffect(() => {
    if (Array.isArray(lst_facture_bag)) {
      set_lst_facture_bag(
        lst_facture_bag.map((el) => ({
          ...el,
          frais_service: el?.decision == "livre" ? frais_liv : el?.decision == "retour" ? frais_retour : el?.decision == "recupere" ? frais_liv : 0.0,
          frais_poids_supp: el?.decision == "livre" ? Number(+el?.poids_supp * +frais_poids_supp_kg).toFixed(3) : 0.0,
        }))
      );
    }
  }, [frais_liv, frais_retour, frais_poids_supp_kg]);

  return (
    <>
      <BillCreatorHeader
        client={client}
        error={error}
        error_colis={error_colis}
        loading={loading}
        onSubmit={onSubmit}
        frais_liv={frais_liv}
        frais_retour={frais_retour}
        setFrais_liv={setFrais_liv}
        setFrais_retour={setFrais_retour}
        montant_colis={montant_colis}
        montant_pay={montant_pay}
        totMontantLiv={totMontantLiv}
        totMontantRetour={totMontantRetour}
        montant_cheque={montant_cheque}
        frais_poids_supp={frais_poids_supp_kg}
        set_frais_poids_supp={set_frais_poids_supp_kg}
        montant_poids_supp={montant_poids_supp}
        frais_timbre_fiscal={frais_timbre_fiscal}
      />
      <ColisBillList
        loading={loading}
        error={error}
        lst_facture_bag={lst_facture_bag}
        set_lst_facture_bag={set_lst_facture_bag}
        onItemDetailsClick={onItemClicked}
        frais_liv={frais_liv}
        frais_retour={frais_retour}
      />
      {client?.mode_reglement == "cheque_especes" ? (
        <ChequeBillList loading={loading} lst_cheque={lst_cheque} set_lst_cheque={set_lst_cheque} />
      ) : (
        <div className="row no-marg rw-header ">
          <div className="col-md-12 p-2">
            <h6 className="fnt-w7">Seulement en espèces</h6>
          </div>
        </div>
      )}
      {/* <ColisAdmDetails data={selected_colis} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEditField={onEditField} loading={loading} /> */}
      <ColisAdmDetailsContainer sel_colis={selected_colis} show={showDetails} onClose={hideDetails} load_data={load_data} />
    </>
  );
};

export default BillCreator;
