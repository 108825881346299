/* eslint-disable */
import React, { useState, useEffect } from 'react';
import InvoiceList from '../../../ui/lists/Invoice.list';
import InvoiceHeader from '../../../ui/headers/Invoice.header';
import { clear , listDettes  } from '../../../slices/FactureAdm';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';


const InvoiceToPay = (props) => {

    const dispatch = useDispatch();
    const [zone , set_zone ] = useState(UserStorage.getZone)
    const { loading, error, item_list } = useSelector(state => state.bill_adm);
    

    useEffect(() => {
        dispatch(listDettes());
        return () => {
            dispatch(clear());
        }
    }, [])

    // useEffect(() => {
    //     if (zone && zone != "null")
    //     dispatch(listDettes());
    // }, [zone])

    return (
        <>
            <InvoiceHeader lst_data={item_list} error={error} loading={loading} zone={zone} onZoneChange={set_zone} />
            <InvoiceList loading={loading} error={error} lst_data={item_list}  />
           
        </>
    )
}

export default InvoiceToPay;



