import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class ChequeService {

    //Adm Services----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------
    createCheque(cheque) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cheque/adm/create`, cheque, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    editCheque(cheque) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cheque/adm/update`, cheque, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    deleteCheque(id) {
        let token = storage.getToken();
        if(id)
        return axios.get(`${api_url}/api/cheque/adm/delete/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    listCheque(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cheque/adm/list`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

}

export default new ChequeService();