import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColisStatus } from "../../utils/constants/Colis.enum";

const ColisEditInfoModal = (props) => {
  const [error, set_error] = useState();

  const onOk = () => {
    if (props.field) {
      set_error("");

      if (props.field == "montant" && !props.f_data.match(/^(\d+\.?\d*|\.\d+)$/)) {
        set_error("Montant non valid ! ");
      } else if (props.field == "poids_supp" && !props.f_data.match(/^(\d+\.?\d*|\.\d+)$/)) {
        set_error("Poids non valid ! ");
      } else if (props.field == "adresse" && !props.f_data) {
        set_error("Adresse non valid !");
      } else if (props.field == "tel" && (props.f_data.length != 8 || !props.f_data.match(/^[0-9]+$/i))) {
        set_error("Numéro tél non valid !");
      } else {
        props.onSubmit(props.field, props.f_data);
        props?.onHide();
      }
    }
  };

  const renderOptionsStatus = () => {
    let opt;

    if (props.f_data) {
      if (
        props.f_data === ColisStatus["LIV-COURS"] ||
        props.f_data === ColisStatus["LIV-COURS-SUCC"] ||
        props.f_data === ColisStatus["LIV-COURS-ECHEC"] ||
        props.f_data === ColisStatus["LIV-COURS-REPO"]
      ) {
        opt = (
          <>
            {" "}
            <option value={ColisStatus["LIV-COURS"]}>Liv en cours</option>
            <option value={ColisStatus["LIV-COURS-SUCC"]}>Liv en cours (Livré / Récupéré)</option>
            <option value={ColisStatus["LIV-COURS-ECHEC"]}>Liv en cours (Annulé)</option>
            <option value={ColisStatus["LIV-COURS-REPO"]}>Liv en cours (Reporté) </option>{" "}
          </>
        );
      } else if (props.f_data === ColisStatus["ATT-LIV"]) {
        opt = (
          <>
            {" "}
            <option value={ColisStatus["ATT-LIV"]}>En Attente de Liv</option>
            <option value={ColisStatus.LIVRE}>Livré</option>
            <option value={ColisStatus.ANL}>Annulé</option>
            <option value={ColisStatus.ENDOM}>Endommagé</option>
            <option value={ColisStatus.RECUPERER}>Récupéré</option>
          </>
        );
      
      } else if (props.f_data === ColisStatus["ATT-RECUP"] || props.f_data === ColisStatus.RECUPERER) {
        opt = (
          <>
            {" "}
            <option value={ColisStatus["ATT-RECUP"]}>En Attente de Récupération</option>
            <option value={ColisStatus.RECUPERER}>Récupéré</option>
            <option value={ColisStatus.ANL}>Annulé</option>
            <option value={ColisStatus.ENDOM}>Endommagé</option>
          </>
        );
      }
      // else if (props.f_data === colisStat.to_return) {
      //     opt = <> <option value={colisStat.pickup}>Pickup (En depot )</option>
      //         <option value={colisStat.to_return}>to Return (retour)</option>
      //     </>;
      // }
      // else if (props.f_data === colisStat.pickup_prog) {
      //     opt = <> <option value={colisStat.pickup}>Pickup (En depot )</option>
      //         <option value={colisStat.pickup_prog}>Pickup fail (Non encore ramassé)</option>
      //     </>;
      // }
      else if (props.f_data === ColisStatus.LIVRE || props.f_data === ColisStatus.ANL || props.f_data === ColisStatus.RECUPERER || props.f_data === ColisStatus.ENDOM) {
        opt = (
          <>
            <option value={ColisStatus["ATT-LIV"]}>En Attente de Liv</option>
            <option value={ColisStatus["ATT-RECUP"]}>En Attente de Récupération</option>
            <option value={ColisStatus.LIVRE}>Livré</option>
            <option value={ColisStatus.RECUPERER}>Récupéré</option>
            <option value={ColisStatus.ANL}>Annulé</option>
            <option value={ColisStatus.ENDOM}>Endommagé</option>
          </>
        );
      }
      // else if (props.f_data === colisStat.completed_non_retourne) {
      //     opt = <> <option value={colisStat.completed}>Completed (Livré) </option>
      //         <option value={colisStat.completed_non_retourne}>Completed Non retourné (Non-livré)</option>
      //         <option value={colisStat.pickup}>Pickup ( En depot )</option>
      //     </>;
      // }
      // }
      // else if (props.f_data === colisStat.pd_transfer_zone_pick) {
      //     opt = <> <option value={colisStat.pd_transfer_zone_pick}>to transfert </option>
      //         <option value={colisStat.pickup}>Pickup ( En depot )</option>
      //     </>;
      else {
        opt = (
          <>
            {" "}
            <option value={props.f_data}>{props.f_data}</option>
          </>
        );
      }
    }

    return opt;
  };

  useEffect(()=>{
        if(props?.colis && props?.field){
            if(props.field == "poids_supp")
                props?.set_f_data(props?.colis?.poids_supp)
            else if(props.field == "montant")
                props?.set_f_data(props?.colis?.montant_ttc)
            else if(props.field == "tel")
                props?.set_f_data(props?.colis?.tel_cl)
        }
  },[props?.colis,props?.field])

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="txt-red">
          <FontAwesomeIcon icon={["fas", "edit"]} /> Edit {props.field} :
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <div className="alert alert-danger" role="alert">
            <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
          </div>
        )}

        <div className="col-md-12 p-3">
          {props.field && props.field == "etat" && (
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className="fnt-w5">Etat</Form.Label>
              <Form.Control as="select" className="force-apparence" value={props.f_data && props.f_data} onChange={(e) => props.set_f_data(e.target.value)}>
                {renderOptionsStatus()}
              </Form.Control>
            </Form.Group>
          )}

          {props.field && props.field == "zone_exped" && (
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className="fnt-w5">Zone d'éxpedition</Form.Label>
              <Form.Control as="select" className="force-apparence" value={props.f_data && props.f_data} onChange={(e) => props.set_f_data(e.target.value)}>
                <option value="ariana">ariana </option>
                <option value="beja">beja </option>
                <option value="ben arous">ben arous </option>
                <option value="bizerte">bizerte </option>
                <option value="grand-tunis">grand-tunis </option>
                <option value="gabes">gabes </option>
                <option value="gafsa">gafsa </option>
                <option value="jendouba">jendouba </option>
                <option value="kairouan">kairouan </option>
                <option value="kasserine">kasserine </option>
                <option value="kebili">kebili</option>
                <option value="kef">kef</option>
                <option value="mahdia">mahdia</option>
                <option value="manouba">manouba</option>
                <option value="medenine">medenine </option>
                <option value="monastir">monastir </option>
                <option value="nabeul">nabeul </option>
                <option value="sfax">sfax </option>
                <option value="sidi-bouzid">sidi-bouzid </option>
                <option value="siliana">siliana </option>
                <option value="sahel">sahel </option>
                <option value="tataouine">tataouine </option>
                <option value="tozeur">tozeur </option>
                <option value="zaghouan">zaghouan </option>
              </Form.Control>
            </Form.Group>
          )}

          {props.field && props.field == "zone_dest" && (
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className="fnt-w5">Zone de destination</Form.Label>
              <Form.Control as="select" className="force-apparence" value={props.f_data && props.f_data} onChange={(e) => props.set_f_data(e.target.value)}>
                <option value="ariana">ariana </option>
                <option value="beja">beja </option>
                <option value="ben arous">ben arous </option>
                <option value="bizerte">bizerte </option>
                <option value="grand-tunis">grand-tunis </option>
                <option value="gabes">gabes </option>
                <option value="gafsa">gafsa </option>
                <option value="jendouba">jendouba </option>
                <option value="kairouan">kairouan </option>
                <option value="kasserine">kasserine </option>
                <option value="kebili">kebili</option>
                <option value="kef">kef</option>
                <option value="mahdia">mahdia</option>
                <option value="manouba">manouba</option>
                <option value="medenine">medenine </option>
                <option value="monastir">monastir </option>
                <option value="nabeul">nabeul </option>
                <option value="sfax">sfax </option>
                <option value="sidi-bouzid">sidi-bouzid </option>
                <option value="siliana">siliana </option>
                <option value="sahel">sahel </option>
                <option value="tataouine">tataouine </option>
                <option value="tozeur">tozeur </option>
                <option value="zaghouan">zaghouan </option>
              </Form.Control>
            </Form.Group>
          )}

          {props.field && props.field == "zone_current" && (
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className="fnt-w5">Zone courrante </Form.Label>
              <Form.Control as="select" className="force-apparence" value={props.f_data && props.f_data} onChange={(e) => props.set_f_data(e.target.value)}>
                <option value="ariana">ariana </option>
                <option value="beja">beja </option>
                <option value="ben arous">ben arous </option>
                <option value="bizerte">bizerte </option>
                <option value="grand-tunis">grand-tunis </option>
                <option value="gabes">gabes </option>
                <option value="gafsa">gafsa </option>
                <option value="jendouba">jendouba </option>
                <option value="kairouan">kairouan </option>
                <option value="kasserine">kasserine </option>
                <option value="kebili">kebili</option>
                <option value="kef">kef</option>
                <option value="mahdia">mahdia</option>
                <option value="manouba">manouba</option>
                <option value="medenine">medenine </option>
                <option value="monastir">monastir </option>
                <option value="nabeul">nabeul </option>
                <option value="sfax">sfax </option>
                <option value="sidi-bouzid">sidi-bouzid </option>
                <option value="siliana">siliana </option>
                <option value="sahel">sahel </option>
                <option value="tataouine">tataouine </option>
                <option value="tozeur">tozeur </option>
                <option value="zaghouan">zaghouan </option>
              </Form.Control>
            </Form.Group>
          )}

          {props.field && props.field == "tel" && (
            <Form.Group as={Col} controlId="formGridState" className="no-marg">
              <Form.Label className="no-marg fnt-w5">Tél Client</Form.Label>
              <Form.Control size="sm" name="tel_cl" value={props.f_data} onChange={(e) => props.set_f_data(e.target.value)} />
            </Form.Group>
          )}

          {props.field && props.field == "montant" && (
            <Form.Group as={Col} controlId="formGridState" className="no-marg">
              <Form.Label className="no-marg fnt-w5">Prix Total TTC inclus frais(en TND ) </Form.Label>
              <Form.Control type="text" size="sm" name="montantttc" value={props.f_data} onChange={(e) => props.set_f_data(e.target.value)} />
            </Form.Group>
          )}

          {props.field && props.field == "adresse" && (
            <Form.Group as={Col} controlId="formGridState" className="no-marg">
              <Form.Label className="no-marg fnt-w5">Adresse</Form.Label>
              <textarea class="form-control form-control-sm" rows="1" value={props.f_data} onChange={(e) => props.set_f_data(e.target.value)} />
            </Form.Group>
          )}

          {props.field && props.field == "poids_supp" && (
            <Form.Group as={Col} controlId="formGridState" className="no-marg">
              <Form.Label className="no-marg fnt-w5">Poids Supp(en KG ) </Form.Label>
              <Form.Control type="number" size="sm" name="poids_supp" value={props.f_data} onChange={(e) => props.set_f_data(e.target.value)} />
            </Form.Group>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.loading ? (
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <Button variant="secondary" onClick={props.onHide}>
              Annuler
            </Button>

            <Button variant="success" onClick={onOk}>
              Ok
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ColisEditInfoModal;
