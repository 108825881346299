import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from '../services/UserService';
import storage from '../services/UserStorage';

const initialState = {
    loading: false,
    error: '',
    done : false ,
    data: null,
};

export const register = createAsyncThunk(
    "register/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserService.register(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const resendVmail = createAsyncThunk(
    "register/sendvmail",
    async ( data , { rejectWithValue }) => {
        try {
            const res = await UserService.resendVmail()
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        clear : (state , action) => {
            state.loading = false ;
            state.error = '';
            state.data = null ;
            state.done = false ;
        },
        
    },
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true ;
                    state.data = action.payload.user;
                    storage.setToken(action.payload.token);
                }
            }
            state.error = '';
            state.loading = false;
            
        },
        [register.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [register.pending]: (state, action) => {
            state.loading = true;
        },
        [resendVmail.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true ;
                }
            }
            state.error = '';
            state.loading = false;
            
        },
        [resendVmail.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [resendVmail.pending]: (state, action) => {
            state.loading = true;
        }
    }
})

const { reducer } = registerSlice;
export const { clear } = registerSlice.actions;
export default reducer;