import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const UserFraisForm = ({ show, onClose, user, onUpdateUser, loading, error }) => {
  const validationSchema = Yup.object().shape({
    frais_liv: Yup.string()
      .typeError("Frais  non valide !")
      .required("Vous devez saisir le frais de livraison !")
      .test("Frais valid", "Frais non valid", (value) => (value + "").match(/^\d+(\.\d{1,3})?$/)),
      frais_retour: Yup.string()
      .typeError("Frais  non valide !")
      .required("Vous devez saisir le frais de retour !")
      .test("Frais valid", "Frais non valid", (value) => (value + "").match(/^\d+(\.\d{1,3})?$/)),
      frais_poids_supp_kg: Yup.string()
      .typeError("Frais  non valide !")
      .required("Vous devez saisir le frais de Poids Supp !")
      .test("Frais valid", "Frais non valid", (value) => (value + "").match(/^\d+(\.\d{1,3})?$/)),
      mode_reglement: Yup.string()
      .required("Vous devez choisir le mode de réglements ! !")
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    if (data && user) {
      let usr = {
        id: user.id,
        frais_liv: data.frais_liv,
        frais_retour: data.frais_retour,
        frais_poids_supp_kg: data.frais_poids_supp_kg,
        mode_reglement: data.mode_reglement,
      };
      onUpdateUser(usr);
    }
  };

  useEffect(() => {
    if (user) {
      setValue("frais_liv", user?.frais_liv || 0.0);
      setValue("frais_retour", user?.frais_retour || 0.0);
      setValue("frais_poids_supp_kg", user?.frais_poids_supp_kg || 0.0);
      setValue("mode_reglement", user?.mode_reglement || "");
    }
  }, [user]);

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row no-marg">
        <div className="col-md-12  bg-wt ">
          <div className="col-md-12">
          
            {error && (
              <div className="alert alert-danger" role="alert">
                <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} id="frm_user_frais" className="col-md-12 py-4">
          <h6>Changement des frais service:</h6>
            <div className="row m-0">
              <Form.Group as={Col} controlId="formGridState2" className="col-md-6">
                <Form.Label className="no-marg small-label">Frais Livraison </Form.Label>
                <Form.Control type="number" size="sm" name="frais_liv" {...register("frais_liv")} />

                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.frais_liv?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState3" className="col-md-6">
                <Form.Label className="no-marg small-label">Frais Retour </Form.Label>
                <Form.Control type="text" size="sm" name="frais_retour" {...register("frais_retour")} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.frais_retour?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState4" className="col-md-6">
                <Form.Label className="no-marg small-label">Frais Poids Supp (TND/KG) </Form.Label>
                <Form.Control type="text" size="sm" name="frais_poids_supp_kg" {...register("frais_poids_supp_kg")} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.frais_poids_supp_kg?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Mode Réglements</Form.Label>
                <Form.Control as="select" defaultValue="Choose..." size="sm" {...register("mode_reglement")} className={`form-control force-apparence ${errors.mode_reglement ? "is-invalid" : "form-control"}`}>
                  <option value="">Choisissez un mode</option>
                  <option value="cheque_especes">Chèque ou espèces</option>
                  <option value="especes">Seulement en espèces</option>
                </Form.Control>
                <p className="text-danger">{errors.mode_reglement?.message}</p>
              </Form.Group>
            </div>

            {loading ? (
              <div class="col-md-12 p-4 txt-ctr">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div class="col-md-12 text-end px-2">
                <button className="btn btn-primary ">
                  <FontAwesomeIcon icon={["fas", "check"]} /> Confirmer
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserFraisForm;
