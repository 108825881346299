import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import HubBox from "../../domains/admin/home/HubBox.container";

function RunsheetAdmHeader({ loading, error, error_colis, onSubmit, onSearch, lst_data, set_lst_data, isEdit, rs }) {
  const [search, setSearch] = useState("");
  const history = useHistory();

  const searchOnchange = (v) => {
    if (!verifColisExist(v.value.ref)) {
      set_lst_data([v.value, ...lst_data]);
      //setSearch(v);
    }
  };

  const verifColisExist = (ref) => {
    console.log(ref);
    let found = false;
    lst_data.forEach((i) => {
      if (i.ref === ref) found = true;
    });
    return found;
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-5 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          {isEdit ? <b className="tit_text_shape ps-4">Edit tournée {rs && `: ${rs.ref}`} </b> : <b className="tit_text_shape ps-4">Créer une tournée</b>}
        </div>

        <div className="col-md-3 no-padd  ">
          <span class="badge bg-success fnt-larg mt-2 position-fixed">{lst_data?.length}</span>
        </div>

        <div className="col-md-4 no-padd  ">
          <HubBox />
        </div>
      </div>

      <div className="row no-marg rw-header p-1">
        <div className="col-md-6 offset-md-3 flex_ctr p-2">
          <Form.Group as={Col} controlId="formGridState" className="no-marg">
            <AsyncSelect loadOptions={onSearch} value={search} onChange={searchOnchange} placeholder="cherchez par Ref colis" />
          </Form.Group>
        </div>

        <div className="col-md-3 no-padd text-center ">
          {loading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : isEdit ? (
            <button className="btn btn-outline-success mt-2" onClick={onSubmit}>
              {" "}
              Enregistrer{" "}
            </button>
          ) : (
            <button className="btn btn-outline-success mt-2" onClick={onSubmit}>
              {" "}
              Crée{" "}
            </button>
          )}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
          {error_colis && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error_colis}</span>
            </div>
          )}
        </div>

     
      </div>
    </>
  );
}

export default RunsheetAdmHeader;
