import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter " className="txt-red">
          <FontAwesomeIcon icon={["fas", "info-circle"]} /> {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="txt-ctr pt-4 pb-4">
        <h5 className="pt-5 pb-5">{props.msg}</h5>

        <div className="col-md-12">
          {props?.error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{props?.error}</span>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props?.loading ? (
          <div class="col-md-12 p-4 txt-ctr">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Button variant="secondary" onClick={props.onHide} disabled={props?.loading}>
              Annuler
            </Button>
            <Button variant="success" onClick={props.onOk} disabled={props?.loading}>
              Oui
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
