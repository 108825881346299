import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, useWatch } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const UserDettePassEdit = ({ show, onClose, user, onUpdateUser, loading, error }) => {
  const validationSchema = Yup.object().shape({
    is_bill_secure: Yup.boolean(),
    bill_password: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, control, setError, setValue } = useForm(formOptions);
  const { errors } = formState;

  const sel_is_bill_secure = useWatch({ control, name: "is_bill_secure" });

  const onSubmit = (data) => {
    console.log(data);
    if (data && user) {
      if (data?.is_bill_secure && !data?.bill_password) {
        setError("bill_password", "Vous devez saisir le mot de passe !");
        return;
      }
      let usr = {
        id: user?.id,
        bill_password: data.bill_password,
        is_bill_secure: data.is_bill_secure,
      };
      onUpdateUser(usr);
    }
  };

  useEffect(() => {
    if (user) {
      setValue("is_bill_secure", user?.is_bill_secure);
    }
  }, [user]);

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row no-marg bg-wt p-4">
        {loading ? (
          <div className="row loading-row">
            <div className="col-md-12 txt-ctr">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="col-md-12" id="frm_user_pass">
            <div className="col-md-12">
              <h6>Mot de passe de facturation :</h6>
              {error && (
                <div className="alert alert-danger" role="alert">
                  <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
                </div>
              )}
            </div>

            <Form.Group as={Col} className="col-md-6">
              <Form.Label className="no-marg small-label">Actif </Form.Label>
              <Form.Check type="checkbox" name="is_bill_secure" {...register("is_bill_secure")} />
            </Form.Group>
            {sel_is_bill_secure && (
              <div className="row m-0">
                <Form.Group as={Col} className="col-md-6">
                  <Form.Label className="no-marg small-label">Mot de passe </Form.Label>
                  <Form.Control size="sm" name="bill_password" {...register("bill_password")} />
                  <Form.Text className="text-muted txt-red">
                    <p className="text-danger">{errors.bill_password?.message}</p>
                  </Form.Text>
                </Form.Group>
              </div>
            )}
            <div className="row m-0">
              <div className="col-md-12 text-end">
                <button className="btn btn-success" type="submit">
                  Modifier
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserDettePassEdit;
