import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

import { stat as RunsheetState } from '../../utils/constants/Runsheet.enum';
import { ColisStatus } from '../../utils/constants/Colis.enum';
import { getStringDate, getDays } from '../../utils/helpers/date.helper';
import { routes } from '../../utils/helpers/routing.helper';

const RunsheetItem = ({ data, onDetails, onDispatch, onPrint }) => {

    useEffect(() => {
        //for formated date
    }, [])

    const renderStat = () => {
        let spn_stat;
        let stat = "";
        switch (data.etat) {
            case RunsheetState.PENDING:
                stat = "Pending to Dispatch";
                break;
            case RunsheetState.IN_PROGRESS:
                stat = "In progress";
                break;
            case RunsheetState.COMPLETED:
                stat = "Completed";
                break;
            case RunsheetState.CLOSED:
                stat = "CLOSED";
                break;
        }

        if (data.etat === RunsheetState.PENDING) {
            spn_stat = <span className="badge bg-warning fnt-w5 txt_blk  mt-2"> {stat} </span>
        } else if (data.etat === RunsheetState.IN_PROGRESS) {
            spn_stat = <span className="badge bg-primary fnt-w5 txt_wt mt-2"> {stat} </span>
        } else if (data.etat === RunsheetState.COMPLETED) {
            spn_stat = <span className="badge bg-success fnt-w5 txt_wt  mt-2"> {stat}</span>
        } else {
            spn_stat = <span className="badge bg-secondary fnt-w5 txt_wt  mt-2"> {stat}</span>
        }
        return spn_stat;
    }

    const getNbrDecision = () => {
        let n = 0
        if (data) {
            if (data.colis) {
                let d = data.colis.filter(elem => elem.etat != ColisStatus['LIV-COURS']);
                n = d.length;
            }
        }
        return n;
    }



    return (
        <div className="row item_box">
            <div className="col-md-1">
                <h3 className="icon"><FontAwesomeIcon icon={['fas', 'shipping-fast']} /></h3>
            </div>
            <div className="col-md-6 dvdr-r-gray">
                <b className="item_box_ref">
                    Runsheet_<span className="fnt-w5 txt-red">{data.ref}</span>
                    {
                        data && data.etat == RunsheetState.IN_PROGRESS ?
                            <span className="ps-2">({getNbrDecision()} / {data.nbr_colis} colis)</span>
                            :
                            <span className="ps-2">( {data && Array.isArray(data.colis) && data.colis.length} colis)</span>
                    }

                </b>

                <b className="sm-gray-txt fnt-sm fnt-w4 float-end">{data && getStringDate(data.createdAt)}
                    <span className="fnt-w5 fnt-sm ps-2">{data && getDays(data.createdAt)}</span> </b>
                <br />
                <b className="float-end">{renderStat()} </b>
                <b className="item_box_driver">
                    <FontAwesomeIcon icon={['fas', 'car-alt']} /> <span className="fnt-w4">{data && data.etat == RunsheetState.PENDING ? 'Livreur ' : 'Dispatched'} </span> - {data && data.rsLiv && data.rsLiv.nom + ' ' + data.rsLiv.prenom}
                </b>



            </div>


            <div className="col-md-5 pt-1 text-end">
                {data && data.etat != RunsheetState.IN_PROGRESS &&
                    <a className="btn btn-outline-success me-1" onClick={e => onDetails(data)}> Details</a>
                }
                <a className="btn btn-outline-success me-1" onClick={e => onPrint(data)}><FontAwesomeIcon icon={['fas', 'print']} /> Imprimer</a>
                {data && data.etat == RunsheetState.PENDING &&
                    <a className="btn btn-outline-success me-1" onClick={e => onDispatch(data)}><FontAwesomeIcon icon={['fas', 'shipping-fast']} /> Dispatch</a>
                }
                {data && data.etat == RunsheetState.IN_PROGRESS &&
                    <Link className="btn box_item_btn text-center ms-3" style={{ width: "80px" }} to={`/dashb/rs/confirm/${data.ref}`} ><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></Link>
                }
            </div>



        </div>
    );
}
export default RunsheetItem;