/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisList from '../../../ui/lists/ColisAdm.list';
import noDataIcon from '../../../assets/img/no_data.png';
import ColisClientSearch from '../../../ui/headers/ColisClientSearch.header';
import ColisAdmDetails from '../../../ui/layouts/ColisAdmDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear, del, selectColis, searchAdmGlobalColis, editColisInfo } from '../../../slices/ColisAdm';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import BarcodeReader from 'react-barcode-reader';
import ColisAdmDetailsContainer from './ColisAdmDetails.container';
import PaginationBox from '../../../ui/pagination/PaginationBox';


const SearchAllColis = (props) => {

    const dispatch = useDispatch();
    const { loading, error, colis_list, selected_colis, count } = useSelector(state => state.colis_adm);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");
    const [last_search, set_last_search] = useState("")
    const [code_scan, set_code_scan] = useState("");

    //pagination
    const [last_filter , set_last_filter] = useState(null);
    const [page , set_page] = useState(0);



    const onItemDetailsClick = data => {
        dispatch(selectColis(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    } 

    const onSearch = (search) => {
        set_last_search(search)
        if (search != ""){
            dispatch(searchAdmGlobalColis({filter : {search} , page : 0}))
            set_page(0)
        }
    }

    const on_paginate = (value) => {
        set_page(value);
        if (last_search != "")
            dispatch(searchAdmGlobalColis({filter : {search : last_search} , page : value}))
    }

    const handleScan = (data) => {
        console.log(data);
        set_code_scan(data);
        if (isNaN(data)) {
            console.log(data + " is not  number ! ");
        } else {
            console.log(data + " is number ");
            onSearch(data);
        }
    }

    const handleError = (err) => {
        console.log(err)
    }



    useEffect(() => {
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <BarcodeReader onError={handleError} onScan={handleScan} />
            <ColisClientSearch lst_data={colis_list} error={error} onSearch={onSearch} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'colis'} />
            <ColisList loading={loading} error={error} lst_data={colis_list} onItemDetailsClick={onItemDetailsClick} onItemSelected={() => { }} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'colis'} />
            <ColisAdmDetailsContainer show={showDetails} onClose={hideDetails} load_data={onSearch} sel_colis={selected_colis} />
            {(!colis_list.length && last_search && !loading ) && 
                    <div className="col-md-12 txt-ctr">
                        <br />
                        <br />
                        <br />
                        <img src={noDataIcon} alt="" width="10%" />
                        <br /><b className="txt-gray fnt-w5">AUCUN DONNEE TROUVE</b>
                    </div>
                }
        </>
    )
}

export default SearchAllColis;



