/* eslint-disable */
import React from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import lottie from 'lottie-web';
import banner from '../../assets/img/home_banner.png';
import delivery from '../../assets/img/delivery_bro.svg';
import delivery_tech from '../../assets/img/delivery_tech.svg';
import clock from '../../assets/img/24h.svg';

import ic_warehouse from '../../assets/img/ic_warehouse.png';
import ic_dtruck from '../../assets/img/ic_dtruck.png';
import ic_support from '../../assets/img/ic_support.png';




const HomePage = () => {


    // const anim_container = useRef(null);
    const history = useHistory();
    const goRegister = () => {
        history.push("/register");
    }


    return (
        <>


            <div className="row no-marg  position-relative box_overlay_home" >
                {/* <img src={wave2} alt=""/> */}
                <div className="col-md-6 mt-5 pt-2 text-center bg_box_effect" style={{ zIndex: "9" }}>
                    <div className="col-md-12">
                        <h2 className="txt_anim_h1 mt-5">Vous êtes un expéditeur ?</h2>
                        {/* <h2 className="txt_anim_h2 fnt-w8 mb-2 mt-2">Livrez avec le spécialiste </h2> */}
                        <h2 className="txt_anim_h3 fnt-w7">On-time every time</h2>

                    </div>

                    <div className="col-md-12">

                        <button className="btn btn-primary btn_bg_sign btn_start" onClick={goRegister} >Commencer</button>
                    </div>
                </div>

                <div className="col-md-6 text-end box_anim_img">

                    <img src={banner} width="90%" alt="" />


                </div>

                <div className="col-md-12 no-padd">

                </div>
            </div>


            <div className="row no-marg bg-wt ps-2 pe-2 pb-5 " id="box_service">
                <div className="col-md-3 text-center mt-5 mb-5">
                    <hr width="100%" />
                </div>
                <div className="col-md-6 text-center mt-5 mb-5 txt-dark-bl">
                    <h1 className="title-green">Nos services</h1>
                </div>
                <div className="col-md-3 text-center mt-5 mb-5">
                    <hr width="100%" />
                </div>

                <div className="col-md-10 offset-md-1">
                    <div className="row">

                        <div className="col-md-4  text-center ps-4 pe-4 pt-2 pb-2 box_service_1">
                            <img src={ic_warehouse} className="item_srvice_img" alt="" />
                            <b className="d-block m-1 txt_dark_bl fnt-largr sub-title-service">Pickup</b>
                            <p className="mt-2" style={{ textAlign: "justify" }}>
                            Vous n'avez pas a sortir de chez vous. Préparez vos colis , notre service pickup s'en charge du ramassage , vos colis seront pris en charge en toute securité.
                    </p>
                        </div>
                        <div className="col-md-4 text-center ps-4 pe-4 pt-2 pb-2 box_service_2">
                            <img src={ic_dtruck} className="item_srvice_img zoom" alt="" />
                            <b className="d-block m-1 txt_dark_bl fnt-largr sub-title-service">Livraison à domicile</b>
                            <p className="mt-2" style={{ textAlign: "justify" }}>
                            Livrez vous colis à domicile ou à un point de proximité. 
                            Profitez de notre service Livraison  dans un délais de 24 h ( des la réception des colis )
                    </p>
                        </div>
                        <div className="col-md-4  text-center ps-4 pe-4 pt-2 pb-2 box_service_3">
                            <img src={ic_support} className="item_srvice_img zoom" alt="" />
                            <b className="d-block m-1 txt_dark_bl fnt-largr sub-title-service">Assistance et suivi</b>
                            <p className="mt-2" style={{ textAlign: "justify" }}>
                            Afin de vous orienter et de répondre a vos questions,  n'hésitez pas a contacter notre service Client sur nos numéros .
                    </p>
                        </div>

                    </div>
                </div>


            </div>

            <div className="row no-marg bg-wt ps-2 pe-2 pb-5 " id="box_propos">
                <div className="col-md-3 text-center mt-5 mb-5">
                    <hr width="100%" />
                </div>
                <div className="col-md-6 text-center mt-5 mb-5 txt-dark-bl">
                    <h1 className="title-green">A propos </h1>
                </div>
                <div className="col-md-3 text-center mt-5 mb-5">
                    <hr width="100%" />
                </div>

                <div className="col-md-12 ">
                    <div className="row">

                        <div className="col-md-8 offset-md-2 text-center">
                            <b className="fnt-w5 fnt-largr">
                            Particulier, professionnels, e-commerçants ? <br/>
                            Delivo est  une société de Livraison de Courrier & Colis sur toute la Tunisie qui offre un service de livraison rapide complet au quotidien avec un suivi en temps réel . 
                            Notre société offre une prestation complète de la collecte des colis jusqu'à la livraison grâce à notre large gamme de technologies innovantes en matière de transport et  de logistique .
                            </b>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row no-marg   ps-2 pe-2 pb-5 pt-5 " id="box_signup">
                        <div className="col-md-12 text-center" style={{zIndex : 9}}>
                            <h1 className="txt-wt">Inscrivez vous</h1>
                        <button className="btn btn-primary btn_bg_sign btn_start" onClick={goRegister} >Commencer</button>

                        </div>
            </div>






        </>
    )
}

export default HomePage;