import React, { useContext, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { api_url } from "../../../services/http_constant";
import UserStorage from "../../../services/UserStorage";
import { UserRoleBg, UserRoleLabels, UserStatusBg, UserStatusLabels, etat as userEtat, role as userRole } from "../../../utils/constants/User.enum";
import { getDays, getStringDate } from "../../../utils/helpers/date.helper";

const mode_reglement_labels = {
  cheque_especes : 'Chèque ou espèces',
  especes : 'Seulement en espèces'
}
const UserDetailsAdm = forwardRef( ({ show, onClose, user, onShowForm, onShowModal, load_data, lst_hub, onUpdateUser, loading, ...props } , ref ) => {
  const [show_hub_form, set_show_hub_form] = useState(false);

  useImperativeHandle(ref, () => ({
    closeHubForm() {
      set_show_hub_form(false)
    }
  }));

  const onEdit = () => {
    onShowForm(user, "edit-info");
  };
  const onEditFrais = () => {
    onShowForm(user, "edit-frais");
  };
  const onEditPass = () => {
    onShowForm(user, "edit-pass");
  };
  const onEditDettePass = () => {
    onShowForm(user, "edit-dette-pass");
  };

  const onEnable = () => {
    onShowModal(user, "enable");
  };
  const onDisable = () => {
    onShowModal(user, "disable");
  };
  const onDelete = () => {
    onShowModal(user, "delete");
  };

  useEffect(()=>{
    if(!show){
      set_show_hub_form(false)
    }
  },[show])

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>



      {user && (
        <div className="row no-marg bg-wt p-4">
          <div className="col-md-12">
            <br />
            <button className="btn btn-outline-primary me-1" onClick={onEdit}>
              <FontAwesomeIcon icon={["fas", "edit"]} /> Edit
            </button>
            {user?.role === userRole.EXPD && (
              <button className="btn btn-outline-primary me-1" onClick={onEditFrais}>
                <FontAwesomeIcon icon={["fas", "edit"]} /> Edit frais & Config
              </button>
            )}
            {(user?.role === userRole.ADM || user?.role === userRole.LIV || user?.role === userRole.EXPD) && (
              <button className="btn btn-outline-primary me-1" onClick={onEditPass}>
                <FontAwesomeIcon icon={["fas", "edit"]} /> Edit Password
              </button>
            )}
            {(user?.role === userRole.EXPD) && (
              <button className="btn btn-outline-primary me-1" onClick={onEditDettePass}>
                <FontAwesomeIcon icon={["fas", "lock"]} /> Sécurité Facturation
              </button>
            )}
            {user?.etat === userEtat.active && user?.role != userRole.ROOT && (
              <button className="btn btn-outline-danger me-1" onClick={onDisable}>
                <FontAwesomeIcon icon={["fas", "ban"]} /> Désactiver
              </button>
            )}
            {(user?.etat === userEtat.disabled || user?.etat === userEtat.invalid) && (
              <button className="btn btn-outline-success me-1" onClick={onEnable}>
                <FontAwesomeIcon icon={["fas", "check"]} /> Activer
              </button>
            )}
            {user?.role != userRole.ROOT && (
              <button className="btn btn-outline-danger me-1" onClick={onDelete}>
                <FontAwesomeIcon icon={["fas", "trash-alt"]} /> Supprimer
              </button>
            )}
            {(user?.role === userRole.EXPD || user?.role === userRole.ADM || user?.role === userRole.LIV) && (
              <button className="btn btn-outline-success " onClick={() => set_show_hub_form(true)}>
                <FontAwesomeIcon icon={["fas", "edit"]} /> Changer Hub
              </button>
            )}
            
            {show_hub_form && <UserHubForm onClose={(e) => set_show_hub_form(false)} lst_hub={lst_hub} onUpdateUser={onUpdateUser} user={user} loading={loading} />}

          </div>

          <div className="col-md-12 dvdr-t-gray mt-2"></div>
          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">Role </span>
              <span className={`badge fnt-w5 ${UserRoleBg[user?.role]}`}>{UserRoleLabels[user?.role]}</span>
            </b>
          </div>
          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">Status du compte </span>
              <span className={`badge fnt-w5 ${UserStatusBg[user?.etat]}`}>{UserStatusLabels[user?.etat]}</span>
            </b>
          </div>

          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">Nom </span>
              <span>{user?.nom}</span>
            </b>
          </div>
          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">Prenom </span>
              <span>{user?.prenom}</span>
            </b>
          </div>
          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">e-mail </span>
              <span>{user?.email}</span>
            </b>
          </div>
          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">Tel </span>
              <span>{user?.tel}</span>
            </b>
          </div>
          <div className="col-md-12">
            <b className="fnt-w5">
              <span className="spn_inf_tit">adresse </span>
              <span>{user?.adresse}</span>
            </b>
          </div>
          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Société </span>
                <span>{user?.nom_com}</span>
              </b>
            </div>
          )}

          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Type de produit </span>
                <span>{user?.produit_type}</span>
              </b>
            </div>
          )}
          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">volume</span>
                <span>{user?.volume}</span>
              </b>
            </div>
          )}
          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Flux d'expédition </span>
                <span>{user?.flux_exped}</span>
              </b>
            </div>
          )}

          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Mode réglement </span>
                <span>{mode_reglement_labels[user?.mode_reglement]}</span>
              </b>
            </div>
          )}

          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Frais Livraison </span>
                <span>{user?.frais_liv ? user?.frais_liv + " TND" : "Non-spécifié"}</span>
              </b>
            </div>
          )}

          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Frais retour </span>
                <span>{user?.frais_retour ? user?.frais_retour + " TND" : "Non-spécifié"}</span>
              </b>
            </div>
          )}

          {user?.role === userRole.EXPD && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Frais Poids Supp </span>
                <span>{user?.frais_poids_supp_kg ? user?.frais_poids_supp_kg + " TND/KG" : "Non-spécifié"}</span>
              </b>
            </div>
          )}

          {(user?.role === userRole.ADM || user?.role === userRole.EXPD ) && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Hub affecté </span>
                <span className="text-danger">{user?.hub?.nom || 'Aucune'}</span>
              </b>
            </div>
          )}

          {user?.role === userRole.LIV && (
            <div className="col-md-12">
              <b className="fnt-w5">
                <span className="spn_inf_tit">Matricule de véhicule </span>
                <span>{user?.matricule}</span>
              </b>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

const UserHubForm = ({ onClose, lst_hub, user, onUpdateUser, loading }) => {
  const [sel_hub, set_sel_hub] = useState("");
  const onSubmit = () => {
    if (sel_hub && user) onUpdateUser({ id: user?.id, id_hub: sel_hub });
  };

  useEffect(() => {
    set_sel_hub(user?.id_hub);
  }, [user?.id_hub]);
  return (
    <div className="row  bg-wt py-1 my-1 dvdr-t-gray dvdr-b-gray">
      <Form.Group as={Col} controlId="formGridState" className="col-md-6 px-2">
        <Form.Label className="no-marg small-label">Changer Hub</Form.Label>
        <Form.Control as="select" defaultValue="Choose..." size="sm" className="force-apparence" value={sel_hub} onChange={(e) => set_sel_hub(e.target.value)}>
          <option value="">Choisissez un Hub</option>
          {Array.isArray(lst_hub) && lst_hub.map((el) => <option value={el?.id}>{el?.nom}</option>)}
        </Form.Control>
      </Form.Group>

      <div className="col-md-6 text-end pt-3">
        <button className="btn btn-sm btn-secondary me-1" onClick={onClose} disabled={loading}>
          Annuler
        </button>
        <button className="btn btn-sm btn-success" onClick={onSubmit} disabled={loading}>
          Confirmer
        </button>
      </div>
    </div>
  );
};

export default UserDetailsAdm;
