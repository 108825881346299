/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisMuList from '../../../ui/lists/ColisMu.list';
import MuCreateHeader from '../../../ui/headers/MuCreate.header';
import { clear, edit, searchColis, getByCode } from '../../../slices/Mu';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from '../../../utils/helpers/routing.helper';

const CreateMu = (props) => {

    const dispatch = useDispatch();
    const { loading, error, done, item } = useSelector(state => state.mu);
    const history = useHistory()
    const [lst_colis, set_lst_colis] = useState([]);
    const [sel_colis, set_sel_colis] = useState();
    const id = props.match.params.code;

    const onSubmit = () => {
        if (lst_colis && item) {
            let sel_c = lst_colis.map(i => (i.ref));
            dispatch(edit({ id: item.id, colis: sel_c })).unwrap().then(data => {
                history.push(routes.mu_list)
            })
        }

    }


    const onSearch = (search, callback) => {

        let locals = searchFromSrc(search);
        dispatch(searchColis(search)).unwrap().then(data => {

            if (Array.isArray(data) && data.length)
                callback(data.map(i => ({ label: `${i.code}.(${i.nom_cl} ${i.prenom_cl} )`, value: i })))
            else if (locals)
                callback(locals.map(i => ({ label: `${i.code}.(${i.nom_cl} ${i.prenom_cl} )`, value: i })))
            else
                callback([]);
        })
    }

    const searchFromSrc = (s) => {
        console.log("search from src ");
        let rslt = [];
        lst_colis.forEach(elem => {
            if (String(elem.code).includes(s)) {
                rslt = [...rslt, elem]
            }
        });
        //console.log(rslt);
        return rslt;
    }

    const onCancel = (indx) => {
        let array = [...lst_colis];

        if (indx !== -1) {
            array.splice(indx, 1);
            set_lst_colis(array);
        }
    }





    useEffect(() => {
        if (id) {
            dispatch(getByCode(id))
        }

        return () => {
            dispatch(clear());
        }
    }, [])

    useEffect(() => {
        if (item) {
            set_lst_colis(item.colis)
        }
    }, [item])

    return (
        <>
            <MuCreateHeader onSubmit={onSubmit} onSearch={onSearch} error={error} loading={loading} lst_data={lst_colis} set_lst_data={set_lst_colis} isEdit={true} />
            <ColisMuList lst_data={lst_colis} loading={loading} error={error} onCancel={onCancel} />
        </>
    )
}

export default CreateMu;



