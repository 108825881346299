import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MovableZoneItemAdm from "./TransferHub.item";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import UserStorage from "../../../services/UserStorage";
import ConfirmModal from "../../../ui/modals/ConfirmModal";
import noDataIcon from "../../../assets/img/no_data.png";
import DriverPickModal from "../../../ui/modals/DriverPickModal";
import HubBox from "../home/HubBox.container";

import { clear, listMuTransfert, dispatchMuTransfert, deleteMuTransfert, loadDrivers } from "../../../slices/MuAdm";
import { useDispatch, useSelector } from "react-redux";
import MuTransfertAdmDetails from "../../../ui/layouts/MuTransfertAdmDetails";
import { Button, Table } from "react-bootstrap";

import { MuLabels, MuStatus, stat as Mv_state } from "../../../utils/constants/Movable.enum";
import { getStringDate } from "../../../utils/helpers/date.helper";
import MuTransfertPrintContainer from "./MuTransfertPrint.container";
import { Link } from "react-router-dom";
import PaginationBox from "../../../ui/pagination/PaginationBox";

const ReceiveHub = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { current_hub } = useSelector((state) => state.hub);
  const { loading, error, item_list, selected_item, driver_list, expeds_list } = useSelector((state) => state.mu_adm);

  const [lst_mu, set_lst_mu] = useState([]);
  const [sel_item, set_sel_item] = useState([]);
  const [sel_liv, set_sel_liv] = useState(null);

  const [showDispModal, setShowDispModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  const [showDelModal, setShowDelModal] = useState(false);
  const [mod_del_title, set_mod_del_title] = useState("");
  const [mod_del_msg, set_mod_del_msg] = useState("");

  //pagination
  const [last_filter, set_last_filter] = useState(null);
  const [page, set_page] = useState(0);
  const [count, set_count] = useState(0);

  const load_mu_transfert = () => {
    dispatch(listMuTransfert({ page: 0 }))
      .unwrap()
      .then((res) => {
        set_lst_mu(Array.isArray(res?.rows) ? res?.rows : []);
        set_page(0);
        set_count(+res?.count);
      });
  };

  const on_paginate = (value) => {
    set_page(value);
    dispatch(listMuTransfert({ page: value }))
    .unwrap()
      .then((res) => {
        set_lst_mu(Array.isArray(res?.rows) ? res?.rows : []);
      });
  };

  const onShowDetails = (data) => {
    set_sel_item(data);
    setShowDetails(true);
  };

  const onCloseDetails = (data) => {
    set_sel_item(null);
    setShowDetails(false);
  };

  const onAskDispatch = (data) => {
    if (data) {
      set_sel_item(data);
      setShowDispModal(true);
    }
  };

  const onCloseDispMod = () => {
    set_sel_item(null);
    setShowDispModal(false);
    set_sel_liv(null);
  };

  const onDispatchTransfert = () => {
    if (sel_item && sel_liv) {
      dispatch(dispatchMuTransfert({ id: sel_item?.id, id_liv: sel_liv }))
        .unwrap()
        .then((d) => {
          onCloseDispMod();
          load_mu_transfert();
        });
    }
  };

  const onAskDelete = () => {
    if (sel_item) {
      set_mod_del_title("Annuler unité de transfert");
      set_mod_del_msg("Voulez-vous annuler l'unité de transfert' " + sel_item.ref + " ?");
      setShowDelModal(true);
    }
  };

  const onDeleteTransfert = () => {
    if (sel_item) {
      dispatch(deleteMuTransfert(sel_item?.id))
        .unwrap()
        .then((d) => {
          onCloseDelMod();
          set_sel_item(null);
          load_mu_transfert();
        });
    }
  };

  const onCloseDelMod = () => {
    setShowDelModal(false);
  };

  const onPrint = () => {
    if (sel_item) setShowPrint(true);
  };

  const onPickupMuTransfert = (ref) => {
    history.push(`pick/${ref}`);
  };

  useEffect(() => {
    load_mu_transfert();
  }, [current_hub]);

  useEffect(() => {
    dispatch(loadDrivers());

    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Transfert Hub </b>
        </div>

        <div className="col-md-2"></div>

        <div className="col-md-4 no-padd  ">
          <HubBox />
        </div>
      </div>
      <div className="row no-marg rw-header py-1">
        {/* <div className="col-md-4 flex_ctr">

                    <Form.Group as={Col} controlId="formGridState" className="no-marg">
                        <Form.Control type="search" className="form-control inpt_search shadow-none" onChange={searchOnchange} placeholder="Search ..." />
                        <button className="btn btn_search" onClick={handleSearch}><FontAwesomeIcon icon={['fas', 'search']} /></button>
                    </Form.Group> 

                </div> */}

        <div className="col-md-8 ">
          <b>
            {Array.isArray(lst_mu) && lst_mu.length} <span className="txt-gray fnt-w5 fnt-sm">unité de transfert</span>{" "}
          </b>
        </div>
        <div className="col-md-4 text-end">
          <Link to="create">
            <button className="btn btn-success btn-sm">+ Nouveau Transfert</button>
          </Link>
        </div>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
        </div>
      )}

      <div className="col-md-12">
        {loading && (
          <div class="col-md-12 p-4 txt-ctr">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}

        <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={"transfert"} />

        <MuTransfertTable lst_mu={lst_mu} current_hub={current_hub} onShowDetails={onShowDetails} onPickup={onPickupMuTransfert} onDispatch={onAskDispatch} />
        <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={"transfert"} />
        {(!Array.isArray(lst_mu) || !lst_mu.length) && (
          <div className="col-md-12 txt-ctr">
            <br />
            <br />
            <br />
            <img src={noDataIcon} alt="" width="10%" />
            <br />
            <b className="txt-gray fnt-w5">NO DATA FOUND</b>
          </div>
        )}
      </div>

      <DriverPickModal onHide={onCloseDispMod} onOk={onDispatchTransfert} show={showDispModal} loading={loading} onSelectDriver={set_sel_liv} drivers={driver_list} />
      <MuTransfertAdmDetails data={sel_item} isShow={showDetails} onHide={onCloseDetails} onPrint={onPrint} onDelete={onAskDelete} />
      <ConfirmModal show={showDelModal} onHide={onCloseDelMod} onOk={onDeleteTransfert} title={mod_del_title} msg={mod_del_msg} loading={loading} />
      <MuTransfertPrintContainer show={showPrint} onClose={(e) => setShowPrint(false)} mu={sel_item} />
    </>
  );
};

const MuTransfertTable = ({ lst_mu, current_hub, onShowDetails, onPickup, onDispatch }) => {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Num</th>
          <th>Hub Expedition</th>
          <th>Hub Destination</th>
          <th>Livreur</th>
          <th>Etat</th>
          <th>Nbr Colis</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(lst_mu) &&
          lst_mu.map((el) => (
            <tr>
              <td className="fnt-w5">{el?.ref}</td>
              <td>{el?.mu_hub_exped?.nom}</td>
              <td>{el?.mu_hub_dest?.nom}</td>
              <td>{el?.mvLiv ? `${el?.mvLiv?.nom} ${el?.mvLiv?.prenom}` : "Aucun"}</td>
              <td>
                <span className={`badge text-capitalize ${el?.etat == MuStatus.PENDING ? "bg-warning" : el?.etat == MuStatus.IN_PROGRESS ? "bg-success" : "bg-secondary"}`}>
                  {MuLabels[el?.etat] ? MuLabels[el?.etat] : el?.etat}
                </span>
              </td>
              <td>{el?.nbr_colis}</td>
              <td>{el?.createdAt ? getStringDate(el.createdAt) : "---"}</td>
              <td className="text-center">
                {el?.etat == MuStatus.PENDING && el?.id_hub_exped == current_hub && (
                  <Button className="btn btn-sm btn-primary me-1" onClick={(e) => onDispatch(el)}>
                    <FontAwesomeIcon icon={["fas", "shipping-fast"]} /> Dispatcher
                  </Button>
                )}
                {el?.etat == MuStatus.IN_PROGRESS && el?.id_hub_dest == current_hub && (
                  <Button className="btn btn-sm btn-success me-1" onClick={(e) => onPickup(el?.ref)}>
                    <FontAwesomeIcon icon={["fas", "truck-loading"]} /> Réceptionner
                  </Button>
                )}
                <Button className="btn btn-sm btn-warning me-1" onClick={(e) => onShowDetails(el)}>
                  <FontAwesomeIcon icon={["fas", "eye"]} /> Détails
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
export default ReceiveHub;
