import React from "react";
import { Form, Table } from "react-bootstrap";

const ChequeBillList = ({ loading, lst_cheque, set_lst_cheque }) => {
  const onSelectItem = (idCheque, value) => {
    if (Array.isArray(lst_cheque)) set_lst_cheque(lst_cheque.map((el) => (el?.idCheque == idCheque ? { ...el, selected: value } : el)));
  };
  const onSelectAllItem = (value) => {
    if (Array.isArray(lst_cheque)) set_lst_cheque(lst_cheque.map((el) => ({ ...el, selected: value })));
  };
  return (
    <>
      <div className="row no-marg rw-header ">
        <div className="col-md-4 p-2">
            <h6 className="fnt-w7">Chéques</h6>
        </div>
      </div>
      <div className="row m-0">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>
                <Form.Group id="formGridCheckbox" className="no-marg">
                  <Form.Check type="checkbox" className="chek_size_lg" id="chk" onChange={(e) => onSelectAllItem(e.target.checked)} />
                </Form.Group>
              </th>
              <th>Numéro</th>
              <th>Expéditeur</th>
              <th>Client</th>
              <th>Montant (TND)</th>
              <th>Etat</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(lst_cheque) &&
              lst_cheque.map((el, index) => (
                <tr>
                  <td>
                    <Form.Group id="formGridCheckbox" className="no-marg px-2">
                      <Form.Check type="checkbox" className="chek_size_lg" id="chk" checked={el?.selected} onChange={(e) => onSelectItem(el?.idCheque, e.target.checked)} />
                    </Form.Group>
                  </td>
                  <td>{el?.num}</td>
                  <td>{el?.chequeFor?.nom_com}</td>
                  <td>{el?.proprietaire}</td>
                  <td className="text-success fnt-w5">{el?.montant}</td>
                  <td>
                    <span className={`badge text-capitalize ${el?.etat == "dispo" ? "bg-success" : "bg-danger"}`}>{el?.etat}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ChequeBillList;
