/* eslint-disable */
import React, {  useState , useEffect} from 'react';
import ColisForm from '../../../ui/forms/Colis.form';
import { clear , edit , getByCode } from '../../../slices/Colis';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {routes} from '../../../utils/helpers/routing.helper';
import { searchLocation } from '../../../slices/Location.slice';

const EditColis = (props) => {

    const dispatch = useDispatch();
    const { loading , error , done , data  } = useSelector(state => state.colis);
    const id = props.match.params ;
    const history = useHistory()

    const onSubmit = updt_data => {
        console.log(updt_data)
        dispatch(edit(updt_data)).unwrap().then(d => {
            history.push(routes.mesColis)
        })
        
    }

    const onSearchLocation = search => {
        return dispatch(searchLocation(search)).unwrap()
    }

    useEffect(()=>{

        if(id){
            dispatch(getByCode(id?.id));
        }

        return ()=>{
            dispatch(clear());
        }
    },[])

    return (
        <ColisForm onDataSubmit={onSubmit}   loading={loading} error={error} done={done} isEdit ={true} colis={data} onSearchLocation={onSearchLocation}  />
    )
}

export default EditColis ;



