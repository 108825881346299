import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import UserService from '../services/UserService';
import storage from '../services/UserStorage';

const initialState = [];

export const login = createAsyncThunk(
    "auth/login",
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const res = await UserService.login(email, password);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        error: '',
        data: null,
        loggedUser: null,
    },
    reducers: {
        clear : (state , action) => {
            state.loading = false ;
            state.error = '';
            state.data = null ;
        },
        logout : (state , action ) => {
            storage.logout();
        },
        loadLoggedUser : (state , action ) => {
            state.loggedUser = storage.getData()
        },
        
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.success) {
                    
                    storage.setData(action.payload.user);
                    storage.setToken(action.payload.token);
                    state.error = '';
                    state.data = action.payload;
                    state.loggedUser = action.payload.user;
                }else{
                    state.error = 'Vous devez nous contacter afin de valider votre compte .';
                }
            }
            
            state.loading = false;
            
        },
        [login.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [login.pending]: (state, action) => {
            state.loading = true;
            state.loggedUser = null;
        }
    }
})

const { reducer } = authSlice;
export const { logout , clear, loadLoggedUser } = authSlice.actions;
export default reducer;