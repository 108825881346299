import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { stat as colisStat } from '../../utils/constants/Colis.enum';
import { useHistory } from "react-router-dom";
import { routes } from '../../utils/helpers/routing.helper';

function ColisClientSearchHeader({ onSearch , loading, lst_data , error }) {

    const [search, setSearch] = useState("");
    const history = useHistory()

    const searchOnchange = e => {
        setSearch(e.target.value);
    }

    const onSearchClick = e => {
        onSearch(search)
    }

 

    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'box-open']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Colis   </b>
                </div>

            </div>

            <div className="row no-marg rw-header ">

                <div className="col-md-6 offset-md-3 flex_ctr p-2">

                    <Form.Group as={Col} controlId="formGridState" className="no-marg">
                        <Form.Control type="search" className="form-control inpt_search shadow-none" placeholder=" Recherche ..." value={search} onChange={searchOnchange} onKeyPress={(e) => { e.key === 'Enter' && onSearchClick(e); }} />
                        
                        {loading ?
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            :
                            <button className="btn btn_search" onClick={onSearchClick}><FontAwesomeIcon icon={['fas', 'search']} /></button>
                        }
                    </Form.Group>

                </div>



                <div className="col-md-6  fnt-largr">

                    <div className="row no-marg ">

                        <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
                            <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">Colis</b>
                        </div>

                        <div className="col-md-9 dvdr-r-gray pt-1 pb-1">
                           
                        </div>

                    </div>


                </div>

                <div className="col-md-6 no-padd mb-2 ">

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                  
                </div>



            </div>

        </>
    );
}

export default ColisClientSearchHeader;