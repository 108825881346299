/* eslint-disable */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import ColisList from "../../../ui/lists/ColisAdm.list";
import RunsheetConfirmHeader from "../../../ui/headers/RunsheetConfirm.header";
import ColisAdmDetails from "../../../ui/layouts/ColisAdmDetails";
import ConfirmModal from "../../../ui/modals/ConfirmModal";

import { clear, getRsByCode, confirmRs } from "../../../slices/RsAdm";
import { clear as clear_colis, selectColis, editColisInfo } from "../../../slices/ColisAdm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from "../../../services/UserStorage";
import { routes } from "../../../utils/helpers/routing.helper";
import { stat as rsStat } from "../../../utils/constants/Runsheet.enum";
import { ColisStatus, ColisTypeEnvoie, ColisTypeEnvoieLabels } from "../../../utils/constants/Colis.enum";

const Runsheet = (props) => {
  const dispatch = useDispatch();
  const { loading, error, item } = useSelector((state) => state.rs_adm);
  const { loading: loading_colis, error: error_colis, selected_colis } = useSelector((state) => state.colis_adm);

  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const hideConfirmMod = () => {
    setShowConfirmModal(false);
  };
  const doShowConfirmModal = () => {
    setShowConfirmModal(true);
  };
  const [mod_Confirm_title, set_mod_Confirm_title] = useState("");
  const [mod_Confirm_msg, set_mod_Confirm_msg] = useState("");
  const id = props.match.params.code;

  const onItemDetailsClick = (data) => {
    dispatch(selectColis(data));
    setShowDetails(true);
  };

  const hideDetails = () => {
    setShowDetails(false);
  };

  const onPrint = (data) => {
    if (data) {
      UserStorage.setParam([data]);
      const win = window.open("/dashb/colis/print/", "_blank");
      win.focus();
    }
  };

  const onEditField = (field, value) => {
    dispatch(editColisInfo({ id: selected_colis.ref, field: field, f_data: value }))
      .unwrap()
      .then((d) => {
        dispatch(getRsByCode(id));
        setShowDetails(false);
      });
  };

  const askConfirm = () => {
    if (item) {
      let mnt = 0.0;
      if (Array.isArray(item.colis))
        item.colis.forEach((element) => {
          if (element.etat === ColisStatus["LIV-COURS-SUCC"]) mnt = mnt + +element.montant_ttc;
        });

      let tb = (
        <table className="table table-striped table-bordered fnt-sm">
          <tbody>
            <tr>
              <td colSpan="2">Vous confirmez que vous avez recus ?</td>
            </tr>
            <tr>
              <td colSpan="2" className="fnt-w5 fnt-larg">
                Montant <span className="txt-green">{mnt} TND</span>{" "}
              </td>
            </tr>
            {item.colis
              .filter(
                (elem) =>
                  (elem.etat == ColisStatus["LIV-COURS-ECHEC"]&& elem?.type_envoi != ColisTypeEnvoie.recuperation) ||
                  (elem.etat == ColisStatus["LIV-COURS-REPO"] && elem?.type_envoi != ColisTypeEnvoie.recuperation) ||
                  (elem.etat == ColisStatus["LIV-COURS-SUCC"] && elem?.type_envoi == ColisTypeEnvoie.recuperation) ||
                  (elem.etat == ColisStatus["LIV-COURS-SUCC"] && elem?.type_envoi == ColisTypeEnvoie.echange)
              )
              .map((item) => (
                <tr>
                  <td className="p-1">
                    {" "}
                    <b>Colis</b>{" "}
                  </td>
                  <td className="p-1">{item.code} [{ColisTypeEnvoieLabels[item?.type_envoi]}]</td>
                </tr>
              ))}
          </tbody>
        </table>
      );

      set_mod_Confirm_title("Confirmer runsheet ");
      set_mod_Confirm_msg(tb);
      setShowConfirmModal(true);
    }
  };

  const onConfirm = () => {
    if (item) {
      dispatch(confirmRs(item.id))
        .unwrap()
        .then((d) => {
          history.push(routes.rs_adm_reco);
        });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getRsByCode(id));
    }

    return () => {
      dispatch(clear());
      dispatch(clear_colis());
    };
  }, []);

  return (
    <>
      <RunsheetConfirmHeader onConfirm={askConfirm} data={item} error={error} loading={loading} loading_colis={loading_colis} error_colis={error_colis} />
      <ColisList loading={loading} error={error} lst_data={item && Array.isArray(item.colis) ? item.colis : []} onItemDetailsClick={onItemDetailsClick} />
      <ColisAdmDetails data={selected_colis} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEditField={onEditField} loading={loading_colis} />
      <ConfirmModal onHide={hideConfirmMod} onOk={onConfirm} title={mod_Confirm_title} msg={mod_Confirm_msg} show={showConfirmModal} loading={loading} />
    </>
  );
};

export default Runsheet;
