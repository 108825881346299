import React from "react";
import Item from "../items/BillClient.item";
import noDataIcon from "../../assets/img/no_data.png";
import { Table } from "react-bootstrap";
import { getStringDate } from "../../utils/helpers/date.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MuClientList = ({ loading, lst_data, onValid, onItemDetailsClick, onPrint }) => {
  return (
    <div className="col-md-12 bg-wt mt-2">
      {loading ? (
        <div class="col-md-12 p-4 txt-ctr">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        Array.isArray(lst_data) && (
          //lst_data.map((s, index) => <Item key={`bill_.${index}`} data={s} onItemClick={onItemDetailsClick} onValid={onValid} onPrint={onPrint} />)
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Réf</th>
                <th>Expéditeur</th>
                <th>Nbr Colis</th>
                <th>Montant Colis</th>
                <th>Frais Service</th>
                <th>Montant à payer</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(lst_data) &&
                lst_data.map((el) => (
                  <tr>
                    <td className="fnt-w5 text-primary">{el?.ref}</td>
                    <td>{el?.facFor?.nom_com}</td>
                    <td>{el?.nbr_colis}</td>
                    <td>{el?.montant_colis}</td>
                    <td>{el?.montant_frais_service}</td>
                    <td className="text-success fnt-w5">{el?.montant_paye}</td>
                    <td>{getStringDate(el?.createdAt)}</td>
                    <td>
                      <button className="btn btn-sm btn-primary me-1" disabled={loading} onClick={(e) => onPrint(el)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} /> Détails
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )
      )}

      {!lst_data.length && !loading && (
        <div className="col-md-12 txt-ctr">
          <br />
          <br />
          <br />
          <img src={noDataIcon} alt="" width="10%" />
          <br />
          <b className="txt-gray fnt-w5">NO DATA FOUND</b>
        </div>
      )}
    </div>
  );
};

export default MuClientList;
