import React, { useState, useEffect , useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import UserStorage from '../../../services/UserStorage';
import noDataIcon from '../../../assets/img/no_data.png';
import { useReactToPrint } from 'react-to-print';
import { api_url } from '../../../services/http_constant';
import { ColisStatusBg, stat as colisStat, colisStatusLabels } from '../../../utils/constants/Colis.enum';
import { getStringDate  } from '../../../utils/helpers/date.helper';

const ClientHistory = (props) => {

    //data list
    const [list, setList] = useState([]);
    const [selClient, setSelClient] = useState(0);
    const [listExped, setListExped] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const componentRef = useRef();

    const style_align_base = {
        alignItems: "flex-end"
    }
    //routing 
    const history = useHistory();
    //user infi
    const [user, setUser] = useState(UserStorage.getData());
    const [token, setToken] = useState(UserStorage.getToken());

    

    const renderStatus = (colis) => {
        let badge;
        if (colis.etat === colisStat.closed) {
            if (colis.livre == true) {
                badge = <span className="badge bg-success fnt-w5"> Payé(Livré) </span>
            } else if (colis.livre == false) {
                badge = <span className="badge bg-success fnt-w5"> Payé(Non-Livré) </span>
            }
        } if (colis.etat === colisStat.completed) {
            if (colis.livre == true) {
                badge = <span className="badge bg-primary fnt-w5"> Non-Payé(Livré) </span>
            } else if (colis.livre == false) {
                badge = <span className="badge bg-primary fnt-w5"> Non-Payé(Non-Livré) </span>
            }
        } if (colis.etat === colisStat.completed_non_retourne) {
            if (colis.livre == true) {
                badge = <span className="badge bg-primary fnt-w5"> A retourné(Livré) </span>
            } else if (colis.livre == false) {
                badge = <span className="badge bg-primary fnt-w5"> A retourné(Non-Livré) </span>
            }
        } else if (colis.etat === colisStat.progress || colis.etat === colisStat.progress_success || colis.etat === colisStat.progress_reported || colis.etat === colisStat.progress_fail) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> livraison en cours </span>
        } else if (colis.etat === colisStat.pickup) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> En depot </span>
        } else if (colis.etat === colisStat.pickup_prog) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Ramassage en cours </span>
        } else if (colis.etat === colisStat.in_rs) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Livraison programmé </span>
        } else if (colis.etat === colisStat.to_return) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Retour </span>
        } else if (colis.etat === colisStat.transfer_zone_progress) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Transfert zone en cours </span>
        } else if (colis.etat === colisStat.pd_receive) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> En attente de ramassage </span>
        } else if (colis.etat === colisStat.pd_transfer) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Non-envoyé </span>
        }


        return badge;
    }

    const handlePrint = useReactToPrint({
        documentTitle: "COLIS-GO",
        content: () => componentRef.current,
    });


    const loadDataExped = () => {
        axios.get(`${api_url}/api/users/adm/exped/list`, {
            headers: {
                'cgo-tkn': token
            }
        })
            .then(res => {
                console.log(res.data);
                setListExped(res.data)
            })
            .catch(error => {
                console.log(error)
            }
            );
    }

    const loadData = () => {
        if (selClient > 0) {
            setIsLoading(true);
            axios.post(`${api_url}/api/colis/adm/history`, { id_client: selClient }, {
                headers: {
                    'cgo-tkn': token
                }
            })
                .then(res => {
                    setIsLoading(false);
                    console.log(res.data);
                    setList(res.data)
                   // setList_src(res.data);
                })
                .catch(error => {
                    setIsLoading(false);
                    // setLoading(false);
                    if (error.response) {
                        console.log(error.response.data.message);
                        if (error.response.data.message === "invalid-token") {
                            UserStorage.logout();
                            history.push("/login");
                        }
                    }
                }
                );
        }
    }
    useEffect(() => {
        console.log("fetch data effect call");
        //loadData();
        loadDataExped();

    }, []);




  




    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'history']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Historique client </b>
                </div>

                <div className="col-md-6 no-padd dsp_inl_flex box_resum ">
                    <b className="box_resum-b padd-l10 dvdr-l-fx-gray"></b>

                </div>
            </div>
            <div className="row no-marg rw-header">



                <div className="row no-marg p-3">
               
                        <Form.Group as={Col} controlId="formGridState" className="col-md-8">
                            <Form.Label className="no-marg small-label">Vendeur</Form.Label>
                            <Form.Control as="select" defaultValue="Choose..." className="force-apparence" size="sm" onChange={(e) => setSelClient(e.target.value)}>
                                <option value="0">Choisissez un vendeur</option>
                                {listExped.map(c => <option value={c.id}>{c.nom_com}</option>)}

                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState" className="pt-3 col-md-4">
                            {isLoading ?
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                <button className="btn btn-outline-success me-3" onClick={loadData}> Chercher</button>
                                <button className="btn btn-outline-success me-3" onClick={handlePrint}> Imprimer</button>
                                <b className="txt-red fnt-w5 ml-3">{list ? list.length : '_'} Colis</b>
                                </>
                            }
                        </Form.Group>
                   




                </div>





                <div className="col-md-2 flex_ctr">

                </div>
            </div>

            <div className="col-md-12" ref={componentRef}>


                <table className="table table-striped table-bordered fnt-sm">
                    <thead>
                        <tr>
                            <th scope="col">Colis</th>
                            <th scope="col">Client</th>
                            <th scope="col">Montant</th>
                            <th scope="col">Status</th>
                            <th scope="col">Facture</th>
                            <th scope="col">Date-facture</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data =>

                            <tr>
                                <td><b>{data.code}</b></td>
                                <td>{data.nom_cl} {data.prenom_cl}</td>
                                <td>{data.montant_ttc}</td>
                                <td><span class={`badge badge-pill  ${ColisStatusBg[data?.etat]}`}>{colisStatusLabels[data?.etat]} </span></td>
                                <td><b>{data.factures.length ? data.factures[0].ref : '_'}</b></td>
                                <td>{data.factures.length ? getStringDate(data.factures[0].updatedAt) : '_'}</td>

                            </tr>

                        )}
                    </tbody>
                </table>

                {!list.length &&
                    <div className="col-md-12 txt-ctr">
                        <br />
                        <br />
                        <br />
                        <img src={noDataIcon} alt="" width="10%" />
                        <br /><b className="txt-gray fnt-w5">AUCUNE DONNEE TROUVEE</b>
                    </div>
                }
            </div>



        </>
    );
}
export default ClientHistory;