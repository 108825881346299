/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MuHistoryClient from '../../../ui/layouts/MuHistoryClient';
import { clear, getClientHistory , del , doSelect } from '../../../slices/Mu';
import { useDispatch, useSelector } from "react-redux";
import MuDetails from '../../../ui/layouts/MuDetails';
import UserStorage from '../../../services/UserStorage';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { routes } from '../../../utils/helpers/routing.helper';
import { useHistory } from "react-router-dom";


const HistoryMuClient = (props) => {

    const dispatch = useDispatch();
    const history = useHistory()
    
    const { loading, error, item_list , selected_item } = useSelector(state => state.mu);
    const [showDetails, setShowDetails] = useState(false);

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    const onPrint = () => {
        if (selected_item) {
            UserStorage.setParam(selected_item)
            const win = window.open(routes.mu_print, "_blank");
            win.focus();
        }
    }

    const onEdit = () => {
        if (selected_item)
            history.push(routes.mu_edit + selected_item.ref);
    }

    const onItemDetailsClick = data => {
        dispatch(doSelect(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }

    const onDelete = () => {
        if (selected_item) {
            set_mod_del_title("Supprimer unité mobile")
            set_mod_del_msg("Voulez-vous supprimer l'unité mobile' " + selected_item.ref + " ?")
            setShowDelModal(true);

        }
    }

    const doDelete = () => {
        if (selected_item) {
            console.log(selected_item)
            dispatch(del(selected_item.ref)).unwrap().then(() => {
                setShowDetails(false)
                setShowDelModal(false);
                dispatch(getClientHistory());
            })
        }
    }


    useEffect(() => {
        dispatch(getClientHistory())
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <MuHistoryClient lst_data={item_list}  error={error}  loading={loading} onItemClick={onItemDetailsClick}  />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} />
            <MuDetails data={selected_item} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} />

        </>
    )
}

export default HistoryMuClient;



