import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {isPendingAction , isRejectedAction , isFulfilledAction} from './slice.helper';
import Api from '../services/ChequeService';

const initialState = {
    loading: false,
    error: '',
    data: null,
}

export const createCheque = createAsyncThunk(
    "cheque/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createCheque(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const updateCheque = createAsyncThunk(
    "cheque/update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.editCheque(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const listCheque = createAsyncThunk(
    "cheque/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listCheque(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const deleteCheque = createAsyncThunk(
    "cheque/delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteCheque(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


const chequeSlice = createSlice({
    name: "cheque",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
        },

    },
    extraReducers: builder => {
        builder

            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
                state.data = action.payload;
            })






    }
})

const { reducer } = chequeSlice;
export const { clear } = chequeSlice.actions;
export default reducer;