/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisMuList from '../../../ui/lists/ColisMu.list';
import MuCreateHeader from '../../../ui/headers/MuCreate.header';
import { clear, create, searchColis } from '../../../slices/Mu';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from '../../../utils/helpers/routing.helper';

const CreateMu = (props) => {

    const dispatch = useDispatch();
    const { loading, error, done } = useSelector(state => state.mu);
    const history = useHistory()
    const [lst_colis, set_lst_colis] = useState([]);
    const [sel_colis, set_sel_colis] = useState();

    const onSubmit = () => {
        if (lst_colis) {
            let sel_c = lst_colis.map(i => (i.ref));
            dispatch(create({ colis: sel_c, nbr_colis: sel_c.length })).unwrap().then(data => {
                history.push(routes.mu_list)
            })
        }

    }


    const onSearch = (search , callback ) => {
        dispatch(searchColis(search)).unwrap().then(data => {
            if(Array.isArray(data))
            callback(data.map(i => ({ label: `${i.code}.(${i.nom_cl} ${i.prenom_cl} )`, value: i })))
        })
    }

    const onCancel = (indx) => {
        let array = [...lst_colis];

        if (indx !== -1) {
            array.splice(indx, 1);
            set_lst_colis(array);
        }
    }

   
    
   

    useEffect(() => {
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <MuCreateHeader onSubmit={onSubmit} onSearch={onSearch} error={error} loading={loading} lst_data={lst_colis} set_lst_data={set_lst_colis} />
            <ColisMuList lst_data={lst_colis} loading={loading} error={error} onCancel={onCancel} />
        </>
    )
}

export default CreateMu;



