/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { routes } from '../../../utils/helpers/routing.helper';
import { useHistory } from "react-router-dom";
import UserService from '../../../services/UserService';
import ResetPassForm from '../../../ui/forms/ResetPass.form';

const ResetPass = (props) => {

    const history = useHistory();
    const [loading, set_loading] = useState(false)
    const [error, set_error] = useState("")
    const [succ_msg, set_succ_msg] = useState("")
    const token = props.match.params.token;



    const doSubmit = (password) => {
        set_error("")
        set_succ_msg("")
        set_loading(true)

        if (token)
            UserService.resetPass({ pass: password }, token)
                .then(res => {
                    console.log(res)

                    if (res.data) {
                        if (res.data.done) {
                            set_succ_msg("Réinitialisation de mot de passe avec succés.")
                            setTimeout(() => {
                                history.push(routes.login)
                            }, 2000);
                        } else {
                            set_error("Echec ! ")
                            set_loading(false)
                        }
                    } else {
                        set_error("Echec ! ")
                        set_loading(false)
                    }
                })
                .catch(err => {
                    set_loading(false)
                    console.log(err)
                    const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
                    set_error(message)
                })
    }

    useEffect(() => {

        if (!token) {
            set_error("Lien de Réinitialisation de mot de passe expiré ou non valid !")
        }

        return () => {

        }
    }, [])



    return (
        <ResetPassForm onDataSubmit={doSubmit} loading={loading} error={error} succ={succ_msg} />
    )
}


export default ResetPass






