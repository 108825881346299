import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { role as userRole } from "../../../utils/constants/User.enum";
import { useDispatch, useSelector } from "react-redux";
import { clear, createAdm, createLiv } from "../../../slices/User.slice";

const UserForm = ({ show, onClose, onAfterSubmit, lst_hub }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);
  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Vous devez choisir le role d'utilisateur ! "),
    nom: Yup.string().required("Vous devez saisir le nom !"),
    prenom: Yup.string().required("Vous devez saisir le prénom !"),
    tel: Yup.string()
      .typeError("numéro tél non valide !")
      .min(8, "numéro tél non valide !")
      .max(8, "numéro tél non valide !")
      .required("Vous devez saisir le num de tél !")
      .test("tél valid", "tél non valid", (value) => (value + "").match(/^\d{1,9}?$/)),
    adresse: Yup.string(),
    id_hub: Yup.string(),
    matricule: Yup.string(),
    email: Yup.string().required("Vous devez saisir l' E-mail !").email("Email non valid !"),
    password: Yup.string().min(6, "Mot de passe non valid ( min 6 caractères ) !").required("Vous devez saisir le mot de passe !"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Les mot de passe ne sont identiques !")
      .required("Vous devez confirmez le mot de passe !"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, control } = useForm(formOptions);
  const { errors } = formState;
  const sel_role = useWatch({ control: control, name: "role" });

  const onSubmit = (data) => {
    if (data?.role == userRole.ADM) {
      dispatch(createAdm(data))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onAfterSubmit('create');
          }
        })
        .catch((err) => console.log(err));
    } else if (data?.role == userRole.LIV) {
      dispatch(createLiv(data))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onAfterSubmit('create');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row no-marg">
        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
        <div className="col-md-12  p-3 bg-wt ">
          <form onSubmit={handleSubmit(onSubmit)} id="frm_user" className="col-md-12">
          <h6>Création utilisateur:</h6>
            <div className="row m-0">
              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Role</Form.Label>
                <Form.Control as="select" defaultValue="Choose..." size="sm" {...register("role")} className={`form-control force-apparence ${errors.role ? "is-invalid" : "form-control"}`}>
                  <option value="">Choisissez un type</option>
                  <option value="admin">Admin</option>
                  <option value="liv">Livreur</option>
                </Form.Control>
                <p className="text-danger">{errors.role?.message}</p>
              </Form.Group>
        
                <Form.Group as={Col} className="col-md-6">
                  <Form.Label className="no-marg small-label">Hub</Form.Label>
                  <Form.Control as="select" defaultValue="Choose..." size="sm" {...register("id_hub")} className={`form-control force-apparence ${errors.id_hub ? "is-invalid" : "form-control"}`}>
                    <option value="">Choisissez un Hub</option>
                    {Array.isArray(lst_hub) && lst_hub.map((el) => <option value={el?.id}>{el?.nom}</option>)}
                  </Form.Control>
                  <p className="text-danger">{errors.id_hub?.message}</p>
                </Form.Group>
       
              <div className="col-md-12"></div>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Nom </Form.Label>
                <Form.Control size="sm" name="nom" {...register("nom")} className={`form-control ${errors.nom ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.nom?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Prenom </Form.Label>
                <Form.Control size="sm" name="prenom" {...register("prenom")} className={`form-control ${errors.prenom ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.prenom?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Tél</Form.Label>
                <Form.Control size="sm" name="tel" {...register("tel")} className={`form-control ${errors.tel ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.tel?.message}</p>
                </Form.Text>
              </Form.Group>
              {sel_role == userRole.LIV && (
                <Form.Group as={Col} className="col-md-6">
                  <Form.Label className="no-marg small-label">Matricule de véhicule </Form.Label>
                  <Form.Control size="sm" name="matricule" {...register("matricule")} className={`form-control ${errors.matricule ? "is-invalid" : "form-control"}`} />
                  <Form.Text className="text-muted txt-red">
                    <p className="text-danger">{errors.matricule?.message}</p>
                  </Form.Text>
                </Form.Group>
              )}

              <Form.Group as={Col} className="col-md-12">
                <Form.Label className="no-marg small-label">Adresse</Form.Label>
                <Form.Control size="sm" name="adresse" {...register("adresse")} className={`form-control ${errors.adresse ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.adresse?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">E-mail </Form.Label>
                <Form.Control size="sm" name="email" {...register("email")} className={`form-control ${errors.email ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.email?.message}</p>
                </Form.Text>
              </Form.Group>

              <div className="col-md-12"></div>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Mot de passe </Form.Label>
                <Form.Control size="sm" name="password" {...register("password")} className={`form-control ${errors.password ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.password?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Confirmation mot de passe </Form.Label>
                <Form.Control size="sm" name="confirmPassword" {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.confirmPassword?.message}</p>
                </Form.Text>
              </Form.Group>

              {loading ? (
                <div class="col-md-12 p-4 txt-ctr">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div class="col-md-12 text-end ">
                  <button className="btn btn-primary">
                    <FontAwesomeIcon icon={["fas", "check"]} /> Confirmer
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
