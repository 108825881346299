var UserStorage = (function() {

  
    var getData = function() {
      var user = JSON.parse(localStorage.getItem('cgo'));
      return user
    };
    var getToken = function() {
      var tkn = localStorage.getItem('cgo-tkn');
      return tkn
    };

    var getZone = function() {
      var zone = localStorage.getItem('cgo-zone');
      return zone
    };

    var getLastZone = function() {
      var zone = localStorage.getItem('cgo-last-zone');
      return zone
    };
  
    var setData = function(user) {
      localStorage.setItem('cgo', JSON.stringify(user));
    };
    var setToken = function(tkn) {
      localStorage.setItem('cgo-tkn', tkn);
    };

    var setZone = function(zone) {
      localStorage.setItem('cgo-zone', zone);
    };

    var setLastZone = function(zone) {
      localStorage.setItem('cgo-last-zone', zone);
    };

    var logout = function() {
      localStorage.removeItem('cgo');
      localStorage.removeItem('cgo-tkn');
    };

    var setParam = function(data) {
      localStorage.setItem('cgo-param', JSON.stringify(data));
    };
    var getParam = function() {
      var data = JSON.parse(localStorage.getItem('cgo-param'));
      return data
    };

    var setParamArr = function(data) {
      localStorage.setItem('cgo-param-arr', JSON.stringify(data));
    };
    var getParamArr = function() {
      var data = JSON.parse(localStorage.getItem('cgo-param-arr'));
      return data
    };



    function  makeCookie(cookieName, cookieValue)  {
      document.cookie = cookieName + " = " + cookieValue;
    }

    function delete_cookie( name ) {
      document.cookie = name + '=';
    }

    function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }
  
    return {
      getData: getData,
      setData: setData,
      logout:logout,
      setToken:setToken,
      getToken:getToken,
      setParam:setParam,
      getParam:getParam,
      setZone : setZone,
      getZone : getZone ,
      setLastZone : setLastZone ,
      getLastZone : getLastZone ,
      setParamArr : setParamArr ,
      getParamArr : getParamArr ,
    }
  
  })();
  
  export default UserStorage;