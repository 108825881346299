import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {isPendingAction , isRejectedAction , isFulfilledAction} from './slice.helper';
import Api from '../services/TicketService';

const initialState = {
    loading: false,
    error: '',
    data: null,
}

export const createExpedTicket = createAsyncThunk(
    "ticket/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createExpedTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const cancelExpedTicket = createAsyncThunk(
    "ticket/cancel",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.cancelExpedTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const listExpedTicket = createAsyncThunk(
    "ticket/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listExpedTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)




export const listAdmTicket = createAsyncThunk(
    "ticket/listAdmTicket",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listAdmTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const applyAdmTicket = createAsyncThunk(
    "ticket/applyAdmTicket",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.applyAdmTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const refuseAdmTicket = createAsyncThunk(
    "ticket/refuseAdmTicket",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.refuseAdmTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const cancelAdmTicket = createAsyncThunk(
    "ticket/cancelAdmTicket",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.cancelAdmTicket(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



const ticketSlice = createSlice({
    name: "ticket",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
        },

    },
    extraReducers: builder => {
        builder

            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
                state.data = action.payload;
            })






    }
})

const { reducer } = ticketSlice;
export const { clear } = ticketSlice.actions;
export default reducer;