import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getStringDate } from "../../../utils/helpers/date.helper";
import logo from "../../../assets/img/logo.png";
import Barcode from "react-barcode";

const MuTransfertPrintContainer = ({ mu, show, onClose }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Fiche",
    content: () => componentRef.current,
  });

  const [montant, set_montant] = useState(0.0);

  useEffect(() => {
    if (Array.isArray(mu?.colis)) {
      let tmp = mu?.colis?.reduce((acc, curr) => +acc + +Number(curr?.montant_ttc).toFixed(3), 0.0);
      set_montant(Number(tmp).toFixed(3));
    }
  }, [mu]);
  return (
    <div className={show ? "col-md-8 bg-gray inf_box_pop_r" : "col-md-8 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>

        {mu && (
          <div className="row m-0 bg-wt p-4">
            <div className="col-md-12 text-end">
              <button className="btn btn-outline-success" onClick={handlePrint}>
                Imprimer Fiche
              </button>
            </div>
            <div className="col-md-12">
              <table width="94%" className="table table-striped table-bordered  mt-2 fnt-sm" ref={componentRef}>
                <thead className="txt-ctr">
                  <tr>

                    <td colSpan={4}>
                      <table className="table table-striped table-bordered" width={"100%"}>
                        <tbody>
                          <tr>
                            <td>
                              <b className="d-block"><span className="fnt-w5">Ref:</span> {mu?.ref}</b>
                            </td>

                            <td colSpan={2} className="text-center">
                              <img src={logo} alt="" width="82px" />
                              <h5>Unité de Transfert Hub</h5>
                            </td>

                            <td>
                              <b className="d-block"> <span className="fnt-w5">Nombre de colis:</span> {Array.isArray(mu?.colis) ? mu?.colis?.length : "---"}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b className="d-block fnt-w5">Date:</b>
                              <b className="d-block">{getStringDate(mu?.createdAt)}</b>
                            </td>

                            <td>
                              <b className="d-block fnt-w5">Hub d'expédition:</b>
                              <b className="d-block">{mu?.mu_hub_exped?.nom}</b>
                            </td>
                            <td>
                              <b className="d-block fnt-w5">Hub de destination:</b>
                              <b className="d-block">{mu?.mu_hub_dest?.nom}</b>
                            </td>

                            <td>
                              <b className="d-block fnt-w5">Montant total:</b>
                              <b className="d-block">{montant} TND</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                  </tr>
                  <tr>
                    <th>Code</th>
                    <th>Info</th>
                    <th>Montant</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(mu?.colis) &&
                    mu?.colis?.map((el) => (
                      <tr>
                        <td className="text-center">
                          <Barcode value={el?.code} width={1} height={50} fontSize={16} />
                        </td>

                        <td className="px-2">
                          <b className="d-block">
                          <span className="fnt-w5">Nom:</span> {el?.nom_cl} {el?.prenom_cl}
                          </b>
                          <b className="d-block"><span className="fnt-w5">Tél:</span> {el?.tel_cl}</b>
                          <b className="d-block"><span className="fnt-w5">Adresse:</span> {el?.adresse}</b>
                        </td>

                        <td><b>{el?.montant_ttc}</b> TND</td>

                        <td className="p-1">{el?.note || "N/A"}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MuTransfertPrintContainer;
