import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ColisService from '../services/ColisService';
import storage from '../services/UserStorage';

const initialState = {
    loading: false,
    error: '',
    done: false,
    data: null,
    colis_list: [],
    colis_list_src : [] ,
    selected_colis: null,
    lst_sel_colis: [],
    count : 0 ,

};

export const create = createAsyncThunk(
    "colis/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.createColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const list = createAsyncThunk(
    "colis/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getClientColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getByCode = createAsyncThunk(
    "colis/getbycode",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getColisByCode(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const edit = createAsyncThunk(
    "colis/edit",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.editColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const del = createAsyncThunk(
    "colis/del",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.deleteColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const searchMesColis = createAsyncThunk(
    "colis/search",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.searchClientColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const searchMesColisAll = createAsyncThunk(
    "colis/searchAll",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.searchClientColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getClientColisHistory = createAsyncThunk(
    "colis/history",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getClientColisHistory(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)




const colisSlice = createSlice({
    name: "colis",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
            state.done = false;
            state.colis_list = [];
            state.colis_list_src = [];
            state.count = 0;
        },
        selectColis: (state, action) => {
            state.selected_colis = action.payload;
        },
        checkColis: (state, action) => {
            console.log(action)
            if (action.payload) {
                if(Array.isArray(state?.colis_list)){
                let lst =
                    state.colis_list.map(item =>
                        item.ref === action.payload.data ? { ...item, checked: action.payload.etat } : item
                    )

                state.colis_list = lst;
                }
            }
        },
        checkAllColis: (state, action) => {
            console.log(action)
                let lst =
                    state.colis_list.map(item =>( { ...item, checked: action.payload.etat }) )

                state.colis_list = lst;
        },
        doFilter : (state , action) => {
            console.log(action.payload)
            if(action.payload.etat != "")
            state.colis_list = state.colis_list_src.filter(elem => elem.etat == action.payload.etat )
            else
            state.colis_list = state.colis_list_src ;
        }

    },
    extraReducers: {
        [create.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.data = action.payload.colis;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [create.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [create.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [list.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = action.payload;
                state.colis_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [list.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [list.pending]: (state, action) => {
            state.error = '';
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },
        [getByCode.fulfilled]: (state, action) => {
            if (action.payload) {
                state.data = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getByCode.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getByCode.pending]: (state, action) => {
            state.loading = true;
        },
        [edit.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.data = action.payload.colis;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [edit.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [edit.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [del.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [del.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [del.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [searchMesColis.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = action.payload;
                state.colis_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [searchMesColis.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [searchMesColis.pending]: (state, action) => {
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },
        [searchMesColisAll.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = action.payload;
                state.colis_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [searchMesColisAll.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [searchMesColisAll.pending]: (state, action) => {
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },

        [getClientColisHistory.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = Array.isArray(action.payload?.rows) ? action.payload?.rows : [];
                state.colis_list_src = Array.isArray(action.payload?.rows) ? action.payload?.rows : [];
                state.count = +action.payload?.count;
            }
            //state.error = '';
            state.loading = false;

        },
        [getClientColisHistory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getClientColisHistory.pending]: (state, action) => {
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },
    }
})

const { reducer } = colisSlice;
export const { clear, selectColis, checkColis , doFilter, checkAllColis } = colisSlice.actions;
export default reducer;