import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stat as RunsheetState } from '../../utils/constants/Runsheet.enum';
import { ColisTypeEnvoieLabels } from '../../utils/constants/Colis.enum';

const RunsheetDetails = ({data , onEdit , onPrint , onDelete , isShow , onHide}) => {

    const getDecisionforRS = (bags)=>{
        if(bags){
            let rs = bags.find(o => o.runsheetId === data.id);
            if(rs)
                return rs.decision ;
                else
                return "_" ;
        }else{
            return "_" ;
        }

    }


    return (
        <div className={isShow ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" onClick={onHide}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>

            {data &&
                <div className="row no-marg bg-wt p-4">

                    <div className="col-md-12">
                        <h5>Tournée <span className="txt-green">{data.ref}</span> </h5>
                        <br />
                        {data.etat === RunsheetState.PENDING &&
                            <button className="btn btn-outline-primary me-1" onClick={onEdit}><FontAwesomeIcon icon={['fas', 'edit']} /> Edit Runsheet</button>
                        }
                        {data.etat === RunsheetState.PENDING &&
                            <button className="btn btn-outline-danger me-1" onClick={onDelete}><FontAwesomeIcon icon={['fas', 'trash-alt']} /> Cancel Runsheet</button>
                        }

                        <button className="btn btn-outline-success me-1" onClick={ e => onPrint(data)}><FontAwesomeIcon icon={['fas', 'print']} /> Imprimer fiche</button>

                        <br /> <br />
                    </div>

                    <div className="col-md-12">
                        <h5 className="txt-green">Liste des colis :</h5>

                        <table className="table table-striped table-bordered fnt-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Colis</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Montant TTC</th>
                                    <th scope="col">Adresse</th>
                                    <th scope="col">Décision</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && Array.isArray(data.colis) && data.colis.map((item) =>
                                    <tr>
                                        <td className="p-1">{item.code}</td>
                                        <td className="p-1">{ColisTypeEnvoieLabels[item.type_envoi]}</td>
                                        <td className="p-1">{item.nom_cl} {item.prenom_cl}</td>
                                        <td className="p-1">{item.montant_ttc}</td>
                                        <td className="p-1">{item.adresse}</td>
                                        <td className={`p-1 ${ item.runsheetBag?.decision == 'livre' ? 'bg-success text-light': item.runsheetBag?.decision == 'reporte' ? 'bg-warning text-dark' :'bg-danger text-light'}`}>{item.runsheetBag?.decision}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>

                    </div>

                </div>
            }



        </div>
    )
}

export default RunsheetDetails;