/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_wt from '../../assets/img/logo.png'
import bg from '../../assets/img/home_banner.png'
import { useHistory } from "react-router-dom";
import { useForm, Controller , useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { routes } from '../../utils/helpers/routing.helper';

import { lst_gov } from "../../utils/constants/Gov.enum";
import { Typeahead } from "react-bootstrap-typeahead";

const Register = ({ onDataSubmit, loading, error, register_done, OnResendMail }) => {


    const history = useHistory();

    const [lst_gov_option, set_lst_gov_option] = useState(lst_gov.map(el => el.nom_gov ))
    const [lst_deleg_option, set_lst_deleg_option] = useState([])

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goLogin = () => {
        history.push(routes.login);
    }

    const validationSchema = Yup.object().shape({
        nom_com: Yup.string()
            .required('Vous devez saisir le nom commercial !'),
        matricule_fiscal: Yup.string()
            .required('Vous devez saisir votre matricule fiscal !'),
        nom: Yup.string()
            .required('Vous devez saisir votre nom !'),
        prenom: Yup.string()
            .required('Vous devez saisir votre prénom !'),
        tel: Yup.string()
            .typeError('numéro tél non valide !')
            .min(8, 'numéro tél non valide !')
            .max(8, 'numéro tél non valide !')
            .required('Vous devez saisir votre tél !')
            .test(
                'tél valid',
                'tél non valid',
                value => (value + "").match(/^\d{1,9}?$/),
            ),
        produit_type: Yup.string()
            .required('Vous devez saisir le type de produit !'),
        flux_exped: Yup.number()
            .typeError('flux d\'éxpedition non valide !')
            .required('Vous devez saisir votre moyenne d\'epédition par jour  !'),
        adresse: Yup.string()
            .required('Vous devez saisir votre adresse !'),
        adresse_gov: Yup.mixed()
            .required('Vous devez choisir votre gouvernorat !'),
        adresse_deleg: Yup.mixed()
            .required('Vous devez saisir votre délégation !'),
        email: Yup.string()
            .required('Vous devez saisir votre E-mail !')
            .email('Email non valid !'),
        password: Yup.string()
            .min(6, 'Mot de passe non valid ( min 6 caractères ) !')
            .required('Vous devez saisir votre mot de passe !'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !')
            .required('Vous devez confirmez votre mot de passe !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState, control } = useForm(formOptions);
    const { errors } = formState;

    const sel_gov = useWatch({ control: control, name: 'adresse_gov' })

    useEffect(()=>{
        console.log(sel_gov)
        if(Array.isArray(sel_gov)){
            if(sel_gov.length > 0 )
                set_lst_deleg_option(lst_gov.find(el => el.nom_gov == sel_gov[0])?.delegations || [])
        }
    },[sel_gov])

    const onSubmit = data => {
        console.log(data)

       data.adresse_gov = (Array.isArray(data?.adresse_gov) && data?.adresse_gov?.length > 0) ? data?.adresse_gov[0] : null;
       data.adresse_deleg = (Array.isArray(data?.adresse_deleg) && data?.adresse_deleg?.length > 0) ? data?.adresse_deleg[0] : null;
        onDataSubmit(data)
    }




    return (
        <div className="row no-marg login-container bg-wt ">


            <div className="col-md-8 h-100 box_form">

                <div className="row no-marg">
                    <div className="col-md-2 no-padd">
                        <button className="btn btn-primary btn_back_home" onClick={goHome}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
                    </div>


                </div>

                <div className="row no-marg ">
                    <div className="col-md-12">
                        <nav className="k-stepper">
                            <ol className="k-step-list">
                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="0" aria-current="step">
                                        <span className="k-step-indic">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'user-plus']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">inscription</b>
                                        </span>
                                    </a>

                                </li>
                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="-1" >
                                        <span className="k-step-indic not-done">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'envelope-open-text']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">validation mail</b>
                                        </span>
                                    </a>

                                </li>

                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="-1" >
                                        <span className="k-step-indic not-done">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'check']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">commencer</b>
                                        </span>
                                    </a>

                                </li>
                            </ol>

                            <div className="k-stepper-line"></div>
                        </nav>
                    </div>


                </div>

                {!register_done ?

                    <div className="col-md-8 offset-md-2 " >
                        <br /> <br />
                        <h2 className="padd-10">Créer un compte</h2>
                        {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}

                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">

                            <div className="row">
                                <div className="col-md-8 form-group ">
                                    <label className="fnt-w5">Nom commercial</label>
                                    <input placeholder="nom commercial ..."  {...register("nom_com")} className={`form-control ${errors.nom_com ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.nom_com?.message}</p>

                                </div>

                                <div className="col-md-4 form-group ">
                                    <label className="fnt-w5">Matricule fiscal</label>
                                    <input placeholder="M/F ..."  {...register("matricule_fiscal")} className={`form-control ${errors.matricule_fiscal ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.matricule_fiscal?.message}</p>

                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-6 form-group ">
                                    <label className="fnt-w5">Nom</label>
                                    <input type="text" placeholder="Nom ..."  {...register("nom")} className={`form-control ${errors.nom ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.nom?.message}</p>
                                </div>

                                <div className="col-md-6 form-group">
                                    <label className="fnt-w5">Prénom</label>
                                    <input type="text" placeholder="Prenom ..."  {...register("prenom")} className={`form-control ${errors.prenom ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.prenom?.message}</p>
                                </div>
                            </div>


                            <div className="row">

                                <div className="form-group col-md-6">
                                    <label className="fnt-w5">Type de produit</label>
                                    <input placeholder="type de produit ..."  {...register("produit_type")} className={`form-control ${errors.produit_type ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.produit_type?.message}</p>

                                </div>

                                <div className="form-group col-md-6">
                                    <label className="fnt-w5">Flux d'expédition</label>
                                    <input placeholder="Flux d'expédition ..."  {...register("flux_exped")} className={`form-control ${errors.flux_exped ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.flux_exped?.message}</p>

                                </div>
                            </div>


                            <div className="row">

                                <div className="form-group col-md-8">
                                    <label className="fnt-w5">Adresse</label>
                                    <input placeholder="adresse ..."  {...register("adresse")} className={`form-control ${errors.adresse ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.adresse?.message}</p>

                                </div>

                                <div className="form-group col-md-4">
                                    <label className="fnt-w5">Gouvernorat</label>
                                    <Controller
                                        control={control}
                                        name="adresse_gov"
                                        render={({ field, fieldState }) => (
                                            <Typeahead
                                                {...field}
                                                id="typeahead-gov"
                                                clearButton
                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                aria-describedby="typeaheadError"
                                                options={lst_gov_option}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.adresse_gov?.message}</p>

                                </div>

                                <div className="form-group col-md-4">
                                    <label className="fnt-w5">Délégation</label>
                                    <Controller
                                        control={control}
                                        name="adresse_deleg"
                                        render={({ field, fieldState }) => (
                                            <Typeahead
                                                {...field}
                                                id="typeahead-deleg"
                                                clearButton
                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                aria-describedby="typeaheadError"
                                                options={lst_deleg_option}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.adresse_deleg?.message}</p>

                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label className="fnt-w5">Tél</label>
                                <input placeholder="+216 ..."  {...register("tel")} className={`form-control ${errors.tel ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.tel?.message}</p>

                            </div>


                            <div className="form-group col-md-12">
                                <label className="fnt-w5">E-mail</label>
                                <input placeholder="E-mail"  {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.email?.message}</p>
                            </div>

                            <div className="row">

                                <div className="col-md-6 form-group ">
                                    <label className="fnt-w5">Mot de passe</label>
                                    <input type="password" placeholder="Mot de passe"  {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.password?.message}</p>
                                </div>

                                <div className="col-md-6 form-group">
                                    <label className="fnt-w5">Confirmation de mot de passe</label>
                                    <input type="password" placeholder="Confirmez mot de passe"  {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.confirmPassword?.message}</p>
                                </div>
                            </div>
                            <div className="col-md-12 form-group form-check">
                                <input type="checkbox" class="form-check-input" />
                                <span className="fnt-w5 fnt-smlr" >J'accepte <a href="#" className="btn_forget_mdp pb-4 d-inline-block"> les termes et conditions d'utilisation.</a></span>
                            </div>
                            <div className="col-md-12 txt-ctr" >
                                {loading ?
                                    <div class="col-md-12 p-4 txt-ctr">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                                }
                            </div>
                            <br /> <br />

                            <span className="fnt-w5 d-block txt-rt" >Je suis déjà membre <a href="#" onClick={(e) => goLogin()} className="btn_forget_mdp pb-4 d-inline-block"> Se connecter</a></span>




                            <br />
                        </form>




                    </div>

                    :

                    (loading ?
                        <div class="col-md-12 p-4 txt-ctr">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className="col-md-8 offset-md-2 txt-ctr p-4" >
                            <h1 style={{ fontSize: "xxx-large" }} className="txt-red pt-4 barcode_icon"><FontAwesomeIcon icon={['fas', 'envelope-open-text']} /></h1>
                            <h3>Un email de confirmation à été envoyé</h3>
                            <h4 className="txt-gray">veuillez vérifier votre boîte de réception</h4>
                            <p className="txt-gray">Je n'ai pas reçu le mail vérification</p>
                            <button type="button" className="btn btn-primary btn-login mt-4" onClick={OnResendMail} >Renvoyer le mail</button>
                        </div>
                    )
                }
            </div>

            <div className="col-md-4 bg-green-smooth h-100" style={{ position: "relative", overflowY : 'hidden' }}>
                <img src={logo_wt} className="avatar_logo" alt="" style={{width : '50%'}} />
                <img src={bg} className="avatar_prog" alt="" />
            </div>
        </div>
    )
}


export default Register;



