import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Form, InputGroup } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "../../../utils/helpers/routing.helper";

import { createHub, updateHub } from "../../../slices/Hub.slice";
import { lst_gov } from "../../../utils/constants/Gov.enum";

const HubFormContainer = ({ show, onClose, sel_item, afterSubmit, ...props }) => {
  const { loading, error } = useSelector((state) => state.cheque);
  const dispatch = useDispatch();
  const history = useHistory();

  const [lst_gov_options , set_lst_gov_options] = useState([])
  const [sel_govs, set_sel_govs] = useState([]);
  const [error_govs, set_error_govs] = useState("");


  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Vous devez saisir le nom du hub ! "),
    tel: Yup.string(),
    adresse: Yup.string(),
    hubGovs: Yup.mixed(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue, getValues } = useForm(formOptions);
  const { errors } = formState;


  const onSearchGovs = (search) => {
    console.log(`search  for ${search}`);
    if (Array.isArray(lst_gov)) {
      let tmp = lst_gov.filter((el) => String(el?.nom_gov).toLowerCase().includes(String(search).toLowerCase())).map(el => el?.nom_gov);
      set_lst_gov_options(tmp);
    }
  };

  const onSelectGovs = (value) => {
    setValue('hubGovs' , value)
    set_sel_govs(value)
  };

  const onSubmit = (data) => {
      set_error_govs('')
      if(sel_govs.length == 0){
          set_error_govs('Vous devez choisir au moins une gouvernerats !')
          return ;
        }

        console.log(data);
        data.nom = `HUB-${data.nom}`

    
    if (sel_item) {
      dispatch(updateHub({ ...sel_item, nom : data?.nom , tel : data?.tel , adresse : data?.adresse , hubGovs : data?.hubGovs }))
        .unwrap()
        .then((res) => {
          if (res?.done) afterSubmit();
        })
        .catch((err) => console.log(err));
    } else {

      dispatch(createHub(data))
        .unwrap()
        .then((res) => {
          if (res?.done) afterSubmit();
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (sel_item) {
      setValue("nom", String(sel_item?.nom).replace("HUB-",""));
      setValue("tel", sel_item?.tel);
      setValue("adresse", sel_item?.adresse);
 
      setValue("hubGovs", Array.isArray(sel_item?.hubGovs) ? sel_item?.hubGovs?.map(el => el?.gov) : []);
      set_sel_govs(Array.isArray(sel_item?.hubGovs) ? sel_item?.hubGovs?.map(el => el?.gov) : [])
    } else reset();
  }, [sel_item]);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if(!show){
      reset()
    }
    
  }, [show]);

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="col-md-12  p-2 bg-wt ">
        <h5>Formulaire de Hub</h5>
        {/* <p>{JSON.stringify(String(errors))}</p> */}
        <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                   
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="frm_user" className="row m-0 mt-4">

          <Form.Group as={Col} className="col-md-12">
            <Form.Label className="no-marg small-label">Nom HUB </Form.Label>
            <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">HUB-</InputGroup.Text>
            </InputGroup.Prepend>
                <Form.Control size="sm" name="nom" {...register("nom")} className={`form-control ${errors.nom ? "is-invalid" : "form-control"}`} />
            </InputGroup>
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.nom?.message}</p>
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} className="col-md-6">
            <Form.Label className="no-marg small-label">Tel </Form.Label>
            <Form.Control size="sm" name="tel" {...register("tel")} className={`form-control ${errors.tel ? "is-invalid" : "form-control"}`} />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.tel?.message}</p>
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} className="col-md-6">
            <Form.Label className="no-marg small-label">Adresse </Form.Label>
            <Form.Control size="sm" name="adresse" {...register("adresse")} className={`form-control ${errors.adresse ? "is-invalid" : "form-control"}`} />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.adresse?.message}</p>
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} className="col-md-12">
            <Form.Label className="no-marg small-label">Liste des gouvernerats </Form.Label>
            {/* <Form.Control size="sm" name="id_exped" {...register("id_exped")} className={`form-control ${errors.id_exped ? "is-invalid" : "form-control"}`} /> */}
            <AsyncTypeahead
              id="HubGovPicker"
              labelKey={(op) => op}
              isLoading={loading}
              onChange={onSelectGovs}
              options={lst_gov_options}
              onSearch={onSearchGovs}
              multiple
              selected={sel_govs}
              placeholder="Recherche Gouvernerat..."
            />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{error_govs}</p>
            </Form.Text>
          </Form.Group>

         



          {loading ? (
            <div class="col-md-12 p-4 txt-ctr">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div class="col-md-12 text-end ">
              <button className="btn btn-primary ">
                <FontAwesomeIcon icon={["fas", "check"]} /> Confirmer
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default HubFormContainer;
