export const lst_gov = [
    {
      "nom_gov": "tunis",
      "delegations": [
        "carthage",
        "la médina",
        "bab bhar",
        "bab souika",
        "omrane",
        "omrane supérieur",
        "attahrir",
        "el menzah",
        "cité alkhadhra",
        "bardo",
        "séjoumi",
        "azzouhour",
        "alhrairia",
        "sidi hsine",
        "ouardia",
        "kabaria",
        "sidi elbéchir",
        "jebel jelloud",
        "la goulette",
        "le kram",
        "la marsa"
      ]
    },
    {
      "nom_gov": "ariana",
      "delegations": [
        "ariana ville",
        "soukra",
        "raouède",
        "kalâat andalous",
        "sidi thabet",
        "cité attadhamon",
        "mnihla"
      ]
    },
    {
      "nom_gov": "manouba",
      "delegations": [
        "manouba",
        "oued ellil",
        "tebourba",
        "battan",
        "jedaida",
        "mornaguia",
        "borj amri",
        "douar hicher"
      ]
    },
    {
      "nom_gov": "ben arous",
      "delegations": [
        "ben arous",
        "nouvelle médina",
        "mourouj",
        "hammam lif",
        "hammam chatt",
        "boumhel bassatine",
        "ezzahra",
        "radès",
        "megrine",
        "mhamdia",
        "fouchana",
        "mornag"
      ]
    },
    {
      "nom_gov": "nabeul",
      "delegations": [
        "nabeul",
        "dar chaâbane elfehri",
        "béni khiar",
        "korba",
        "menzel temime",
        "mida",
        "kelibia",
        "hammam ghezaz",
        "haouaria",
        "takelsa",
        "slimane",
        "menzel bouzelfa",
        "béni khalled",
        "grombalia",
        "bouârgoub",
        "hammamet"
      ]
    },
    {
      "nom_gov": "bizerte",
      "delegations": [
        "bizerte nord",
        "jarzouna",
        "bizerte sud",
        "sejnane",
        "joumine",
        "mateur",
        "ghezala",
        "menzel bourguiba",
        "tinja",
        "utique",
        "ghar el melh",
        "menzel jemil",
        "el alia",
        "ras jebel"
      ]
    },
    {
      "nom_gov": "zaghouan",
      "delegations": [
        "zaghouan",
        "zériba",
        "bir mecharga",
        "fahs",
        "nadhour",
        "saouaf"
      ]
    },
    {
      "nom_gov": "sousse",
      "delegations": [
        "sousse ville",
        "zaouia ksiba thrayat",
        "sousse ryadh",
        "sousse jawhara",
        "sousse sidi abdelhamid",
        "hammam sousse",
        "akouda",
        "kalâa elkébira",
        "sidi bouali",
        "hergla",
        "enfidha",
        "bouficha",
        "koundar",
        "sidi elheni",
        "msaken",
        "kalâa ességhira"
      ]
    },
    {
      "nom_gov": "monastir",
      "delegations": [
        "monastir",
        "ouerdanine",
        "sahline",
        "zéramdine",
        "béni hassan",
        "jammel",
        "benbla",
        "moknine",
        "bekalta",
        "teboulba",
        "ksar helal",
        "ksibet medyouni",
        "sayada lamta bouhjar"
      ]
    },
    {
      "nom_gov": "mahdia",
      "delegations": [
        "mahdia",
        "boumerdes",
        "ouled chamekh",
        "chorbane",
        "hbira",
        "souassi",
        "eljem",
        "chebba",
        "malloulech",
        "sidi alouane",
        "ksour essef"
      ]
    },
    {
      "nom_gov": "sfax",
      "delegations": [
        "sfax ville",
        "sfax ouest",
        "sakiet ezzit",
        "sakiet eddaier",
        "sfax sud",
        "tina",
        "agareb",
        "jebeniana",
        "el amra",
        "el hencha",
        "menzel chaker",
        "ghraiba",
        "bir ali ben khelifa",
        "sekhira",
        "mahrès",
        "kerkennah"
      ]
    },
    {
      "nom_gov": "béja",
      "delegations": [
        "béja nord",
        "béja sud",
        "amdoun",
        "nefza",
        "teboursouk",
        "tibar",
        "testour",
        "goubellat",
        "mejez el bab"
      ]
    },
    {
      "nom_gov": "jendouba",
      "delegations": [
        "jendouba",
        "jendouba nord",
        "boussalem",
        "tabarka",
        "ain drahem",
        "fernana",
        "ghardimaou",
        "oued mliz",
        "balta bouaouene"
      ]
    },
    {
      "nom_gov": "kef",
      "delegations": [
        "kef ouest",
        "kef est",
        "nebeur",
        "sakiet sidi youssef",
        "tejerouine",
        "kalâat sinane",
        "kalâa el khasba",
        "jerissa",
        "gsour",
        "dahmani",
        "le sers"
      ]
    },
    {
      "nom_gov": "siliana",
      "delegations": [
        "siliana nord",
        "siliana sud",
        "bouarada",
        "gâafour",
        "el aroussa",
        "le krib",
        "bourouis",
        "makther",
        "rouhia",
        "kesra",
        "bargou"
      ]
    },
    {
      "nom_gov": "kairouan",
      "delegations": [
        "kairouan nord",
        "kairouan sud",
        "chebika",
        "sebikha",
        "oueslatia",
        "haffouz",
        "el ala",
        "hajeb el ayoun",
        "nasrallah",
        "cherarda",
        "bouhajla"
      ]
    },
    {
      "nom_gov": "sidi bouzid",
      "delegations": [
        "sidi bouzid ouest",
        "sidi bouzid est",
        "jelma",
        "sabbalet ouled askar",
        "bir hfay",
        "sidi ali benôun - menzel bouzayane",
        "meknassi",
        "souk jedid",
        "mezouna",
        "regueb",
        "ouled haffouz"
      ]
    },
    {
      "nom_gov": "kasserine",
      "delegations": [
        "kasserine nord",
        "kasserine sud",
        "azzouhour",
        "hassi ferid",
        "sbitla",
        "sbiba",
        "jedliane",
        "el ayoun",
        "tela",
        "hidra",
        "foussana",
        "feriana",
        "mejel bel abbes"
      ]
    },
    {
      "nom_gov": "gabès",
      "delegations": [
        "gabès ville",
        "gabès ouest",
        "gabès sud",
        "ghannouch",
        "metouia",
        "menzel habib",
        "hamma",
        "matmata",
        "matmata nouvelle",
        "mareth"
      ]
    },
    {
      "nom_gov": "medenine",
      "delegations": [
        "mednine nord",
        "mednine sud",
        "béni khedach",
        "ben guerdene",
        "zazis",
        "jerba houmet souk",
        "jerba midoun",
        "jerba ajim",
        "sidi makhlouf"
      ]
    },
    {
      "nom_gov": "gafsa",
      "delegations": [
        "gafsa nord",
        "sidi aich",
        "el ksar",
        "gafsa sud",
        "moulares",
        "redyef",
        "métlaoui",
        "el mdhilla",
        "el guettar",
        "belkhir",
        "sned"
      ]
    },
    {
      "nom_gov": "tozeur",
      "delegations": [
        "tozeur",
        "degueche",
        "tameghza",
        "nefta",
        "hezoua"
      ]
    },
    {
      "nom_gov": "tataouine",
      "delegations": [
        "tataouine nord",
        "tataouine sud",
        "smar",
        "bir lahmer",
        "ghomrassen",
        "dhehiba",
        "remada"
      ]
    },
    {
      "nom_gov": "kébili",
      "delegations": [
        "kébili sud",
        "kébili nord",
        "souk el ahad",
        "douz nord",
        "douz sud",
        "el faouar"
      ]
    }
  ]