import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

import {stat as Mv_state } from '../../utils/constants/Movable.enum';
import { getStringDate , getDays } from '../../utils/helpers/date.helper';

const MovableItemCient = ({ data , onItemClick , onDispatch }) => {

    useEffect(() => {
        //for formated date
    }, [])

    const renderStat = () => {
        let spn_stat;
        let stat = "";
        switch (data.etat) {
            case Mv_state.PENDING:
                stat = "A confirmer le transfert";
                break;
            case Mv_state.PENDING_PICK:
                stat = "En attente de ramassage";
                break;

            case Mv_state.IN_PROGRESS:
                stat = "En cours de ramassage";
                break;
            case Mv_state.COMPLETED:
                stat = "Terminé";
                break;



        }

        if (data.etat === Mv_state.PENDING || data.etat === Mv_state.PENDING_PICK) {
            spn_stat = <span className="badge bg-warning fnt-w5 txt_blk"> {stat} </span>
        } else if (data.etat === Mv_state.IN_PROGRESS || data.etat === Mv_state.COMPLETED) {
            spn_stat = <span className="badge bg-success fnt-w5 txt_wt"> {stat} </span>
        } else {
            spn_stat = <span className="badge bg-secondary fnt-w5 txt_wt"> {stat}</span>
        }
        return spn_stat;
    }

    return (
        <div className="row item_box">
            <div className="col-md-3 dvdr-r-gray">
                <b className="item_box_ref">
                    <span className="fnt-w5 txt_blk"> <span className="txt-green">Ref:</span>  {data.ref}</span>
                    {/* <span>{data.type}</span> */}
                </b> <br />
                <b className="item_box_driver">
                    {renderStat()} &nbsp;&nbsp;
                </b>
            </div>

            <div className="col-md-2 dvdr-r-gray">
                <span className="float-left">
                    <b className="fnt-w5 fnt-sm"><FontAwesomeIcon icon={['fas', 'box-open']} /> Colis:</b> <br />
                    <b className="item_box_money">{data.nbr_colis} <span></span> </b>
                </span>

            </div>

            <div className="col-md-4 dvdr-r-gray">
                <span className="float-start">
                    <b className="fnt-w5 fnt-sm"><FontAwesomeIcon icon={['fas', 'box-open']} /> Colis ramassé:</b> <br />
                    <b className="item_box_money">{data.nbr_pick} <span></span> </b>
                </span>
                <span className="float-end text-end">
                    <b className="fnt-w5 fnt-sm text-end">
                        {data.createdAt && getStringDate(data.createdAt) } <FontAwesomeIcon icon={['fas', 'clock']} />
                    </b> <br />
                    <span className="fnt-w5 fnt-sm">{data.createdAt && getDays(data.createdAt) } </span>
                </span>
            </div>



            <div className="col-md-3 pt-1">
                <a className="btn btn-outline-secondary ms-1" onClick={() => onItemClick(data)}> Details</a>

                {data.etat === Mv_state.PENDING &&
                    <a className="btn btn-outline-success ms-1 " onClick={() => onDispatch(data)}> <FontAwesomeIcon icon={['fas', 'shipping-fast']} /> Dispatch</a>
                }

            </div>


        </div>
    );
}
export default MovableItemCient;