import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stat as muStat } from '../../utils/constants/Movable.enum';
import { getStringDate } from '../../utils/helpers/date.helper';
import { useHistory } from "react-router-dom";


function MuHistoryClient({ loading, lst_data, error , onItemClick }) {

    const renderStatus = (mu) => {
        let badge;
        if (mu.etat === muStat.PENDING) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> A confirmer le transfert </span>
        } else if (mu.etat === muStat.PENDING_PICK) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> En attente de ramassage </span>
        } else if (mu.etat === muStat.IN_PROGRESS) {
            badge = <span className="badge bg-success fnt-w5 txt_blk"> En cours de ramassage </span>
        } else if (mu.etat === muStat.COMPLETED) {
            badge = <span className="badge bg-secondary fnt-w5 txt_blk"> Terminé </span>
        }



        return badge;
    }





    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'shipping-fast']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Historique transfert  </b>
                </div>

            </div>



            <div className="row no-marg rw-header ">

                <div className="col-md-6 offset-md-3 flex_ctr p-2">


                </div>



                <div className="col-md-6  fnt-largr">

                    <div className="row no-marg ">

                        <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
                            <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">unité mobile(s)</b>
                        </div>

                        <div className="col-md-9 dvdr-r-gray pt-1 pb-1">

                        </div>

                    </div>


                </div>

                <div className="col-md-6 no-padd mb-2 ">

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                </div>



            </div>


            <div className="col-md-12 p-2 bg-wt mt-2">


                <table className="table table-striped table-bordered fnt-sm">
                    <thead>
                        <tr>
                            <th scope="col">ref</th>
                            <th scope="col">etat</th>
                            <th scope="col">colis</th>
                            <th scope="col">crée le</th>
                            <th scope="col">action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lst_data.map(data =>

                            <tr>
                                <td><b>{data.ref}</b></td>
                                <td>{renderStatus(data)}</td>
                                <td>{data.nbr_pick} / {data.nbr_colis} ramassé</td>
                                <td>{data.createdAt ? getStringDate(data.createdAt) : '_'}</td>
                                <td>
                                    <a className="btn btn-outline-secondary ms-1" onClick={() => onItemClick(data)}> Details</a>

                                </td>

                            </tr>

                        )}
                    </tbody>
                </table>

                {!lst_data.length &&
                    <div className="col-md-12 txt-ctr">
                        <br />
                        <br />
                        <br />
                        {/* <img src={noDataIcon} alt="" width="10%" /> */}
                        <br /><b className="txt-gray fnt-w5">AUCUN DONNEE TROUVEE</b>
                    </div>
                }

                {loading &&
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                }
            </div>




        </>
    );
}

export default MuHistoryClient;