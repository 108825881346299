import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserStorage from '../../../services/UserStorage';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { api_url } from '../../../services/http_constant';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ProfileClientEditPass = (props) => {


    const [registerErr, setRegisterErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(UserStorage.getToken());
    const [user, setUser] = useState(UserStorage.getData());

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Mot de passe non valid ( min 6 caractères ) !')
            .required('Vous devez saisir le mot de passe !'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !')
            .required('Vous devez confirmez le mot de passe !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {

        if (data && token) {
            let usr = {
                pass: data.password,
            }
            console.log(usr);
            setLoading(true);
            setRegisterErr("");

            axios.post(`${api_url}/api/users/changepass`, usr, {
                headers: {
                    'cgo-tkn': token
                }
            })
                .then(res => {
                    setLoading(false);
                    if (res.data) {
                        console.log(res.data);
                        if (res.data) {
                            props.loadData();
                            props.setShowEdit(false)
                        }else{
                            setRegisterErr("Echec de modification de mot de passe")
                        }
                        
                    }else{
                        setRegisterErr("Echec de modification de mot de passe")
                    }

                })
                .catch(error => {
                    setLoading(false);
                    if (error.response) {
                        console.log(error.response.data.message);
                        if (error.response.status === 400) {
                            setRegisterErr(error.response.data.message);
                        }
                    }
                }
                );
        }


    }





    return (
        <div className={props.showEdit ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" onClick={() => props.setShowEdit(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>

                <div className="row no-marg bg-wt p-4">
                    {loading ?
                        <div className="row loading-row">
                            <div className="col-md-12 txt-ctr">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        :
                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                            {registerErr && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {registerErr}</p>}

                            <Form.Row>
                            <Form.Group as={Col} controlId="formGridState7" className="col-md-6">
                                <Form.Label className="no-marg small-label">Mot de passe </Form.Label>
                                <Form.Control size="sm" name="password" {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.password?.message}</p>
                                </Form.Text>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState8" className="col-md-6">
                                <Form.Label className="no-marg small-label">Confirmation mot de passe </Form.Label>
                                <Form.Control size="sm" name="confirmPassword" {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? 'is-invalid' : 'form-control'}`} />
                                <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.confirmPassword?.message}</p>
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                            

                            <div className="row p-4">
                                <div className="col-md-12 txt-ctr">
                                    <button className="btn btn-primary btn_register" type="submit">Modifier mot de passe</button>
                                </div>
                            </div>



                        </form>
                    }


                </div>
            



        </div>
    )
}

export default ProfileClientEditPass;