import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Barcode from "react-barcode";
import logo from "../../../assets/img/logo.png";
import UserStorage from "../../../services/UserStorage";
import { routes } from "../../../utils/helpers/routing.helper";

const MuPrint = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Colis-Go",
    content: () => componentRef.current,
  });

  const [rs, setRs] = useState(UserStorage.getParam());
  const [list, setList] = useState([]);
  const [date, setDate] = useState(new Date());

  //routing
  const history = useHistory();

  const getMontant = () => {
    let m = 0;
    m = list.reduce((a, b) => a + +b.montant_ttc, 0.0);
    console.log(m);
    return parseFloat(m).toFixed(3);
  };

  useEffect(() => {
    if (rs) {
      setList(rs.colis);
    }
  }, []);

  return (
    <div className="col-md-12 bg-wt">
      <div className="col-md-12 pt-2 pb-2 ps-4 pe-4">
        <button className="btn btn-outline-success" onClick={handlePrint}>
          {" "}
          Imprimer Fiche{" "}
        </button>
      </div>

      <div className="row no-marg" ref={componentRef}>
        {/* <div className="col-12">
          <div className="row">
            <div className="col-4 fnt-sm pt-2">
              <b>
                Ref Movable unit : <span className="fnt-w5">{rs && rs.ref}</span>
              </b>{" "}
              <br />
              <b>
                Date :{" "}
                <span className="fnt-w5">
                  {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
                </span>
              </b>{" "}
              <br />
            </div>

            <div className="col-4 txt-ctr">
              <img src={logo} alt="" width="80px" />
            </div>

            <div className="col-4 fnt-sm  pt-2">
              <b>
                Nombre colis : <span className="fnt-w5">{rs && list.length}</span>
              </b>{" "}
              <br />
              <b>
                Montant total : <span className="fnt-w5">{rs && getMontant()} TND</span>
              </b>{" "}
              <br />
            </div>
          </div>
        </div> */}

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-12 no-padd">
              <table width="94%" className="table table-striped table-bordered  mt-2 fnt-sm">
                <thead className="txt-ctr">
                  <tr>
                    <td className="text-start">
                      <b className="d-block">
                        <span className="fnt-w5">Date:</span> {String(rs?.createdAt)?.substring(0,10)} <br/>
                        <span className="fnt-w5">Expéditeur :</span> {rs?.mvExped?.nom_com}
                      </b>
                    </td>

                    <td className="text-center">
                      <img src={logo} alt="" width="82px" />
                      <h6>Unité de ramassage</h6>
                      <h6 className="fnt-w7">{rs?.ref}</h6>
                    </td>

                    <td className="text-start">
                      <b className="d-block">
                        <span className="fnt-w5">Nbr Colis:</span> {Array.isArray(list) ? list?.length : '---'}  <br/>
                        <span className="fnt-w5">Montant Colis:</span> {rs && getMontant()} TND <br/>
                      </b>
                    </td>

                  </tr>
                  <tr>
                    <th>Colis</th>
                    <th>Infos</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {rs &&
                    Array.isArray(list) &&
                    list.map((data, index) => (
                      <tr>
                        <td className="p-1" width="20%">
                          <Barcode value={data.code} width={1.25} height={50} />
                        </td>
                        <td className="p-1">
                          <b>Nom: </b>
                          {data.nom_cl} {data.prenom_cl} <br />
                          <b>Tél: </b>
                          {data.tel_cl} <br />
                          <b>Adresse: </b>
                          {data.adresse}, {data.adresse_deleg}, {data.adresse_gov} <br />
                          <b>Montant: </b>
                          {data.montant_ttc} TND
                        </td>
                        <td className="p-1">
                          <b></b>
                          {data.note && data.note} <br />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MuPrint;
