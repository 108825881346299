import React from "react";
import Item from "../items/MovableAdm.item";
import noDataIcon from "../../assets/img/no_data.png";
import { Button, Table } from "react-bootstrap";
import { MuLabels, MuStatus } from "../../utils/constants/Movable.enum";
import { getStringDate } from "../../utils/helpers/date.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MuClientList = ({ loading, lst_data, onItemDetailsClick, onDispatch, onPickMu }) => {
  return (
    <div className="col-md-12 bg-wt mt-2">
      {loading ? (
        <div class="col-md-12 p-4 txt-ctr">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        Array.isArray(lst_data) && (
          //lst_data.map((s, index) => <Item key={`mu_.${index}`} data={s} onItemClick={onItemDetailsClick} onDispatch={onDispatch} onPickMu={onPickMu} />)
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Num</th>
                <th>Expediteur</th>
                <th>Livreur</th>
                <th>Etat</th>
                <th>Nbr Colis</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(lst_data) &&
                lst_data.map((el) => (
                  <tr>
                    <td className="fnt-w5 text-success">{el?.ref}</td>
                    <td className="fnt-w5">{el?.mvExped?.nom_com}</td>
                    <td>{el?.mvLiv ? `${el?.mvLiv?.nom} ${el?.mvLiv?.prenom}` : "Aucun"}</td>
                    <td>
                      <span className={`badge  ${el?.etat == MuStatus.PENDING ? "bg-warning" : el?.etat == MuStatus.PENDING_PICK ? "bg-warning text-dark" : el?.etat == MuStatus.IN_PROGRESS ? "bg-success" : "bg-secondary"}`}>
                        {MuLabels[el?.etat] ? MuLabels[el?.etat] : el?.etat}
                      </span>
                    </td>
                    <td><span className="badge badge-pill bg-dark txt_wt">{el?.nbr_colis}</span></td>
                    <td>{el?.createdAt ? getStringDate(el.createdAt) : "---"}</td>
                    <td className="text-center">
                      {el?.type === "dispatch" && el?.etat === MuStatus.PENDING_PICK && (
                        <Button className="btn btn-sm btn-primary me-1" onClick={(e) => onDispatch(el)}>
                          <FontAwesomeIcon icon={["fas", "shipping-fast"]} /> Dispatcher
                        </Button>
                      )}
                      {el?.type === "dispatch" && (el?.etat === MuStatus.PENDING_PICK || el?.etat === MuStatus.IN_PROGRESS) && (
                        <Button className="btn btn-sm btn-success me-1" onClick={(e) => onPickMu(el?.ref)}>
                          <FontAwesomeIcon icon={["fas", "truck-loading"]} /> Réceptionner
                        </Button>
                      )}
                      <Button className="btn btn-sm btn-warning me-1" onClick={(e) => onItemDetailsClick(el)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} /> Détails
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )
      )}

      {!lst_data.length && !loading && (
        <div className="col-md-12 txt-ctr">
          <br />
          <br />
          <br />
          <img src={noDataIcon} alt="" width="10%" />
          <br />
          <b className="txt-gray fnt-w5">NO DATA FOUND</b>
        </div>
      )}
    </div>
  );
};

export default MuClientList;
