import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DriverModal = (props) => {

    const getDriverDispo = () => {
        if (props.drivers) {

            if (props.drivers.length)
                return "Choisissez un livreur...";
        }
        return "aucun livreur disponible !";
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <FontAwesomeIcon icon={['fas', 'car']} /> Choisissez un livreur :
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Livreur(s)</Form.Label>
                    <Form.Control as="select" className="force-apparence" defaultValue="Choisissez..." onChange={(e) => props.onSelectDriver(e.target.value)}>
                        <option value="0" >{getDriverDispo()}</option>
                        {
                            props.drivers && Array.isArray(props.drivers) && 
                            props.drivers.map(d => <option value={d.id}>{d.nom} {d.prenom}</option>)
                        }
                    </Form.Control>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                {props.loading ?
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    :
                    <>
                        <Button variant="secondary" onClick={props.onHide}>
                            Annuler
                </Button>

                        <Button variant="primary" onClick={props.onOk}>
                            Ok
                </Button>
                    </>
                }

            </Modal.Footer>
        </Modal>
    );

}

export default DriverModal;