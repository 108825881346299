import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Barcode from 'react-barcode';
import logo from '../../../assets/img/logo.svg'
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import { getStringDate } from '../../../utils/helpers/date.helper';
import { useDispatch, useSelector } from 'react-redux';
import { getExpedFactureByCode } from '../../../slices/Facture';
import { company_info } from '../../../utils/constants/company.info';

const BillPrintExped = (props) => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        documentTitle: "Facture",
        content: () => componentRef.current,
    });
    

    const dispatch = useDispatch();
    const { loading, error} = useSelector(state => state.bill_adm);

    const [bill, setBill] = useState();
    const [list, setList] = useState([]);
    const [listSorted, setListSorted] = useState([]);

    //routing 
    const history = useHistory();
    const code = props?.match?.params?.code;

    const load_data = ()=>{
        if(code){
            dispatch(getExpedFactureByCode(code)).unwrap().then(res => {
                console.log(res)
                setBill(res)
            }).catch(err => console.log(err))
        }
    }
    useEffect(() => {
        if (bill) {
            if (Array.isArray(bill?.factureBags)) {
                let arr = [...bill.factureBags];

                arr.sort((a, b) => {

                    return a?.decision - b?.decision;
                })
                setListSorted(arr);
            }
        }


    }, [bill]);

    useEffect(() => {
        // if (!bill) {
        //     history.push(routes.bill_adm_list)
        // }
        load_data()

    }, [code]);

    return (
        <div className="col-md-12 bg-wt">
            <div className="col-md-12 pt-2 pb-2 ps-4">
                <button className="btn btn-outline-success" onClick={handlePrint}> Imprimer Fiche </button>
            </div>

            <div className="row no-marg" ref={componentRef}>

                <div className="col-12">

                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-5 fnt-sm">
                            <h3>{company_info?.nom}</h3>
                            <b>M/F : <span className="fnt-w5">{company_info?.mf} </span></b> <br />
                            <b>Adresse : <span className="fnt-w5">{company_info?.adresse}</span></b> <br />
                            <b>Tél : <span className="fnt-w5">{company_info?.phone} </span></b> <br />
                        </div>

                        <div className="col-5 fnt-sm bill-title">
                            <h3 className="txt-ctr">FACTURE</h3>

                            <h3>{bill?.facFor?.nom_com}</h3>
                            <b>M/F : <span className="fnt-w5">{bill?.facFor?.matricule_fiscal}</span></b> <br />
                            <b>Adresse : <span className="fnt-w5">{bill?.facFor?.adresse}</span></b> <br />
                            <b>Tél : <span className="fnt-w5">{bill?.facFor?.tel}</span></b> <br />

                            <br />
                            <b>Réf facture : <span className="fnt-w5">{bill?.ref}</span></b> <br />
                            <b>Date : <span className="fnt-w5">{getStringDate(bill?.createdAt)}</span></b> <br />
                            <br />
                        </div>
                        <div className="col-1"></div>
                    </div>

                </div>

                <div className="col-md-12">
                    <div className="row">

                        <div className="col-md-1"></div>
                        <div className="col-md-10 no-padd">
                            <table width="94%" className="table table-striped table-bordered  mt-2 fnt-sm" style={{ width: "97%" }}>
                                <thead className="txt-ctr">
                                    <th>Colis</th>
                                    <th>Résultat</th>
                                    <th>Montant Colis</th>
                                    <th>Frais de Service</th>
                                </thead>
                                <tbody>

                                    {listSorted.map(data =>
                                        <tr>
                                            <td className="p-1">{data?.coli?.code}</td>
                                            <td className="p-1">{data?.decision}</td>
                                            <td className="p-1">{data?.montant_colis}</td>
                                            <td className="p-1">{data?.frais_service}</td>
                                        </tr>
                                    )}

                                    <tr className="">
                                        <th colspan="2" scope="row" className="txt_blk p-1">Total</th>
                                        <td className="fnt-w5 p-1">{bill && bill.montant_colis} TND</td>
                                        <td className="fnt-w5 p-1">{bill && bill.montant_frais_service} TND</td>
                                    </tr>

                                </tbody>
                            </table>


                            <table width="94%" className="table table-striped table-bordered  mt-2 fnt-sm" style={{ width: "97%" }}>
                                <thead className="txt-ctr">
                                    <th>Num Chéque</th>
                                    <th>Proprietaire</th>
                                    <th>Montant</th>
                                </thead>
                                <tbody>

                                    {Array.isArray(bill?.cheques) && bill?.cheques.map(data =>
                                        <tr>
                                            <td className="p-1">{data.num}</td>
                                            <td className="p-1">{data.proprietaire}</td>
                                            <td className="p-1">{data.montant}</td>
                                        </tr>
                                    )}

                                    <tr className="">
                                        <th colspan="2" scope="row" className="txt_blk p-1">Total Cheque</th>
                                        <td className="fnt-w5 p-1">{bill?.montant_cheque} TND</td>
                                    </tr>





                                </tbody>
                            </table>


                            <table width="94%" className="table table-striped table-bordered  mt-2 fnt-sm" style={{ width: "97%" }}>
                                <tbody>
                                    <tr className="">
                                        <th colspan="2" scope="row" className="txt_blk p-1"><h5>Total à payer</h5></th>
                                        <td colspan="2" className="fnt-w5 p-1 txt-ctr"><h5>{bill?.montant_paye}  TND </h5></td>
                                    </tr>





                                </tbody>
                            </table>


                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 no-padd">

                            <table width="100%" className="table table-striped table-bordered  mt-2 fnt-sm" style={{ width: "97%" }}>
                                <tr>
                                    <td>
                                        <b>Signature et cachet</b>
                                        <div className="row" style={{ height: "160px" }}>

                                        </div>
                                    </td>

                                    <td>
                                        <b>Signature et cachet du client</b>
                                        <div className="row" style={{ height: "160px" }}>

                                        </div>
                                    </td>
                                </tr>
                            </table>


                        </div>
                        <div className="col-md-1"></div>


                    </div>
                </div>


            </div>





        </div>
    );
}
export default BillPrintExped;