import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { ColisStatusBg, stat as colisStat, colisStatusLabels } from '../../utils/constants/Colis.enum';
import { getStringDate } from '../../utils/helpers/date.helper';
import { useHistory } from "react-router-dom";


function ColisHistoryClient({ loading, lst_data, error }) {

    const renderStatus = (colis) => {
        let badge;
        if (colis.etat === colisStat.closed) {
            if (colis.livre == true) {
                badge = <span className="badge bg-success fnt-w5"> Payé(Livré) </span>
            } else if (colis.livre == false) {
                badge = <span className="badge bg-success fnt-w5"> Payé(Non-Livré) </span>
            }
        } if (colis.etat === colisStat.completed) {
            if (colis.livre == true) {
                badge = <span className="badge bg-primary fnt-w5"> Non-Payé(Livré) </span>
            } else if (colis.livre == false) {
                badge = <span className="badge bg-primary fnt-w5"> Non-Payé(Non-Livré) </span>
            }
        } if (colis.etat === colisStat.completed_non_retourne) {
            if (colis.livre == true) {
                badge = <span className="badge bg-primary fnt-w5"> A retourné(Livré) </span>
            } else if (colis.livre == false) {
                badge = <span className="badge bg-primary fnt-w5"> A retourné(Non-Livré) </span>
            }
        } else if (colis.etat === colisStat.progress || colis.etat === colisStat.progress_success || colis.etat === colisStat.progress_reported || colis.etat === colisStat.progress_fail) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> livraison en cours </span>
        } else if (colis.etat === colisStat.pickup) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> En depot </span>
        } else if (colis.etat === colisStat.pickup_prog) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Ramassage en cours </span>
        } else if (colis.etat === colisStat.in_rs) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Livraison programmé </span>
        } else if (colis.etat === colisStat.to_return) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Retour </span>
        } else if (colis.etat === colisStat.transfer_zone_progress) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Transfert zone en cours </span>
        } else if (colis.etat === colisStat.pd_receive) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> En attente de ramassage </span>
        } else if (colis.etat === colisStat.pd_transfer) {
            badge = <span className="badge bg-warning fnt-w5 txt_blk"> Non-envoyé </span>
        }


        return badge;
    }





    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'box-open']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Historique colis   </b>
                </div>

            </div>

            

            <div className="row no-marg rw-header ">

                <div className="col-md-6 offset-md-3 flex_ctr p-2">


                </div>



                <div className="col-md-6  fnt-largr">

                    <div className="row no-marg ">

                        <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
                            <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">Colis</b>
                        </div>

                        <div className="col-md-9 dvdr-r-gray pt-1 pb-1">

                        </div>

                    </div>


                </div>

                <div className="col-md-6 no-padd mb-2 ">

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                </div>



            </div>


            <div className="col-md-12 p-2 bg-wt mt-2">


                <table className="table table-striped table-bordered fnt-sm">
                    <thead>
                        <tr>
                            <th scope="col">Colis</th>
                            <th scope="col">Client</th>
                            <th scope="col">Montant</th>
                            <th scope="col">Status</th>
                            <th scope="col">Facture</th>
                            <th scope="col">Date-facture</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lst_data.map(data =>

                            <tr>
                                <td><b>{data.code}</b></td>
                                <td>{data.nom_cl} {data.prenom_cl}</td>
                                <td>{data.montant_ttc}</td>
                                <td className='text-start'><span class={`badge badge-pill  ${ColisStatusBg[data?.etat]}`}>{colisStatusLabels[data?.etat]} </span> </td>
                                <td><b>{data.factures && data.factures.length ? data.factures[0].ref : '_'}</b></td>
                                <td>{data.factures && data.factures.length ? getStringDate(data.factures[0].updatedAt) : '_'}</td>

                            </tr>

                        )}
                    </tbody>
                </table>

                {!lst_data.length &&
                    <div className="col-md-12 txt-ctr">
                        <br />
                        <br />
                        <br />
                        {/* <img src={noDataIcon} alt="" width="10%" /> */}
                        <br /><b className="txt-gray fnt-w5">AUCUN COLIS TROUVEE</b>
                    </div>
                }

                {loading &&
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                }
            </div>




        </>
    );
}

export default ColisHistoryClient;