import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Table } from "react-bootstrap";
import ChequeFormContainer from "./ChequeForm.container";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { deleteCheque, listCheque } from "../../../slices/Cheque.slice";
import { listAdmExped } from "../../../slices/User.slice";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ConfirmModal from "../../../ui/modals/ConfirmModal";
import { cheque_status_options } from "../../../utils/constants/cheque.enum";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Opération non autorisée</Popover.Title>
    <Popover.Content>Opération non autorisée pour les chéques déja inclus dans une facture!</Popover.Content>
  </Popover>
);

const ChequeListContainer = () => {
  const { loading, error } = useSelector((state) => state.cheque);

  const dispatch = useDispatch();
  const history = useHistory();

  const [lst_cheque, set_lst_cheque] = useState([]);
  const [show_form, set_show_form] = useState(false);
  const [sel_item, set_sel_item] = useState(null);
  const [show_confirm_del_modal, set_show_confirm_del_modal] = useState(false);

  //filter & search
  const [filter, set_filter] = useState({etat : "all", search : ""});
  const [lst_exped, set_lst_exped] = useState([]);
  const [lst_exped_options, set_lst_exped_options] = useState([]);
  const [sel_exped, set_sel_exped] = useState([]);

  const load_expeds = () => {
    dispatch(listAdmExped())
      .unwrap()
      .then((res) => {
        set_lst_exped(Array.isArray(res) ? res : []);
      });
  };

  const onSearchExpeds = (search) => {
    if (Array.isArray(lst_exped)) {
      let tmp = lst_exped.filter((el) => String(el?.nom_com).toUpperCase().includes(String(search).toUpperCase()));
      set_lst_exped_options(tmp);
    }
  };

  const onSelectExpeds = (value) => {
    set_sel_exped(value)
    if (Array.isArray(value)) {
      if (value.length == 1) set_filter({...filter , id_exped : value[0]?.id});
    }
  };

  const onClearFilter = ()=>{
    set_filter({etat : 'all' , search : ''})
    set_sel_exped([])
    dispatch(listCheque())
      .unwrap()
      .then((res) => {
        set_lst_cheque(Array.isArray(res?.rows) ? res?.rows : []);
      })
      .catch((err) => console.log(err));
    
  }

  const onCloseForm = () => {
    set_show_form(false);
    set_sel_item(null);
  };

  const onAfterCE = () => {
    set_show_form(false);
    load_data();
  };

  const onEditItem = (item) => {
    set_sel_item(item);
    set_show_form(true);
  };

  const onDeleteItem = (item) => {
    set_sel_item(item);
    set_show_confirm_del_modal(true);
  };

  const onCloseDelModal = () => {
    set_sel_item(null);
    set_show_confirm_del_modal(false);
  };

  const onDeleteCheque = () => {
    if (sel_item?.idCheque)
      dispatch(deleteCheque(sel_item?.idCheque))
        .unwrap()
        .then((res) => {
          load_data();
          onCloseDelModal();
        })
        .catch((err) => console.log(err));
  };

  const load_data = () => {
    let filter_data = {}

    if(filter?.etat != 'all' && filter?.etat)
    filter_data.etat = filter?.etat

    if(filter?.id_exped)
    filter_data.id_exped = filter?.id_exped

    if(filter?.search)
    filter_data.search = filter?.search

    dispatch(listCheque(filter_data))
      .unwrap()
      .then((res) => {
        set_lst_cheque(Array.isArray(res?.rows) ? res?.rows : []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    load_data();
    load_expeds();
  }, []);

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "money-check-alt"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Les Chéques </b>
        </div>

        <div className="col-md-6 no-padd dsp_inl_flex box_resum ">
          <b className="box_resum-b padd-l10 dvdr-l-fx-gray">
            {" "}
            <span></span>
          </b>
        </div>
      </div>

      <div className="row no-marg rw-header">
        <div className="row m-0 p-2">
          <Form.Group className="col-md-3 px-2">
            <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
            <Form.Control type="search" className="form-control inpt_search shadow-none" style={{borderRadius : '.2rem'}} placeholder="Search ..." onChange={(e) => set_filter({...filter , search : e.target.value})} value={filter?.search} />
          </Form.Group>

          <Form.Group className="col-md-3 px-2">
            <Form.Label className="fnt-sm fnt-w5 m-0">Expéditeur</Form.Label>
            <AsyncTypeahead
              id="expedPickersearch"
              size="sm"
              labelKey={(op) => op?.nom_com}
              isLoading={loading}
              onChange={onSelectExpeds}
              options={lst_exped_options}
              onSearch={onSearchExpeds}
              selected={sel_exped}
              placeholder="Recherche expediteur..."
            />
          </Form.Group>

          <Form.Group className="col-md-3 px-2">
            <Form.Label className="fnt-sm fnt-w5 m-0">Etat</Form.Label>
            <Form.Control as="select" size="sm" className="force-apparence" value={filter?.etat} onChange={e => set_filter({...filter , etat : e?.target?.value})}>
            <option value={"all"}>Tous</option>
              {Array.isArray(cheque_status_options) &&
                cheque_status_options.map((op) => (
                  <option value={op} key={op}>
                    {op}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="col-md-3 px-2 pt-4">
            <button className="btn btn_search m-0" onClick={load_data}>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </button>
            <button className="btn btn_search bg-success m-0 ms-1" onClick={load_data}>
              <FontAwesomeIcon icon={["fas", "sync-alt"]} />
            </button>
            <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
              <FontAwesomeIcon icon={["fas", "times"]} />
            </button>
          </Form.Group>
        </div>

        <div className="col-md-12 d-inline-block text-end py-1">
          <button className="btn btn-outline-success" type="button" onClick={() => set_show_form(true)}>
            {" "}
            Ajouter un chéque
          </button>
        </div>
      </div>

      <div className="row m-0">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Numéro</th>
              <th>Expéditeur</th>
              <th>Colis</th>
              <th>Client</th>
              <th>Montant (TND)</th>
              <th>Etat</th>
              <th>Facture</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(lst_cheque) &&
              lst_cheque.map((el) => (
                <tr>
                  <td>{el?.idCheque}</td>
                  <td>{el?.num}</td>
                  <td>{el?.chequeFor?.nom_com}</td>
                  <td className="fnt-w5">{el?.coli?.code}</td>
                  <td>{el?.proprietaire}</td>
                  <td className="text-success fnt-w5">{el?.montant}</td>
                  <td>
                    <span className={`badge text-capitalize ${el?.etat == "dispo" ? "bg-success" : "bg-danger"}`}>{el?.etat}</span>
                  </td>
                  <td className="fnt-w5">{el?.facture?.ref}</td>
                  <td>
                    {el?.etat == "dispo" ? (
                      <>
                        <button className="btn btn-sm btn-warning me-1" onClick={(e) => onEditItem(el)}>
                          <FontAwesomeIcon icon={["fas", "edit"]} />
                        </button>
                        <button className="btn btn-sm btn-danger" onClick={(e) => onDeleteItem(el)}>
                          <FontAwesomeIcon icon={["fas", "trash"]} />
                        </button>
                      </>
                    ) : (
                      <>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                          <button className="btn btn-sm btn-warning me-1">
                            <FontAwesomeIcon icon={["fas", "edit"]} />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                          <button className="btn btn-sm btn-danger">
                            <FontAwesomeIcon icon={["fas", "trash"]} />
                          </button>
                        </OverlayTrigger>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <ChequeFormContainer show={show_form} onClose={onCloseForm} afterSubmit={onAfterCE} sel_item={sel_item} />
      <ConfirmModal title="suppression de chéque" msg={"Vous confirmer cette opération ?"} show={show_confirm_del_modal} onHide={onCloseDelModal} onOk={onDeleteCheque} />
    </>
  );
};

export default ChequeListContainer;
