import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class MovableService {


    searchMuColis(search){
        
        let token = storage.getToken();
        return axios.get(`${api_url}/api/mu/exped/search/colis/${search}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    createClientMu(mu){
        
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/exped/create`, mu, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    dispatchClientMu(code){

        let token = storage.getToken();
        return axios.get(`${api_url}/api/mu/exped/dispatch/${code}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    cancelClientMu(code){
        
        let token = storage.getToken();
        return axios.get(`${api_url}/api/mu/exped/del/${code}`,{
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getClientMuByCode(code){
        
        let token = storage.getToken();
        return axios.get(`${api_url}/api/mu/exped/details/${code}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    editClientMu(mu){
        
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/exped/update`, mu, {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    getClientMuList(data){
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/exped/list`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getClientMuHistory(data){
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/exped/history`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }




    // createDispatchedClientMu(mu) {
    //     let token = storage.getToken();
    //     return axios.post(`${api_url}/api/mu/create/dispatch`, mu, {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     });
    // }
    // searchClientMu(search){
        
    //     let token = storage.getToken();
    //     return axios.get(`${api_url}/api/mu/mesmu/search/${search}`, {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     });
    // }
    // getClientMuArchive() {
    //     let token = storage.getToken();
    //     return axios.get(`${api_url}/api/expd/getarchiveMyMv`, {
    //         headers: {
    //             'cgo-tkn': token
    //         }
    //     })
    // }


    //ADM------------------------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------------------------


    getMuByCode(code) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/mu/adm/getbycode/${code}` , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    pickMu(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/receive`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getMuList(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/list`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    dispatchPickMu(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/dispatch`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    excludeMuColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/colis/exclude`, data , {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    refuseMu(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/refuse`, data , {
            headers: {
                'cgo-tkn': token
            }
        })
    }

    
    createMuTransfert(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/transfert/create`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    dispatchMuTransfert(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/transfert/dispatch`, data ,  {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    deleteMuTransfert(id) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/mu/adm/transfert/del/${id}`,  {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    pickMuTransfert(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/transfert/receive`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    listMuTransfert(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/mu/adm/transfert/list`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    
    
    
}

export default new MovableService();