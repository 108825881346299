import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import { getStringDate, getDays } from '../../utils/helpers/date.helper';
import { ColisStatusBg, stat as colisStat, colisStatusLabels } from '../../utils/constants/Colis.enum';


const ColisRsFormItem = ({ data, onItemDetailsClick , onRemove , index }) => {

    const onItemClick = () => {
        onItemDetailsClick(data);
    }

    const renderStat = () => {
        let spn_stat;
        let stat = "";
        switch (data.etat) {
            case colisStat.pd_receive:
                stat = "Pending to receive";
                break;
            case colisStat.pickup:
                stat = "Picked up";
                break;
            case colisStat.pickup_prog:
                stat = "Picked up in progress";
                break;
            case colisStat.in_rs:
                stat = "Added to runsheet";
                break;
            case colisStat.progress:
                stat = "In progress";
                break;
            case colisStat.progress_success:
                stat = "In progress success (Livré)";
                break;
            case colisStat.progress_fail:
                stat = "In progress failur (Echec)";
                break;
            case colisStat.progress_reported:
                stat = "In progress reschedule (reporté)";
                break;
            case colisStat.pd_transfer_zone_pick:
                stat = "To transfer zone ";
                break;

            case colisStat.pd_transfer_zone_return:
                stat = "To transfer zone (Retour)";
                break;

            case colisStat.transfer_zone_pick:
                stat = "transfer zone progress ";
                break;

            case colisStat.transfer_zone_return:
                stat = "transfer zone progress (Retour) ";
                break;
                
            default:
                stat = data.etat;

        }

        if (data.etat === colisStat.pd_receive || data.etat === colisStat.in_rs || data.etat === colisStat.progress || data.etat === colisStat.pd_transfer_zone_pick || data.etat === colisStat.transfer_zone_pick || data.etat === colisStat.transfer_zone_return) {
            spn_stat = <span className="badge bg-warning fnt-w5 txt_blk"> {stat} </span>
        } else if (data.etat === colisStat.pickup || data.etat === colisStat.completed || data.etat === colisStat.progress_success) {
            spn_stat = <span className="badge bg-success fnt-w5 txt_wt"> {stat} </span>
        } else if (data.etat === colisStat.progress_fail || data.etat === colisStat.to_return || data.etat === colisStat.pd_transfer_zone_return) {
            spn_stat = <span className="badge bg-danger fnt-w5 txt_wt"> {stat}</span>
        } else if (data.etat === colisStat.progress_reported) {
            spn_stat = <span className="badge bg-danger bg-org fnt-w5 txt_wt"> {stat}</span>
        } else {
            spn_stat = <span className="badge bg-secondary fnt-w5 txt_wt"> {stat}</span>
        }
        return spn_stat;
    }

    return (
        <div className="row item_box no-marg">

            <div className="col-md-2 dvdr-r-gray " >
                <b className="item_box_ref">
                    <span className="txt_dark_bl"><FontAwesomeIcon icon={['fas', 'barcode']} /> </span>
                    <span className="fnt-w5 txt_blk">{data.code}</span> <br />
                    {data &&
                        <span class={`badge badge-pill  ${ColisStatusBg[data?.etat]}`}>{colisStatusLabels[data?.etat]} </span>
                    }
                    <span class={+data?.attempt < 3 ? "badge badge-pill bg-dark txt_wt ms-1" : "badge badge-pill bg-danger txt_wt ms-1"}>{data.attempt}/3</span>

                </b>

            </div>

            {/* <div className="col-md-2 dvdr-r-gray">
                <b className="fnt-w5 fnt-sm txt-gray">Expediteur:</b>  <br />
                <b className="item_box_money fnt-w5">{data && data.user && data.user.nom_com}  </b>
            </div> */}

            <div className="col-md-2 dvdr-r-gray">
                <b className="fnt-w5 fnt-sm txt-gray">Expediteur:</b>  <br />
                <b className="item_box_money fnt-w5">{data.user.nom_com}  </b>
                
            </div>

            <div className="col-md-4 dvdr-r-gray">
                <b className="fnt-w5 fnt-sm txt-gray ">Adresse: </b> <br />
                <b className="fnt-w5 txt-green"><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></b>
                <b className="fnt-w4 fnt-sm" > {data.adresse} </b>
                <b className="fnt-w4 badge-out-succ text-capitalize">{data.adresse_lieu}</b>
            </div>



            <div className="col-md-2  ps-2">
                <b className="sm-gray-txt fnt-sm">{data.createdAt ? getStringDate(data.createdAt) : '_'}</b> <br />
                <span className="fnt-w5 fnt-sm">{data.createdAt ? getDays(data.createdAt) : '_'}</span>
            </div>

            <div className={false ? "col-md-1 pt-2" : "col-md-1 pt-2"}>
                <button className="box_item_btn no-marg" onClick={onItemClick}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
            </div>

            <div className="col-md-1 flex_ctr  flex_ctr">
                <button className="btn btn-outline-danger round" onClick={() => data && onRemove(index)}> <FontAwesomeIcon icon={['fas', 'times']} /> </button>
            </div>









        </div>
    );
}
export default ColisRsFormItem;