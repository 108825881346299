import React  from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ColisMuItem = ({data , onCancel , index}) => {

    

    return (
        <div className="row item_box">

        

            <div className="col-md-5 dvdr-r-gray pt-1" >
                <b className="item_box_ref">
                    <span className="fnt-w5 txt_blue"><FontAwesomeIcon icon={['fas', 'barcode']} /> </span>
                    <span className="fnt-w5 txt_blk">{data.code}</span>
                </b>

            </div>
            {/* <div className="col-md-1 dvdr-r-gray pt-1">
                    <span>x{data.nbr} </span> 
            </div> */}

            <div className="col-md-3 dvdr-r-gray  pt-1">
                <b className="fnt-w5 fnt-sm">Montant TTC :</b>
                <b className="item_box_money">{data.montant_ttc} <span>TND</span> </b>
            </div>

            <div className="col-md-3 dvdr-r-gray  pt-1">
                <b className="fnt-w5 fnt-sm">Client:</b>
                <b className="fnt-w4">{data.nom_cl} {data.prenom_cl} </b>
            </div>

            <div className="col-md-1 flex_ctr  flex_ctr">
                <button className="btn btn-outline-danger round" onClick={() => data && onCancel(index)}> <FontAwesomeIcon icon={['fas', 'times']} /> </button>
            </div>


            






        </div>
    );
}
export default ColisMuItem;