import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { TicketStatus, TicketStatusBg, TicketType, TicketTypeLabel } from "../../../utils/constants/ticket.enum";
import { getStringDate } from "../../../utils/helpers/date.helper";
import { ColisStatus, colisStatusLabels } from "../../../utils/constants/Colis.enum";
import { Form } from "react-bootstrap";
import { applyAdmTicket, refuseAdmTicket } from "../../../slices/Ticket.slice";
import { useDispatch, useSelector } from "react-redux";

const TicketAdmDeatilsContainer = ({ ticket, show, onClose, load_data }) => {
  const [show_apply_form, set_show_apply_form] = useState(false);
  const [show_refuse_form, set_show_refuse_form] = useState(false);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.ticket);

  const onShowDecisionForm = (value) => {
    if (value == "apply") set_show_apply_form(true);
    else if (value == "refuse") set_show_refuse_form(true);
  };

  const onSubmitDecision = (value, note_adm) => {
    if (ticket?.id) {
      let data = { id: ticket?.id, note_adm: note_adm };
      if (value == true) onApplyTicket(data);
      else onRefuseTicket(data);
    }
  };
  const onApplyTicket = (data) => {
    dispatch(applyAdmTicket(data))
      .unwrap()
      .then((res) => {
        if (res?.done) {
          set_show_apply_form(false);
          load_data();
        }
      })
      .catch((err) => console.log(err));
  };

  const onRefuseTicket = (data) => {
    dispatch(refuseAdmTicket(data))
      .unwrap()
      .then((res) => {
        if (res?.done) {
          set_show_refuse_form(false);
          load_data();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row m-0 p-2 ">
        <div className="col-md-12 text-center p-2 bg-wt">
          <b className="item_box_ref">
            <span className="spn_inf_tit dsp_blk fnt-sm">Ticket Num</span>
            <span>
              <FontAwesomeIcon icon={["fas", "ticket-alt"]} />{" "}
            </span>
            <span className="fnt-w5 txt_blk">{ticket?.ref}</span>
          </b>
        </div>

        <div className="col-md-12 bg-wt mt-3 p-2">
          <div className="row m-0">
            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Status</b>
              <span className={`badge badge-sm text-capitalize ${TicketStatusBg[ticket?.status]}`}>{ticket?.status}</span>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Expediteur</b>
              <b className="d-block fnt-w5 fnt-sm">{ticket?.user?.nom_com}</b>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Date création</b>
              <b className="d-block fnt-w5 fnt-sm">{getStringDate(ticket?.createdAt)}</b>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Colis</b>
              <b className="d-block fnt-w5 fnt-sm">{ticket?.coli?.code}</b>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Type Ticket</b>
              <b className="d-block fnt-w5 fnt-sm">{TicketTypeLabel[ticket?.type]}</b>
            </div>

            <div className="col-md-12 text-end dvdr-t-gray pt-3">
              {!show_apply_form && !show_refuse_form && (
                <>
                  {ticket?.status == TicketStatus["EN-ATTENTE"] && (
                    <button className="btn btn-sm btn-success me-1" disabled={loading} onClick={(e) => onShowDecisionForm("apply")}>
                      <FontAwesomeIcon icon={["fas", "check-circle"]} /> Appliquer
                    </button>
                  )}
                  {ticket?.status == TicketStatus["EN-ATTENTE"] && (
                    <button className="btn btn-sm btn-danger me-1" disabled={loading} onClick={(e) => onShowDecisionForm("refuse")}>
                      <FontAwesomeIcon icon={["fas", "ban"]} /> Refuser
                    </button>
                  )}
                </>
              )}

              {show_apply_form && <DecisionForm ticket={ticket} onSubmit={onSubmitDecision} loading={loading} accept={true} onClose={(e) => set_show_apply_form(false)} />}
              {show_refuse_form && <DecisionForm ticket={ticket} onSubmit={onSubmitDecision} loading={loading} accept={false} onClose={(e) => set_show_refuse_form(false)} />}
            </div>

            <div className="col-md-12 dvdr-t-gray mt-3 pt-3">
              <b className="d-block fnt-w5 fnt-sm">Les nouvelle données :</b>
            </div>

            {ticket?.type == TicketType["INFOS-ADRS"] && (
              <>
                <div className="col-md-12">
                  <b className="d-block fnt-w5 fnt-sm text-muted">Adresse</b>
                  <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.adresse}</b>
                </div>

                <div className="col-md-4">
                  <b className="d-block fnt-w5 fnt-sm text-muted">Lieu</b>
                  <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.adresse_lieu}</b>
                </div>
              </>
            )}

            {ticket?.type == TicketType["INFOS-MNT"] && (
              <div className="col-md-4">
                <b className="d-block fnt-w5 fnt-sm text-muted">Montant</b>
                <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.montant_ttc} TND</b>
              </div>
            )}

            {ticket?.type == TicketType["INFOS-TEL"] && (
              <div className="col-md-4">
                <b className="d-block fnt-w5 fnt-sm text-muted">Tél client</b>
                <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.tel_cl}</b>
              </div>
            )}
            {ticket?.type == TicketType.STATUS && (
              <div className="col-md-4">
                <b className="d-block fnt-w5 fnt-sm text-muted">Status</b>
                <b className="d-block fnt-w5 fnt-sm text-capitalize">{colisStatusLabels[ticket?.data?.etat]}</b>
              </div>
            )}

            <div className="col-md-12 pt-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Note Expéditeur :</b>
              {ticket?.note_exped && (
                <div className="fnt-sm fnt-w5" style={{ border: "#000 1px dashed", padding: 3 }} dangerouslySetInnerHTML={{ __html: String(ticket?.note_exped).replace(/\n/g, "<br />") }} />
              )}
            </div>

            <div className="col-md-12 pt-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Note Administration :</b>
              {ticket?.note_adm && (
                <div className="fnt-sm fnt-w5" style={{ border: "#000 1px dashed", padding: 3 }} dangerouslySetInnerHTML={{ __html: String(ticket?.note_adm).replace(/\n/g, "<br />") }} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DecisionForm = ({ loading, onSubmit, ticket, accept, onClose }) => {
  const [note_adm, set_note_adm] = useState("");
  const submitHandler = () => {
    onSubmit(accept, note_adm);
  };
  return (
    <div className="col-md-12 my-2 text-start">
      {accept == true ? <h6>Voulez-vous Appliquer les chagement du ticket Num {ticket?.ref} ?</h6> : <h6>Voulez-vous refuser les chagement du ticket Num {ticket?.ref} ?</h6>}

      <Form.Group className="col-md-12 ">
        <Form.Label className="fnt-sm fnt-w5 m-0">Réponse (optionnel) :</Form.Label>
        <Form.Control as="textarea" rows={3} size="sm" value={note_adm} onChange={(e) => set_note_adm(e?.target?.value)} />
      </Form.Group>

      <div className="col-md-12 text-end my-1">
        <button className="btn btn-sm btn-success me-1 px-3" disabled={loading} onClick={submitHandler}>
          Confirmer
        </button>
        <button className="btn btn-sm btn-danger " disabled={loading} onClick={onClose}>
          Fermer
        </button>
      </div>
    </div>
  );
};

export default TicketAdmDeatilsContainer;
