import { configureStore } from '@reduxjs/toolkit'
import RegisterReducer from './slices/Register';
import authReducer from './slices/Auth';
import AccessCheckReducer from './slices/AccessCheck';
import ColisReducer from './slices/Colis';
import MuReducer from './slices/Mu';
import FactureReducer from './slices/Facture';
import ColisAdmReducer from './slices/ColisAdm';
import MuAdmReducer from './slices/MuAdm';
import RsAdmReducer from './slices/RsAdm';
import FactureAdmReducer from './slices/FactureAdm';
import ChequeReducer from './slices/Cheque.slice';
import UserReducer from './slices/User.slice';
import HubReducer from './slices/Hub.slice';
import TicketReducer from './slices/Ticket.slice';
import LivReducer from './slices/Liv.slice';
import AfexReducer from './slices/Afex.slice';
import locationReducer from './slices/Location.slice';

const reducer = {
  register : RegisterReducer,
  auth: authReducer,
  accessCheck : AccessCheckReducer,
  colis : ColisReducer ,
  mu : MuReducer ,
  bill : FactureReducer ,
  colis_adm : ColisAdmReducer ,
  mu_adm : MuAdmReducer ,
  rs_adm : RsAdmReducer ,
  bill_adm : FactureAdmReducer ,
  cheque : ChequeReducer ,
  user : UserReducer ,
  hub : HubReducer ,
  ticket : TicketReducer ,
  liv : LivReducer ,
  afex : AfexReducer ,
  location : locationReducer ,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;