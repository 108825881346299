import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_wt from '../../../assets/img/logo.svg'
import bg from '../../../assets/img/home_banner.svg'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import appApk from '../../../assets/livappv10.txt';

import {api_url} from '../../../services/http_constant';
import { routes } from '../../../utils/helpers/routing.helper';

const DownloadApp = (props) => {


    const [can, setCan] = useState(false);
    const history = useHistory();
    const [loading , set_loading ] = useState(false)
    const [error , set_error ] = useState("")
    const [succ_msg , set_succ_msg ] = useState("")

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Vous devez saisir votre E-mail !')
            .email('Email non valid !'),
        password: Yup.string()
            .min(6, 'Mot de passe non valid !')
            .required('Vous devez saisir votre mot de passe !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        set_error("")
        set_loading(true)
        axios.post(`${api_url}/api/users/login`, { email: data.email, password: data.password })
            .then(res => {
                set_loading(false)
                const msg = res.data;
                console.log(msg);
                if (res.data) {
                    if (res.data.success) {
                        if(res.data.user){
                            if(res.data.user.role != "exped")
                            setCan(true);
                            else
                            set_error("Accés refusé !")
                        }
                        
                    } else {
                        set_error("Contactez nous afin de valider votre compte SVP");
                    }
                }


            })
            .catch(err => {
                set_loading(false)
                const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
                set_error(message)
            }
            );

    };

    const goHome = () => {
        history.push(routes.home_public)
    }


    return (

        <div className="row no-marg login-container bg-wt">

            <div className="col-md-8 h-100">

                <div className="row no-marg">
                    <div className="col-md-2 no-padd">
                        <button className="btn btn-primary btn_back_home" onClick={goHome} ><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
                    </div>

                    <div className="col-md-8 txt-ctr no-padd">
                        {/* <img src={logo} className="scale15" style={{ filter: "opacity(70%)" }} width="200" alt="" /> */}
                    </div>
                </div>



                <div className="col-md-8 offset-md-2 " >
                    <br /> <br />
                    <h2 className="padd-10 mb-5">Connectez-vous pour télécharger l'application</h2>
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="marg-10 padd-10">
                        <div className="form-group">
                            <label className="fnt-w5"><FontAwesomeIcon icon={['fas', 'envelope']} /> Email</label>
                            <input placeholder="email ..."  {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.email?.message}</p>
                        </div>

                        <div className="form-group">
                            <label className="fnt-w5"><FontAwesomeIcon icon={['fas', 'unlock-alt']} /> Mot de passe</label>
                            <input type="password" placeholder="mot de passe ..."  {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.password?.message}</p>
                        </div>

                        <div className="col-md-12 txt-ctr" >
                            {loading ?
                                <div className="col-md-12 p-4 txt-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-primary btn-login mt-4" >Se connecter</button>
                            }
                        </div>
                        <br />

                        <div className="col-md-12 mb-4 txt-ctr" >
                            {can &&

                                <a className="btn btn-outline-success p-3 btn-lg" href={appApk} download><FontAwesomeIcon icon={['fab', 'android']} /> Télécharger</a>
                            }
                        </div>

                        <br /> <br />
                    </form>




                </div>

            </div>



            <div className="col-md-4 bg-green-smooth h-100" style={{ position: "relative" }}>
                <img src={logo_wt} className="avatar_logo" alt="" width="40%" />
                <img src={bg} className="avatar_prog" alt="" />
            </div>
        </div>

    )
}

export default DownloadApp;


