import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class LocationService {

    //Cummon Services----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------
    searchLocation(search) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/location/commun/search/${search}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }


    
}

export default new LocationService();