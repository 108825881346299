import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class RunsheetService {

    createRs(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/rs/adm/create`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    editRs(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/rs/adm/edit`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    dispatchRs(id) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/rs/adm/dispatch/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    confirmRs(id) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/rs/adm/confirm/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    deleteRs(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/rs/adm/del`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getRsByCode(code) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/rs/adm/view/${code}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    
    


    getRsList(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/rs/adm/list`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

}

export default new RunsheetService();