/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisList from '../../../ui/lists/Colis.list';
import ColisClientSearch from '../../../ui/headers/ColisClientSearch.header';
import ColisClientDetails from '../../../ui/layouts/ColisClientDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear, del, list, selectColis,  searchMesColisAll } from '../../../slices/Colis';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';


const MesColis = (props) => {

    const dispatch = useDispatch();
    const { loading, error, colis_list, selected_colis } = useSelector(state => state.colis);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");
    const [last_search , set_last_search] = useState("")



    const onItemDetailsClick = data => {
        dispatch(selectColis(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }


    const onPrint = data => {
        if (data) {
            UserStorage.setParam([data])
            const win = window.open("/dashb/colis/print/", "_blank");
            win.focus();
        }
    }



    const onEdit = data => {
        if (data) {
            history.push("/dashb/colis/edit/" + data.code)
        }
    }

    const onDelete = data => {
        if (data) {
            set_mod_del_title("Supprimer colis")
            set_mod_del_msg("Voulez-vous supprimer le colis " + data.code + " ?")
            setShowDelModal(true);

        }
    }

    const doDelete = () => {
        if (selected_colis) {
            console.log(selected_colis)
            dispatch(del({ ref: selected_colis.ref })).unwrap().then(() => {
                setShowDetails(false)
                setShowDelModal(false);
                if(last_search != "")
                dispatch(searchMesColisAll(last_search))
            })
        }
    }



    const onSearch = (search) => {

        set_last_search(search)
        if(search != "")
        dispatch(searchMesColisAll(search))

    }

    useEffect(() => {
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <ColisClientSearch lst_data={colis_list}  error={error}  onSearch={onSearch}  />
            <ColisList loading={loading} error={error} lst_data={colis_list} onItemDetailsClick={onItemDetailsClick} onItemSelected={()=>{}} />
            <ColisClientDetails data={selected_colis} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} />
        </>
    )
}

export default MesColis;



