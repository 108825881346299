import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {isPendingAction , isRejectedAction , isFulfilledAction} from './slice.helper';
import Api from '../services/HubService';

const initialState = {
    loading: false,
    error: '',
    data: null,
    current_hub: null,
}

export const createHub = createAsyncThunk(
    "cheque/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createHub(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const updateHub = createAsyncThunk(
    "cheque/update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.editHub(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const listHub = createAsyncThunk(
    "cheque/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listHub(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const deleteHub = createAsyncThunk(
    "cheque/delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteHub(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const selectHub = createAsyncThunk(
    "cheque/select",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.selectHub(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


const chequeSlice = createSlice({
    name: "cheque",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
        },
        setCurrentHub: (state, action) => {
            state.current_hub = action.payload;
        },

    },
    extraReducers: builder => {
        builder

            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
                state.data = action.payload;
            })






    }
})

const { reducer } = chequeSlice;
export const { clear , setCurrentHub } = chequeSlice.actions;
export default reducer;