import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const UserPassEdit = ({ show, onClose, user, onUpdateUser, loading, error }) => {
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, "Mot de passe non valid ( min 6 caractères ) !").required("Vous devez saisir le mot de passe !"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Les mot de passe ne sont identiques !")
      .required("Vous devez confirmez le mot de passe !"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    if (data && user) {
      let usr = {
        id: user?.id,
        password: data.password,
      };
      onUpdateUser(usr);
    }
  };

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row no-marg bg-wt p-4">
        {loading ? (
          <div className="row loading-row">
            <div className="col-md-12 txt-ctr">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="col-md-12" id="frm_user_pass">
            <div className="col-md-12">
                <h6>Changement de mot de passe:</h6>
              {error && (
                <div className="alert alert-danger" role="alert">
                  <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
                </div>
              )}
            </div>

            <div className="row m-0">
              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Mot de passe </Form.Label>
                <Form.Control size="sm" name="password" {...register("password")} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.password?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Confirmation mot de passe </Form.Label>
                <Form.Control size="sm" name="confirmPassword" {...register("confirmPassword")} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.confirmPassword?.message}</p>
                </Form.Text>
              </Form.Group>
            </div>

            <div className="row m-0">
              <div className="col-md-12 text-end">
                <button className="btn btn-success" type="submit">
                  Modifier mot de passe
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserPassEdit;
