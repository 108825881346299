/* eslint-disable */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useForm, useWatch, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { routes } from "../../utils/helpers/routing.helper";

// import { lst_gov } from "../../utils/constants/Gov.enum";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import { ColisTypeEnvoieOptions } from "../../utils/constants/Colis.enum";

const ColisForm = ({ onDataSubmit, loading, error, done, isEdit, colis, onSearchLocation }) => {
  // const [nomArt, setNomArt] = useState("");
  // const [nbrArt, setNbrArt] = useState("");
  const [montant_ht, setMontant_ht] = useState(0.0);
  const frai_timbre = 0.6;
  const frai_liv = 0.0;
  const frai_tva = 0.19;

  // const [lst_gov_option, set_lst_gov_option] = useState(lst_gov.map((el) => el.nom_gov));
  // const [lst_deleg_option, set_lst_deleg_option] = useState([]);
  const [lst_location, set_lst_location] = useState([]);

  const history = useHistory();

  const goMesColis = () => {
    history.push(routes.mesColis);
  };

  const searchLocation = (query) => {
    onSearchLocation(query)
      .then((res) => {
        console.log(res);
        set_lst_location(Array.isArray(res) ? res : []);
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = Yup.object().shape({
    nom_cl: Yup.string().required("Vous devez saisir le nom du client !"),
    marchandise: Yup.string().required("Vous devez saisir le marchandise du client !"),
    tel_cl: Yup.string()
      .typeError("numéro tél non valide !")
      .min(8, "numéro tél non valide !")
      .max(8, "numéro tél non valide !")
      .required("Vous devez saisir le tél du client !")
      .test("tél valid", "tél non valid", (value) => (value + "").match(/^\d{1,9}?$/)),
    montant_ttc: Yup.string()
      .typeError("Montant ttc non valide !")
      .required("Vous devez saisir le montant ttc !")
      .test("Montant ttc valid", "Montant ttc non valid", (value) => (value + "").match(/^\d+(\.\d{1,3})?$/)),
    // montant_remboursement: Yup.string()
    //   .typeError("Montant ttc non valide !")
    //   .required("Vous devez saisir le montant de remboursement !")
    //   .test("Montant ttc valid", "Montant ttc non valid", (value) => (value + "").match(/^\d+(\.\d{1,3})?$/)),
    order_ref: Yup.string(),
    note: Yup.string(),
    adresse: Yup.string().required("Vous devez saisir l'adresse du client !"),
    adresse_lieu: Yup.mixed().required("Vous devez saisir le lieu du client !"),
    adresse_gov: Yup.mixed().required("Vous devez choisir le gouvernorat !"),
    adresse_deleg: Yup.mixed().required("Vous devez choisir la délégation !"),
    adresse_zip: Yup.string().required("le code postal est obligatoire !"),
    type_envoi: Yup.string().required("le type d'envoi est obligatoire !"),
    nbr_paquets: Yup.number().required("le nbr paquets est obligatoire !"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue, control } = useForm(formOptions);
  const { errors } = formState;
  const sel_lieu = useWatch({ control: control, name: "adresse_lieu" });

  useEffect(() => {
    console.log(sel_lieu)
    if (Array.isArray(sel_lieu)) {
      if (sel_lieu.length > 0) {
        setValue("adresse_gov", sel_lieu[0]._gov);
        setValue("adresse_deleg", sel_lieu[0]._del);
        setValue("adresse_zip", sel_lieu[0]._zip);
      }
    }
  }, [sel_lieu]);

  const onSubmit = (data) => {
    if (Array.isArray(sel_lieu)) {
      if (sel_lieu.length > 0) {
        data.adresse_lieu = `${sel_lieu[0]._loc} | ${sel_lieu[0]._del} | ${sel_lieu[0]._gov} | ${sel_lieu[0]._zip}`;
      }
    }
    if (isEdit) {
      if (colis) {
        // let array_art = [
        //   {
        //     libelle: nomArt,
        //     nbr: nbrArt,
        //     prix_unit: 0.0,
        //     prix_ht: 0.0,
        //     prix_ttc: 0.0,
        //     ref_colis: colis.ref,
        //   },
        // ];

        let c = {
          ...colis,
          nom_cl: data.nom_cl,
          marchandise: data.marchandise,
          tel_cl: data.tel_cl,
          adresse: data.adresse,
          adresse_gov: data.adresse_gov,
          adresse_deleg: data.adresse_deleg,
          adresse_lieu: data.adresse_lieu,
          adresse_zip: data.adresse_zip,
          montant_ttc: data.montant_ttc,
          note: data.note,
          order_ref: data.order_ref,
          frai_liv: frai_liv,
          tva: frai_tva,
          droit_timbre: frai_timbre,
          montant_ht: montant_ht,
          montant_remboursement: data.montant_ttc,
          type_envoi: data.type_envoi,
          nbr_paquets: data.nbr_paquets,
        };
        onDataSubmit(c);
      }
    } else {
      let c = {
        ...data,
        frai_liv: frai_liv,
        tva: frai_tva,
        droit_timbre: frai_timbre,
        montant_ht: montant_ht,
        montant_remboursement: data.montant_ttc,
      };
      onDataSubmit(c);
    }
  };

  const onMontantChange = (data) => {
    let rgxd = /^\d+(\.\d{1,3})?$/;
    if (rgxd.test(data)) {
      if (+data > 0) {
        let mnt = 0.0;
        let price_no_timbre = +data - +frai_timbre;

        mnt = parseFloat(+price_no_timbre / (1 + +frai_tva)).toFixed(3);
        setMontant_ht(mnt);
      } else {
        setMontant_ht(0.0);
      }
    }
  };

  useEffect(() => {
    if (isEdit && colis) {

      if(colis?.adresse_lieu){
        let tmp = String(colis?.adresse_lieu).split(' | ')
        setValue("adresse_lieu",[
          {
            _loc: tmp.length > 0 ? tmp[0] : '',
            _zip: colis.adresse_zip,
            _del: colis.adresse_deleg,
            _gov: colis.adresse_gov,
          },
        ]);
      }
      setValue("nom_cl", colis.nom_cl);
      setValue("marchandise", colis.marchandise);
      setValue("tel_cl", colis.tel_cl);
      setValue("montant_ttc", colis.montant_ttc);
      setValue("type_envoi", colis.type_envoi);
      if (colis.order_ref) setValue("order_ref", colis.order_ref);
      if (colis.note) setValue("note", colis.note);
      setValue("adresse", colis.adresse);
      // setValue("adresse_gov", [colis.adresse_gov]);
      // setValue("adresse_deleg", [colis.adresse_deleg]);
      // setValue("adresse_zip", [colis.adresse_zip]);
      //setValue("adresse_lieu", [colis.adresse_lieu]);
      setValue("nbr_paquets", [colis.nbr_paquets]);

      

      // if (Array.isArray(colis.articles)) {
      //   if (colis.articles.length > 0) {
      //     setNomArt(colis.articles[0].libelle);
      //     setNbrArt(colis.articles[0].nbr);
      //   }
      // }

      onMontantChange(colis.montant_ttc);
    }
  }, [isEdit, colis]);

  return (
    <div className="row no-marg bg-wt  pb-5">
      <div className="col-md-12 ">
        <div className="row no-marg">
          <div className="col-md-2 no-padd">
            <button className="btn btn-primary btn_back_home" onClick={goMesColis}>
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </button>
          </div>
        </div>

        <div className="col-md-12  ps-4 pe-4 ">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}

          {/* {errors && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{JSON.stringify(errors)}</span>
            </div>
          )} */}

          {done && (
            <div className="alert alert-success" role="alert">
              <FontAwesomeIcon icon={["fas", "check-circle"]} /> <span className="ps-3">Création de votre colis avec succés </span>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
            <div className="row">
              <h2 className="pb-2 txt-red col-md-8">{isEdit && colis ? `Edit colis ${colis.code}` : "Créer un colis"}</h2>
              <div className="col-md-4 text-end">
                {loading ? (
                  <div class="col-md-12 p-4 txt-ctr">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button type="submit" className="btn btn-primary btn-login ">
                    Confirmer
                  </button>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group ">
                <label className="fnt-w5">Nom client</label>
                <input type="text" {...register("nom_cl")} className={`form-control ${errors.nom_cl ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.nom_cl?.message}</p>
              </div>

              <div className="form-group col-md-6">
                <label className="fnt-w5">Tél</label>
                <input placeholder="+216 ..." {...register("tel_cl")} className={`form-control ${errors.tel_cl ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.tel_cl?.message}</p>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label className="fnt-w5">Adresse</label>
                <input {...register("adresse")} className={`form-control py-2 ${errors.adresse ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.adresse?.message}</p>
              </div>

              <div className="form-group col-md-6">
                <label className="fnt-w5">Lieu</label>
                <Controller
                  control={control}
                  name="adresse_lieu"
                  render={({ field, fieldState }) => (
                    <AsyncTypeahead
                      {...field}
                      id="typeahead-loc"
                      isLoading={loading}
                      labelKey={(option) => `${option._loc} | ${option._del} | ${option._gov} | ${option._zip}`}
                      clearButton
                      className={fieldState.invalid ? "is-invalid" : ""}
                      aria-describedby="typeaheadError"
                      options={lst_location}
                      onSearch={searchLocation}
                      selected={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.adresse_lieu?.message}</p>
              </div>

              {/* <div className="form-group col-md-3">
                                    <label className="fnt-w5">Gouvernorat</label>
                                    <Controller
                                        control={control}
                                        name="adresse_gov"
                                        render={({ field, fieldState }) => (
                                            <Typeahead
                                                {...field}
                                                id="typeahead-gov"
                                                clearButton
                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                aria-describedby="typeaheadError"
                                                options={lst_gov_option}
                                                selected={field.value}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.adresse_gov?.message}</p>

                            </div>

                            <div className="form-group col-md-3">
                                    <label className="fnt-w5">Délégation</label>
                                    <Controller
                                        control={control}
                                        name="adresse_deleg"
                                        render={({ field, fieldState }) => (
                                            <Typeahead
                                                {...field}
                                                id="typeahead-deleg"
                                                clearButton
                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                aria-describedby="typeaheadError"
                                                options={lst_deleg_option}
                                                selected={field.value}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.adresse_deleg?.message}</p>

                            </div> */}
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <label className="fnt-w5">Marchandise</label>
                <input type="text" {...register("marchandise")} className={`form-control ${errors.marchandise ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.marchandise?.message}</p>
              </div>

              <div className="col-md-4 form-group">
                <label className="fnt-w5">Nombre de paquets</label>
                <input type="number" defaultValue={1} {...register("nbr_paquets")} className={`form-control ${errors.nbr_paquets ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.nbr_paquets?.message}</p>
              </div>

              <div className="form-group col-md-4">
                <label className="fnt-w5">
                  Réf du commande <span className="txt-gray">(optionel )</span>{" "}
                </label>
                <input type="text" {...register("order_ref")} className={`form-control ${errors.order_ref ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.order_ref?.message}</p>
              </div>

              <div className="form-group col-md-4">
                <label className="fnt-w5">
                  Note <span className="txt-gray">(optionel )</span>{" "}
                </label>
                <input type="text" {...register("note")} className={`form-control ${errors.note ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.note?.message}</p>
              </div>
            </div>

            <div className="row">
              <Form.Group as={Col} className="col-md-4">
                <label className="fnt-w5">Type d'envoi</label>
                <Form.Control as="select" size="sm" {...register("type_envoi")} className={`form-control force-apparence ${errors.type_envoi ? "is-invalid" : "form-control"}`}>
                  <option value="">Choisissez un type</option>
                  {Array.isArray(ColisTypeEnvoieOptions) && ColisTypeEnvoieOptions.map((el) => <option value={el?.value}>{el?.label}</option>)}
                </Form.Control>
                <p className="text-danger">{errors.type_envoi?.message}</p>
              </Form.Group>

              <div className="form-group col-md-4">
                <label className="fnt-w5">Prix TTC (En TND )</label>
                <input
                  {...register("montant_ttc")}
                  className={`form-control ${errors.montant_ttc ? "is-invalid form-control-sm" : "form-control form-control-sm"}`}
                  onChange={(e) => onMontantChange(e.target.value)}
                  defaultValue={0}
                />
                <p className="text-danger">{errors.montant_ttc?.message}</p>
              </div>
              {/* <div className="form-group col-md-4">
                <label className="fnt-w5">Prix HT (En TND )</label>
                <input type="text" value={montant_ht} name="montantht" className="form-control  form-control-sm" readOnly />
              </div> */}
              {/* <div className="form-group col-md-4">
                <label className="fnt-w5">Prix Remboursement (En TND )</label>
                <input {...register("montant_remboursement")} className={`form-control ${errors.montant_remboursement ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
                <p className="text-danger">{errors.montant_remboursement?.message}</p>
              </div> */}
            </div>

            {/* <div className="row">
              <div className="form-group col-md-6">
                <label className="fnt-w5">Article</label>
                <input type="text" value={nomArt} onChange={(e) => setNomArt(e.target.value)} className="form-control  form-control-sm" required />
              </div>
              <div className="form-group col-md-6">
                <label className="fnt-w5">Quantité</label>
                <input type="number" value={nbrArt} onChange={(e) => setNbrArt(e.target.value)} className="form-control  form-control-sm" required />
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ColisForm;
