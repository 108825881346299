import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

const page_size = 25;
const PaginationBox = ({ page, on_paginate , count, loading , label}) => {

    const [page_count , set_page_count] = useState(1);

    useEffect(()=> {
        let tmp = +Number(+count / +page_size).toFixed(3)
        if(+tmp < 1) set_page_count(1)
            else set_page_count(+Number(+tmp - parseInt(tmp)).toFixed(3) > 0 ? parseInt(tmp) + 1 : parseInt(tmp))
    },[count])
  return (
    <div className="col-md-12 text-end px-1">
        <Badge className="badge bg-light text-dark">{`${+page +1}/${+page_count} (${count} ${label})`}</Badge>
        <button onClick={e => on_paginate(+page - 1 )} className="btn btn-secondary" style={{borderRadius : 3, width : 30 , height : 30, padding : 2}} disabled={page == 0 || loading}><FontAwesomeIcon icon={['fas', 'angle-left']} /></button>
        <button onClick={e => on_paginate(+page + 1 )} className="btn btn-secondary ms-1" style={{borderRadius : 3, width : 30 , height : 30, padding : 2}} disabled={+page +1  == page_count || loading}><FontAwesomeIcon icon={['fas', 'angle-right']} /></button>
    </div>
  );
};

export default PaginationBox;
