import React, { useEffect, useState } from "react";
import { clear, getRsTask, getPickupTask } from "../../../slices/Liv.slice";
import { loadLoggedUser } from "../../../slices/Auth";
import { useDispatch, useSelector } from "react-redux";

import orderIcon from "../../../assets/img/order.svg";
import pickupIcon from "../../../assets/img/trolley.svg";
import { Link } from "react-router-dom";

const HomeLiv = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.liv);
  const { loggedUser } = useSelector((state) => state.auth);

  const [runsheet, set_runsheet] = useState(null);
  const [lst_mu, set_lst_mu] = useState(null);

  const [nbr_colis_liv, set_nbr_colis_liv] = useState(0);
  const [nbr_colis_pick, set_nbr_colis_pick] = useState(0);

  const load_runsheet = () => {
    dispatch(getRsTask())
      .unwrap()
      .then((res) => {
        set_runsheet(res?.rs);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const load_pickup = () => {
    dispatch(getPickupTask())
      .unwrap()
      .then((res) => {
        console.log(res);
        set_lst_mu(Array.isArray(res?.mv) ? res?.mv : []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(loadLoggedUser());
    load_runsheet();
    load_pickup();
  }, []);


  useEffect(() => {
    if(Array.isArray(runsheet?.colis)){
      set_nbr_colis_liv(runsheet?.colis?.length)
    }
  }, [runsheet]);

  useEffect(() => {
    if(Array.isArray(lst_mu)){
      let tmp = lst_mu.reduce((acc, curr) => Array.isArray(curr?.colis) ? +acc + curr?.colis?.length : acc , 0)
      set_nbr_colis_pick(tmp)
    }
  }, [lst_mu]);

  return (
    <div className="row m-0">
      <div className="col-12 text-start mt-2 p-2 px-4">
        <h6 className="fnt-sm">Bienvenu dans votre espace , {loggedUser?.nom} {loggedUser?.prenom}</h6>
      </div>
      <Link to={"/dashb/rs"} style={{textDecoration : 'none'}}>
        <div className="col-12 col-md-3 txt-ctr  p-2">
          <div className="col-md-12 bg-wt home-item">
            <div className="row m-0">
              <div className="col-4">
                <img src={orderIcon} width="42px" alt="" />
              </div>
              <div className="col-5 text-start brd-l-1">
                <h6 className="no-marg">Livraison</h6>
                <h5 className="no-marg">
                  {nbr_colis_liv} <span className="fnt-w4">Colis</span>
                </h5>
              </div>
              <div className="col-3 text-end">
                <span
                  className="mt-2"
                  style={{ clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", width: 24, height: 24, background: "#f4d474", display: "inline-block" }}
                ></span>
                <span
                  className="mt-2"
                  style={{ clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", width: 24, height: 24, background: "#263238", display: "inline-block" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <Link to={"/dashb/mu"}  style={{textDecoration : 'none'}}>
      <div className="col-12 col-md-3 txt-ctr  p-2">
        <div className="col-md-12 bg-wt home-item">
          <div className="row m-0">
            <div className="col-4">
              <img src={pickupIcon} width="42px" alt="" />
            </div>
            <div className="col-5 text-start brd-l-1">
              <h6 className="no-marg">Ramassage</h6>
              <h5 className="no-marg">
                {nbr_colis_pick} <span className="fnt-w4">Colis</span>
              </h5>
            </div>
            <div className="col-3 text-end">
              <span className="mt-2" style={{ clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", width: 24, height: 24, background: "#f4d474", display: "inline-block" }}></span>
              <span className="mt-2" style={{ clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", width: 24, height: 24, background: "#263238", display: "inline-block" }}></span>
            </div>
          </div>
        </div>
      </div>
      </Link>
    </div>
  );
};

export default HomeLiv;
