import React from 'react';
import Item from '../items/Runsheet.item';
import noDataIcon from '../../assets/img/no_data.png';

const RunsheetList = ({ loading, lst_data, onDetails, onDispatch, onPrint }) => {
    return (
        <div className="col-md-12 bg-wt mt-2">

            {loading ?
                <div class="col-md-12 p-4 txt-ctr">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                :
                (
                    Array.isArray(lst_data) &&
                    lst_data.map((s, index) => <Item key={`rs_.${index}`} data={s} onDetails={onDetails} onDispatch={onDispatch} onPrint={onPrint} />)
                )
            }

            {Array.isArray(lst_data) && !lst_data.length && !loading &&
                <div className="col-md-12 txt-ctr">
                    <br />
                    <br />
                    <br />
                    <img src={noDataIcon} alt="" width="10%" />
                    <br /><b className="txt-gray fnt-w5">NO DATA FOUND</b>
                </div>
            }

        </div>
    );
};

export default RunsheetList;