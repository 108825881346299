/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisList from '../../../ui/lists/ColisAdm.list';
import { clear, del, listColisAdm, selectColis, checkColis , doFilter , editColisInfo , loadExpedsList ,   } from '../../../slices/ColisAdm';
import { manifestAfexColis  } from '../../../slices/Afex.slice';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import ColisAdmDetailsContainer from '../package/ColisAdmDetails.container';
import ColisAdmAfexHeader from '../../../ui/headers/ColisAdmAfex.header';
import ColisAdmAfexList from '../../../ui/lists/ColisAdmAfex.list';
import PaginationBox from '../../../ui/pagination/PaginationBox';




const ColisAfexListContainer = () => {

    const dispatch = useDispatch();
    const {current_hub } = useSelector((state) => state.hub);
    const { loading, error, colis_list, selected_colis , expeds_list, count } = useSelector(state => state.colis_adm);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

     //pagination
     const [last_filter , set_last_filter] = useState(null);
     const [page , set_page] = useState(0);


    const onItemDetailsClick = data => {
        dispatch(selectColis(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }
    const onItemSelected = (data, etat) => {
        dispatch(checkColis({ data, etat }));

    }

    const onPrint = data => {
        if (data) {
            UserStorage.setParam([data])
            const win = window.open("/dashb/colis/print/", "_blank");
            win.focus();
        }
    }

    const onPrintMany = () => {
        if (colis_list) {
            let c = colis_list.filter(elem => elem.checked == true);
            if (c.length) {
                UserStorage.setParam(c)
                const win = window.open("/dashb/colis/print/", "_blank");
                win.focus();
            }
        }
    }

    const onManifestMany = () => {
        if (colis_list) {
            let codes = colis_list.filter(elem => elem.checked == true).map(el => el?.code);
            dispatch(manifestAfexColis({ codes }));
        }
    }


    const load_data = (filter)=>{
        dispatch(listColisAdm({ filter : {...filter, is_afex: true}, page : 0 }))
        set_last_filter({...filter, is_afex: true})
        set_page(0)
    }

    const on_paginate = (value) => {
        set_page(value);
        dispatch(listColisAdm({ filter : last_filter , page : value}));
    }

    useEffect(() => {
        dispatch(loadExpedsList());
        load_data()
        return () => {
            dispatch(clear());
        }
    }, [])

    useEffect(() => {
        load_data()
    }, [current_hub ])

    useEffect(() => {
        if(Array.isArray(colis_list) && selected_colis)
            dispatch(selectColis(colis_list.find(el => el?.ref == selected_colis?.ref)))
    }, [colis_list ])

    return (
        <>
            <ColisAdmAfexHeader lst_data={colis_list} onPrintMany={onPrintMany}  error={error}  lst_exped={expeds_list} load_data={load_data} loading={loading} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'colis'} />
            <ColisAdmAfexList loading={loading} error={error} lst_data={colis_list} onItemDetailsClick={onItemDetailsClick} onItemSelected={onItemSelected} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'colis'} />
            {/* <ColisAdmDetails data={selected_colis} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} onEditField={onEditField} loading={loading} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} /> */}
            <ColisAdmDetailsContainer show={showDetails} onClose={hideDetails} load_data={load_data} sel_colis={selected_colis} />
        </>
    )
}

export default ColisAfexListContainer;



