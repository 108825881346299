import React, { useEffect, useState } from "react";
import { clear, getPickupTask, selfAfectPick, pickMuColis } from "../../../slices/Liv.slice";
import { loadLoggedUser } from "../../../slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import { ColisStatus, ColisStatusBg, colisStatusLabels } from "../../../utils/constants/Colis.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Card, Form, Table } from "react-bootstrap";
import { getStringDate } from "../../../utils/helpers/date.helper";
import BarcodeScannerComponent from "@steima/react-qr-barcode-scanner";

const MovableLivContainer = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.liv);
  const { loggedUser } = useSelector((state) => state.auth);
  const [lst_mu, set_lst_mu] = useState(null);
  const [nbr_colis_pick, set_nbr_colis_pick] = useState(0);
  const [show_details, set_show_details] = useState(false);
  const [show_scanner, set_show_scanner] = useState(false);
  const [sel_item, set_sel_item] = useState(false);

  const load_pickup = () => {
    dispatch(getPickupTask())
      .unwrap()
      .then((res) => {
        console.log(res);
        if (Array.isArray(res?.mv)) {
          set_lst_mu(res?.mv);
          if (sel_item) set_sel_item(res?.mv?.find((el) => el?.id == sel_item?.id));
        }
      })
      .catch((err) => console.log(err));
  };

  const onSelfAfectPickup = (id) => {
    dispatch(selfAfectPick(id))
      .unwrap()
      .then((res) => {
        load_pickup();
      })
      .catch((err) => console.log(err));
  };

  const onPickMuColis = (data) => {
    return dispatch(pickMuColis(data)).unwrap();
  };

  const onViewMu = (mu) => {
    set_sel_item(mu);
    set_show_details(true);
  };

  const onScan = () => {
    set_show_scanner(true);
  };

  const onCloseDetails = () => {
    set_sel_item(null);
    set_show_details(false);
  };

  const onCloseScanner = () => {
    set_show_scanner(false);
  };

  useEffect(() => {
    dispatch(loadLoggedUser());
    load_pickup();
  }, []);

  useEffect(() => {
    if (Array.isArray(lst_mu)) {
      let tmp = lst_mu.reduce((acc, curr) => (Array.isArray(curr?.colis) ? +acc + curr?.colis?.length : acc), 0);
      set_nbr_colis_pick(tmp);
    }
  }, [lst_mu]);

  return (
    <div className="row m-0">
      <div className="col-12 text-center">
        <Card className="mt-2">
          <Card.Body>
            <div className="row m-0">
              <div className="col-6">
                <h6 className="fnt-w5">Ramassage</h6>
              </div>

              <div className="col-6 brd-l-1">
                <h6>
                  {nbr_colis_pick}{" "}
                  <b className="text-success fnt-sm fnt-w5" style={{ marginTop: -8 }}>
                    colis
                  </b>
                </h6>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="col-12">{Array.isArray(lst_mu) && lst_mu?.map((el) => <MuItem mu={el} onViewDetails={onViewMu} key={el?.id} />)}</div>

      <MuDetails mu={sel_item} show={show_details} onClose={onCloseDetails} onSelfAfectPickup={onSelfAfectPickup} loading={loading} onScan={onScan} />
      <MuColisCollect mu={sel_item} show={show_scanner} onClose={onCloseScanner} onPickMuColis={onPickMuColis} loading={loading} load_data={load_pickup} />
    </div>
  );
};

const MuItem = ({ mu, onViewDetails }) => {
  return (
    <Card className="mt-1 p-0" style={{ cursor: "pointer" }} onClick={(e) => onViewDetails(mu)}>
      <Card.Body className="p-1">
        <div className="row m-0">
          <div className="col-auto text-center pt-3">
            <span className={`d-inline-block p-1 bg-warning`} style={{ color: "white", width: 32, height: 32, borderRadius: "100%" }}>
              <FontAwesomeIcon icon={["fas", "truck"]} />
            </span>
          </div>

          <div className="col-10 brd-l-1 text-start pe-1">
            <h6 className="fnt-w6 m-0">
              {mu?.ref}
              <span
                className="float-end"
                style={{ clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", width: 24, height: 24, background: "#263238", display: "inline-block" }}
              ></span>
            </h6>
            <h6 className="fnt-w5 fnt-sm text-muted" style={{ marginTop: -2 }}>{`${mu?.mvExped?.nom_com}`}</h6>
            <h6>
              <FontAwesomeIcon icon={["fas", "car"]} className="text-success" /> <b className="fnt-w5">{`${mu?.mvLiv?.nom || "---"} ${mu?.mvLiv?.prenom || "---"}`}</b>
            </h6>
            <h6 className="fnt-w5 fnt-sm m-0 dvdr-t-gray pt-1">
              <FontAwesomeIcon icon={["fas", "map-marker-alt"]} /> {`${mu?.mvExped?.adresse}`}
            </h6>
            <h6 className="fnt-w5 fnt-sm text-success" style={{ marginTop: 0 }}>
              <FontAwesomeIcon icon={["fas", "mobile"]} /> {`${mu?.mvExped?.tel}`} <span className="float-end">{Array.isArray(mu?.colis) ? mu?.colis?.length : "---"} Colis</span>
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const MuDetails = ({ mu, show, onClose, onSelfAfectPickup, loading, onScan }) => {
  return (
    <div className={show ? "col-12 bg-gray inf_box_pop_r" : "col-12 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row p-0 m-0 bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="col-12  p-2 bg-wt ">
        <div className="row m-0">
          <div className="col-2 text-center pt-1">
            <span className={`d-inline-block p-1 bg-success`} style={{ color: "white", width: 32, height: 32, borderRadius: "100%" }}>
              <FontAwesomeIcon icon={["fas", "truck"]} />
            </span>
          </div>
          <div className="col-10 brd-l-1 text-start pe-1">
            <h6 className="fnt-w6 m-0">{mu?.ref}</h6>
            <h6 className="fnt-w5 fnt-sm text-muted" style={{ marginTop: -2 }}>{`${mu?.mvExped?.nom_com}`}</h6>
            {mu?.id_liv ? (
              <span className="float-end my-1 px-3">
                <FontAwesomeIcon icon={["fas", "car"]} className="text-success" /> <b>{`${mu?.mvLiv?.nom} ${mu?.mvLiv?.prenom}`}</b>
              </span>
            ) : (
              <span className="float-end my-1">
                <button className="btn btn-sm btn-success fnt-sm px-4" onClick={(e) => onSelfAfectPickup(mu?.id)} disabled={loading}>
                  Prendre en charge
                </button>
              </span>
            )}
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12  text-start pe-1">
            <h6 className="fnt-w5 fnt-sm m-0 dvdr-t-gray pt-2">
              <FontAwesomeIcon icon={["fas", "map-marker-alt"]} /> {`${mu?.mvExped?.adresse}`}
            </h6>
            <span className="float-end mt-1">
              <button className="btn btn-sm btn-warning fnt-sm fnt-w5 px-4 ms-1" onClick={onScan}>
                Scanner
              </button>
            </span>
            <span className="float-end mt-1">
              <a className="btn btn-sm btn-success fnt-sm px-4" href={`tel:${mu?.mvExped?.tel_cl}`}>
                Appeler
              </a>
            </span>

            <h6 className="fnt-w5  text-success dvdr-t-gray py-2" style={{ marginTop: 0 }}>
              <FontAwesomeIcon icon={["fas", "mobile"]} /> {`${mu?.mvExped?.tel}`}{" "}
            </h6>
            <h6 className="fnt-w5  text-center dvdr-t-gray py-2" style={{ marginTop: 0 }}>
              {" "}
              {`${Array.isArray(mu?.colis) ? mu?.colis?.length : "---"} Colis`}{" "}
            </h6>
          </div>
        </div>
        <div className="row m-0">
          {Array.isArray(mu?.colis) &&
            mu?.colis?.map((el) => (
              <Card key={el?.ref} className="p-0 mt-1">
                <Card.Body>
                  <div className="row m-0">
                    <div className="col-12">
                      <h6 className="m-0 text-success">
                        <span>
                          <FontAwesomeIcon icon={["fas", "barcode"]} />{" "}
                        </span>{" "}
                        {el?.code}
                      </h6>
                      <h6 className="m-0 text-muted fnt-sm">
                        {el?.nom_cl} {el?.prenom_cl}
                      </h6>
                      {el?.etat == ColisStatus["ENLEV-SUCC"] ? <span class="badge badge-pill bg-success badge-sm txt-wt float-end">Ramassé</span> : <span class="badge badge-pill bg-warning txt_blk badge-sm float-end">Non-Ramassé </span>}
                     <br/>
                      <h6 className="fnt-w5 fnt-sm m-0 dvdr-t-gray pt-1">
                        <FontAwesomeIcon icon={["fas", "map-marker-alt"]} /> {`${el?.adresse_lieu}`}
                      </h6>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

const MuColisCollect = ({ mu, show, onClose, onPickMuColis, loading, load_data }) => {
  const [lst_colis, set_lst_colis] = useState([]);
  const [nbr_selected, set_nbr_selected] = useState(0);

  const onItemSelected = (ref, value) => {
    if (Array.isArray(lst_colis) && ref) set_lst_colis(lst_colis.map((el) => (el?.ref == ref ? { ...el, selected: value } : el)));
  };

  const onItemScan = (value) => {
    console.log(value?.text);
    if (Array.isArray(lst_colis) && value?.text) {
      let tmp = [...lst_colis];
      tmp.forEach((el) => {
        if (el?.code == value?.text) el.selected = true;
      });
      console.log(tmp);
      set_lst_colis(tmp);
    }
  };

  useEffect(() => {
    if (Array.isArray(mu?.colis)) {
      console.log("mu changed");
      set_lst_colis(mu?.colis?.map((el) => ({ ...el, selected: false })));
    }
  }, [mu?.colis]);

  useEffect(() => {
    console.log("lst changed");
    if (Array.isArray(lst_colis)) {
      set_nbr_selected(lst_colis.filter((el) => el?.selected == true)?.length);
    }
  }, [lst_colis]);

  const onSubmit = () => {
    if (mu && Array.isArray(lst_colis)) {
      if (lst_colis.length > 0) {
        let data = {
          id_mu: mu?.id,
          colis: lst_colis.filter((el) => el?.selected == true).map((el) => el.ref),
        };
        onPickMuColis(data)
          .then((res) => {
            load_data();
            onClose();
          })
          .catch((err) => console.log(err));
      }
    }
  };

  return (
    <div className={show ? "col-12 bg-gray inf_box_pop_r" : "col-12 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row p-0 m-0 bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="col-12  p-2 bg-wt ">
        <div className="row m-0">
          <div className="col-12 text-center">
            {show && (
              <BarcodeScannerComponent
                width={"100%"}
                height={"auto"}
                onUpdate={(err, result) => {
                  if (result) onItemScan(result);
                }}
              />
            )}
          </div>
          <div className="col-12 text-center">
            <Alert className="fnt-w5" variant={nbr_selected == lst_colis?.length ? "success" : "warning"}>{`${nbr_selected}/${lst_colis?.length}`}</Alert>
            <button className="btn btn-success px-4 mb-2" onClick={onSubmit} disabled={loading}>
              Confirmer la récéption
            </button>
          </div>
        </div>
        <div className="row m-0">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th></th>
                <th>Code</th>
                <th>Destinataire</th>
                <th>Adresse</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(lst_colis) &&
                lst_colis.map((el) => (
                  <tr style={{ background: `${el?.selected ? "#37ba7740" : "#dd475540"}` }} key={el?.code}>
                    <td>
                      <Form.Group id="formGridCheckbox" className="ms-2">
                        <Form.Check type="checkbox" className="chek_size_lg" id="chk" checked={el.selected} onChange={(e) => onItemSelected(el?.ref, e.target.checked)} />
                      </Form.Group>
                    </td>
                    <td className="fnt-w5">
                      <span>
                        <FontAwesomeIcon icon={["fas", "barcode"]} />{" "}
                      </span>
                      <span className="fnt-w5 txt_blk">{el?.code}</span>
                    </td>

                    <td>{el?.nom_cl}</td>

                    <td className="text-capitalize">{`${el?.adresse_lieu}`}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MovableLivContainer;
