import React  from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {stat as Mv_state } from '../../utils/constants/Movable.enum';
import { getStringDate } from '../../utils/helpers/date.helper';

const MuDetails = ({ onHide , isShow , data , onDelete , onEdit , onPrint }) => {
    



    return (
        <div className={isShow ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" onClick={onHide}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>


            {data &&
                <div className="row no-marg bg-wt p-4">
                    

                    <div className="col-md-12">

                        <br />

                        <button className="btn btn-outline-success ms-4" onClick={onPrint}><FontAwesomeIcon icon={['fas', 'print']} /> Imprimer fiche</button>

                        {data.etat === Mv_state.PENDING &&
                            <button className="btn btn-outline-primary ms-4" onClick={onEdit}><FontAwesomeIcon icon={['fas', 'edit']} /> Modifier Unité mobile</button>
                        }
                        {data.etat === Mv_state.PENDING &&
                            <button className="btn btn-outline-danger ms-4" onClick={() => onDelete(data)}><FontAwesomeIcon icon={['fas', 'trash-alt']} /> Annuler Unité mobile</button>
                        }
                        <br /> <br />
                    </div>

                    <div className="col-md-12">
                        <h4 className="txt-blue">Liste des colis :</h4>

                        <table className="table table-striped table-bordered fnt-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Colis</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Montant TTC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.colis.map((item) =>
                                    <tr>
                                        <td className="p-1">{item.code}</td>
                                        <td className="p-1">{item.nom_cl} {item.prenom_cl}</td>
                                        <td className="p-1">{item.montant_ttc}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>

                    </div>

                </div>
            }



        </div>
    )
}

export default MuDetails;