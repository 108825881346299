import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/helpers/routing.helper";
import { stat as Mv_state } from "../../utils/constants/Movable.enum";
import { Link } from "react-router-dom";

function MuAdmHeader({ loading, mu, error, onSubmit, onCheckAll , code_scan }) {

  const [nbr_sel, set_nbr_sel] = useState(0);

  useEffect(() => {
    if (Array.isArray(mu?.colis)) {
      set_nbr_sel(mu?.colis?.filter((elem) => elem.checked == true)?.length);
    }
  }, [mu]);

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-8 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape ps-1 d-block pt-0 fnt-sm" style={{ clipPath: "none" }}>
            <Link to="/dashb/mu/pickup/list" className="d-block">
              <button className="btn btn-sm py-0 text-success fnt-w5">
                <FontAwesomeIcon icon={["fas", "long-arrow-alt-left"]} /> Liste des Mu
              </button>
            </Link>
            <span className="text-dark">Réceptionner MU :</span> {mu?.ref}
          </b>
        </div>
      </div>

      <div className="row no-marg rw-header p-1">

        <div className="col-md-3">
              <b className="fnt-w5">Code Scanné : </b> <b className="txt-blue d-block">{code_scan}</b>
        </div>

        <div className="col-md-6 p-1 green_box">
              <div className="row">
                <div className="col-md-12 dvdr-r-gray dvdr-l-gray text-center">
                  <b className="fnt-w5 fnt-sm">Colis Reçus</b> <br />
                  <b className="item_box_money">
                    {nbr_sel} / {Array.isArray(mu?.colis) ? mu?.colis?.length : "--"}
                  </b>
                </div>
              </div>
            </div>

        <div className="col-md-3 no-padd text-center ">
          {loading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <button className="btn btn-outline-success mt-2" onClick={onSubmit}>
              Confirmer{" "}
            </button>
          )}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MuAdmHeader;
