import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { stat as colisStat } from "../../utils/constants/Colis.enum";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/helpers/routing.helper";
import { stat as Mv_state } from "../../utils/constants/Movable.enum";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

function FactureAdmHeader({ load_data, loading, lst_data, error, lst_exped }) {


  const [filter, set_filter] = useState({ search: "" });
  const [lst_exped_options, set_lst_exped_options] = useState([]);
  const [sel_exped, set_sel_exped] = useState([]);

  const onSearch = (e) => {
    let filter_data = {}

    if(filter?.id_exped)
    filter_data.id_exped = filter?.id_exped

    if(filter?.search)
    filter_data.search = filter?.search

    load_data(filter_data);
  };

  const onClearFilter = (e) => {
    set_filter({ search: "" });
    set_sel_exped([])
    load_data();
  };

  const onSearchExpeds = (search) => {
    if (Array.isArray(lst_exped)) {
      let tmp = lst_exped.filter((el) => String(el?.nom_com).toUpperCase().includes(String(search).toUpperCase()));
      set_lst_exped_options(tmp);
    }
  };

  const onSelectExpeds = (value) => {
    console.log(value)
    set_sel_exped(value)
    if (Array.isArray(value)) {
      if (value.length == 1) set_filter({...filter , id_exped : value[0]?.id});
    }
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "dollar-sign"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Facture(s) </b>
        </div>
      </div>

      <div className="row no-marg rw-header ">
        <Form.Group className="col-md-4 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
          <Form.Control
            type="search"
            className="form-control inpt_search shadow-none"
            style={{ borderRadius: ".2rem" }}
            placeholder="Recherche par (Réf) ..."
            onChange={(e) => set_filter({ ...filter, search: e.target.value })}
            value={filter?.search}
          />
        </Form.Group>

        <Form.Group className="col-md-3 px-2">
            <Form.Label className="fnt-sm fnt-w5 m-0">Expéditeur</Form.Label>
            <AsyncTypeahead
              id="expedPickersearch"
              size="sm"
              labelKey={(op) => op?.nom_com}
              isLoading={loading}
              onChange={onSelectExpeds}
              options={lst_exped_options}
              onSearch={onSearchExpeds}
              selected={sel_exped}
              placeholder="Recherche expediteur..."
            />
          </Form.Group>

        <Form.Group className="col-md-4 text-end px-2 pt-4">
          <button className="btn btn_search m-0" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>
          <button className="btn btn_search bg-success m-0 ms-1" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "sync-alt"]} />
          </button>
          <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </Form.Group>

        <div className="col-md-6  fnt-largr">
          <div className="row no-marg ">
            <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
              <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">Facture(s)</b>
            </div>
          </div>
        </div>

        <div className="col-md-6 no-padd mb-2 ">
          {loading && (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FactureAdmHeader;
