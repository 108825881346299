import axios from "axios";
import { api_url } from './http_constant';
import storage from './UserStorage';

class UserService {

    //-----------------------------------------------------------------------Public
    registerExped(user) {
        return axios.post(`${api_url}/api/users/register/exped`, user);
    }

    login(email, password) {
        return axios.post(`${api_url}/api/users/login`, { email: email, password: password });
    }


    //-----------------------------------------------------------------------Common
    checkUser() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/users/checkuser`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    profile() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/users/profile`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    logout() {
        storage.logout();
    }



    resendVmail() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/sendvmail`, {}, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    forgetPass(data) {
        return axios.post(`${api_url}/api/users/forgetpass`, data);
    }
    resetPass(data , token ) {
        return axios.post(`${api_url}/api/users/changepass`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    

    
    //-----------------------------------------------------------------------Common
    createAdm(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/register-adm`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    createLiv(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/register-liv`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    listAdmExped() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/users/adm/exped/list`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    listAdmDriver() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/users/adm/liv/list`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    listAdmDriverDispo() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/users/adm/liv/list/dispo`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    listAdmUsers(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/list`, data,{
            headers: {
                'cgo-tkn': token
            }
        });
    }

    enableAdmUser(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/enb-Acnt`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    disableAdmUser(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/disb-Acnt`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    deleteAdmUser(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/delete`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    updateAdmUser(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/adm/update`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    
}

export default new UserService();