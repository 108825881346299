import React , {useState , useEffect} from 'react';
import { useHistory } from "react-router-dom";
import fastIcon from '../../../assets/img/fast.svg';
import receiveIcon from '../../../assets/img/stopwatch_blue.svg';
import todoIcon from '../../../assets/img/product.svg';
import toreturnIcon from '../../../assets/img/return.svg';
import orderIcon from '../../../assets/img/order.svg';
import pickupIcon from '../../../assets/img/trolley.svg';
import driverIcon from '../../../assets/img/driver.svg';
import clientIcon from '../../../assets/img/man.svg';
import HubBox from './HubBox.container';
import UserStorage from '../../../services/UserStorage';
import axios from 'axios';
import { api_url } from '../../../services/http_constant';
import { useSelector } from 'react-redux';

const Home = ({ match }) => {

    const {current_hub } = useSelector((state) => state.hub);
    const [to_pay, set_to_pay] = useState(0);
    const [to_transfer, set_to_transfer] = useState(0);
    const [in_prog, set_in_prog] = useState(0);
    const [to_return, set_to_return] = useState(0);
    const [to_receive, set_to_receive] = useState(0);
    const [rs_prog, set_rs_prog] = useState(0);
    const [rs_pd, set_rs_pd] = useState(0);
    const [rs_compl, set_rs_compl] = useState(0);
    const [nbr_exped, set_nbr_exped] = useState(0);
    const [nbr_liv, set_nbr_liv] = useState(0);
    
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState(UserStorage.getData());
    const [token, setToken] = useState(UserStorage.getToken());
    const history = useHistory();

    const loadData = () => {
        setIsLoading(true);

        axios.post(`${api_url}/api/users/adm/resume`, { id_hub: current_hub ? current_hub : 'all' }, {
            headers: {
                'cgo-tkn': token
            }
        })
            .then(res => {
                setIsLoading(false);
                console.log(res.data);
                if (res.data) {
                    set_to_pay(res.data.to_pay);
                    set_to_transfer(res.data.to_transfer);
                    set_in_prog(res.data.in_prog);
                    set_to_return(res.data.to_return);
                    set_to_receive(res.data.to_receive);
                    set_rs_prog(res.data.rs_prog);
                    set_rs_pd(res.data.rs_pd);
                    set_rs_compl(res.data.rs_compl);
                    set_nbr_exped(res.data.nbr_exped);
                    set_nbr_liv(res.data.nbr_liv);




                }
            })
            .catch(error => {
                setIsLoading(false);
                // setLoading(false);
                if (error.response) {
                    console.log(error.response.data.message);
                    if (error.response.data.message === "invalid-token") {
                        UserStorage.logout();
                        history.push("/login");
                    }
                }
            }
            );
    }



    useEffect(() => {
        loadData();
    }, [current_hub]);

    return (

        <>

            <div className="row no-marg  pb-3 pt-3">

                <div className="col-8 txt-ctr ">
                    {/* <p className="no-marg sm-gray-txt">This month</p> */}
                    <h4 className="item_box_money no-marg">{parseFloat(to_pay).toFixed(3)} <span>TND</span> </h4>
                    <p className="no-marg fnt-w5">Dettes</p>
                </div>
                <div className="col-md-4 no-padd  ">
                    <HubBox isReview={true}  />

                </div>
            </div>

            <div className="row  no-marg">

                <div className="col-12 no-padd">
                    <br />
                    <h5 className="mini_title col-3  fnt-w4 ">Colis</h5>
                </div>


                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={orderIcon} width="42px" alt="" />
                        <h3 className="no-marg">{parseFloat(in_prog).toFixed(0)} </h3>
                        <span className="fnt-w4">En cours de livraison</span>
                    </div>

                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={todoIcon} width="42px" alt="" />
                        <h3 className="no-marg">{parseFloat(to_transfer).toFixed(0)} </h3>
                        <span className="fnt-w4">A livrer</span>
                    </div>
                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={pickupIcon} width="42px" alt="" />
                        <h3 className="no-marg">{parseFloat(to_receive).toFixed(0)}</h3>
                        <span className="fnt-w4">A Enlever</span>
                    </div>
                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={toreturnIcon} width="42px" alt="" />
                        <h3 className="no-marg">{parseFloat(to_return).toFixed(0)}</h3>
                        <span className="fnt-w4">A Retourner</span>

                    </div>
                </div>


            </div>



            <div className="row  no-marg">

                <div className="col-12 no-padd">
                    <br />
                    <h5 className="mini_title col-3  fnt-w4 ">Runsheets</h5>
                </div>


                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={fastIcon} width="42px" alt="" />
                        <h3 className="no-marg">{parseFloat(rs_prog).toFixed(0)}</h3>
                        <span className="fnt-w4">En Cours</span>
                    </div>

                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={receiveIcon} width="42px" alt="" />
                        <h3 className="no-marg">{parseFloat(rs_pd).toFixed(0)}</h3>
                        <span className="fnt-w4">A Dispatcher</span>
                    </div>
                </div>




            </div>


            <div className="row  no-marg">

                <div className="col-12 no-padd">
                    <br />
                    <h5 className="mini_title col-3  fnt-w4 ">Utilisateur</h5>
                </div>


                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={clientIcon} width="42px" alt="" />
                        <h3 className="no-marg">{nbr_exped}</h3>
                        <span className="fnt-w4">Client(s)</span>
                    </div>

                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={driverIcon} width="42px" alt="" />
                        <h3 className="no-marg">{nbr_exped}</h3>
                        <span className="fnt-w4">Livreur(s)</span>
                    </div>
                </div>




            </div>



        </>
    )

}

export default Home;