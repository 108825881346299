import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Route, NavLink, useHistory } from "react-router-dom";
import HomeLiv from "./HomeLiv";
import logo from "../../../assets/img/logo.png";
import RunsheetLivContainer from "../runsheet/RunsheetLiv.container";
import MovableLivContainer from "../movable/MovableLiv.container";
import UserStorage from "../../../services/UserStorage";

const DashboardLivContainer = ({ match, ...props }) => {
  return (
    <>
      <DashboardNav />
      <div className={"rw-cont rw-cont-mobile bg-gay "}>
        <Route
          path={`${match.path}`}
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={HomeLiv} exact />
              <Route path={`${url}/rs`} component={RunsheetLivContainer} exact />
              <Route path={`${url}/mu`} component={MovableLivContainer} exact />
            </>
          )}
        />
      </div>
      <DashboardMenu match={match} />
    </>
  );
};

const DashboardNav = () => {

  const history = useHistory()
  const onLogOut = ()=>{
    UserStorage.logout()
    history.push('/')
  }
  return (
    <nav className={"hd-navbar fixed-top hd-navbar-mobile"}>
      <a className="navbar-brand ms-1" href="#">
        <img src={logo} alt="" height={40}  />
      </a>
      <div className="navbar-custom-menu  pt-1" style={{ float: "right", marginRight: "4px", display: "inline-block" }}>
        <ul className="nav navbar-nav" style={{ flexDirection: "row" }}>
          <li className="nav-item ">
            <Dropdown drop="down">
              <Dropdown.Toggle variant="default  fnt-sm  no-arrow user-nav-box " id="dropdown-basic">
                <b className="fnt-w5 fnt-sm">
                  <FontAwesomeIcon icon={["fas", "user"]} />
                  <span className="fnt-sm ps-2">| chokri mechichi</span>
                  <span className="fnt-sm ps-2">
                    <FontAwesomeIcon icon={["fas", "sort-down"]} />{" "}
                  </span>
                </b>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb text-center">
                <button className="btn btn-sm btn-danger" onClick={onLogOut}>Déconnecter</button>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const DashboardMenu = ({ match }) => {
  return (
    <div className="col-12 position-fixed  p-0 m-0" style={{ height: 50, bottom: 0 }}>
      <ul className="sidebar-menu row m-0 p-0 m-0" style={{ height: "100%", background: "#263238", boxShadow: "3px -5px 20px rgb(0 0 0 / 28%)", overflowY: "hidden" }}>
        <li className="col-4">
          <NavLink to={`${match?.path}/`} className="p-1" activeClassName="activel" exact>
            <span className="d-block text-center">
              <FontAwesomeIcon icon={["fas", "home"]} />
            </span>
            <span className="d-block text-center fnt-sm">Accueil</span>
          </NavLink>
        </li>

        <li className="col-4">
          <NavLink to="/dashb/rs" activeClassName="activel" className="p-1">
            <span className="d-block text-center">
              <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
            </span>
            <span className="d-block text-center fnt-sm">Livraison</span>
          </NavLink>
        </li>

        <li className="col-4">
          <NavLink to="/dashb/mu" activeClassName="activel" className="p-1">
            <span className="d-block text-center">
              <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
            </span>
            <span className="d-block text-center fnt-sm">Ramassage</span>
          </NavLink>
        </li>

      
      </ul>
    </div>
  );
};
export default DashboardLivContainer;
