import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserStorage from '../../../services/UserStorage';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import { api_url } from '../../../services/http_constant';

const ProfileClientEdit = (props) => {


    const [profileData, setProfileData] = useState();
    const [registerErr, setRegisterErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(UserStorage.getToken());
    const [user, setUser] = useState(UserStorage.getData());

    const validationSchema = Yup.object().shape({
        nom_com: Yup.string()
            .required('Vous devez saisir le nom commercial !'),
        nom: Yup.string()
            .required('Vous devez saisir votre nom !'),
        prenom: Yup.string()
            .required('Vous devez saisir votre prénom !'),
        tel: Yup.string()
            .typeError('numéro tél non valide !')
            .min(8, 'numéro tél non valide !')
            .max(8, 'numéro tél non valide !')
            .required('Vous devez saisir votre tél !')
            .test(
                'tél valid',
                'tél non valid',
                value => (value + "").match(/^\d{1,9}?$/),
            ),
        produit_type: Yup.string()
            .required('Vous devez saisir le type de produit !'),
        matricule_fiscal: Yup.string()
            .required('Vous devez saisir votre matricule fiscal !'),
        flux_exped: Yup.number()
            .typeError('flux d\'éxpedition non valide !')
            .required('Vous devez saisir votre moyenne d\'epédition par jour  !'),
        adresse: Yup.string()
            .required('Vous devez saisir votre adresse !'),
        email: Yup.string()
            .required('Vous devez saisir votre E-mail !')
            .email('Email non valid !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {

        if (data && profileData) {
            let usr = {
                id: profileData.id,
                adresse: data.adresse,
                email: data.email,
                flux_exped: data.flux_exped,
                nom: data.nom,
                nom_com: data.nom_com,
                prenom: data.prenom,
                produit_type: data.produit_type,
                tel: data.tel,
                matricule_fiscal: data.matricule_fiscal,
            }
            console.log(usr);
            setLoading(true);
            setRegisterErr("");

            axios.post(`${api_url}/api/users/editprofile`, usr, {
                headers: {
                    'cgo-tkn': token
                }
            })
                .then(res => {
                    setLoading(false);
                    if (res.data) {
                        const msg = res.data;
                        console.log(msg.nom);
                        if (res.data.done) {
                            //UserStorage.setData({ ...user, nom: res.data.nom, prenom: res.data.prenom, nom_com: res.data.nom_com });
                            localStorage.setItem('cgo', JSON.stringify({ ...user, nom: res.data.user.nom, prenom: res.data.user.prenom, nom_com: res.data.user.nom_com , matricule_fiscal: res.data.user.matricule_fiscal }));
                            localStorage.setItem('cgo-tkn', res.data.token);
                            props.loadData();
                            props.setShowEdit(false)
                        } else {
                            setRegisterErr("Echec de modification")
                        }

                    } else {
                        setRegisterErr("Echec de modification")
                    }

                })
                .catch(error => {
                    setLoading(false);
                    if (error.response) {
                        console.log(error.response.data.message);
                        if (error.response.status === 400) {
                            setRegisterErr(error.response.data.message);
                        }
                    }
                }
                );
        }


    }

    useEffect(() => {
        console.log("edit profile");
        console.log(props.profile)
        setProfileData(props.profile)
        if(props.profile){
            setValue("nom_com" , props.profile.nom_com);
            setValue("nom" , props.profile.nom);
            setValue("prenom" , props.profile.prenom);
            setValue("tel" , props.profile.tel);
            setValue("adresse" , props.profile.adresse);
            setValue("email" , props.profile.email);
            setValue("produit_type" , props.profile.produit_type);
            setValue("flux_exped" , props.profile.flux_exped);
            setValue("matricule_fiscal" , props.profile.matricule_fiscal);

        }
    }, [props.profile])




    return (
        <div className={props.showEdit ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" onClick={() => props.setShowEdit(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>

            {props.profile &&
                <div className="row no-marg bg-wt p-4">
                    {loading ?
                        <div className="row loading-row">
                            <div className="col-md-12 txt-ctr">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        :
                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                            {registerErr && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {registerErr}</p>}

                            <Form.Row className='row m-0'>
                                <Form.Group as={Col} controlId="formGridState" className="col-md-6">
                                    <Form.Label className="no-marg small-label">Nom </Form.Label>
                                    <Form.Control size="sm" name="nomclt" onChange={(e) => setProfileData({ ...profileData, nom: e.target.value })} {...register("nom")} className={`form-control ${errors.nom ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.nom?.message}</p>
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="col-md-6">
                                    <Form.Label className="no-marg small-label">Prenom </Form.Label>
                                    <Form.Control size="sm" name="prenomclt"  onChange={(e) => setProfileData({ ...profileData, prenom: e.target.value })}  {...register("prenom")} className={`form-control ${errors.prenom ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.prenom?.message}</p>
                                    </Form.Text>
                                </Form.Group>
                            </Form.Row>


                            <Form.Row className='row m-0'>
                                <Form.Group as={Col} controlId="formGridState" className="col-md-4">
                                    <Form.Label className="no-marg small-label">Nom commercial</Form.Label>
                                    <Form.Control size="sm" name="nomcom" disabled  onChange={(e) => setProfileData({ ...profileData, nom_com: e.target.value })}  {...register("nom_com")} className={`form-control ${errors.nom_com ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.nom_com?.message}</p>
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="col-md-4">
                                    <Form.Label className="no-marg small-label">Matricule fiscal</Form.Label>
                                    <Form.Control size="sm" name="matricule_fiscal"  onChange={(e) => setProfileData({ ...profileData, matricule_fiscal: e.target.value })}  {...register("matricule_fiscal")} className={`form-control ${errors.matricule_fiscal ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.matricule_fiscal?.message}</p>
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="col-md-4">
                                    <Form.Label className="no-marg small-label">Type de produit</Form.Label>
                                    <Form.Control size="sm" name="produit"  onChange={(e) => setProfileData({ ...profileData, produit_type: e.target.value })}  {...register("produit_type")} className={`form-control ${errors.produit_type ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.produit_type?.message}</p>
                                    </Form.Text>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className='row m-0'>
                                <Form.Group as={Col} controlId="formGridState" className="col-md-6">
                                    <Form.Label className="no-marg small-label">Flux d'expédition</Form.Label>
                                    <Form.Control type="number" size="sm" name="fluxexpd"  onChange={(e) => setProfileData({ ...profileData, flux_exped: e.target.value })}  {...register("flux_exped")} className={`form-control ${errors.flux_exped ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.flux_exped?.message}</p>
                                    </Form.Text>
                                </Form.Group>


                            </Form.Row>

                            <Form.Row className='row m-0'>
                                <Form.Group as={Col} controlId="formGridState" className="col-md-6">
                                    <Form.Label className="no-marg small-label">Tél</Form.Label>
                                    <Form.Control size="sm" name="tel"  onChange={(e) => setProfileData({ ...profileData, tel: e.target.value })}  {...register("tel")} className={`form-control ${errors.tel ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.tel?.message}</p>
                                    </Form.Text>
                                </Form.Group>


                            </Form.Row>
                            <Form.Row className='row m-0'>
                                <Form.Group as={Col} controlId="formGridState" className="col-md-12">
                                    <Form.Label className="no-marg small-label">Adresse</Form.Label>
                                    <Form.Control size="sm" name="adresse"  onChange={(e) => setProfileData({ ...profileData, adresse: e.target.value })}  {...register("adresse")} className={`form-control ${errors.adresse ? 'is-invalid' : 'form-control'}`}/>
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.adresse?.message}</p>
                                    </Form.Text>
                                </Form.Group>


                            </Form.Row>

                            <Form.Row className='row m-0'>


                                <Form.Group as={Col} controlId="formGridState" className="col-md-6">
                                    <Form.Label className="no-marg small-label">E-mail </Form.Label>
                                    <Form.Control size="sm" name="email"  onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}   {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : 'form-control'}`} />
                                    <Form.Text className="text-muted txt-red">
                                    <p className="text-danger">{errors.email?.message}</p>
                                    </Form.Text>
                                </Form.Group>
                            </Form.Row>

                            <div className="row p-4">
                                <div className="col-md-12 txt-ctr">
                                    <button className="btn btn-primary btn_register" type="submit">Modifier</button>
                                </div>
                            </div>



                        </form>
                    }


                </div>
            }



        </div>
    )
}

export default ProfileClientEdit;