import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/helpers/routing.helper";
import HubBox from "../../domains/admin/home/HubBox.container";
import { Typeahead } from "react-bootstrap-typeahead";
import { RsStatusOptions } from "../../utils/constants/Runsheet.enum";

function RunsheetHeader({ loading, lst_data, error, driver_list, load_data }) {
  const [filter, set_filter] = useState({ search: "", etat: [], id_liv: [] });
  const history = useHistory();

  const onSearch = (e) => {
    let filter_data = {};

    if (filter?.search) filter_data.search = filter?.search;

    if (Array.isArray(filter?.id_liv) && filter?.id_liv?.length > 0) filter_data.id_liv = filter?.id_liv?.map((el) => el?.id);

    if (Array.isArray(filter?.etat) && filter?.etat?.length > 0) filter_data.etat = filter?.etat?.map((el) => el?.value);

    console.log(filter_data);

    load_data(filter_data);
  };

  const onClearFilter = (e) => {
    set_filter({ search: "", etat: [], id_liv: [] });
    load_data();
  };

  const gotoCreate = () => {
    history.push(routes.rs_adm_create);
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-8 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Tournées </b>
        </div>

        <div className="col-md-4">
          <HubBox />
        </div>
      </div>

      <div className="row no-marg rw-header ">
        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
          <Form.Control
            type="search"
            className="form-control inpt_search shadow-none"
            style={{ borderRadius: ".2rem" }}
            placeholder="Recherche par (Réf) ..."
            onChange={(e) => set_filter({ ...filter, search: e.target.value })}
            value={filter?.search}
          />
        </Form.Group>

        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Livreur</Form.Label>
          <Typeahead
            id="livPickersearch"
            size="sm"
            multiple
            labelKey={(op) => `${op?.nom} ${op?.prenom}`}
            onChange={(v) => set_filter({ ...filter, id_liv: v })}
            options={driver_list}
            selected={filter?.id_liv}
            placeholder="Filtre Livreur..."
          />
        </Form.Group>

        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Statut</Form.Label>
          <Typeahead
            id="statusPickersearch"
            size="sm"
            multiple
            labelKey={(op) => op?.label}
            onChange={(v) => set_filter({ ...filter, etat: v })}
            options={RsStatusOptions}
            selected={filter?.etat}
            placeholder="Filtre Status..."
          />
        </Form.Group>

        <Form.Group className="col-md-3 text-center px-2 pt-4">
          <button className="btn btn_search m-0" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>
          <button className="btn btn_search bg-success m-0 ms-1" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "sync-alt"]} />
          </button>
          <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </Form.Group>

        <div className="col-md-12 py-1 text-end">
          {loading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={gotoCreate}>
                + Créer une tournée
              </button>
            </>
          )}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RunsheetHeader;
