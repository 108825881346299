/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisHistoryClient from '../../../ui/layouts/ColisHistoryClient';
import { clear, getClientColisHistory } from '../../../slices/Colis';
import { useDispatch, useSelector } from "react-redux";


const HistoryColisClient = (props) => {

    const dispatch = useDispatch();
    const { loading, error, colis_list } = useSelector(state => state.colis);


    useEffect(() => {
        dispatch(getClientColisHistory())
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <ColisHistoryClient lst_data={colis_list}  error={error}  loading={loading}  />
        </>
    )
}

export default HistoryColisClient;



