import React from "react";
import Item from "../items/ColisAdm.item";
import noDataIcon from "../../assets/img/no_data.png";
import { Table } from "react-bootstrap";
import { ColisStatus, ColisStatusBg, colisStatusLabels, ColisAfexStatusBg, ColisAfexStatusLabels, ColisTypeEnvoie, ColisTypeEnvoieLabels } from "../../utils/constants/Colis.enum";
import { getStringDate } from "../../utils/helpers/date.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ColisAdmList = ({ loading, lst_data, onItemDetailsClick, onItemSelected }) => {
  const checkColisToTransfert = (colis) => {
    if (colis?.etat == ColisStatus["ATT-LIV"] && !colis?.id_hub_dest) return false;
    if (colis?.etat == ColisStatus["ATT-LIV"] && colis?.id_hub_current != colis?.id_hub_dest) return true;
    else if (colis?.id_hub_current != colis?.id_hub_exped && (colis?.etat == ColisStatus.ANL || colis?.etat == ColisStatus.RECUPERER || colis?.etat == ColisStatus.ENDOM)) return true;
  };

  return (
    <div className="col-md-12 bg-wt mt-2">
      {loading ? (
        <div class="col-md-12 p-4 txt-ctr">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        Array.isArray(lst_data) && (
          //lst_data.map((s, index) => <Item key={`colis_.${index}`} data={s} onItemDetailsClick={onItemDetailsClick} onSelect={onItemSelected} />)
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th>Code</th>
                <th>Expéditeur</th>
                <th>Destinataire</th>
                <th>Type d'envoi</th>
                <th>Statut</th>
                <th>Tentative</th>
                <th>Adresse</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(lst_data) &&
                lst_data.map((el) => (
                  <tr>
                    <td className="fnt-w5 text-success">{el?.code}</td>
                    <td>{el?.user?.nom_com}</td>
                    <td>{`${el?.nom_cl}`}</td>
                    <td className="fnt-w5">{`${ColisTypeEnvoieLabels[el?.type_envoi]}`}</td>
                    {el?.is_afex ? (
                      <td>
                        <span class={`badge badge-pill  ${ColisAfexStatusBg[el?.etat_afex]}`}>{ColisAfexStatusLabels[el?.etat_afex]} </span>{" "}
                        {/* {checkColisToTransfert(el) && <span class={`badge badge-pill bg-warning text-dark`}>A-Transférer</span>}{" "} */}
                      </td>
                    ) : (
                      <td>
                        {String(el?.etat).includes("LIV-") && el?.type_envoi == ColisTypeEnvoie.recuperation ? (
                          <span class={`badge badge-pill  ${ColisStatusBg[el?.etat]}`}>{String(colisStatusLabels[el?.etat]).replace("Livraison", "Récupération")} </span>
                        ) : (
                          <span class={`badge badge-pill  ${ColisStatusBg[el?.etat]}`}>{colisStatusLabels[el?.etat]} </span>
                        )}
                        {checkColisToTransfert(el) && <span class={`badge badge-pill bg-warning text-dark ms-1`}>A-Transférer</span>}{" "}
                      </td>
                    )}
                    <td>
                      <span class={el?.attempt < 3 ? "badge badge-pill bg-dark txt_wt" : "badge badge-pill bg-danger txt_wt"}>{el?.attempt}/3</span>
                    </td>
                    <td className="text-success fnt-w5">{`${el?.adresse_lieu}`}</td>
                    <td>{getStringDate(el?.createdAt)}</td>
                    <td>
                      <button className="btn btn-sm btn-dark me-1" onClick={(e) => onItemDetailsClick(el)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} /> Fiche
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )
      )}

      {!lst_data.length && !loading && (
        <div className="col-md-12 txt-ctr">
          <br />
          <br />
          <br />
          <img src={noDataIcon} alt="" width="10%" />
          <br />
          <b className="txt-gray fnt-w5">NO DATA FOUND</b>
        </div>
      )}
    </div>
  );
};

export default ColisAdmList;
