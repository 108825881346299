import React , {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../../assets/img/logo.png';
import { Route, NavLink } from 'react-router-dom';
import HomeContent from '../../../ui/statics/Home';
import {routes} from '../../../utils/helpers/routing.helper';

/* eslint-disable */


const Home = ({ match }) => {


    const padd_menu = {
        padding: 0,
        paddingLeft: "10%",
        paddingRight: "10%"
    }

    const [isOpen, setIsopen] = useState(true);

    return (
        <>
            <nav id="header_nav" className="navbar navbar-expand-md navbar-light " style={ isOpen ? padd_menu : {padding : "0"}  }>
                <a className="navbar-brand" href="#"><img className="logo_home" src={logo} alt="" width="120px" style={{transform : 'scale(1.5)'}} /></a>
                <button onClick={() => setIsopen(!isOpen)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={isOpen ? "collapse navbar-collapse" : "collapse navbar-collapse show "  }id="navbarCollapsemenu">
                    <ul className="navbar-nav ml-auto nav_ctr">
                        <li className="nav-item ">
                            <NavLink to={`${match.path}`} exact activeClassName="active" className="nav-link" >Accueil</NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#box_propos">A propos</a>
                        </li>
                        <li className="nav-item">
                            <a href="#box_service"   className="nav-link" >Service</a>
                        </li>
                        <li className="nav-item">
                            <NavLink to={routes.login} activeClassName="active" className="nav-link " >Connecter<span className="link_btm_arrow" ><FontAwesomeIcon icon={['fas', 'angle-up']} /></span></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={routes.register} activeClassName="active" className="nav-link " >S'inscrire<span className="link_btm_arrow" ><FontAwesomeIcon icon={['fas', 'angle-up']} /></span></NavLink>
                        </li>
                    </ul>

                </div>
            </nav>

            <div className='col-md-2 box_call'>
                <h4><FontAwesomeIcon icon={['fas', 'phone-alt']} /> +216 98 360 460</h4>
            </div>

            
            <Route
                    path={`${match.path}`}
                    render={({ match: { url } }) => (
                        <>

                            <Route path={`${url}/`} component={props => <HomeContent {...props} />} />
                            

                        </>
                    )}
                />



            <footer id="footer" className="footer">
                <div className="container">
                    <div className="main_footer">
                        <div className="row pt-5" >
                            <div className="col-sm-3 col-xs-12">
                                <img src={logo} className="footer_logo" width="50%" />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <b className="footer-title">Service</b> <br />
                                <a href="/login" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Espace Client</a>
                                {/* <a href="/liv/app" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> App livreur</a> */}
                                <a href="#box_service" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Service</a>
                                <a href="#" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Comment ça marche </a>
                                
 
                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <b className="footer-title">Contact</b><br />

                                {/* <b className="d-block pb-3 fnt-w4 txt-wt"> <span className="contact_icon"><FontAwesomeIcon icon={['fas', 'envelope']} /></span> mail@gmail.com</b>  */}

                                <b className="d-block pb-3 fnt-w5"> <span className="contact_icon"><FontAwesomeIcon icon={['fas', 'phone-alt']} /></span> +216 98 360 460 | +216 71 870 528</b>

                                <b className="d-block pb-3 fnt-w5"> <span className="contact_icon"><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> 09 rue Beyya el Ayari , El manar 1</b>
                       


                                
                            </div>

                            <div className=" col-md-1 footer_socail txt-ctr mt-3" >
                                    <br/>
                                    <a className="footer_social mb-3" href="https://www.facebook.com/delivo.tn/" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                                    
                                    <a className="footer_social" href="#" target="_blank"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                                   
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-xs-12 txt-ctr  mt-5">
                                <div className="copyright_text">
                                    <p className=" wow fadeInRight fnt-smlr" data-wow-duration="1s" >© Copyright 2024 <b>Delivo</b>.Powered by <b>Polvo-Mind</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    )
}

export default Home;