import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { stat as colisStat, ColisStatus, ColisStatusOptions } from "../../utils/constants/Colis.enum";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/helpers/routing.helper";

function ColisClientHeader({ onFilter, loading, lst_data, onTransfer, onPrintMany, error, error_mu }) {
  const [filter_values, set_filter_values] = useState({});
  const [nbr_selected, set_nbr_selected] = useState(0);
  const [allow_transfert, set_allow_transfert] = useState(false);
  const history = useHistory();

  const searchOnchange = (e) => {
    set_filter_values({ ...filter_values, search: e.target.value });
  };

  const onSearchClick = (e) => {
    if (filter_values) {
      let filter_data = {};
      if (filter_values?.search) filter_data.search = filter_values?.search;

      if (filter_values?.etat && filter_values?.etat != "all") filter_data.etat = filter_values?.etat;

      onFilter(filter_data);
    }
  };

  const onFilterChange = (e) => {
    set_filter_values({ ...filter_values, etat: e.target.value });
  };

  const onClearFilter = (e) => {
    set_filter_values({ etat: "all", search: "" });
    onFilter({});
  };

  const gotoCreate = () => {
    history.push(routes.colis_create);
  };

  const gotoMultiple = () => {
    history.push(routes.colis_create_multiple);
  };
  const askTransfer = () => {
    onTransfer();
  };

  const printColis = () => {
    onPrintMany();
  };

  useEffect(() => {
    let tmp = [];
    if (Array.isArray(lst_data)) tmp = lst_data.filter((el) => el.checked == true);
    set_nbr_selected(tmp.length);

    set_allow_transfert(tmp.filter(el => el?.etat != ColisStatus["ATT-ENVOIE"]).length == 0)
  }, [lst_data]);

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "box-open"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Colis </b>
        </div>
      </div>

      <div className="row no-marg rw-header p-2">
        {/* <div className="col-md-6 offset-md-3 flex_ctr p-2">
                    <Form.Group as={Col} controlId="formGridState" className="no-marg">
                        <Form.Control type="search" className="form-control inpt_search shadow-none" placeholder=" Recherche ..." value={filter_values?.search} onChange={searchOnchange} />
                        <button className="btn btn_search" onClick={onSearchClick}><FontAwesomeIcon icon={['fas', 'search']} /></button>
                    </Form.Group>
                </div> */}

        <Form.Group className="col-md-4 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
          <Form.Control
            type="search"
            className="form-control inpt_search shadow-none"
            style={{ borderRadius: ".2rem" }}
            placeholder="Code/Client ..."
            value={filter_values?.search}
            onChange={searchOnchange}
          />
        </Form.Group>

        <Form.Group className="col-md-4 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Status</Form.Label>
          <Form.Control as="select" defaultValue="Choose..." size="sm" value={filter_values?.etat} onChange={onFilterChange} className="force-apparence">
            <option value="all">Tous</option>
            {Array.isArray(ColisStatusOptions) && ColisStatusOptions.map(el => <option value={el?.value}>{el?.label}</option> )}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-md-3 px-2 pt-4">
          <button className="btn btn_search m-0" onClick={onSearchClick}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>

          <button className="btn btn_search bg-success m-0 ms-1" onClick={onSearchClick}>
            <FontAwesomeIcon icon={["fas", "sync-alt"]} />
          </button>

          <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </Form.Group>


        <div className="col-md-12 no-padd mb-2 ">
          {loading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={gotoCreate}>
                + Créer un bordereau
              </button>
              <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={gotoMultiple}>
                + Créer plusieurs bordereaux
              </button>

              <button className="btn btn-outline-success btn-sm ms-2 mt-1 float-end px-3" onClick={askTransfer} disabled={!allow_transfert}>
                Envoyer ({nbr_selected})
              </button>

              <button className="btn btn-outline-success btn-sm ms-2 mt-1" onClick={printColis}>
                Imprimer ({nbr_selected})
              </button>
            </>
          )}

          {/* <span className="txt-red pl-2">{transfer_err}</span> */}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}

          {error_mu && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error_mu}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ColisClientHeader;
