import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TicketStatusBg, TicketType, TicketTypeLabel } from "../../../utils/constants/ticket.enum";
import { getStringDate } from "../../../utils/helpers/date.helper";
import { ColisStatus, colisStatusLabels } from "../../../utils/constants/Colis.enum";

const TicketDeatilsContainer = ({ ticket, show, onClose }) => {
  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row m-0 p-2 ">
        <div className="col-md-12 text-center p-2 bg-wt">
          <b className="item_box_ref">
            <span className="spn_inf_tit dsp_blk fnt-sm">Ticket Num</span>
            <span>
              <FontAwesomeIcon icon={["fas", "ticket-alt"]} />{" "}
            </span>
            <span className="fnt-w5 txt_blk">{ticket?.ref}</span>
          </b>
        </div>

        <div className="col-md-12 bg-wt mt-3 p-2">
          <div className="row m-0">
            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Statut</b>
              <span className={`badge badge-sm text-capitalize ${TicketStatusBg[ticket?.status]}`}>{ticket?.status}</span>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Date création</b>
              <b className="d-block fnt-w5 fnt-sm">{getStringDate(ticket?.createdAt)}</b>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Colis</b>
              <b className="d-block fnt-w5 fnt-sm">{ticket?.coli?.code}</b>
            </div>

            <div className="col-md-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Type Ticket</b>
              <b className="d-block fnt-w5 fnt-sm">{TicketTypeLabel[ticket?.type]}</b>
            </div>

            <div className="col-md-12 dvdr-t-gray mt-3 pt-3">
              <b className="d-block fnt-w5 fnt-sm">Les nouvelle données :</b>
            </div>

            {ticket?.type == TicketType["INFOS-ADRS"] && (
              <>
                <div className="col-md-12">
                  <b className="d-block fnt-w5 fnt-sm text-muted">Adresse</b>
                  <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.adresse}</b>
                </div>

                <div className="col-md-4">
                  <b className="d-block fnt-w5 fnt-sm text-muted">Lieu</b>
                  <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.adresse_lieu}</b>
                </div>
              </>
            )}

            {ticket?.type == TicketType["INFOS-MNT"] && (
              <div className="col-md-4">
                <b className="d-block fnt-w5 fnt-sm text-muted">Montant</b>
                <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.montant_ttc} TND</b>
              </div>
            )}

            {ticket?.type == TicketType["INFOS-TEL"] && (
              <div className="col-md-4">
                <b className="d-block fnt-w5 fnt-sm text-muted">Tél client</b>
                <b className="d-block fnt-w5 fnt-sm text-capitalize">{ticket?.data?.tel_cl}</b>
              </div>
            )}
            {ticket?.type == TicketType.STATUS && (
              <div className="col-md-4">
                <b className="d-block fnt-w5 fnt-sm text-muted">Statut</b>
                <b className="d-block fnt-w5 fnt-sm text-capitalize">{colisStatusLabels[ticket?.data?.etat]}</b>
              </div>
            )}

            <div className="col-md-12 pt-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Note Expéditeur :</b>
              {ticket?.note_exped && (
                <div className="fnt-sm fnt-w5" style={{ border: "#000 1px dashed", padding: 3 }} dangerouslySetInnerHTML={{ __html: String(ticket?.note_exped).replace(/\n/g, "<br />") }} />
              )}
            </div>

            <div className="col-md-12 pt-3">
              <b className="d-block fnt-w5 fnt-sm text-muted">Note Administration :</b>
              {ticket?.note_adm && (
                <div className="fnt-sm fnt-w5" style={{ border: "#000 1px dashed", padding: 3 }} dangerouslySetInnerHTML={{ __html: String(ticket?.note_adm).replace(/\n/g, "<br />") }} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDeatilsContainer;
