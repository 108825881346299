/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../assets/img/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Route, NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
// import AdmApiCall from '../../../services/api/AdmApiCall';
// import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { ColisStatus } from '../../../utils/constants/Colis.enum';
import { stat as muStat } from '../../../utils/constants/Movable.enum';
import { stat as rsStat } from '../../../utils/constants/Runsheet.enum';


import HomeAdm from './HomeAdm';
import ColisAdmList from '../package/ColisAdmList.container';
import MuAdmList from '../movable/MuAdmList.container';
import MovableDepot from '../movable/MuDepot.container';
import PrintColis from '../../exped/Packages/PrintColis.container';
import SearchColis from '../package/SearchColis.container';
import RunsheetForm from '../runsheet/RunsheetForm.container';
import Runsheet from '../runsheet/Runsheet.container';
import RunsheetPrint from '../runsheet/RunsheetPrint.container';
import RunsheetConfirm from '../runsheet/RunsheetConfirm.container';
import InvoiceToPay from '../facture/InvoiceToPay.container';
import BillCreator from '../facture/BillCreator.container';
import Bill from '../facture/Bill.container';
import BillPrint from '../facture/BillPrint.container';
import PrintMu from '../../exped/transfer/PrintMu.container';
import Users from '../accounts/Users.container';
import MuTransfertForm from '../transfertHub/MuTransfertForm.container';
import MuTransfertList from '../transfertHub/MuTransfertList.container.';
import ClientHistory from '../history/ClientHistory.container';
import DriverHistory from '../history/DriverHistory.container';


import { logout } from '../../../slices/Auth';
import { useDispatch } from "react-redux";
import ChequeListContainer from '../cheque/ChequeList.container';
import HubListContainer from '../Hub/HubList.container';
import MuTransfertDepot from '../transfertHub/MuTransfertDepot.container';
import TicketAdmListContainer from '../ticket/TicketAdmList.container';
import AfexColisListContainer from '../afex/AfexColisList.container';
import AfexTransfertFormContainer from '../afex/AfexTransfertForm.container';
import logo_afex from '../../../assets/img/logo_afex.png'

const DashboardAdm = ({ match }) => {

    const [isOpen, setIsopen] = useState(true);
    const user = UserStorage.getData()
    // const token = Storage.getToken();
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(0);


    const doLogout = () => {
        dispatch(logout());
        history.push("/login");
    }


    return (

        <>

            <nav className={isOpen ? "hd-navbar fixed-top" : "hd-navbar fixed-top hd-navbar-mini"}>
                <button className="sidebar-toggle" data-toggle="collapse" data-target=".main-sidebar" role="button"
                    onClick={() => setIsopen(!isOpen)}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </button>
                <div className="navbar-custom-menu pr-4" style={{ float: "right", marginRight: "24px", display: "inline-block" }}>
                    <ul className="nav navbar-nav" style={{ flexDirection: "row" }}>


                        <li className="nav-item ">

                            <Dropdown drop="down"  >
                                <Dropdown.Toggle variant="default  fnt-largr  no-arrow user-nav-box " id="dropdown-basic" >
                                    <b className="fnt-w5">
                                        <FontAwesomeIcon icon={['fas', 'user']} />
                                        <span className="fnt-sm ps-2"> | {user?.nom} {user?.prenom} ({user?.role})</span>
                                        <span className="fnt-sm ps-2"><FontAwesomeIcon icon={['fas', 'sort-down']} /> </span>
                                    </b>

                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">




                                </Dropdown.Menu>
                            </Dropdown>


                        </li>



                        {/* <li className="nav-item ">

                            <Dropdown drop="down"  >
                                <Dropdown.Toggle variant="default  fnt-largr  no-arrow btn_round_notif p-0" id="dropdown-basic" >
                                    <FontAwesomeIcon icon={['fas', 'bell']} />
                                    {nbr_notif_unread > 0 &&
                                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fnt-sm mt-2">
                                            {nbr_notif_unread}
                                        </span>
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">




                                </Dropdown.Menu>
                            </Dropdown>


                        </li> */}


                    </ul>
                </div>


            </nav>

            <aside className={isOpen ? "main-sidebar" : "main-sidebar mini-sidebar"}>
                <section className="sidebar">

                    <div className="col-md-12 py-2" style={{background : '#f4d474'}}>
                        <a href={`${match.path}/`} className={isOpen ? "sidebar_logo" : "sidebar_logo sidebar_logo_mini"}>
                            <img src={logo} alt="" />
                        </a>
                    </div>



                    <ul className="sidebar-menu">

                        <li>
                            <NavLink to={`${match.path}/`} activeClassName="activel" exact ><FontAwesomeIcon icon={['fas', 'home']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Accueil
                                </span>

                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`${match.path}/hub`} activeClassName="activel" exact ><FontAwesomeIcon icon={['fab', 'hubspot']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Hub
                                </span>

                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/colis/search" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'search']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Recherche
                                </span></NavLink>
                        </li>


                        {user && user.role == 'root' &&

                        <li>
                            <NavLink to="/dashb/account" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'user-friends']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Utilisateur
                                </span> </NavLink>
                        </li>
                        }


                        <li>
                            <NavLink to="/dashb/mu/pickup/list" activeClassName="activel">
                                <FontAwesomeIcon icon={['fas', 'dolly']} />
                                <span className={isOpen ? "" : "hidebal"}> Ramassage</span>

                            </NavLink>
                        </li>
                        
                        <li>
                            <NavLink to="/dashb/colis/list" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'box-open']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Colis
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/mu/transfert/list" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'exchange-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Transfert Hub
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/rs/pending" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Livraison
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/ticket/list" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'ticket-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Ticket
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/afex" activeClassName="activel"><img src={logo_afex} alt='' width={26}/>
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;Afex
                                </span></NavLink>
                        </li>


                        {/* <Accordion defaultActiveKey="">
                            <Card className="card-menu">
                                <Card.Header className="card-menu-header">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="card-menu-btn">
                                        <FontAwesomeIcon icon={['fas', 'eye']} />
                                        <span className={isOpen ? "" : "hidebal"}>
                                            &nbsp;&nbsp;Reconcile
                                        </span>
                                        <span className={isOpen ? "" : "hidebal"}>
                                            <FontAwesomeIcon className="menu_icn_arrow" icon={['fas', 'angle-down']} />
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="card-menu-container">
                                    <Card.Body className="no-padd">
                                        <ul>
                                            <li>
                                                <NavLink to="/dashb/rs/reconcile" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'truck']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Runsheet</span>

                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashb/mu/reconcile" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'truck']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Movable units</span>

                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion> */}


                        {user && user.role == 'root' &&
                        <Accordion defaultActiveKey="">
                            <Card className="card-menu">
                                <Card.Header className="card-menu-header">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="card-menu-btn">
                                        <FontAwesomeIcon icon={['fas', 'dollar-sign']} />
                                        <span className={isOpen ? "" : "hidebal"}>
                                            &nbsp;&nbsp;Paiement
                                        </span>
                                        <span className={isOpen ? "" : "hidebal"}>
                                            <FontAwesomeIcon className="menu_icn_arrow" icon={['fas', 'angle-down']} />
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="card-menu-container">
                                    <Card.Body className="no-padd">
                                        <ul>
                                            <li>
                                                <NavLink to="/dashb/invoice" activeClassName="activel" >
                                                    <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Dettes</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashb/bill" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'file-invoice']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Facture</span>

                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashb/cheque" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'money-check-alt']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Chéques</span>

                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>

                        }
                        <Accordion defaultActiveKey="">
                            <Card className="card-menu">
                                <Card.Header className="card-menu-header">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="card-menu-btn">
                                        <FontAwesomeIcon icon={['fas', 'folder-open']} />
                                        <span className={isOpen ? "" : "hidebal"}>
                                            &nbsp;&nbsp;History
                                        </span>
                                        <span className={isOpen ? "" : "hidebal"}>
                                            <FontAwesomeIcon className="menu_icn_arrow" icon={['fas', 'angle-down']} />
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="card-menu-container">
                                    <Card.Body className="no-padd">
                                        <ul>
                                            <li>
                                                <NavLink to="/dashb/history/client" activeClassName="activel" >
                                                    <FontAwesomeIcon icon={['fas', 'box-open']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Vendeur</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashb/history/driver" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Runsheets</span>

                                                </NavLink>
                                            </li>


                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>



                    </ul>

                    <ul>
                        <li className="cont_log_out">
                            <button className="btn_log_out" onClick={doLogout}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Déconnecter
                                </span></button>
                        </li>
                    </ul>

                </section>
            </aside>


            <div className={isOpen ? "rw-cont bg-gay" : "rw-cont rw-cont-mini bg-gay"}>


                <Route
                    path={`${match.path}`}
                    render={({ match: { url } }) => (
                        <>
                            {/* home --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/`} component={HomeAdm} exact />

                            {/* Hub --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/hub`} component={HubListContainer} exact />

                            {/* users --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/account`} component={Users} exact />

                            {/* colis --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/colis/list`} component={ColisAdmList} exact  />
                            <Route path={`${url}/colis/print`} component={PrintColis} exact />
                            <Route path={`${url}/colis/search`} component={SearchColis} exact />

                            {/* movable --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/mu/pickup/list`} component={MuAdmList} exact  />
                            <Route path={`${url}/mu/depot/:code`} component={MovableDepot} exact />
                            <Route path={`${url}/mu/print`} component={PrintMu} exact />

                            {/* runsheet --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/rs/create`} exact render={(props) => <RunsheetForm {...props} isEdit={false} />} />
                            <Route path={`${url}/rs/edit/:code`} exact render={(props) => <RunsheetForm {...props} isEdit={true} />} />
                            <Route path={`${url}/rs/pending`} exact render={(props) => <Runsheet {...props} etat={[rsStat.PENDING]} />} />
                            <Route path={`${url}/rs/reconcile`} exact render={(props) => <Runsheet {...props} etat={[rsStat.IN_PROGRESS]} />} />
                            <Route path={`${url}/rs/print`} component={RunsheetPrint} exact />
                            <Route path={`${url}/rs/confirm/:code`} component={RunsheetConfirm} exact />

                            {/* Invoices & bills --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/colis/done`} exact render={(props) => <Colis {...props} etat={[ColisStatus.LIVRE , ColisStatus.ANL , ColisStatus.ENDOM , ColisStatus.RECUPERER ]} />} />
                            <Route path={`${url}/invoice`} component={InvoiceToPay} exact />
                            <Route path={`${url}/invoice/client/:client`} component={BillCreator} exact />
                            <Route path={`${url}/bill`} component={Bill} exact />
                            <Route path={`${url}/bill/print/:code`} component={BillPrint} exact />
                            <Route path={`${url}/cheque`} component={ChequeListContainer} exact />

                            {/* Transfer zone --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/mu/transfert/create`} component={MuTransfertForm} exact />
                            <Route path={`${url}/mu/transfert/list`} component={MuTransfertList} exact />
                            <Route path={`${url}/mu/transfert/pick/:id`} component={MuTransfertDepot} exact />
                           
                            {/* History --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/history/client`} component={ClientHistory} exact />
                            <Route path={`${url}/history/driver`} component={DriverHistory} exact />

                            {/* Ticket --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/ticket/list`} component={TicketAdmListContainer} exact />

                            {/* Afex --------------------------------------------------------------------------------------*/}
                            <Route path={`${url}/afex`} component={AfexColisListContainer} exact />
                            <Route path={`${url}/afex/create`} component={AfexTransfertFormContainer} exact />




                        </>
                    )}
                />

            </div>

        </>
    )

}

export default DashboardAdm;