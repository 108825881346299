/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MuList from '../../../ui/lists/MuAdm.list';
import MuAdmHeader from '../../../ui/headers/MuAdm.header';
import MuDetails from '../../../ui/layouts/MuAdmDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import DriverPickModal from '../../../ui/modals/DriverPickModal';
import { clear, listMu, refuseMu, dispatchPickMu , doSelect , excludeMuColis , loadExpedsList , loadDrivers , doFilter } from '../../../slices/MuAdm';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import PaginationBox from '../../../ui/pagination/PaginationBox';


const MuAdmListContainer = () => {

    const dispatch = useDispatch();
    const {current_hub } = useSelector((state) => state.hub);
    const { loading, error, item_list, selected_item , driver_list , expeds_list , count } = useSelector(state => state.mu_adm);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()
    const [sel_liv, set_sel_liv] = useState(0);
    
    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    const [showDispModal, setShowDispModal] = useState(false);
    const hideDispMod = () => { setShowDispModal(false) }
    const doShowDispModal = () => { setShowDispModal(true) }

    //pagination
   const [last_filter , set_last_filter] = useState(null);
   const [page , set_page] = useState(0);

    const onItemDetailsClick = data => {
        dispatch(doSelect(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }
    // const onItemSelected = (data, etat) => {
    //     dispatch(checkColis({ data, etat }));

    // }

    const onPrint = () => {
        if (selected_item) {
            UserStorage.setParam(selected_item)
            const win = window.open(routes.mu_print, "_blank");
            win.focus();
        }
    }

    const onEdit = () => {
        if (selected_item)
            history.push(routes.mu_edit + selected_item.ref);
    }

    const onDelete = () => {
        if (selected_item) {
            set_mod_del_title("Refuser unité de ramassage")
            set_mod_del_msg("Voulez-vous refuser l'unité de ramassage' " + selected_item.ref + " ?")
            setShowDelModal(true);

        }
    }
    //refuse
    const doDelete = () => {
        if (selected_item) {
            console.log(selected_item)
            dispatch(refuseMu({id : selected_item?.id})).unwrap().then(() => {
                setShowDetails(false)
                setShowDelModal(false);
                load_mu()
            })
        }
    }

    const askDispatch = (data) => {
        if (data) {
            dispatch(doSelect(data));
            setShowDispModal(true);
        }
    }

    const onDispatchPick = () => {
        if (selected_item) {
            dispatch(dispatchPickMu({ id :selected_item.id , id_liv: sel_liv })).unwrap().then(d => {
                hideDispMod()
                setShowDetails(false)
                load_mu()
            })
        }

    }

    const onCancelColis = (id_mu , id_colis)=>{
            dispatch(excludeMuColis({id_mu , id_colis}))
    }

    const onPickMu = (ref) => {
        history.push(routes.mu_adm_depo+ref)
    }

    const load_mu = (filter)=>{
        dispatch(listMu({filter : filter , page : 0}));
        set_page(0);
        set_last_filter(filter)
    }

    const on_paginate = (value) => {
        set_page(value);
        dispatch(listMu({ filter : last_filter , page : value}));
    }

    useEffect(() => {

        dispatch(loadExpedsList());
        dispatch(loadDrivers());

        return () => {
            dispatch(clear());
        }
    }, [])

    useEffect(() => {
        load_mu()
    }, [current_hub ])

    return (
        <>
            <MuAdmHeader lst_data={item_list} error={error} loading={loading} load_data={load_mu} lst_exped={expeds_list} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'manifest'} />
            <MuList loading={loading} error={error} lst_data={Array.isArray(item_list) ? item_list : []} onItemDetailsClick={onItemDetailsClick} onDispatch={askDispatch} onPickMu={onPickMu} />
            <PaginationBox page={page} on_paginate={on_paginate} count={count} loading={loading} label={'manifest'} />
            <MuDetails data={selected_item} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} onCancelColis={onCancelColis} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} />
            <DriverPickModal onHide={hideDispMod} onOk={onDispatchPick} show={showDispModal} loading={loading} onSelectDriver={set_sel_liv} drivers={driver_list} />
           
        </>
    )
}

export default MuAdmListContainer;



