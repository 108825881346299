import React, { useEffect, useState } from "react";
import ColisClientDetails from "../../../ui/layouts/ColisClientDetails";
import UserStorage from "../../../services/UserStorage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { del, getByCode } from "../../../slices/Colis";
import ConfirmModal from "../../../ui/modals/ConfirmModal";
import TicketFormWidget from "../ticket/TicketForm.widget";

const ColisExpedDetailsContainer = ({ show, onClose, sel_colis, load_data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector((state) => state.colis);

  const [showDelModal, setShowDelModal] = useState(false);
  const [show_form_ticket, set_show_form_ticket] = useState(false);
  const hideDelMod = () => {
    setShowDelModal(false);
  };

  const [colis, set_colis] = useState(null);

  const load_colis = () => {
    if(sel_colis?.code)
    dispatch(getByCode(sel_colis?.code))
      .unwrap()
      .then((res) => {
        set_colis(res);
      })
      .catch((err) => console.log(err));
  };

  const onPrint = () => {
    if (colis) {
      UserStorage.setParam([colis]);
      const win = window.open("/dashb/colis/print/", "_blank");
      win.focus();
    }
  };

  const onEdit = () => {
    if (colis) {
      history.push("/dashb/colis/edit/" + colis?.code);
    }
  };

  const onDelete = () => {
    if (colis) {
      setShowDelModal(true);
    }
  };

  const doDelete = () => {
    if (colis) {
      dispatch(del({ ref: colis.ref }))
        .unwrap()
        .then(() => {
          setShowDelModal(false);
          onClose();
          load_data();
        })
        .catch((err) => console.log(err));
    }
  };

  const onAfterTicket = ()=>{
    history.push("/dashb/ticket/mes-ticket")
  }

  useEffect(() => {
    load_colis();
  }, [sel_colis]);

  return (
    <>
      <ColisClientDetails data={colis} isShow={show} onHide={onClose} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} onTicket={e => set_show_form_ticket(true)} />
      <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={"Supprimer colis"} msg={`Voulez-vous supprimer le colis ${colis?.code} ?`} show={showDelModal} loading={loading} />
      <TicketFormWidget show={show_form_ticket} onClose={(e) => set_show_form_ticket(false)} onAfterSubmit={onAfterTicket} colis={colis} />
    </>
  );
};

export default ColisExpedDetailsContainer;
