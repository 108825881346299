const State = {
    PENDING: 'pending',
    PENDING_PICK: 'pending_pick',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
}
const Labels = {
    pending: 'En-Attente de dispatch',
    pending_pick: 'En-Attente de ramassage',
    in_progress: 'En-Cours',
    completed: 'Terminé',
}

exports.stat = State ;
exports.MuStatus = State ;
exports.MuLabels = Labels ;

exports.MuStatusOptions = [
    {value : 'pending' , label : 'En-Attente de dispatch'},
    {value : 'pending_pick' , label : 'En-Attente de ramassage'},
    {value : 'in_progress' , label : 'En-Cours'},
    {value : 'completed' , label : 'Terminé'},
];