import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { stat as colisStat } from "../../utils/constants/Colis.enum";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/helpers/routing.helper";
import { stat as Mv_state } from "../../utils/constants/Movable.enum";

function FactureClientHeader({ load_data, loading, lst_data, error }) {
  const history = useHistory();
  const [filter, set_filter] = useState({ search: "" });

  const onSearch = (e) => {
    if (filter?.search) load_data(filter);
    else load_data();
  };

  const onClearFilter = (e) => {
    set_filter({ search: "" });
    load_data();
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "dollar-sign"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Facture(s) </b>
        </div>
      </div>

      <div className="row no-marg rw-header ">
        <Form.Group className="col-md-6 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
          <Form.Control
            type="search"
            className="form-control inpt_search shadow-none"
            style={{ borderRadius: ".2rem" }}
            placeholder="Recherche par (Réf) ..."
            onChange={(e) => set_filter({ ...filter, search: e.target.value })}
            value={filter?.search}
          />
        </Form.Group>

        <Form.Group className="col-md-6 text-end px-2 pt-4">
          <button className="btn btn_search m-0" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>
          <button className="btn btn_search bg-success m-0 ms-1" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "sync-alt"]} />
          </button>
          <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </Form.Group>

        <div className="col-md-6  fnt-largr">
          <div className="row no-marg ">
            <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
              <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">Facture(s)</b>
            </div>
          </div>
        </div>

        <div className="col-md-6 no-padd mb-2 ">
          {loading && (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FactureClientHeader;
