import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { clear , checkUser  } from '../slices/AccessCheck';
import { useDispatch, useSelector } from "react-redux";


const PrivateRoute = ({ component: Component, component2: Component2, component3: Component3, ...rest }) => {
    

    const dispatch = useDispatch();
    const { loading , error , allow , checked , user  } = useSelector(state => state.accessCheck)

    // useEffect(() => {
    //     axios.post(`${BASE_URL}/api/users/checkuser`, {}, {
    //         headers: {
    //             'livreme-tkn': token
    //         }
    //     })
    //         .then(res => {
    //             if (res.data) {
    //                 setUser(res.data);
    //                 UserProfile.setData(res.data);
    //                 UserProfile.setZone(res.data.zone);
    //                 console.log("from midleware ");
    //                 console.log(res.data);
    //             } else {
    //                 UserProfile.logout();
    //             }
    //             setChecked(true);
    //         })
    //         .catch(error => {
    //             setChecked(true);
    //             UserProfile.logout();
    //             if (error.response) {
    //                 console.log(error.response.data.message);
    //                 // if(error.response.status === 401){
    //                 //     setLoginErr(error.response.data.message);
    //                 // }                       
    //             }
    //         }
    //         );

    // }, [])

    useEffect(()=>{
        dispatch(clear());
        dispatch(checkUser());

    } , [])

    useEffect(()=>{
        return ()=>{
            dispatch(clear());
        }
    },[])

    return (
        checked &&
        <Route {...rest} render={props => (
            (allow && user ) ?
                (user.role === "admin" || user.role === "root" ) ?
                    <Component {...props} />
                    : (user.role === "liv" ) ?
                    <Component3 {...props} />
                    :
                    <Component2 {...props} />
                : <Redirect to="/login" />
        )} />


    );
};

export default PrivateRoute;