/* eslint-disable */
import React, { useState, useEffect } from 'react';
import LoginForm from '../../../ui/forms/Login.form';
import { routes } from '../../../utils/helpers/routing.helper';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login , clear } from '../../../slices/Auth';

const Login = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.auth)


    const doLogin = (email, password) => {
        dispatch(login({ email, password }))
            .unwrap()
            .then(data => {
                console.log(data)
                if (data.success) {
                   history.push(routes.dashb)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {

        return ()=>{
            dispatch(clear());
        }
    }, [])



    return (
        <LoginForm onDataSubmit={doLogin} loading={loading} error={error} />
    )
}


export default Login






