import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import { api_url } from '../../../services/http_constant';
import UserStorage from '../../../services/UserStorage';
import ProfileClientEdit from './ClientProfileEdit';
import ProfileClientEditPass from './ClientProfileEditPass';
import { useHistory } from "react-router-dom";
import axios from 'axios';

const ProfileClient = (props) => {

    const style_align_base = {
        alignItems: "flex-end"
    }
    //routing 
    const history = useHistory();
    //user infi
    const [user, setUser] = useState(UserStorage.getData());
    const [token, setToken] = useState(UserStorage.getToken());
    const [profile, setProfile] = useState();

    const [showEdit, setShowEdit] = useState(false);
    const [showEditPass, setShowEditPass] = useState(false);

    const loadData = () => {
        axios.get(`${api_url}/api/users/profile`, {
            headers: {
                'cgo-tkn': token
            }
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    setProfile(res.data)
                }

            })
            .catch(error => {
                console.log(error)
    });
    }
    useEffect(() => {
        console.log("fetch data effect call");
        loadData();


    }, []);

    const [datec, setDatec] = useState();
    const [dateu, setDateu] = useState();

    useEffect(() => {
        //for formated date
        if (profile) {
            if (profile.createdAt) {
                setDatec(new Date(profile.createdAt));
            }
            if (profile.updatedAt) {
                setDateu(new Date(profile.updatedAt));
            }

        }
    }, [profile])

    return (
        <>
            <div className="row no-marg tit_rw " >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'id-badge']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Mon profil </b>
                </div>

                <div className="col-md-6 no-padd dsp_inl_flex box_resum ">
                    <b className="box_resum-b padd-l10 dvdr-l-fx-gray"></b>

                </div>
            </div>
            <div className="row no-marg rw-header">





                <div className="col-md-8 p-2">
                    <Form.Row style={style_align_base}>

                    </Form.Row>

                </div>



                <div className="col-md-4 flex_ctr p-3">
                    <button onClick={()=>setShowEdit(true)} className="btn btn-outline-success" href="/dashb"> Edit profil</button>
                    <button onClick={()=>setShowEditPass(true)} className="btn btn-outline-primary ms-2" href="/dashb"> Changer mot de passe</button>
                </div>
            </div>

            <div className="col-md-12 bg-wt pb-5">
                <div className="row no-marg">
                    <div className="col-md-6 pt-4 dvdr-r-fx-gray">
                        <p className="fnt-w5 txt-green">Info société </p>

                        <div className="row">
                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">Nom</b> <br />
                                <b className="fnt-w5">{profile && profile.nom}</b>
                            </div>

                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">Prenom</b> <br />
                                <b className="fnt-w5">{profile && profile.prenom}</b>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-md-4">
                                <b className="fnt-w5 txt-red fnt-sm">Nom-commercial</b> <br />
                                <b className="fnt-w5">{profile && profile.nom_com ? profile.nom_com : '_'}</b>
                            </div>
                            
                            <div className="col-md-4">
                                <b className="fnt-w5 txt-red fnt-sm">Matricule Fiscal</b> <br />
                                <b className="fnt-w5">{profile?.matricule_fiscal || '_'}</b>
                            </div>

                            <div className="col-md-4">
                                <b className="fnt-w5 txt-red fnt-sm">Type de produit</b> <br />
                                <b className="fnt-w5">{profile && profile.produit_type ? profile.produit_type : '_'}</b>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">Flux d'expédition</b> <br />
                                <b className="fnt-w5">{profile && profile.flux_exped ? profile.flux_exped : '_'}</b>
                            </div>

                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">Adresse</b> <br />
                                <b className="fnt-w5">{profile && profile.adresse ? profile.adresse : '_'}</b>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-md-12">
                                <b className="fnt-w5 txt-red fnt-sm">Tél</b> <br />
                                <b className="fnt-w5">{profile && profile.tel ? profile.tel : '_'}</b>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 pt-4">
                        <p className="fnt-w5 txt-green">Info compte </p>

                        <div className="row">
                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">E-mail</b> <br />
                                <b className="fnt-w5">{profile && profile.email}</b>
                            </div>

                        </div>

                        <div className="row pt-2">
                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">Crée le</b> <br />
                                <b className="fnt-w5">{datec && `${datec.getDate()}/${datec.getMonth()}/${datec.getFullYear()}-${datec.getHours()}:${datec.getMinutes()}`}</b>
                            </div>

                            <div className="col-md-6">
                                <b className="fnt-w5 txt-red fnt-sm">Modifié le </b> <br />
                                <b className="fnt-w5">{datec && `${dateu.getDate()}/${dateu.getMonth()}/${dateu.getFullYear()}-${dateu.getHours()}:${dateu.getMinutes()}`}</b>
                            </div>
                        </div>

                    </div>

                </div>


            </div>

            <ProfileClientEdit profile={profile} showEdit={showEdit} setShowEdit={setShowEdit} loadData={loadData}  />
            <ProfileClientEditPass  showEdit={showEditPass} setShowEdit={setShowEditPass} loadData={loadData}  />
        </>
    );
}
export default ProfileClient;