
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './middleware/PrivateRoute';
import Home from './domains/public/home/Home.container';
import LivApp from './domains/public/home/LivApp.container';
import Login from './domains/public/login/Login.container';
import Register from './domains/public/register/register.container';
import ForgetPass from './domains/public/login/ForgetPass.container';
import ResetPass from './domains/public/login/ResetPass.container';
import Dashboard_adm from './domains/admin/home/Dashboard.container';
import Dashboard_exped from './domains/exped/home/Dashboard.container';
import Dashboard_liv from './domains/liv/home/Dashboard.container';
function App() {
  return (
    <Router >
      <>

        <Switch>

          <Route path="/" exact >
            <Redirect to='/home' />
          </Route>

          <Route path="/home" component={Home} />
          <Route path="/liv/app" component={LivApp} exact/>
          <Route path="/login" component={Login} exact/>
          <Route path="/register" component={Register} exact/>
          <Route path="/forget-pass" component={ForgetPass} exact/>
          <Route path="/acc/rstp/:token" component={ResetPass} exact/>
          <PrivateRoute component={Dashboard_adm} component2={Dashboard_exped} component3={Dashboard_liv} path="/dashb" />

          {/* <Route path="/login" component={Login} exact/>
          <Route path="/forget-pass" component={ForgetPass} exact/>
          <Route path="/register" component={Register} exact/>
          <Route path="/acc/rst-pass/:id" component={ResetPass} />

          <Route path="/register/vfcl/:id" component={VerifClient} />
          <Route path="/register/vfcd/:id" component={VerifCond} />

          <Route path="/register/cl/inf" component={RegisterClientInfo} />
          <Route path="/register/cd/inf" component={RegisterCondidatInfo} />
          <Route path="/register/cd/educ" component={RegisterCondidatFormation} />
          <Route path="/register/cd/exp" component={RegisterCondidatExp} />
          <PrivateRoute  path="/dashb" /> */}

          {/* <Route path="/home" component={props => <Home_base {...props} />} /> */}
          {/* <Route path="/login" component={props => <Login {...props} />} /> */}
          
          
          {/* <Route path="/register/cl/inf/:id" component={props => <RegisterClientInfo {...props} exact/>} /> */}
          {/* <Route path="/register/cl/inf/:id" render={(props) => <RegisterClientInfo {...props} />} /> */}
          

          

          {/* <Route path="/dashb" component={props => <MenubarClient {...props} />} />
          <Route path="/dashbcd" component={props => <MenubarCond {...props} />} /> */}
          {/* <Route path="/home" render={props => <Home_base {...props} />} /> */}
          
          {/* 
          <Route path="/login" exact component={Login} />
          <PrivateRoute component={Menubar} component2={MenubarClient} path="/dashb" />
          <Route path="/404" component={PnotFound} />
          <Route path="/mobile/app" component={DownloadApp} exact/>
          <Redirect to="/404" />

          {user ?
            user.role === "admin" ?
              <Route path="/dashb" component={Menubar} />
              :
              <Route path="/dashb" component={MenubarClient} />
            :
            <Redirect to='/login' />
          } */}

        </Switch>
      </>
    </Router>

  );
}


export default App;