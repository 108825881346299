import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { ColisAfexStatusLabels, ColisStatusOptions } from "../../utils/constants/Colis.enum";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/helpers/routing.helper";
import HubBox from "../../domains/admin/home/HubBox.container";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import logo_afex from "../../assets/img/logo_afex.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const colis_afex_status_option = Object.keys(ColisAfexStatusLabels)?.map(el => ({value : el , label : ColisAfexStatusLabels[el]}));

function ColisAdmAfexHeader({ lst_exped, loading, lst_data, error, load_data }) {
  const [filter, set_filter] = useState({ search: "", etat: [], id_exped: [] });

  const onSearch = (e) => {
    let filter_data = {};

    if (filter?.search) filter_data.search = filter?.search;

    if (Array.isArray(filter?.id_exped) && filter?.id_exped?.length > 0) filter_data.id_exped = filter?.id_exped?.map((el) => el?.id);

    if (Array.isArray(filter?.etat) && filter?.etat?.length > 0) filter_data.etat_afex = filter?.etat?.map((el) => el?.value);

    console.log(filter_data);

    load_data(filter_data);
  };

  const onClearFilter = (e) => {
    set_filter({ search: "", etat: [], id_exped: [] });
    load_data();
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-8 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <img src={logo_afex} alt="" width={42} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Colis Afex</b>
        </div>

        <div className="col-md-4">
          <HubBox />
        </div>
      </div>

      <div className="row no-marg rw-header ">
        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
          <Form.Control
            type="search"
            className="form-control inpt_search shadow-none"
            style={{ borderRadius: ".2rem" }}
            placeholder="Recherche par (Réf) ..."
            onChange={(e) => set_filter({ ...filter, search: e.target.value })}
            value={filter?.search}
          />
        </Form.Group>

        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Expéditeur</Form.Label>
          <Typeahead
            id="expedPickersearch"
            size="sm"
            multiple
            labelKey={(op) => op?.nom_com}
            onChange={(v) => set_filter({ ...filter, id_exped: v })}
            options={lst_exped}
            selected={filter?.id_exped}
            placeholder="Filtre Expediteur..."
          />
        </Form.Group>

        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Statut Colis</Form.Label>
          <Typeahead
            id="statusPickersearch"
            size="sm"
            multiple
            labelKey={(op) => op?.label}
            onChange={(v) => set_filter({ ...filter, etat: v })}
            options={colis_afex_status_option}
            selected={filter?.etat}
            placeholder="Filtre Status..."
          />
        </Form.Group>

        <Form.Group className="col-md-3 text-center px-2 pt-4">
          <button className="btn btn_search m-0" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>
          <button className="btn btn_search bg-success m-0 ms-1" onClick={onSearch}>
            <FontAwesomeIcon icon={["fas", "sync-alt"]} />
          </button>
          <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </Form.Group>

        <div className="col-md-6  fnt-largr">
          <div className="row no-marg ">
            <div className="col-md-3 dvdr-r-gray pt-1 pb-1">
              <b>{lst_data.length}</b> <b className="fnt-w5 fnt-sm me-5">Colis</b>
            </div>

            <div className="col-md-9 dvdr-r-gray pt-1 pb-1 text-end">
              {/* <button className="btn btn_search no-marg">
                <FontAwesomeIcon icon={["fas", "filter"]} />
              </button> */}
              {/* <Form.Control as="select" defaultValue="Choose..." size="sm" onChange={onFilterChange} className="d-inline-block ms-2 force-apparence" style={{ width: "80%" }}>
                <option value="">Tous</option>
                {expeds_list && Array.isArray(expeds_list) && expeds_list.map((elem) => <option value={elem.id}>{elem.nom_com}</option>)}
              </Form.Control> */}
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-2 text-end px-2">
          <Link to="/dashb/afex/create">
            <button className="btn btn-success btn-sm">+ Nouveau Transfert Afex</button>
          </Link>
          {loading && (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}

          {/* <span className="txt-red pl-2">{transfer_err}</span> */}
        </div>

        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ColisAdmAfexHeader;
