import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { role as userRole } from "../../../utils/constants/User.enum";

const UserFormEdit = ({ show, onClose, user, onUpdateUser, loading, error }) => {
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Vous devez saisir le nom !"),
    prenom: Yup.string().required("Vous devez saisir le prénom !"),
    nom_com: user?.role == userRole.EXPD ? Yup.string().required("Vous devez saisir le nom du société !") : Yup.string(),
    tel: Yup.string()
      .typeError("numéro tél non valide !")
      .min(8, "numéro tél non valide !")
      .max(8, "numéro tél non valide !")
      .required("Vous devez saisir le num de tél !")
      .test("tél valid", "tél non valid", (value) => (value + "").match(/^\d{1,9}?$/)),
    adresse: Yup.string(),
    matricule: Yup.string(),
    email: Yup.string().required("Vous devez saisir l' E-mail !").email("Email non valid !"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, control, setValue } = useForm(formOptions);
  const { errors } = formState;
  const sel_role = useWatch({ control: control, name: "role" });

  const onSubmit = (data) => {
    if(data && user)
    onUpdateUser({...data, id : user?.id})
  };

  useEffect(() => {
    if (user) {
      setValue("nom", user?.nom);
      setValue("prenom", user?.prenom);
      if (user?.role == userRole.EXPD) setValue("nom_com", user?.nom_com);
      setValue("tel", user?.tel);
      setValue("adresse", user?.adresse);
      setValue("email", user?.email);
      if (user?.role == userRole.LIV) setValue("matricule", user?.matricule);
    } else {
      reset();
    }
  }, [user]);

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="row no-marg">
        <div className="col-md-12">
          {error && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
            </div>
          )}
        </div>
        <div className="col-md-12  p-3 bg-wt ">
          <form onSubmit={handleSubmit(onSubmit)} id="frm_user" className="col-md-12">
            <h6>Création utilisateur:</h6>
            <div className="row m-0">
              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Role</Form.Label>
                <Form.Control disabled as="select" defaultValue="Choose..." size="sm" value={user?.role} className={`form-control`}>
                  <option value="">Choisissez un type</option>
                  <option value="admin">Admin</option>
                  <option value="liv">Livreur</option>
                  <option value="exped">Expediteur</option>
                </Form.Control>
              </Form.Group>

              <div className="col-md-12"></div>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Nom </Form.Label>
                <Form.Control size="sm" name="nom" {...register("nom")} className={`form-control ${errors.nom ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.nom?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Prenom </Form.Label>
                <Form.Control size="sm" name="prenom" {...register("prenom")} className={`form-control ${errors.prenom ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.prenom?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">Tél</Form.Label>
                <Form.Control size="sm" name="tel" {...register("tel")} className={`form-control ${errors.tel ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.tel?.message}</p>
                </Form.Text>
              </Form.Group>
              {user?.role == userRole.LIV && (
                <Form.Group as={Col} className="col-md-6">
                  <Form.Label className="no-marg small-label">Matricule de véhicule </Form.Label>
                  <Form.Control size="sm" name="matricule" {...register("matricule")} className={`form-control ${errors.matricule ? "is-invalid" : "form-control"}`} />
                  <Form.Text className="text-muted txt-red">
                    <p className="text-danger">{errors.matricule?.message}</p>
                  </Form.Text>
                </Form.Group>
              )}
              {user?.role == userRole.EXPD && (
                <Form.Group as={Col} className="col-md-6">
                  <Form.Label className="no-marg small-label">Nom commercial </Form.Label>
                  <Form.Control size="sm" name="nom_com" {...register("nom_com")} className={`form-control ${errors.nom_com ? "is-invalid" : "form-control"}`} />
                  <Form.Text className="text-muted txt-red">
                    <p className="text-danger">{errors.nom_com?.message}</p>
                  </Form.Text>
                </Form.Group>
              )}

              <Form.Group as={Col} className="col-md-12">
                <Form.Label className="no-marg small-label">Adresse</Form.Label>
                <Form.Control size="sm" name="adresse" {...register("adresse")} className={`form-control ${errors.adresse ? "is-invalid" : "form-control"}`} />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.adresse?.message}</p>
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} className="col-md-6">
                <Form.Label className="no-marg small-label">E-mail </Form.Label>
                <Form.Control size="sm" name="email" {...register("email")} className={`form-control ${errors.email ? "is-invalid" : "form-control"}`} disabled />
                <Form.Text className="text-muted txt-red">
                  <p className="text-danger">{errors.email?.message}</p>
                </Form.Text>
              </Form.Group>

              <div className="col-md-12"></div>

              {loading ? (
                <div class="col-md-12 p-4 txt-ctr">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div class="col-md-12 text-end ">
                  <button className="btn btn-primary">
                    <FontAwesomeIcon icon={["fas", "check"]} /> Confirmer
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserFormEdit;
