import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColisStatus } from '../../utils/constants/Colis.enum';
import { getStringDate } from '../../utils/helpers/date.helper';



function RunsheetHeader({ loading, data, error, onConfirm , loading_colis , error_colis }) {

    const [allow, set_allow] = useState(false);
    const [collected, set_collected] = useState(false);

    const getNbrStat = (etat) => {
        let m = 0;
        if (data) {
            if (Array.isArray(data.colis))
                data.colis.forEach(element => {
                    if (element.etat === etat)
                        m = m + 1
                });
        }
        return m;

    }


    useEffect(() => {
        let can = false;
        let mnt = 0.0;
        if (data) {
            if (Array.isArray(data.colis)) {
                can = true;
                data.colis.forEach(c => {
                    if (c.etat === ColisStatus['LIV-COURS'])
                        can = false;

                    if (c.etat === ColisStatus['LIV-COURS-SUCC'])
                        mnt = mnt + +c.montant_ttc;
                });
            }
        }
        set_allow(can)
        set_collected(mnt)

    }, [data])

    



    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-8 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'shipping-fast']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >Runsheet_{data && data.ref} </b>
                </div>

                <div className="col-md-4">

                </div>

            </div>

            <div className="row no-marg rw-header pb-2 pt-2">

                <div className="col-md-3 dvdr-r-gray fnt-sm">
                    <b className="inf_item"> <span>Ref: </span>{data && data.ref}</b>
                    <b className="inf_item"> <span>Driver: </span> {data && data.rsLiv && `${data.rsLiv.nom} ${data.rsLiv.prenom}`}</b>
                    <b className="inf_item"> <span>Date: </span> {data && getStringDate(data.createdAt)}</b>

                </div>
                <div className="col-md-3 dvdr-r-gray fnt-sm">
                    <b className="fnt-w5 fnt-sm">Montant:</b> <br />
                    <b className="item_box_money">{data && collected } <span>TND</span> </b>
                    /
                    <b className="item_box_money"> {data && data.montant_tot ? data.montant_tot : 0} <span>TND</span></b>
                </div>

                <div className="col-md-2 dvdr-r-gray fnt-sm">
                    <b className="fnt-w5 fnt-sm">Décision(s):</b> <br />

                    <b className="item_box_money inf_in_prog">{data && getNbrStat(ColisStatus['LIV-COURS'])} <span className="txt_blk fnt-w5">In progress</span> </b> <br />
                    <b className="item_box_money inf_in_prog_suc">{data && getNbrStat(ColisStatus['LIV-COURS-SUCC'])} <span className="txt_blk fnt-w5">Livré</span> </b> <br />
                    <b className="item_box_money inf_in_prog_fail">{data && getNbrStat(ColisStatus['LIV-COURS-REPO'])} <span className="txt_blk fnt-w5">Reporté</span> </b>
                    <b className="item_box_money inf_in_prog_fail">{data && getNbrStat(ColisStatus['LIV-COURS-ECHEC'])} <span className="txt_blk fnt-w5">Annuler</span> </b>
                </div>

                <div className="col-md-4  mt-2  text-center">

                    { (loading || loading_colis ) ?
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        :
                        (allow ?
                            <button className="btn btn-outline-success ms-2 mt-1" onClick={onConfirm}>
                                <FontAwesomeIcon icon={['fas', 'check-circle']} /> Confirmer
                            </button>
                            :
                            <button className="btn btn-outline-secondary  ms-2 mt-1" disabled>
                                <FontAwesomeIcon icon={['fas', 'check-circle']} /> Confirmer
                            </button>

                        )
                    }

                </div>

                <div className="col-md-12">
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    {error_colis &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error_colis}</span>
                        </div>
                    }


                </div>



            </div>

        </>
    );
}

export default RunsheetHeader;