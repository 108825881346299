import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmDepotMvZoneItem from "./ConfirmDepotColis.item";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import noDataIcon from "../../../assets/img/no_data.png";
import ConfirmModal from "../../../ui/modals/ConfirmModal";
import BarcodeReader from "react-barcode-reader";
import { ColisStatus, stat as colisStat } from "../../../utils/constants/Colis.enum";
import { Button, Table } from 'react-bootstrap';

import { api_url } from "../../../services/http_constant";
import { useDispatch, useSelector } from "react-redux";

import { clear, getMuByCode, checkColis, checkAllColis, scanCheckColis, pickMuTransfert } from "../../../slices/MuAdm";
import { getStringDate } from "../../../utils/helpers/date.helper";
import { Link } from "react-router-dom";

const MuTransfertDepot = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, item } = useSelector((state) => state.mu_adm);
  const [id, setId] = useState(props?.match?.params?.id);
  const [code_scan, set_code_scan] = useState("");

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const hideConfirmMod = () => {
    setShowConfirmModal(false);
  };
  const [mod_confirm_title, set_mod_confirm_title] = useState("");
  const [mod_confirm_msg, set_mod_confirm_msg] = useState("");

  const onSelectItem = (ref, etat) => {
    dispatch(checkColis({ ref, etat }));
  };
  const onSelectAllItem = (etat) => {
    dispatch(checkAllColis({ etat }));
  };

  const handleScan = (data) => {
    console.log(data);
    set_code_scan(data);
    if (isNaN(data)) {
      console.log(data + " is not  number ! ");
    } else {
      console.log(data + " is number ");
      if (item) {
        dispatch(scanCheckColis({ code: data, etat: true }));
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
  };

  const onAskConfirm = () => {
    if (item) {
      if (Array.isArray(item.colis)) {
        let lst_pick = item.colis.filter((elem) => elem.checked == true);
        set_mod_confirm_title("Mettre en depot unité de transfert");
        set_mod_confirm_msg("Voulez-vous confirmer le ramassage " + lst_pick.length + " / " + item.colis.length + "  colis ?");
        setShowConfirmModal(true);
      }
    }
  };

  const doConfirm = () => {
    if (item) {
      let lst_pick = [];
      let lst_fail = [];
      if (Array.isArray(item.colis)) {
        lst_pick = item.colis.filter((elem) => elem.checked == true);
        lst_fail = item.colis.filter((elem) => !elem.checked);

        lst_pick = lst_pick.map((elem) => elem.ref);
        lst_fail = lst_fail.map((elem) => elem.ref);
      }

      dispatch(pickMuTransfert({ id: item.id, pick_colis: lst_pick, fail_colis: lst_fail }))
        .unwrap()
        .then((d) => {
          history.push("/dashb/mu/transfert/list");
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  useEffect(() => {
    if (id) dispatch(getMuByCode(id));
  }, [id]);

  return (
    <>
      <MuTransfertDepotHeader mu={item} onAskConfirm={onAskConfirm} onSelectAllItem={onSelectAllItem} code_scan={code_scan} loading={loading} />
      <div className="col-md-12">
        <BarcodeReader onError={handleError} onScan={handleScan} />
        {/* {Array.isArray(item?.colis) && item?.colis.map((data) => (
          <ConfirmDepotMvZoneItem key={data.id} data={data} onCheck={onSelectItem} />
        ))} */}

        <MuTransfertDepotTable lst_colis={item?.colis} onCheck={onSelectItem} onCheckAll={onSelectAllItem}/>

        { (!Array.isArray(item?.colis) || !item?.colis?.length) && (
          <div className="col-md-12 txt-ctr">
            <br />
            <br />
            <br />
            <img src={noDataIcon} alt="" width="10%" />
            <br />
            <b className="txt-gray fnt-w5">NO DATA FOUND</b>
          </div>
        )}
      </div>

      <ConfirmModal onHide={hideConfirmMod} onOk={doConfirm} title={mod_confirm_title} msg={mod_confirm_msg} show={showConfirmModal} loading={loading} />


      {/* <ConfirmModal title={md_title} msg={md_msg} show={modalShow} onHide={handleClose} onOk={handleOkConfirm} />
            <ConfirmModal title={md_title_err} msg={md_msg_err} show={modalShow_err} onHide={handleClose_err} onOk={handleClose_err} />
             */}
      {/* <ConfirmModal onHide={handleClose} onOk={handleOkConfirm} title={md_title} msg={md_msg} show={modalShow} loading={isLoading} />
      <ConfirmModal onHide={handleClose_err} onOk={handleClose_err} title={md_title_err} msg={md_msg_err} show={modalShow_err} loading={isLoading} /> */}
    </>
  );
};

const MuTransfertDepotHeader = ({ mu, onSelectAllItem, onAskConfirm, code_scan, loading }) => {
  const [checkAll, setcheckAll] = useState(false);
  const [nbr_sel, set_nbr_sel] = useState(0);

  useEffect(() => {
    if (Array.isArray(mu?.colis)) {
      set_nbr_sel(mu?.colis?.filter((elem) => elem.checked == true)?.length);
    }
  }, [mu]);

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "dolly"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape ps-4 d-block pt-0 fnt-sm" style={{clipPath : 'none'}}>
            <Link to='/dashb/mu/transfert/list' className="d-block">
                <button className='btn btn-sm py-0 text-success fnt-w5'><FontAwesomeIcon icon={["fas", "long-arrow-alt-left"]} /> Liste des transferts</button>
            </Link>
              <span className="d-block">
              <span className="text-dark">Réceptionner Transfert Hub :</span> <span className="">{mu?.ref}</span>
              </span>
          </b>
        </div>

        <div className="col-md-6 no-padd dsp_inl_flex box_resum ">
          <b className="box_resum-b padd-l10 dvdr-l-fx-gray"></b>
        </div>
      </div>
      <div className="row no-marg rw-header">
        <div className="col-md-12">
          <div className="row p-2">

            <div className="col-md-3">
              <b className="fnt-w5">Code Scanné : </b> <b className="txt-blue d-block">{code_scan}</b>
            </div>

            <div className="col-md-6 p-1 green_box">
              <div className="row">
                <div className="col-md-12 dvdr-r-gray dvdr-l-gray text-center">
                  <b className="fnt-w5 fnt-sm">Colis Reçus</b> <br />
                  <b className="item_box_money">
                    {nbr_sel} / {Array.isArray(mu?.colis) ? mu?.colis?.length : "--"}
                  </b>
                </div>
              </div>
            </div>

            <div className="col-md-3 flex_ctr">
              {loading ? (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <button className="btn btn-outline-success" onClick={onAskConfirm}>
                  Confirmer
                </button>
              )}
              {/* <button className="btn btn-outline-success" onClick={()=> checkItem("0000000000013", true) } > test </button> */}
            </div>
          </div>

          <div className="row">
            
          </div>
        </div>
      </div>
    </>
  );
};

const MuTransfertDepotTable = ({lst_colis, onCheck, onCheckAll , onItemDetailsClick})=>{
  return(
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>
              <Form.Group id="formGridCheckbox" className="no-marg">
                <Form.Check type="checkbox" className="chek_size_lg" id="chk" onChange={e => onCheckAll(e.target.checked)} />
              </Form.Group>
            </th>
            <th>Ref</th>
            <th>Tentative</th>
            <th>Motif</th>
            <th>Expéditeur</th>
            <th>Adresse</th>
            <th>Date Colis</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(lst_colis) &&
            lst_colis.map((el) => (
              <tr style={{background : `${el?.checked ? '#37ba7740': '#dd475540'}`}}>
                <td>
                    <Form.Group id="formGridCheckbox" className="ms-2">
                        <Form.Check type="checkbox" className="chek_size_lg" id="chk" checked={el.checked} onChange={(e) => onCheck(el?.ref, e.target.checked)} />
                    </Form.Group>
                </td>
                <td className='fnt-w5'>
                    <span><FontAwesomeIcon icon={['fas', 'barcode']} /> </span>
                    <span className="fnt-w5 txt_blk">{el?.code}</span> 
                </td>

                <td>
                  <span class={el?.attempt < 3 ? "badge badge-pill bg-success txt_wt" : "badge badge-pill bg-danger txt_wt"}>{el?.attempt}/3</span>
                </td>
                <td>
                  <span class={el?.last_etat == ColisStatus["ATT-LIV"] ? "badge badge-pill bg-success txt_wt" : "badge badge-pill bg-danger txt_wt"}>{el?.last_etat == ColisStatus["ATT-LIV"] ?'Livraison':'Retour'}</span>
                </td>

                <td>{el?.user?.nom_com}</td>

                <td>{`${el?.adresse} (${el?.adresse_gov}/${el?.adresse_deleg})`}</td>

                <td>{el?.createdAt ? getStringDate(el.createdAt) : "---"}</td>

                <td>
                      <button className="btn btn-sm btn-dark me-1" disabled onClick={(e) => onItemDetailsClick(el)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} /> Fiche
                      </button>
                  </td>
      
              </tr>
            ))}
        </tbody>
      </Table>
  )
}
export default MuTransfertDepot;
