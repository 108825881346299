import React, { useEffect, useState } from "react";
import { createExpedTicket, clear } from "../../../slices/Ticket.slice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import AsyncSelect from "react-select/async";
import { TicketType, TicketTypeOptions } from "../../../utils/constants/ticket.enum";
import { lst_gov } from "../../../utils/constants/Gov.enum";
import { ColisStatus } from "../../../utils/constants/Colis.enum";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { searchLocation } from "../../../slices/Location.slice";

const TicketFormWidget = ({ show, onClose, onAfterSubmit, colis }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector((state) => state.ticket);
  const [form_values, set_form_values] = useState({});
  const [form_error, set_form_error] = useState({});

  const onSubmit = () => {
    if (form_values) {
      set_form_error(null);
      if (!colis) {
        set_form_error({ colis: "Vous devez choisir un colis" });
        return;
      }
      if (!form_values?.type) {
        set_form_error({ type: "Vous devez choisir le type de ticket" });
        return;
      }
      if (!form_values?.data) {
        set_form_error({ data: "Vous devez saisir les nouvelles données !" });
        return;
      }
      let tmp = { ...form_values };
      tmp.id_colis = colis?.ref;

      if (Array.isArray(tmp?.data?.adresse_lieu)) {
        if (tmp?.data?.adresse_lieu?.length > 0) {
          tmp.data.adresse_gov = tmp?.data?.adresse_lieu[0]._gov;
          tmp.data.adresse_deleg = tmp?.data?.adresse_lieu[0]._del;
          tmp.data.adresse_zip = tmp?.data?.adresse_lieu[0]._zip;
          tmp.data.adresse_lieu = `${tmp?.data?.adresse_lieu[0]._loc} | ${tmp?.data?.adresse_lieu[0]._del} | ${tmp?.data?.adresse_lieu[0]._gov} | ${tmp?.data?.adresse_lieu[0]._zip}`;
        }
      }
      // if(tmp?.data?.adresse_gov)
      //     tmp.data.adresse_gov = (Array.isArray(tmp?.data?.adresse_gov) && tmp?.data?.adresse_gov?.length > 0) ? tmp?.data?.adresse_gov[0] : null;
      // if(tmp?.data?.adresse_deleg)
      //     tmp.data.adresse_deleg = (Array.isArray(tmp?.data?.adresse_deleg) && tmp?.data?.adresse_deleg?.length > 0) ? tmp?.data?.adresse_deleg[0] : null;

      dispatch(createExpedTicket(tmp))
        .unwrap()
        .then((res) => {
          if (res?.done) onAfterAdd();
        })
        .catch((err) => console.log(err));
    }
  };

  const onSearchLocation = (search) => {
    return dispatch(searchLocation(search)).unwrap();
  };

  const onAfterAdd = () => {
    set_form_error(null);
    set_form_values({});
    onClose();
    onAfterSubmit();
  };

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="col-md-12  p-2 bg-wt ">
        <h4>Nouveau Ticket</h4>
        <TicketForm form_values={form_values} set_form_values={set_form_values} form_error={form_error} colis={colis} onSubmit={onSubmit} loading={loading} onSearchLocation={onSearchLocation} />
      </div>
    </div>
  );
};

const TicketForm = ({ form_values, set_form_values, form_error, colis, onSubmit, loading, onSearchLocation }) => {
  const searchOnchange = (v) => {
    set_form_values({ ...form_values, colis: v });
  };

  const [data, set_data] = useState(null);
  const [lst_gov_option, set_lst_gov_option] = useState(lst_gov.map((el) => el.nom_gov));
  const [lst_deleg_option, set_lst_deleg_option] = useState([]);

  const [lst_location, set_lst_location] = useState([]);

  const searchLocation = (query) => {
    onSearchLocation(query)
      .then((res) => {
        console.log(res);
        set_lst_location(Array.isArray(res) ? res : []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(data?.adresse_gov);
    if (Array.isArray(data?.adresse_gov) && data?.adresse_gov.length > 0) {
      set_lst_deleg_option(lst_gov.find((el) => el.nom_gov == data?.adresse_gov[0])?.delegations || []);
    }
  }, [data?.adresse_gov]);

  useEffect(() => {
    set_form_values({ ...form_values, data: data });
  }, [data]);

  return (
    <div className="row m-0">
      <Form.Group className="col-md-12">
        <b className="d-block fnt-w5">Colis à modifier</b>
        <Form.Control as="input" size="sm" value={colis?.code} disabled></Form.Control>
      </Form.Group>

      {colis && (
        <>
          <Form.Group className="col-md-12  mb-3">
            <Form.Label className="fnt-sm fnt-w5 m-0">Type de Ticket</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={form_values?.type}
              onChange={(e) => set_form_values({ ...form_values, type: e?.target?.value })}
              className={`force-apparence ${form_error?.colis && `is-invalid`}`}
            >
              <option value={""}>Choisissez une option</option>
              {Array.isArray(TicketTypeOptions) && TicketTypeOptions.map((el) => <option value={el?.value}>{el?.label}</option>)}
            </Form.Control>
            <b className="d-block text-danger">{form_error?.type && form_error?.type}</b>
          </Form.Group>

          <div className="col-md-12 text-center">
            <b className="d-block text-danger">{form_error?.data && form_error?.data}</b>
          </div>
          {form_values?.type == TicketType["INFOS-ADRS"] && (
            <>
              <Form.Group className="col-md-12 ">
                <Form.Label className="fnt-sm fnt-w5 m-0">Adresse</Form.Label>
                <Form.Control as="textarea" rows={1} size="sm" value={data?.adresse} onChange={(e) => set_data({ ...data, adresse: e?.target?.value })} className={form_error?.data && `is-invalid`} />
              </Form.Group>

              <Form.Group className="col-md-12 ">
                <Form.Label className="fnt-sm fnt-w5 m-0">Lieu</Form.Label>
                {/* <Form.Control as="textarea" rows={1} size="sm" value={data?.adresse} onChange={(e) => set_data({ ...data, adresse_lieu: e?.target?.value })} className={form_error?.data && `is-invalid`} /> */}
                <AsyncTypeahead
                  id="typeahead-loc"
                  isLoading={loading}
                  labelKey={(option) => `${option._loc} | ${option._del} | ${option._gov} | ${option._zip}`}
                  clearButton
                  className={!data?.adresse_lieu ? "is-invalid" : ""}
                  aria-describedby="typeaheadError"
                  options={lst_location}
                  onSearch={searchLocation}
                  selected={data?.adresse_lieu}
                  onChange={(selected) => set_data({ ...data, adresse_lieu: selected })}
                />
              </Form.Group>
            </>
          )}
          {form_values?.type == TicketType["INFOS-TEL"] && (
            <Form.Group className="col-md-12 ">
              <Form.Label className="fnt-sm fnt-w5 m-0">Tél Client</Form.Label>
              <Form.Control as="input" size="sm" value={data?.tel_cl} onChange={(e) => set_data({ tel_cl: e?.target?.value })} className={form_error?.data && `is-invalid`} />
            </Form.Group>
          )}
          {form_values?.type == TicketType["INFOS-MNT"] && (
            <Form.Group className="col-md-12 ">
              <Form.Label className="fnt-sm fnt-w5 m-0">Montant TTC</Form.Label>
              <Form.Control as="input" type="number" size="sm" value={data?.montant_ttc} onChange={(e) => set_data({ montant_ttc: e?.target?.value })} className={form_error?.data && `is-invalid`} />
            </Form.Group>
          )}
          {form_values?.type == TicketType.STATUS && (
            <Form.Group className="col-md-12">
              <Form.Label className="fnt-sm fnt-w5 m-0">Nouveau Statut</Form.Label>
              <Form.Control as="select" size="sm" value={data?.etat} onChange={(e) => set_data({ etat: e?.target?.value })} className={`force-apparence ${form_error?.data && `is-invalid`}`}>
                <option disabled>Choisissez une option</option>
                <option value={ColisStatus.ANL}>Colis Annulé</option>
                <option value={ColisStatus.FACTURE}>Colis Facturé</option>
                <option value={ColisStatus.ENDOM}>Colis Endommagé</option>
                <option value={ColisStatus.LIVRE}>Colis Livré</option>
                <option value={ColisStatus.RECUPERER}>Colis Recupéré</option>
                <option value={ColisStatus["ATT-LIV"]}>Colis à livré</option>
              </Form.Control>
            </Form.Group>
          )}

          {form_values?.type && (
            <Form.Group className="col-md-12 ">
              <Form.Label className="fnt-sm fnt-w5 m-0">Note Expéditeur (optionnel) :</Form.Label>
              <Form.Control as="textarea" rows={3} size="sm" value={form_values?.note_exped} onChange={(e) => set_form_values({ ...form_values, note_exped: e?.target?.value })} />
            </Form.Group>
          )}

          <div className="col-md-12 text-end py-2">
            <button className="btn btn-success btn-sm px-4" disabled={loading} onClick={onSubmit}>
              Confirmer
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TicketFormWidget;
