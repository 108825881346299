exports.TicketStatus = {
    'EN-ATTENTE': 'EN-ATTENTE',
    'ACCEPTE': 'ACCEPTE',
    'REFUSE': 'REFUSE',
    'ANNULE': 'ANNULE',
}

exports.TicketStatusBg = {
    'EN-ATTENTE': 'bg-warning',
    'ACCEPTE': 'bg-success',
    'REFUSE': 'bg-danger',
    'ANNULE': 'bg-secondary',
}

exports.TicketType = {
    'INFOS-ADRS': 'INFOS-ADRS',//adresse client / gov / deleg
    'INFOS-TEL': 'INFOS-TEL', //tel client
    'INFOS-MNT': 'INFOS-MNT',//montant colis
    'STATUS': 'STATUS',//status colis
}

exports.TicketTypeOptions = [
    {value: 'INFOS-ADRS', label : 'Adresse Colis'},
    {value: 'INFOS-TEL', label : 'Tél Client'},
    {value: 'INFOS-MNT', label : 'Montant Colis'},
    {value: 'STATUS', label : 'Status Colis'},
]

exports.TicketTypeLabel = {
    'INFOS-ADRS': 'Adresse',//adresse client / gov / deleg
    'INFOS-TEL': 'Tél client', //tel client
    'INFOS-MNT': 'Montant colis',//montant colis
    'STATUS': 'Status',//status colis
}
