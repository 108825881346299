/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisDepotList from '../../../ui/lists/ColisDepot.list';
import MuAdmDepotHeader from '../../../ui/headers/MuAdmDepot.header';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear, getMuByCode, pickMu, checkColis, checkAllColis , scanCheckColis } from '../../../slices/MuAdm';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import { stat as muStat } from '../../../utils/constants/Movable.enum';
import BarcodeReader from 'react-barcode-reader';


const MovableDepot = (props) => {

    const dispatch = useDispatch();
    const { loading, error, item } = useSelector(state => state.mu_adm);
    const [code_scan, set_code_scan] = useState("");

    const history = useHistory()
    const [id, setId] = useState(props.match.params.code);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const hideConfirmMod = () => { setShowConfirmModal(false) }
    const doShowConfirmModal = () => { setShowConfirmModal(true) }
    const [mod_confirm_title, set_mod_confirm_title] = useState("");
    const [mod_confirm_msg, set_mod_confirm_msg] = useState("");

    const onSelectItem = (ref, etat) => {
        dispatch(checkColis({ ref, etat }))
    }
    const onSelectAllItem = (etat) => {
        dispatch(checkAllColis({ etat }))
    }

    const handleScan = (data) => {
        console.log(data);
        set_code_scan(data);
        if (isNaN(data)) {
            console.log(data + " is not  number ! ");
        } else {
            console.log(data + " is number ");
            if (item) {
                dispatch(scanCheckColis({ code :data, etat :true}))
            }
        }
    }

    const handleError = (err) => {
        console.log(err)
    }

    const askConfirm = () => {
        if (item) {
            if (Array.isArray(item.colis)) {
                let lst_pick = item.colis.filter(elem => elem.checked == true)
                set_mod_confirm_title("Mettre en depot unité mobile")
                set_mod_confirm_msg("Voulez-vous confirmer le ramassage " + lst_pick.length + " / " + item.colis.length + "  colis ?")
                setShowConfirmModal(true)
            }

        }
    }

    const doConfirm = () => {
        if (item) {

            let lst_pick = [];
            let lst_fail = [];
            if (Array.isArray(item.colis)) {
                lst_pick = item.colis.filter(elem => elem.checked == true)
                lst_fail = item.colis.filter(elem => !elem.checked)

                lst_pick = lst_pick.map(elem => elem.ref)
                lst_fail = lst_fail.map(elem => elem.ref)
            }

            dispatch(pickMu({ id: item.id, pick_colis: lst_pick, fail_colis: lst_fail })).unwrap().then(d => {
                history.push('/dashb/mu/pickup/list');
            })
        }

    }


    useEffect(() => {

        return () => {
            dispatch(clear());
        }
    }, [])

    useEffect(() => {
        if (id)
            dispatch(getMuByCode(id));
    }, [id])

    return (
        <>
            {item && Array.isArray(item.colis) &&
                <>
                    <BarcodeReader onError={handleError} onScan={handleScan} />
                    <MuAdmDepotHeader mu={item} error={error} onSubmit={askConfirm}  code_scan={code_scan} />
                    <ColisDepotList loading={loading} error={error} lst_data={item.colis} onItemSelected={onSelectItem} onCheckAll={onSelectAllItem} />
                    <ConfirmModal onHide={hideConfirmMod} onOk={doConfirm} title={mod_confirm_title} msg={mod_confirm_msg} show={showConfirmModal} loading={loading} />
                </>
            }
        </>
    )
}

export default MovableDepot;



