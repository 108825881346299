/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { routes } from '../../../utils/helpers/routing.helper';
import { useHistory } from "react-router-dom";
import UserService from '../../../services/UserService';
import ForgetPassForm from '../../../ui/forms/ForgetPass.form';

const ForgetPass = (props) => {

    const history = useHistory();
    const [loading , set_loading ] = useState(false)
    const [error , set_error ] = useState("")
    const [succ_msg , set_succ_msg ] = useState("")



    const doSubmit = (email) => {
        set_error("")
        set_succ_msg("")
        set_loading(true)
        UserService.forgetPass({email : email })
            .then(res => {
                console.log(res)
                set_loading(false)
                if (res.data) {
                    if(res.data.done )
                    set_succ_msg("Vérifier votre boite mail SVP.")
                    else
                    set_error("Echec ! verifier votre adresse mail !")
                }else{
                    set_error("Echec ! verifier votre adresse mail !")
                }
            })
            .catch(err => {
                set_loading(false)
                console.log(err)
                const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
                set_error(message)
            })
    }

    useEffect(() => {

        return ()=>{
            
        }
    }, [])



    return (
        <ForgetPassForm onDataSubmit={doSubmit} loading={loading} error={error} succ={succ_msg}  />
    )
}


export default ForgetPass






