import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";

const ColisTransferHubItem = (props) => {
  const data = props.data;
  const style_dsp_left = {
    justifyContent: "start",
  };

  const doSel = () => {
    //window.scrollTo(0, 0);
    props.setSelData(data);
    props.setShowP(true);
  };

  return (
    <div className="row item_box">
      {/* <div className="col-md-1 flex_ctr">
                <Form.Group id="formGridCheckbox" className="no-marg">
                    <Form.Check type="checkbox" className="chek_size_lg" id="chk" checked={data.checked}  onChange={(e)=> props.onCheck(data.code , e.target.checked)} />         
                </Form.Group>
            </div> */}

      <div className="col-md-2 dvdr-r-gray " style={style_dsp_left}>
      <b className="fnt-w5 fnt-sm d-block text-muted">Colis : </b>
        <b className="item_box_ref">
          <span>
            <FontAwesomeIcon icon={["fas", "barcode"]} />{" "}
          </span>
          <span className="fnt-w5 txt_blk">{data.code}</span>
        </b>
      </div>
      <div className="col-md-2 dvdr-r-gray">
      <b className="fnt-w5 fnt-sm d-block text-muted">Expediteur : </b>
        <span>{data.user.nom_com} </span>
      </div>
      <div className="col-md-2 dvdr-r-gray">
      <b className="fnt-w5 fnt-sm d-block text-muted">Destinataire : </b>
        <span className="text-capitalize">
          {data.nom_cl}
        </span>
      </div>

      <div className="col-md-4 ">
      <b className="fnt-w5 fnt-sm d-block text-muted">Adresse: </b>
        <span>
          <FontAwesomeIcon icon={["fas", "map-marker-alt"]} /> {data.adresse}{" "}
        </span>
        <b className="fnt-w4 badge-out-succ text-capitalize px-1">
          {data.adresse_lieu}
        </b>
      </div>

      <div className="col-md-1 pt-2">
        <button className="box_item_btn no-marg" onClick={e => props?.onItemClick(data)}>
          <FontAwesomeIcon icon={["fas", "long-arrow-alt-right"]} />
        </button>
      </div>

      <div className="col-md-1 flex_ctr">
        <button className="btn btn-outline-danger round" onClick={() => props.onCancel(data.code)}>
          {" "}
          <FontAwesomeIcon icon={["fas", "times"]} />{" "}
        </button>
      </div>
    </div>
  );
};
export default ColisTransferHubItem;
