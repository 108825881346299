import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert, Col, Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "../../../utils/helpers/routing.helper";
import { listAdmExped } from "../../../slices/User.slice";
import { createCheque, updateCheque } from "../../../slices/Cheque.slice";
import BarcodeReader from "react-barcode-reader";
import { clear, getOneColisByFilter, listColisAdm, setError } from "../../../slices/ColisAdm";
import AsyncSelect from "react-select/async";

const ChequeFormContainer = ({ show, onClose, sel_item, afterSubmit, ...props }) => {
  const { loading, error } = useSelector((state) => state.cheque);
  const { error: error_colis } = useSelector((state) => state.colis_adm);
  const dispatch = useDispatch();
  const history = useHistory();

  const [lst_colis, set_lst_colis] = useState([]);
  const [sel_colis, set_sel_colis] = useState(null);

  const validationSchema = Yup.object().shape({
    num: Yup.string().required("Vous devez saisir le numéro du chéque ! "),
    id_exped: Yup.mixed(),
    ref_colis: Yup.mixed().required("Vous devez choisir un colis !"),
    montant: Yup.number().required("Vous devez saisir le montant (TND) !"),
    proprietaire: Yup.string().required("Vous devez saisir le proprietaire du chéque !"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue, getValues } = useForm(formOptions);
  const { errors } = formState;

  const onSearchColis = (search, callback) => {
    dispatch(listColisAdm({ filter: { search: search } }))
      .unwrap()
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) callback(data.map((i) => ({ label: `${i.code}.(${i.nom_cl} )`, value: i })));
        else callback([]);
      });
  };

  const handleScan = (data) => {
    console.log(data);
    if (isNaN(data)) {
      console.log(data + " is not  number ! ");
      dispatch(setError("code colis erroné , tapez sur MAJ et essayer svp !"));
    } else {
      console.log(data + " is number ");
      if (data) {
        dispatch(getOneColisByFilter({ code: data }))
          .unwrap()
          .then((colis) => {
            console.log(colis);
            if (colis) {
              set_lst_colis([{ label: `${colis.code}.(${colis.nom_cl} )`, value: colis }]);
              set_sel_colis({ label: `${colis.code}.(${colis.nom_cl} )`, value: colis });
            } else dispatch(setError("Aucun Colis trouvé avec le réf :" + data + " !"));
          });
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
  };

  const onSubmit = (data) => {
    console.log(data);

    if (+data?.montant > +sel_colis?.value?.montant_ttc) {
      dispatch(setError("Montant Invalide !"));
      return;
    }

    if (sel_item) {
      dispatch(updateCheque({ ...sel_item, id_exped: data?.id_exped, num: data?.num, montant: data?.montant, proprietaire: data?.proprietaire, ref_colis: data?.ref_colis }))
        .unwrap()
        .then((res) => {
          if (res?.done) afterSubmit();
        })
        .catch((err) => console.log(err));
    } else {
      dispatch(createCheque(data))
        .unwrap()
        .then((res) => {
          if (res?.done) afterSubmit();
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (sel_item) {
      set_lst_colis([{ label: `${sel_item?.coli?.code}.(${sel_item?.coli?.nom_cl} )`, value: sel_item?.coli }]);
      set_sel_colis({ label: `${sel_item?.coli?.code}.(${sel_item?.coli?.nom_cl} )`, value: sel_item?.coli });
      setValue("num", sel_item?.num);
      setValue("id_exped", sel_item?.chequeFor);
      setValue("proprietaire", sel_item?.proprietaire);
      setValue("montant", sel_item?.montant);
      setValue("ref_colis", sel_item?.coli?.ref);
      
    } else reset();
  }, [sel_item]);

  useEffect(() => {
    if (!show) {
      reset();
      set_lst_colis([]);
      set_sel_colis(null);
    }
  }, [show]);

  const onClisSelect = (v)=> {
    if (v?.value) {
      setValue("id_exped", v?.value?.id_exped);
      setValue("montant", +v?.value?.montant_ttc);
      setValue("ref_colis", v?.value?.ref);
      setValue("proprietaire", v?.value?.nom_cl);
      set_sel_colis(v)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
      <div className="row no-padd no-marg bg-gray">
        <button className="btn_close" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </button>
      </div>

      <div className="col-md-12  p-2 bg-wt ">
        <h5>Formulaire de chéque</h5>
        {error && <Alert variant="danger">{error}</Alert>}
        {error_colis && <Alert variant="danger">{error_colis}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} id="frm_user" className="row m-0 mt-4">
          {show && <BarcodeReader onError={handleError} onScan={handleScan} />}
          <Alert variant="secondary">Vous pouvez scanner le colis .</Alert>

          <Form.Group as={Col} className="col-md-12">
            <Form.Label className="no-marg small-label">Code Colis </Form.Label>
            <AsyncSelect loadOptions={onSearchColis} value={sel_colis} onChange={(v) => onClisSelect(v)} placeholder="cherchez par Ref colis" />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.ref_colis?.message}</p>
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} className="col-md-6">
            <Form.Label className="no-marg small-label">Numéro chéque </Form.Label>
            <Form.Control size="sm" name="num" {...register("num")} className={`form-control ${errors.num ? "is-invalid" : "form-control"}`} />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.num?.message}</p>
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} className="col-md-6">
            <Form.Label className="no-marg small-label">Client </Form.Label>
            <Form.Control size="sm" name="proprietaire" {...register("proprietaire")} className={`form-control ${errors.proprietaire ? "is-invalid" : "form-control"}`} />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.proprietaire?.message}</p>
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} className="col-md-12">
            <Form.Label className="no-marg small-label">Montant (TND) </Form.Label>
            <Form.Control size="sm" type="number" name="montant" {...register("montant")} className={`form-control ${errors.montant ? "is-invalid" : "form-control"}`} />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.montant?.message}</p>
            </Form.Text>
          </Form.Group>

          {loading ? (
            <div class="col-md-12 p-4 txt-ctr">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div class="col-md-12 text-end ">
              <button className="btn btn-primary ">
                <FontAwesomeIcon icon={["fas", "check"]} /> Confirmer
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ChequeFormContainer;
