import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class LivService {

    getRsTask() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/liv/v10/getmytask`, null, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    getPickupTask() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/liv/v10/getmypick`, null, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    selfAfectPick(id) {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/liv/v10/mu/self-afect/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    pickMuColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/liv/v10/mu/pick`, data , {
            headers: {
                'cgo-tkn': token
            }
        });
    }


    updateColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/liv/v10/pushcolis`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

   

}

export default new LivService();