import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { ColisStatus } from '../../utils/constants/Colis.enum';



const InvoiceItem = ({item}) => {

    const [montant_pay, setMontant_pay] = useState(0.0);
    const [montant_colis, setMontant_colis] = useState(0.0);
    const [frais_liv, setFrais_liv] = useState();


    // useEffect(() => {
    //     if (item) {
    //         console.log("calcule called")
    //         let mnt_colis = 0.0 ;
    //         let frais = 0.0 ;
    //         if (Array.isArray(item.colis)) {
    //             item.colis.forEach(elem => {
    //                 if(!frais_liv){
    //                     frais = +elem.frai_liv
    //                     setFrais_liv(+elem.frai_liv)
    //                 }
                    
    //                 if(elem.etat == ColisStatus.LIVRE) 
    //                     mnt_colis = mnt_colis + +elem.montant_ttc

    //             });
    //         }
    //         console.log(mnt_colis)
    //         console.log(frais)
    //         setMontant_colis(mnt_colis);
    //         if(Array.isArray(item?.colis))
    //         setMontant_pay(parseFloat(+mnt_colis - (+frais * +item?.colis?.length)).toFixed(3));
    //     }
    // }, [item])




    return (
        <div className="row item_box">
            <div className="col-md-1">
                <h3 className="icon"><FontAwesomeIcon icon={['fas', 'cash-register']} /></h3>
            </div>
            <div className="col-md-3 dvdr-r-gray pt-2">
                <b className="item_box_ref">
                <b className="fnt-w5 fnt-sm pe-1">Client:</b>   <span>{item.nom_com}</span>
                </b>
            </div>

            <div className="col-md-4 dvdr-r-gray pt-2">
                {/* <b className="fnt-w5 fnt-sm pe-1">Montant:</b> 
                <b className="item_box_money">{montant_colis} <span>TND</span> </b> */}
            </div>

            <div className="col-md-3 pt-2">
                <b className="fnt-w5 fnt-sm pe-1">Colis:</b> 
                <b className="item_box_money">{item && Array.isArray(item.colis) && item.colis.length} <span>Colis</span> </b>
            </div>

            

            <div className="col-md-1 text-center">
                <Link className="box_item_btn" to={`/dashb/invoice/client/${item.id}`} ><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></Link>

            </div>


        </div>
    );
}
export default InvoiceItem;