import React from 'react';
import Item from '../items/ColisMu.item';

const ColisList = ({ loading, lst_data, onCancel }) => {
    return (
        <div className="col-md-12 bg-wt mt-2">

            {loading ?
                <div class="col-md-12 p-4 txt-ctr">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                :
                (
                    Array.isArray(lst_data) &&
                    lst_data.map((s, index) => <Item key={`colis_.${index}`} data={s} index ={index}  onCancel={onCancel} />)
                )
            }

        </div>
    );
};

export default ColisList;