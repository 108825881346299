import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import UserStorage from '../../../services/UserStorage';
import RunsheetHistoryDetails from './RunsheetHistoryDetails';
import noDataIcon from '../../../assets/img/no_data.png';
import { api_url } from '../../../services/http_constant';
import { stat as RunsheetState } from '../../../utils/constants/Runsheet.enum'
import { getStringDate } from '../../../utils/helpers/date.helper';



const DriverHistory = (props) => {

    //user infi
    const [user, setUser] = useState(UserStorage.getData());
    const [token, setToken] = useState(UserStorage.getToken());
    //Modal dispatch confirm
    const [md_title_disp, setMd_title_disp] = useState("");
    const [md_msg_disp, setMd_msg_disp] = useState("");
    const [modalShow_disp, setModalShow_disp] = useState(false);
    const handleClose_disp = () => setModalShow_disp(false);
    const handleShow_disp = () => setModalShow_disp(true);

    //data list
    const [list, setList] = useState([]);
    const [list_src, setList_src] = useState([]);
    const [listDriver, setListDriver] = useState([]);
    const [search, setSearch] = useState(null);
    //Modal delete confirm
    const [md_title_del, setMd_title_del] = useState("");
    const [md_msg_del, setMd_msg_del] = useState("");
    const [modalShow_del, setModalShow_del] = useState(false);
    const handleClose_del = () => setModalShow_del(false);
    const handleShow_del = () => setModalShow_del(true);

    const [zone, setZone] = useState(UserStorage.getZone());
    const [isLoading, setIsLoading] = useState(false);
    const [selDriver, setSelDriver] = useState(0);


    //Details panel 
    const [showP, setShowP] = useState(false);
    const showDetails = (data) => {
        setSelData(data);
        setShowP(true)
    }
    //routing 
    const history = useHistory();

    //current selected item
    const [selData, setSelData] = useState();


    const loadDataLiv = () => {
        axios.get(`${api_url}/api/users/adm/liv/list`, {
            headers: {
                'cgo-tkn': token
            }
        })
            .then(res => {
                console.log(res.data);
                setListDriver(res.data)
            })
            .catch(error => {
                // setLoading(false);
                if (error.response) {
                    console.log(error.response.data.message);
                    if (error.response.data.message === "invalid-token") {
                        UserStorage.logout();
                        history.push("/login");
                    }
                }
            }
            );
    }



    const loadData = () => {


        setIsLoading(true);

        axios.post(`${api_url}/api/rs/adm/getDriverHistory`, { id_liv: selDriver }, {
            headers: {
                'cgo-tkn': token
            }
        })
            .then(res => {
                setIsLoading(false);
                console.log(res.data);
                setList(res.data)
                setList_src(res.data);
            })
            .catch(error => {
                setIsLoading(false);
                // setLoading(false);
                if (error.response) {
                    console.log(error.response.data.message);
                    if (error.response.data.message === "invalid-token") {
                        UserStorage.logout();
                        history.push("/login");
                    }
                }
            }
            );
    }
    useEffect(() => {
        console.log("fetch data effect call");
        //loadData();
        loadDataLiv();


    }, []);

    const renderStat = (data) => {
        let spn_stat;
        let stat = "";
        switch (data.etat) {
            case RunsheetState.PENDING:
                stat = "Pending to Dispatch";
                break;
            case RunsheetState.IN_PROGRESS:
                stat = "In progress";
                break;
            case RunsheetState.COMPLETED:
                stat = "Completed";
                break;
            case RunsheetState.CLOSED:
                stat = "CLOSED";
                break;


        }

        if (data.etat === RunsheetState.PENDING) {
            spn_stat = <span className="badge bg-warning fnt-w5 txt_blk  mt-2"> {stat} </span>
        } else if (data.etat === RunsheetState.IN_PROGRESS) {
            spn_stat = <span className="badge bg-primary fnt-w5 txt_wt mt-2"> {stat} </span>
        } else if (data.etat === RunsheetState.COMPLETED) {
            spn_stat = <span className="badge bg-success fnt-w5 txt_wt  mt-2"> {stat}</span>
        } else {
            spn_stat = <span className="badge bg-secondary fnt-w5 txt_wt  mt-2"> {stat}</span>
        }
        return spn_stat;
    }






    const style_align_base = {
        alignItems: "flex-end"
    }
    return (
        <>
            <div className="row no-marg tit_rw" >
                <div className="col-md-6 no-padd dsp_inl_flex">
                    <b className="tit_icon_shape" ><FontAwesomeIcon icon={['fas', 'shipping-fast']} /></b>
                    <b className="tit_spacer_shape" ></b>
                    <b className="tit_text_shape" >History / Driver Runsheets </b>
                </div>

                <div className="col-md-3 no-padd dsp_inl_flex box_resum ">
                    <b className="box_resum-b padd-l10 dvdr-l-fx-gray pt-2">Runsheets <span className="txt-red">{list.length}</span></b>

                </div>

                <div className="col-md-3 no-padd  ">

                </div>
            </div>
            <div className="row no-marg rw-header p-3">

                <Form.Group as={Col} controlId="formGridState" className="col-md-8">
                    <Form.Label className="no-marg  small-label">Livreur</Form.Label>
                    <Form.Control as="select" defaultValue="Choose..." size="sm" className="force-apparence" onChange={(e) => setSelDriver(e.target.value)}>
                        <option value="0">Choisissez un livreur</option>
                        {listDriver.map(c => <option value={c.id}>{c.nom} {c.prenom}</option>)}
                    </Form.Control>
                </Form.Group>


                <Form.Group as={Col} controlId="formGridState" className="pt-3 col-md-4">
                    {isLoading ?
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        :
                        <button className="btn btn-outline-success" onClick={loadData}> Chercher</button>
                    }
                </Form.Group>




            </div>

            <div className="col-md-12">

                <table className="table table-striped table-bordered fnt-sm">
                    <thead>
                        <tr>
                            <th scope="col">ref</th>
                            <th scope="col">etat</th>
                            <th scope="col">colis</th>
                            <th scope="col">crée le</th>
                            <th scope="col">action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data =>

                            <tr>
                                <td><b>{data.ref}</b></td>
                                <td>{renderStat(data)}</td>
                                <td>{data.nbr_colis} colis</td>
                                <td>{data.createdAt ? getStringDate(data.createdAt) : '_'}</td>
                                <td>
                                    <a className="btn btn-outline-secondary ms-1" onClick={() => showDetails(data)}> Details</a>

                                </td>

                            </tr>

                        )}
                    </tbody>
                </table>

            </div>

            {!list.length &&
                <div className="col-md-12 txt-ctr">
                    <br />
                    <br />
                    <br />
                    <img src={noDataIcon} alt="" width="10%" />
                    <br /><b className="txt-gray fnt-w5">NO DATA FOUND</b>
                </div>
            }

            <RunsheetHistoryDetails showP={showP} setShowP={setShowP} selData={selData} setSelData={setSelData} />

        </>
    );
}
export default DriverHistory;