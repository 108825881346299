/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../assets/img/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Route, NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Storage from '../../../services/UserStorage';
// import AdmApiCall from '../../../services/api/AdmApiCall';
// import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'


import HomeExped from './HomeExped';
import CreateColis from '../Packages/CreateColis.container';
import CreateManyColis from '../Packages/CreateManyColis.container';
import MesColis from '../Packages/MesColis.container';
import PrintColis from '../Packages/PrintColis.container';
import EditColis from '../Packages/EditColis.container';
import SearchColis from '../Packages/SearchColis.container';
import MesMu from '../transfer/MesMu.container';
import CreateMu from '../transfer/CreateMu.container';
import EditMu from '../transfer/EditMu.container';
import PrintMu from '../transfer/PrintMu.container';
import HistoryColis from '../historique/HistoryColis.container';
import HistoryMu from '../historique/HistoryMu.container';
import MesFacture from '../factures/MesFacture.container';
import ClientProfile from '../profile/ClientProfile.container';
import BillPrintExped from '../factures/BillPrintExped.container';
import TicketList from '../ticket/TicketList.container';



const DashboardExped = ({ match }) => {

    const [isOpen, setIsopen] = useState(true);
    const user = Storage.getData();
    // const token = Storage.getToken();
    const history = useHistory();

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(0);

    const logout = () => {

        Storage.logout();
        history.push("/login");
    }


    return (

        <>

            <nav className={isOpen ? "hd-navbar fixed-top" : "hd-navbar fixed-top hd-navbar-mini"}>
                <button className="sidebar-toggle" data-toggle="collapse" data-target=".main-sidebar" role="button"
                    onClick={() => setIsopen(!isOpen)}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </button>
                <div className="navbar-custom-menu pr-4" style={{ float: "right", marginRight: "24px", display: "inline-block" }}>
                    <ul className="nav navbar-nav" style={{ flexDirection: "row" }}>


                        <li className="nav-item ">

                            <Dropdown drop="down"  >
                                <Dropdown.Toggle variant="default  fnt-largr  no-arrow user-nav-box " id="dropdown-basic" >
                                <b className="fnt-w5">
                                    <FontAwesomeIcon icon={['fas', 'user']} /> 
                                    <span className="fnt-med ps-2">{user?.nom_com} ({user?.role})</span>
                                    <span className="fnt-med ps-2"><FontAwesomeIcon icon={['fas', 'sort-down']} /> </span>  
                                </b> 
                                   
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">




                                </Dropdown.Menu>
                            </Dropdown>


                        </li>



                        <li className="nav-item ">

                            <Dropdown drop="down"  >
                                <Dropdown.Toggle variant="default  fnt-largr  no-arrow btn_round_notif p-0" id="dropdown-basic" >
                                    <FontAwesomeIcon icon={['fas', 'bell']} />
                                    {nbr_notif_unread > 0 &&
                                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fnt-sm mt-2">
                                            {nbr_notif_unread}
                                        </span>
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu  style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">

                                    {/* {lst_notif.map((ntf) =>
                                        <>
                                            <Dropdown.Item href={ntf.url}>
                                                <div className="row no-marg">
                                                    <div className="col-md-1 txt-dark-bl fnt-largr p-0">
                                                        {renderNotifIcon(ntf.titre)}
                                                    </div>
                                                    <div className="col-md-10 p-0">
                                                        <p style={{ whiteSpace: "normal" }} className="mb-0">
                                                            <div dangerouslySetInnerHTML={{ __html: ntf.content }} />

                                                            <b className="d-block txt-gray fnt-sm fnt-w5"> {getStringOnlyDate(ntf.createdAt)}</b>
                                                        </p>
                                                    </div>
                                                </div>

                                            </Dropdown.Item>
                                            <NavDropdown.Divider />
                                        </>
                                    )

                                    } */}



                                </Dropdown.Menu>
                            </Dropdown>


                        </li>


                    </ul>
                </div>


            </nav>

            <aside className={isOpen ? "main-sidebar" : "main-sidebar mini-sidebar"}>
                <section className="sidebar">

                    <div className="col-md-12" style={{background : '#f4d474'}}>
                        <a href={`${match.path}/`} className={isOpen ? "sidebar_logo" : "sidebar_logo sidebar_logo_mini"}>
                            <img src={logo} alt="" />
                        </a>
                    </div>



                    <ul className="sidebar-menu">
                        <li>
                            <NavLink to={`${match.path}/`} activeClassName="activel" exact ><FontAwesomeIcon icon={['fas', 'home']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Résume
                                </span>

                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashb/profile" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'id-badge']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Profil
                                </span></NavLink>
                        </li>

                        {/* <li>
                            <NavLink to="/dashb/colis/mescolis/search" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'search']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Recherche
                                </span></NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/dashb/ticket/mes-ticket" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'ticket-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Ticket
                                </span></NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashb/colis/mescolis" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'dolly']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Mes colis
                                </span></NavLink>
                        </li>



                        {/* <Accordion defaultActiveKey="">
                            <Card className="card-menu">
                                <Card.Header className="card-menu-header">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="card-menu-btn">
                                        <FontAwesomeIcon icon={['fas', 'dolly']} />
                                        <span className={isOpen ? "" : "hidebal"}>
                                            &nbsp;&nbsp;Colis
                                        </span>
                                        <span className={isOpen ? "" : "hidebal"}>
                                            <FontAwesomeIcon className="menu_icn_arrow" icon={['fas', 'angle-down']} />
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="card-menu-container">
                                    <Card.Body className="no-padd">
                                        <ul>
                                            <li>
                                                <NavLink to="/dashb/colis/mescolis" activeClassName="activel" >
                                                    <FontAwesomeIcon icon={['fas', 'box-open']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Mes colis</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashb/colis/create" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'plus']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Nouveau bordereau</span>

                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion> */}



                        <li>
                            <NavLink to="/dashb/mu/mesmu" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Mes transferts
                                </span></NavLink>
                        </li>

                        {/* <Accordion defaultActiveKey="">
                            <Card className="card-menu">
                                <Card.Header className="card-menu-header">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="card-menu-btn">
                                        <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                        <span className={isOpen ? "" : "hidebal"}>
                                            &nbsp;&nbsp;Transfert
                                        </span>
                                        <span className={isOpen ? "" : "hidebal"}>
                                            <FontAwesomeIcon className="menu_icn_arrow" icon={['fas', 'angle-down']} />
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="card-menu-container">
                                    <Card.Body className="no-padd">
                                        <ul>
                                            <li>
                                                <NavLink to="/dashb/mu/mesmu" activeClassName="activel" >
                                                    <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Mes transferts</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashb/mu/create" activeClassName="activel">
                                                    <FontAwesomeIcon icon={['fas', 'plus']} />
                                                    <span className={isOpen ? "" : "hidebal"}> Nouveau transfert</span>

                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion> */}

                        <li>
                            <NavLink to="/dashb/bill/mesbill" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'dollar-sign']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Facture(s)
                                </span></NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/dashb/colis/history" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'history']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Historique colis
                                </span></NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashb/mu/history" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'history']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Historique transfert
                                </span></NavLink>
                        </li> */}




                    </ul>

                    <ul>
                        <li className="cont_log_out">
                            <button className="btn_log_out" onClick={logout}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Déconnecter
                                </span></button>
                        </li>
                    </ul>

                </section>
            </aside>


            <div className={isOpen ? "rw-cont bg-gay" : "rw-cont rw-cont-mini bg-gay"}>

             
            <Route
                    path={`${match.path}`}
                    render={({ match: { url } }) => (
                        <>
                            <Route path={`${url}/`} component={HomeExped} exact />
                            <Route path={`${url}/profile`} component={ClientProfile} exact />
                            <Route path={`${url}/colis/create`} component={CreateColis} exact />
                            <Route path={`${url}/colis/create/many`} component={CreateManyColis} exact />
                            <Route path={`${url}/colis/mescolis`} component={MesColis} exact />
                            <Route path={`${url}/colis/edit/:id`} component={EditColis} exact />
                            <Route path={`${url}/colis/print`} component={PrintColis} exact />
                            <Route path={`${url}/colis/mescolis/search`} component={SearchColis} exact />
                            <Route path={`${url}/mu/mesmu`} component={MesMu} exact />
                            <Route path={`${url}/mu/create`} component={CreateMu} exact />
                            <Route path={`${url}/mu/edit/:code`} component={EditMu} exact />
                            <Route path={`${url}/mu/print`} component={PrintMu} exact />

                            <Route path={`${url}/colis/history`} component={HistoryColis} exact />
                            <Route path={`${url}/mu/history`} component={HistoryMu} exact />
                            <Route path={`${url}/bill/mesbill`} component={MesFacture} exact />
                            <Route path={`${url}/bill/mesbill/print/:code`} component={BillPrintExped} exact />


                            <Route path={`${url}/ticket/mes-ticket`} component={TicketList} exact />
                           

                        </>
                    )}
                />
            
            </div>

        </>
    )

}

export default DashboardExped;