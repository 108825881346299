import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm, useWatch } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";
import UserStorage from "../../../services/UserStorage";

import { lst_gov } from "../../../utils/constants/Gov.enum";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { ColisTypeEnvoieOptions } from "../../../utils/constants/Colis.enum";

const steps = {
  idle: "idle",
  validate: "validate",
  ready: "ready",
  send: "send",
  loading: "loading",
  done: "done",
};

const PackageItem = (props) => {
  const data = props.colis;
  const isSel = false;
  //(props.selData.id === data.id) || null ;

  const [nom_cl, setNom_cl] = useState();
  const [prenom_cl, setPrenom_cl] = useState();
  const [tel_cl, setTel_cl] = useState();
  const [adresse_cl, setAdresse_cl] = useState();
  const [order, setOrder] = useState();
  const [note, setNote] = useState();
  const [user, setUser] = useState(UserStorage.getData());
  const [token, setToken] = useState(UserStorage.getToken());
  const [montant_ht, setMontant_ht] = useState(0.0);
  const [montant_ttc, setMontant_ttc] = useState(0.0);
  const [montant_ttc_frai, setMontant_ttc_frai] = useState(0.0);
  const [nbr, setNbr] = useState("");
  const [nom, setNom] = useState("");
  const [zone_dest, setZone_dest] = useState("none");

  const [lst_gov_option, set_lst_gov_option] = useState(lst_gov.map((el) => el.nom_gov));
  const [lst_deleg_option, set_lst_deleg_option] = useState([]);

  const [lst_location, set_lst_location] = useState([]);

  const searchLocation = (query) => {
    props
      ?.onSearchLocation(query)
      .then((res) => {
        console.log(res);
        set_lst_location(Array.isArray(res) ? res : []);
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = Yup.object().shape({
    nom_cl: Yup.string().required("Vous devez saisir le nom du client !"),
    tel_cl: Yup.string()
      .typeError("numéro tél non valide !")
      .min(8, "numéro tél non valide !")
      .max(8, "numéro tél non valide !")
      .required("Vous devez saisir le tél du client !")
      .test("tél valid", "tél non valid", (value) => (value + "").match(/^\d{1,9}?$/)),
    montant_ttc: Yup.string()
      .typeError("Montant ttc non valide !")
      .required("Vous devez saisir le montant ttc !")
      .test("Montant ttc valid", "Montant ttc non valid", (value) => (value + "").match(/^\d+(\.\d{1,3})?$/)),
    order_ref: Yup.string(),
    note: Yup.string(),
    marchandise: Yup.string().required("Vous devez saisir le marchandise du client !"),
    adresse: Yup.string().required("Vous devez saisir l'adresse du client !"),
    adresse_lieu: Yup.mixed().required("Vous devez saisir le lieu du client !"),
    adresse_gov: Yup.mixed().required("Vous devez choisir le gouvernorat !"),
    adresse_deleg: Yup.mixed().required("Vous devez choisir la délégation !"),
    adresse_zip: Yup.string().required("le code postal est obligatoire !"),
    type_envoi: Yup.string().required("le type d'envoi est obligatoire !"),
    nbr_paquets: Yup.number().required("le nbr paquets est obligatoire !"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue, control } = useForm(formOptions);
  const { errors } = formState;
  const sel_lieu = useWatch({ control: control, name: "adresse_lieu" });

  useEffect(() => {
    if (Array.isArray(sel_lieu)) {
      if (sel_lieu.length > 0) {
        setValue("adresse_gov", sel_lieu[0]._gov);
        setValue("adresse_deleg", sel_lieu[0]._del);
        setValue("adresse_zip", sel_lieu[0]._zip);
      }
    }
  }, [sel_lieu]);

  const onMontantChange = (data) => {
    console.log("montant change");
    console.log(data);
    setMontant_ttc(data);
    let rgxd = /^\d+(\.\d{1,3})?$/;
    if (rgxd.test(data)) {
      if (+data > 0) {
        let mnt = 0.0;
        let price_no_timbre = +data - +props.frais_timbre;
        //let prix_tva = (+price_no_timbre * +frai_tva)
        console.log("prix sans timbr");
        console.log(price_no_timbre);

        mnt = parseFloat(+price_no_timbre / (1 + +props.frais_tva)).toFixed(3);
        setMontant_ht(mnt);
        setMontant_ttc_frai(+data + +props.frais_liv);

        console.log("prix ht");
        console.log(mnt);
      } else {
        setMontant_ht(0.0);
        setMontant_ttc_frai(0.0);
      }
    }
  };

  const onSubmit = (data) => {
    console.log("succ");
    console.log(data);
    let arr = [...props.lst_colis];
    if (Array.isArray(sel_lieu)) {
      if (sel_lieu.length > 0) {
        data.adresse_lieu = `${sel_lieu[0]._loc} | ${sel_lieu[0]._del} | ${sel_lieu[0]._gov} | ${sel_lieu[0]._zip}`;
      }
    }

    let colis_data = {
      ...data,
      id_exped: user.id,
      frai_liv: props.frais_liv,
      tva: props.frais_tva,
      droit_timbre: props.frais_timbre,
      montant_ht: montant_ht,
      montant_icl_frai: data.montant_ttc,
      marchandise: data?.marchandise,
      montant_remboursement : montant_ttc,
    };
    arr[props.index] = { ...arr[props.index], step: steps.ready, result: true, data: colis_data };
    props.set_lst_colis(arr);
  };
  
  const onFail = (data) => {
    console.log("fail index : " + props.index);
    console.log(data);

    let arr = [...props.lst_colis];
    arr[props.index] = { ...arr[props.index], step: steps.ready, result: false };
    props.set_lst_colis(arr);
  };

  useEffect(() => {
    if (data) {
      if (data.step === steps.validate) {
        console.log("validate call index : " + props.index);
        handleSubmit(onSubmit, onFail)();
      }
    }
  }, [data]);

  return (
    <form className={isSel ? "row item_box item_box_active" : "row item_box"} id={`frm${props.index}`}>
      <div className="col-md-4"></div>
      <div className="col-md-4"></div>
      <div className="col-md-3"></div>
      <div className="col-md-1 no-padd text-end">
        {props.isLoading ? (
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <button type="button" className="btn btn-outline-danger round" onClick={() => props.onCancel(props.index)}>
            {" "}
            <FontAwesomeIcon icon={["fas", "times"]} />{" "}
          </button>
        )}
      </div>

      <div className="col-md-3 dvdr-r-gray">
        <b className="item_box_driver fnt-sm">
          <span className="fnt-w5 txt-blue">Client : </span> <br />
          <Form.Row>
            <Form.Group as={Col} className="no-marg">
              <Form.Label className="no-marg small-label">Nom Client</Form.Label>
              <Form.Control
                size="sm"
                name="nom_cl"
                onChange={(e) => setNom_cl(e.target.value)}
                {...register("nom_cl")}
                className={`form-control ${errors.nom_cl ? "is-invalid form-control-sm" : "form-control form-control-sm"}`}
              />
              <Form.Text className="text-muted txt-red">
                <p className="text-danger">{errors.nom_cl?.message}</p>
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="no-marg">
              <Form.Label className="no-marg small-label">Tél Client</Form.Label>
              <Form.Control
                size="sm"
                name="tel_cl"
                onChange={(e) => setTel_cl(e.target.value)}
                {...register("tel_cl")}
                className={`form-control ${errors.tel_cl ? "is-invalid form-control-sm" : "form-control form-control-sm"}`}
              />
              <Form.Text className="text-muted txt-red">
                <p className="text-danger">{errors.tel_cl?.message}</p>
              </Form.Text>
            </Form.Group>
          </Form.Row>
        </b>
      </div>

      <div className="col-md-3 dvdr-r-gray">
        <span className="fnt-w5 txt-blue small-label">Destination : </span> <br />
        <Form.Row>
          <Form.Group as={Col} className="no-marg">
            <Form.Label className="no-marg small-label">Adresse</Form.Label>
            <textarea
              rows="1"
              onChange={(e) => setAdresse_cl(e.target.value)}
              size="sm"
              name="adresse"
              {...register("adresse")}
              className={`form-control ${errors.adresse ? "is-invalid form-control-sm" : "form-control form-control-sm"}`}
            />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.adresse?.message}</p>
            </Form.Text>
          </Form.Group>
        </Form.Row>
        <div className="form-group col-md-12">
          <label className="fnt-w5">Lieu</label>
          <Controller
            control={control}
            name="adresse_lieu"
            render={({ field, fieldState }) => (
              <AsyncTypeahead
                {...field}
                id="typeahead-loc"
                isLoading={props?.loading}
                labelKey={(option) => `${option._loc} | ${option._del} | ${option._gov} | ${option._zip}`}
                clearButton
                className={fieldState.invalid ? "is-invalid" : ""}
                aria-describedby="typeaheadError"
                options={lst_location}
                onSearch={searchLocation}
                selected={field.value}
              />
            )}
          />
          <p className="text-danger">{errors.adresse_lieu?.message}</p>
        </div>
      </div>

      <div className="col-md-3  dvdr-r-gray">
        <span className="fnt-w5 txt-blue small-label">Prix: </span> <br />
        <Form.Row>
          <Form.Group as={Col} className="no-marg">
            <Form.Label className="no-marg small-label">Prix Total TTC (TND ) </Form.Label>
            <Form.Control
              type="text"
              size="sm"
              name="montant_ttc"
              {...register("montant_ttc")}
              onChange={(e) => onMontantChange(e.target.value)}
              className={`form-control ${errors.montant_ttc ? "is-invalid form-control-sm" : "form-control form-control-sm"}`}
            />
            <Form.Text className="text-muted txt-red">
              <p className="text-danger">{errors.montant_ttc?.message}</p>
            </Form.Text>
          </Form.Group>
        </Form.Row>

        <Form.Group as={Col} className="col-md-12">
          <Form.Label className="no-marg small-label">Type d'envoi</Form.Label>
          <Form.Control as="select" size="sm" {...register("type_envoi")} className={`form-control force-apparence ${errors.type_envoi ? "is-invalid" : "form-control"}`}>
            <option value="">Choisissez un type</option>
            {Array.isArray(ColisTypeEnvoieOptions) && ColisTypeEnvoieOptions.map((el) => <option value={el?.value}>{el?.label}</option>)}
          </Form.Control>
          <p className="text-danger">{errors.type_envoi?.message}</p>
        </Form.Group>
    
      </div>

      <div className="col-md-3  ">
        <span className="fnt-w5 txt-blue small-label">Article: </span> <br />
        <div className="col-md-12 form-group">
          <Form.Label className="no-marg small-label">Marchandise</Form.Label>
          <input type="text" {...register("marchandise")} className={`form-control ${errors.marchandise ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
          <p className="text-danger">{errors.marchandise?.message}</p>
        </div>
        

        <div className="col-md-12 form-group">
            <Form.Label className="no-marg small-label">Nombre de paquets</Form.Label>
            <input type="number" defaultValue={1} {...register("nbr_paquets")} className={`form-control ${errors.nbr_paquets ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
            <p className="text-danger">{errors.nbr_paquets?.message}</p>
          </div>

        <div className="form-group col-md-12">
          <label className="fnt-w5">
            Note <span className="txt-gray">(optionel )</span>
          </label>
          <input type="text" {...register("note")} className={`form-control ${errors.note ? "is-invalid form-control-sm" : "form-control form-control-sm"}`} />
          <p className="text-danger">{errors.note?.message}</p>
        </div>
      </div>
    </form>
  );
};
export default PackageItem;
