import React  from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {MuLabels, MuStatus, stat as Mv_state } from '../../utils/constants/Movable.enum';
import { getStringDate } from '../../utils/helpers/date.helper';

const MuTransfertAdmDetails = ({ onHide , isShow , data , onDelete , onEdit , onPrint , onCancelColis}) => {
    



    return (
        <div className={isShow ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" onClick={onHide}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>


            {data &&
                <div className="row no-marg bg-wt p-4">
                    

                    <div className="col-md-12 px-0">

                        <button className="btn btn-outline-success" onClick={onPrint}><FontAwesomeIcon icon={['fas', 'print']} /> Imprimer fiche</button>

                        {/* {data.etat === Mv_state.PENDING &&
                            <button className="btn btn-outline-primary ms-4" onClick={onEdit}><FontAwesomeIcon icon={['fas', 'edit']} /> Modifier Unité mobile</button>
                        } */}

                        {data.etat === Mv_state.PENDING &&
                            <button className="btn btn-outline-danger ms-4" onClick={() => onDelete(data)}><FontAwesomeIcon icon={['fas', 'trash-alt']} /> Annuler Unité de transfert</button>
                        }
                        <br /> <br />
                    </div>

                    <div className="col-md-4 text-center">
                        <b className='d-block fnt-w5 fnt-sm'>Unité de Transfert</b>
                        <b className='d-block '>{data?.ref}</b>
                    </div>
                    <div className="col-md-4 text-center">
                        <b className='d-block fnt-w5 fnt-sm'>Status</b> 
                        <span className={`badge text-capitalize ${data?.etat == MuStatus.PENDING ? "bg-warning"  : data?.etat == MuStatus.IN_PROGRESS ? "bg-success" : "bg-secondary"}`}>{MuLabels[data?.etat] ? MuLabels[data?.etat] : data?.etat}</span>
                    </div>

                    <div className="col-md-4 text-center">
                        <b className='d-block fnt-w5 fnt-sm'>Date</b>
                        <b className='d-block '>{getStringDate(data?.createdAt)}</b>
                    </div>

                    <div className="col-md-12 text-center my-1 dvdr-b-gray">
                    </div>
                    <div className="col-md-4 text-center">
                        <b className='d-block fnt-w5 fnt-sm'>Hub d'expédition</b>
                        <b className='d-block '>{data?.mu_hub_exped?.nom}</b>
                    </div>
                    <div className="col-md-4 text-center">
                        <b className='d-block fnt-w5 fnt-sm'>Hub de destination</b>
                        <b className='d-block '>{data?.mu_hub_dest?.nom}</b>
                    </div>
                    <div className="col-md-4 text-center">
                        <b className='d-block fnt-w5 fnt-sm'>Livreur</b>
                        <b className='d-block '>{data?.mvLiv ? `${data?.mvLiv?.nom} ${data?.mvLiv?.prenom}` : 'Aucun'}</b>
                    </div>

                    <div className="col-md-12 dvdr-t-gray pt-1 px-0">
                        {/* <h6 className="txt-blue">Liste des colis :</h6> */}

                        <div className="col-md-12 text-center">
                            <b className='d-block fnt-w5 fnt-sm'>Nbr Colis</b>
                            <b className='d-block '>{Array.isArray(data.colis) ? data?.colis?.length : 'N/A'}</b>
                        </div>

                        <table className="table table-striped table-bordered fnt-sm" width={"100%"}>
                            <thead>
                                <tr>
                                    <th scope="col">Colis</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Adresse</th>

                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(data.colis) && data.colis.map((item) =>
                                    <tr>
                                        <td className="p-1">{item.code}</td>
                                        <td className="p-1">{item.nom_cl} {item.prenom_cl}</td>
                                        <td className="p-1 text-center">{item.adresse}</td>

                                    </tr>
                                )}

                            </tbody>
                        </table>

                    </div>

                </div>
            }



        </div>
    )
}

export default MuTransfertAdmDetails;